



export const SAVEPATIENTTRIAGE_LIST_REQUEST = 'SAVEPATIENTTRIAGE_LIST_REQUEST';
export const SAVEPATIENTTRIAGE_LIST_SUCCESS = 'SAVEPATIENTTRIAGE_LIST_SUCCESS';
export const SAVEPATIENTTRIAGE_LIST_FAIL = 'SAVEPATIENTTRIAGE_LIST_FAIL';

export const GETPATIENTTRIAGE_LIST_REQUEST = 'GETPATIENTTRIAGE_LIST_REQUEST';
export const GETPATIENTTRIAGE_LIST_SUCCESS = 'GETPATIENTTRIAGE_LIST_SUCCESS';
export const GETPATIENTTRIAGE_LIST_FAIL = 'GETPATIENTTRIAGE_LIST_FAIL';

export const GETERPATIENTLOS_LIST_REQUEST = 'GETERPATIENTLOS_LIST_REQUEST';
export const GETERPATIENTLOS_LIST_SUCCESS = 'GETERPATIENTLOS_LIST_SUCCESS';
export const GETERPATIENTLOS_LIST_FAIL = 'GETERPATIENTLOS_LIST_FAIL';

export const GETERIssueData_REQUEST = 'GETERIssueData_REQUEST';
export const GETERIssueData_SUCCESS = 'GETERIssueData_SUCCESS';
export const GETERIssueData_FAIL = 'GETERIssueData_FAIL';

export const ERFaceSheet_LIST_REQUEST = 'ERFaceSheet_LIST_REQUEST';
export const ERFaceSheet_LIST_SUCCESS = 'ERFaceSheet_LIST_SUCCESS';
export const ERFaceSheet_LIST_FAIL = 'ERFaceSheet_LIST_FAIL';