export const Document_LIST_REQUEST = 'Document_LIST_REQUEST';
export const Document_LIST_SUCCESS = 'Document_LIST_SUCCESS';
export const Document_LIST_FAIL = 'Document_LIST_FAIL';

export const Document_Type_REQUEST = 'Document_Type_REQUEST';
export const Document_Type_SUCCESS = 'Document_Type_SUCCESS';
export const Document_Type_FAIL = 'Document_Type_FAIL';

export const Document_SAVE_REQUEST = 'Document_SAVE_REQUEST';
export const Document_SAVE_SUCCESS = 'Document_SAVE_SUCCESS';
export const Document_SAVE_FAIL = 'Document_SAVE_FAIL';
export const Document_SAVE_RESET = 'Document_SAVE_RESET';

export const Document_UHID_REQUEST = 'Document_UHID_REQUEST';
export const Document_UHID_SUCCESS = 'Document_UHID_SUCCESS';
export const Document_UHID_FAIL = 'Document_UHID_FAIL';

export const Document_Data_REQUEST = 'Document_Data_REQUEST';
export const Document_Data_SUCCESS = 'Document_Data_SUCCESS';
export const Document_Data_FAIL = 'Document_Data_FAIL';

export const Document_Data_Dtl_REQUEST = 'Document_Data_Dtl_REQUEST';
export const Document_Data_Dtl_SUCCESS = 'Document_Data_Dtl_SUCCESS';
export const Document_Data_Dtl_FAIL = 'Document_Data_Dtl_FAIL';

export const Document_Delete_REQUEST = 'Document_Delete_REQUEST';
export const Document_Delete_SUCCESS = 'Document_Delete_SUCCESS';
export const Document_Delete_FAIL = 'Document_Delete_FAIL';


export const DocumentDetails_Image_REQUEST = 'DocumentDetails_Image_REQUEST';
export const DocumentDetails_Image_SUCCESS = 'DocumentDetails_Image_SUCCESS';
export const DocumentDetails_Image_FAIL = 'DocumentDetails_Image_FAIL';

export const CustomFild_REVIEW_SAVE_REQUEST = 'CustomFild_REVIEW_SAVE_REQUEST';
export const CustomFild_REVIEW_SAVE_SUCCESS = 'CustomFild_REVIEW_SAVE_SUCCESS';
export const CustomFild_REVIEW_SAVE_FAIL = 'CustomFild_REVIEW_SAVE_FAIL';
export const CustomFild_REVIEW_SAVE_RESET = 'CustomFild_REVIEW_SAVE_RESET';

export const ClaimBook_Request = 'ClaimBook_Request';
export const ClaimBook_Success = 'ClaimBook_Success';
export const ClaimBook_Fail = 'ClaimBook_Fail';

export const Document_Data_DtlClm_REQUEST = 'Document_Data_DtlClm_REQUEST';
export const Document_Data_DtlClm_SUCCESS = 'Document_Data_DtlClm_SUCCESS';
export const Document_Data_DtlClm_FAIL = 'Document_Data_DtlClm_FAIL';