export const SHOW_REPORT_REQUEST = 'SHOW_REPORT_REQUEST';
export const SHOW_REPORT_SUCCESS = 'SHOW_REPORT_SUCCESS';
export const SHOW_REPORT_FAIL = 'SHOW_REPORT_FAIL';



export const CURRENT_ADMITTED_PATIENT_LIST_REQUEST = 'CURRENT_ADMITTED_PATIENT_LIST_REQUEST';
export const CURRENT_ADMITTED_PATIENT_LIST_SUCCESS = 'CURRENT_ADMITTED_PATIENT_LIST_SUCCESS';
export const CURRENT_ADMITTED_PATIENT_LIST_FAIL = 'CURRENT_ADMITTED_PATIENT_LIST_FAIL';

export const DISCHARGE_PATIENT_LIST_REQUEST = 'DISCHARGE_PATIENT_LIST_REQUEST';
export const DISCHARGE_PATIENT_LIST_SUCCESS = 'DISCHARGE_PATIENT_LIST_SUCCESS';
export const DISCHARGE_PATIENT_LIST_FAIL = 'DISCHARGE_PATIENT_LIST_FAIL';

export const BED_TRANSFER_LIST_REQUEST = 'BED_TRANSFER_LIST_REQUEST';
export const BED_TRANSFER_LIST_SUCCESS = 'BED_TRANSFER_LIST_SUCCESS';
export const BED_TRANSFER_LIST_FAIL = 'BED_TRANSFER_LIST_FAIL';

export const PRACTITIONER_TRANSFER_LIST_REQUEST = 'PRACTITIONER_TRANSFER_LIST_REQUEST';
export const PRACTITIONER_TRANSFER_LIST_SUCCESS = 'PRACTITIONER_TRANSFER_LIST_SUCCESS';
export const PRACTITIONER_TRANSFER_LIST_FAIL = 'PRACTITIONER_TRANSFER_LIST_FAIL';

export const RE_ADMISSION_LIST_REQUEST = 'RE_ADMISSION_LIST_REQUEST';
export const RE_ADMISSION_LIST_SUCCESS = 'RE_ADMISSION_LIST_SUCCESS';
export const RE_ADMISSION_LIST_FAIL = 'RE_ADMISSION_LIST_FAIL';


export const SAVE_FORM_SETTINGS = 'RE_ADMISSION_LIST_REQUEST';
export const SAVE_FORM_SETTINGS_SUCCESS = 'RE_ADMISSION_LIST_SUCCESS';
export const SAVE_FORM_SETTINGS_FAIL = 'RE_ADMISSION_LIST_FAIL';

export const DISCLAIMER_LIST_REQUEST = 'DISCLAIMER_LIST_REQUEST';
export const DISCLAIMER_LIST_SUCCESS = 'DISCLAIMER_LIST_SUCCESS';
export const DISCLAIMER_LIST_FAIL = 'DISCLAIMER_LIST_FAIL';

export const SAVE_DISCLAIMER_REQUEST = 'SAVE_DISCLAIMER_REQUEST';
export const SAVE_DISCLAIMER_SUCCESS = 'SAVE_DISCLAIMER_SUCCESS';
export const SAVE_DISCLAIMER_FAIL = 'SAVE_DISCLAIMER_FAIL';

export const GET_DAY_LIST_REQUEST = 'GET_DAY_LIST_REQUEST';
export const GET_DAY_LIST_SUCCESS = 'GET_DAY_LIST_SUCCESS';
export const GET_DAY_LIST_FAIL = 'GET_DAY_LIST_FAIL';

export const GET_FREQUENCY_LIST_REQUEST = 'GET_FREQUENCY_LIST_REQUEST';
export const GET_FREQUENCY_LIST_SUCCESS = 'GET_FREQUENCY_LIST_SUCCESS';
export const GET_FREQUENCY_LIST_FAIL = 'GET_FREQUENCY_LIST_FAIL';

export const GET_REPEAT_LIST_REQUEST = 'GET_REPEAT_LIST_REQUEST';
export const GET_REPEAT_LIST_SUCCESS = 'GET_REPEAT_LIST_SUCCESS';
export const GET_REPEAT_LIST_FAIL = 'GET_REPEAT_LIST_FAIL';

export const SCHEDULER_SAVE_REQUEST = 'SCHEDULER_SAVE_REQUEST';
export const SCHEDULER_SAVE_SUCCESS = 'SCHEDULER_SAVE_SUCCESS';
export const SCHEDULER_SAVE_FAIL = 'SCHEDULER_SAVE_FAIL';

export const GET_SCHEDULER_LIST_REQUEST = 'GET_SCHEDULER_LIST_REQUEST';
export const GET_SCHEDULER_LIST_SUCCESS = 'GET_SCHEDULER_LIST_SUCCESS';
export const GET_SCHEDULER_LIST_FAIL = 'GET_SCHEDULER_LIST_FAIL';

export const SCHEDULER_DELETE_REQUEST = 'SCHEDULER_DELETE_REQUEST';
export const SCHEDULER_DELETE_SUCCESS = 'SCHEDULER_DELETE_SUCCESS';
export const SCHEDULER_DELETE_FAIL = 'SCHEDULER_DELETE_FAIL';


export const AuditStatus_LIST_REQUEST ="AuditStatus_LIST_REQUEST";
export const AuditStatus_LIST_SUCCESS = "AuditStatus_LIST_SUCCESS";
export const AuditStatus_LIST_FAIL = "AuditStatus_LIST_FAIL";


export const Resend_SMS_REQUEST = "Resend_SMS_REQUEST";
export const Resend_SMS_SUCCESS = "Resend_SMS_SUCCESS";
export const Resend_SMS_FAIL = "Resend_SMS_FAIL";


export const GetAllEmployee_List_REQUEST = 'GetAllEmployee_List_REQUEST';
export const GetAllEmployee_List_SUCCESS = 'GetAllEmployee_List_SUCCESS';
export const GetAllEmployee_List_FAIL = 'GetAllEmployee_List_FAIL';


export const GetOTPDetails_List_REQUEST = 'GetOTPDetails_List_REQUEST';
export const GetOTPDetails_List_SUCCESS = 'GetOTPDetails_List_SUCCESS';
export const GetOTPDetails_List_FAIL = 'GetOTPDetails_List_FAIL';


export const User_Cash_Collection_REQUEST = 'User_Cash_Collection_REQUEST';
export const User_Cash_Collection_SUCCESS = 'User_Cash_Collection_SUCCESS';
export const User_Cash_Collection_FAIL = 'User_Cash_Collection_FAIL';

export const GetSendEmail_Audit_Collection_LIST_REQUEST = 'GetSendEmail_Audit_Collection_LIST_REQUEST';
export const GetSendEmail_Audit_Collection_LIST_SUCCESS = 'GetSendEmail_Audit_Collection_LIST_SUCCESS';
export const GetSendEmail_Audit_Collection_LIST_FAIL = 'GetSendEmail_Audit_Collection_LIST_FAIL';