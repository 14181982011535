
import * as AMCnst from '../Constants/WorkListConstants';

const intialstate = {};
// ujjwal work start
function GetPatientWorkListReducer(state = {
    PatientWorkListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientWorkList_SEARCH_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PatientWorkList_SEARCH_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.PatientWorkList_SEARCH_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                PatientWorkListData: action.payload
            }
        }
        default: return state;
    }
}
function GetPatientWLDocumentReducer(state = {
    WLDocumentData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientWorkList_Document_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PatientWorkList_Document_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.PatientWorkList_Document_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                WLDocumentData: action.payload
            }
        }
        default: return state;
    }
}
function GetPatientWLDocumentDetailReducer(state = {
    WLDocumentDetailData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientWorkList_DocumentDetails_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PatientWorkList_DocumentDetails_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.PatientWorkList_DocumentDetails_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                WLDocumentDetailData: action.payload
            }
        }
        default: return state;
    }
}

function GetPatientWorkNotificationListReducer(state = {
    PatientWorkListNotificationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientWorkListNotification_SEARCH_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PatientWorkListNotification_SEARCH_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.PatientWorkListNotification_SEARCH_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                PatientWorkListNotificationData: action.payload
            }
        }
        default: return state;
    }
}
function GetPatientWorkCompletedListReducer(state = {
    PatientWorkListCompletedData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientWorkListCompleted_SEARCH_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PatientWorkListCompleted_SEARCH_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.PatientWorkListCompleted_SEARCH_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                PatientWorkListCompletedData: action.payload
            }
        }
        default: return state;
    }
}

function GetPatientWorkListDetailReducer(state = {
    PatientWorkListDetailData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientWorkListDetail_SEARCH_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PatientWorkListDetail_SEARCH_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.PatientWorkListDetail_SEARCH_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                PatientWorkListDetailData: action.payload
            }
        }
        default: return state;
    }

}

function PatientWorkListCancelReducer(state = {
    PatientWorkListCancelData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientWorkList_CANCEL_REQUEST: { return { ...state, fetching: true, fetched: false, error: null} }
        case AMCnst.PatientWorkList_CANCEL_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.PatientWorkList_CANCEL_SUCCESS: {return {...state, fetching: false, fetched: true, PatientWorkListCancelData: action.payload }}
        default: return state;
    }

}
function AppSaveProcessOwnerReducer(state = {
    ProcessOwnerDataSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ProcessOwner_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ProcessOwner_SAVE_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.ProcessOwner_SAVE_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                ProcessOwnerDataSave: action.payload
            }
        }
        default: return state;
    }

}
function AppWFTATReducer(state = {
    WFTATData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WFTAT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WFTAT_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.WFTAT_LIST_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                WFTATData: action.payload
            }
        }
        default: return state;
    }

}
function AppSaveWFTATReducer(state = {
    WFTATDataSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WFTAT_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WFTAT_SAVE_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.WFTAT_SAVE_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                WFTATDataSave: action.payload
            }
        }
        default: return state;
    }

}
function AppWFNotificationReducer(state = {
    WFNotificationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WFNotification_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WFNotification_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.WFNotification_LIST_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                WFNotificationData: action.payload
            }
        }
        default: return state;
    }

}
function AppSaveWFNotificationReducer(state = {
    WFNotificationDataSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WFNotification_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WFNotification_SAVE_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.WFNotification_SAVE_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                WFNotificationDataSave: action.payload
            }
        }
        default: return state;
    }

}
function AppWFFallbackReducer(state = {
    WFFallbackData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WFFallback_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WFFallback_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.WFFallback_LIST_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                WFFallbackData: action.payload
            }
        }
        default: return state;
    }

}
function AppSaveWFFallbackReducer(state = {
    WFFallbackDataSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WFFallback_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WFFallback_SAVE_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.WFFallback_SAVE_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                WFFallbackDataSave: action.payload
            }
        }
        default: return state;
    }

}
function AppProcessOwnerReducer(state = {
    ProcessOwnerData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ProcessOwner_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ProcessOwner_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.ProcessOwner_LIST_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                ProcessOwnerData: action.payload
            }
        }
        default: return state;
    }

}
function AppSaveWorkflowMainReducer(state = {
    WorkflowMainDataSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WorkflowMain_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WorkflowMain_SAVE_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.WorkflowMain_SAVE_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                WorkflowMainDataSave: action.payload
            }
        }
        default: return state;
    }

}
function AppSaveWorkflowFacilityReducer(state = {
    WorkflowFacilityDataSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WorkflowFacility_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WorkflowFacility_SAVE_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.WorkflowFacility_SAVE_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                WorkflowFacilityDataSave: action.payload
            }
        }
        default: return state;
    }

}
function AppSaveWorkflowStepsReducer(state = {
    WorkflowStepsDataSave: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WorkflowSteps_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WorkflowSteps_SAVE_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.WorkflowSteps_SAVE_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                WorkflowStepsDataSave: action.payload
            }
        }
        default: return state;
    }

}
function AppWorkflowMainReducer(state = {
    WorkflowMainData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WorkflowMain_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WorkflowMain_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.WorkflowMain_LIST_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                WorkflowMainData: action.payload
            }
        }
        default: return state;
    }

}
function AppWorkflowFacilityReducer(state = {
    WorkflowFacilityData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WorkflowFacility_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WorkflowFacility_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.WorkflowFacility_LIST_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                WorkflowFacilityData: action.payload
            }
        }
        default: return state;
    }
}
function AppWorkflowStepsReducer(state = {
    WorkflowStepsData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WorkflowSteps_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WorkflowSteps_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.WorkflowSteps_LIST_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                WorkflowStepsData: action.payload
            }
        }
        default: return state;
    }
}
function AppWorkflowDetailMasterReducer(state = {
    WorkflowDetailMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WorkflowDetailMaster_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WorkflowDetailMaster_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.WorkflowDetailMaster_LIST_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                WorkflowDetailMasterData: action.payload
            }
        }
        default: return state;
    }
}

function AppGetPageCTAReducer(state = {
    PageCTAData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PageCTA_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PageCTA_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.PageCTA_LIST_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                PageCTAData: action.payload
            }
        }
        default: return state;
    }
}
function AppGetToPageCTAReducer(state = {
    ToPageCTAData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ToPageCTA_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ToPageCTA_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.ToPageCTA_LIST_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                ToPageCTAData: action.payload
            }
        }
        default: return state;
    }
}
function AppGetWorkflowSCMReducer(state = {
    WorkflowSCMData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WorkflowSCM_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WorkflowSCM_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.WorkflowSCM_LIST_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                WorkflowSCMData: action.payload
            }
        }
        default: return state;
    }
}
function AppWFDetailMasterReducer(state = {
    WFDetailMasterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WFDetailMaster_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WFDetailMaster_SAVE_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.WFDetailMaster_SAVE_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                WFDetailMasterData: action.payload
            }
        }
        default: return state;
    }
}
function AppWFSTOPReducer(state = {
    WFSTOPData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.WFSTOP_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.WFSTOP_SAVE_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true } }
        case AMCnst.WFSTOP_SAVE_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                WFSTOPData: action.payload
            }
        }
        default: return state;
    }
}
// ujjwal work end


export {
    // ujjwal work start
    GetPatientWorkListReducer, GetPatientWorkListDetailReducer, PatientWorkListCancelReducer,
    AppSaveProcessOwnerReducer, AppProcessOwnerReducer, AppSaveWorkflowMainReducer, AppWorkflowMainReducer,
    AppWorkflowFacilityReducer, AppSaveWorkflowFacilityReducer, AppSaveWorkflowStepsReducer, AppWorkflowStepsReducer,
    AppGetPageCTAReducer, AppGetWorkflowSCMReducer, AppWorkflowDetailMasterReducer, AppWFDetailMasterReducer, AppGetToPageCTAReducer,
    AppSaveWFNotificationReducer, AppSaveWFTATReducer, AppWFNotificationReducer, AppWFTATReducer, GetPatientWorkNotificationListReducer,
    GetPatientWorkCompletedListReducer, GetPatientWLDocumentReducer, GetPatientWLDocumentDetailReducer, AppWFSTOPReducer,
    AppWFFallbackReducer, AppSaveWFFallbackReducer
    // ujjwal work end
};
