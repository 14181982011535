
import * as AMCnst from '../Constants/ADTConstants';

const intialstate = {};



// ujjwal work start
function CostEstimationNoReducer(state = {
    CostEstimationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CostEstimation_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CostEstimation_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CostEstimation_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CostEstimationData: action.payload
            }
        }
        default: return state;
    }

}
function PreAuthNoReducer(state = {
    PreAuthData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PreAuth_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PreAuth_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PreAuth_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PreAuthData: action.payload
            }
        }
        default: return state;
    }

}
function PreAuthTPACheckListReducer(state = {
    PreAuthTPACheckList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PreAuth_CheckList_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PreAuth_CheckList_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PreAuth_CheckList_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PreAuthTPACheckList: action.payload
            }
        }
        default: return state;
    }

}
function saveAdmissionReducer(state = {
    AdmissionData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Admission_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Admission_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Admission_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AdmissionData: action.payload
            }
        }
        default: return state;
    }

}
function savePreAuthReducer(state = {
    savePreAuthorization: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PreAuth_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PreAuth_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PreAuth_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                savePreAuthorization: action.payload
            }
        }
        default: return state;
    }

}
function cancelPreAuthReducer(state = {
    cancelPreAuthorization: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PreAuth_CANCEL_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PreAuth_CANCEL_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PreAuth_CANCEL_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                cancelPreAuthorization: action.payload
            }
        }
        default: return state;
    }

}
function savePreAuthQueryReducer(state = {
    savePreAuthQuery: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PreAuthQuery_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PreAuthQuery_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PreAuthQuery_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                savePreAuthQuery: action.payload
            }
        }
        default: return state;
    }

}
function savePreAuthQueryCommunicationReducer(state = {
    savePreAuthQueryCommunication: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PreAuthCommunication_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PreAuthCommunication_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PreAuthCommunication_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                savePreAuthQueryCommunication: action.payload
            }
        }
        default: return state;
    }

}
// ujjwal work end
// rajesh work start
function AppAdmissionNoReducer(state = {
    AdmissionNoData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AdmissionRequest_GET_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.AdmissionRequest_GET_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.AdmissionRequest_GET_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AdmissionNoData: action.payload
            }
        }
        default: return state;
    }

}

function AppAdmissionRequestReducer(state = {
    AdmRequestData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AdmissionRequest_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.AdmissionRequest_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload, fetched: true } }
        case AMCnst.AdmissionRequest_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AdmRequestData: action.payload
            }
        }
        default: return state;
    }

}
function AppAdmissionRequestListReducer(state = {
    AdmRequestData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AdmissionRequest_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.AdmissionRequest_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.AdmissionRequest_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AdmRequestData: action.payload
            }
        }
        default: return state;
    }

}
function saveAdmissionRequestReducer(state = {
    AdmissionRequestData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AdmissionRequest_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.AdmissionRequest_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.AdmissionRequest_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AdmissionRequestData: action.payload
            }
        }
        default: return state;
    }

}
function EncounterReducer(state = {
    EncounterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Encounter_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Encounter_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Encounter_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EncounterData: action.payload
            }
        }
        default: return state;
    }

}
function ERVisitDetailsReducer(state = {
    ERVisitDetailsData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ERVisitDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ERVisitDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ERVisitDetails_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ERVisitDetailsData: action.payload
            }
        }
        default: return state;
    }

}
function ERPatientDetailsReducer(state = {
    ERPatientDetailsData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ERPatientDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ERPatientDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ERPatientDetails_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ERPatientDetailsData: action.payload
            }
        }
        default: return state;
    }
}
function ERAllPatientDetailsReducer(state = {
    ERAllPatientDetailsData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ERAllPatientDetails_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ERAllPatientDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ERAllPatientDetails_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ERAllPatientDetailsData: action.payload
            }
        }
        default: return state;
    }
}

function AppERBedOccupiReducer(state = {
    ERBedOccupiData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ERBedOCCUP_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ERBedOCCUP_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ERBedOCCUP_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ERBedOccupiData: action.payload
            }
        }
        default: return state;
    }

}
function saveBedBookReducer(state = {
    BedBookingData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.BedBook_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.BedBook_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.BedBook_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                BedBookingData: action.payload
            }
        }
        default: return state;
    }

}
function AppBedBookingReducer(state = {
    ADTBedBookData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.BedBook_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.BedBook_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.BedBook_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ADTBedBookData: action.payload
            }
        }
        default: return state;
    }

}
function AppAdmissionAdmittedPatientReducer(state = {
    AdmPatientData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Admission_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Admission_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Admission_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AdmPatientData: action.payload
            }
        }
        default: return state;
    }

}
function AppTimeLineViewReducer(state = {  //fetchPatientTransferbyEncounterId
    TimeLineViewData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AdmissionRequest_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.AdmissionRequest_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.AdmissionRequest_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                TimeLineViewData: action.payload
            }
        }
        default: return state;
    }

}

function AppPractitionerTransferbyEncounterIdReducer(state = {
    PractitionerTransferbyEncounterIdData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientTransfer_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PatientTransfer_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PatientTransfer_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PractitionerTransferbyEncounterIdData: action.payload
            }
        }
        default: return state;
    }

}
function savePatientTransferReducer(state = {
    PatientTransferData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientTransfer_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PatientTransfer_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PatientTransfer_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PatientTransferData: action.payload
            }
        }
        default: return state;
    }

}
function AppPatientTransferReducer(state = {
    ADTPatientTransferData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientTransfer_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PatientTransfer_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PatientTransfer_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ADTPatientTransferData: action.payload
            }
        }
        default: return state;
    }

}
function savePractitionerTransferReducer(state = {
    PractitionerTransferData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PractitionerTransfer_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PractitionerTransfer_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PractitionerTransfer_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PractitionerTransferData: action.payload
            }
        }
        default: return state;
    }

}
function AppPractitionerTransferReducer(state = {
    ADTPractitionerTransferData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PractitionerTransfer_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PractitionerTransfer_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PractitionerTransfer_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ADTPractitionerTransferData: action.payload
            }
        }
        default: return state;
    }

}
// rajesh work end

function AppAdmissionReducer(state = {
    AppAdmissionReducer: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Get_Admission_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Get_Admission_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Get_Admission_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AppAdmissionReducer: action.payload
            }
        }
        default: return state;
    }

}
function AppPatientTransferbyEncounterIdReducer(state = {
    PatientTransferbyEncounterIdData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientTransfer_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PatientTransfer_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PatientTransfer_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PatientTransferbyEncounterIdData: action.payload
            }
        }
        default: return state;
    }

}

function savePatientTransferRequestReducer(state = {
    PatientTransferRequestData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientTransferRequest_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PatientTransferRequest_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PatientTransferRequest_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PatientTransferRequestData: action.payload
            }
        }
        default: return state;
    }

}
function AppPatientTransferRequestReducer(state = {
    ADTPatientTransferRequestData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientTransferRequest_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PatientTransferRequest_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PatientTransferRequest_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ADTPatientTransferData: action.payload
            }
        }
        default: return state;
    }

}
function AppPatientTransferRequestbyEncounterIdReducer(state = {
    PatientTransferRequestbyEncounterIdData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientTransferRequest_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PatientTransferRequest_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PatientTransferRequest_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PatientTransferRequestbyEncounterIdData: action.payload
            }
        }
        default: return state;
    }

}


function AppSaveRetainBedReducer(state = {
    RetainBedSaveStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RetainBed_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RetainBed_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RetainBed_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                RetainBedSaveStatus: action.payload
            }
        }
        default: return state;
    }

}
function AppSaveBedTimelineReducer(state = {
    BedTimelineSaveStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.BedTimeline_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.BedTimeline_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.BedTimeline_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                BedTimelineSaveStatus: action.payload
            }
        }
        default: return state;
    }

}

function saveDiagnosisData(state = {
    saveDiagnosisData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.saveDiagnosisData_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.saveDiagnosisData_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.saveDiagnosisData_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveDiagnosisData: action.payload
            }
        }
        default: return state;
    }
}


function deleteDiagnosisData(state = {
    deleteDiagnosisData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.deleteDiagnosis_Data_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.deleteDiagnosis_Data_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.deleteDiagnosis_Data_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                deleteDiagnosisData: action.payload
            }
        }
        default: return state;
    }

}


function AppSaveEstTemplateMasterReducer(state = {
    EstTemplateMasterSaveStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EstConfig_Template_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EstConfig_Template_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EstConfig_Template_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EstTemplateMasterSaveStatus: action.payload
            }
        }
        default: return state;
    }

}

function AppGetEstTemplateListReducer(state = {
    EstTemplateListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ESTIMATE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ESTIMATE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ESTIMATE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EstTemplateListData: action.payload
            }
        }
        default: return state;
    }
}

function AppGetEstTemplateLDetailsReducer(state = {
    EstTemplateLDetailsData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ESTIMATE_TEMPALTEDETAILS_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ESTIMATE_TEMPALTEDETAILS_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ESTIMATE_TEMPALTEDETAILS_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EstTemplateLDetailsData: action.payload
            }
        }
        default: return state;
    }
}

function AppSaveEstBILLHeadReducer(state = {
    EstBILLHeadSaveStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EstConfig_BILLHEAD_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EstConfig_BILLHEAD_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EstConfig_BILLHEAD_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EstBILLHeadSaveStatus: action.payload
            }
        }
        default: return state;
    }

}
function AppGetEstTemplateBillHeadDetailsReducer(state = {
    EstTemplateBillHeadDetailsData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ESTIMATE_BILLHEADDETAILS_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ESTIMATE_BILLHEADDETAILS_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ESTIMATE_BILLHEADDETAILS_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EstTemplateBillHeadDetailsData: action.payload
            }
        }
        default: return state;
    }
}
function AppGetEstTemplateBillHeadDetailsBedCatgWiseReducer(state = {
    EstTemplateBillHeadDetailsBetCatgWiseData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ESTIMATE_BILLHEADDETAILS_BedCatgWise_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ESTIMATE_BILLHEADDETAILS_BedCatgWise_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ESTIMATE_BILLHEADDETAILS_BedCatgWise_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EstTemplateBillHeadDetailsBetCatgWiseData: action.payload
            }
        }
        default: return state;
    }
}

function AppGetEstTemplateBillHeadDetailsServiceWiseReducer(state = {
    EstTemplateBillHeadDetailsServiceWiseData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ESTIMATE_BILLHEADDETAILS_ServiceWise_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ESTIMATE_BILLHEADDETAILS_ServiceWise_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ESTIMATE_BILLHEADDETAILS_ServiceWise_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EstTemplateBillHeadDetailsServiceWiseData: action.payload
            }
        }
        default: return state;
    }
}

function AppSaveEstimateReducer(state = {
    SaveEstimateStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ESTIMATE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ESTIMATE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ESTIMATE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveEstimateStatus: action.payload
            }
        }
        default: return state;
    }

}
function AppCancelEstimateReducer(state = {
    CancelEstimateStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ESTIMATE_CANCEL_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ESTIMATE_CANCEL_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ESTIMATE_CANCEL_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CancelEstimateStatus: action.payload
            }
        }
        default: return state;
    }

}
function AppGetGetEstimateDetailsReducer(state = {
    GetEstimateDetailsData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ESTIMATE_GETDETAILS_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ESTIMATE_GETDETAILS_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ESTIMATE_GETDETAILS_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetEstimateDetailsData: action.payload
            }
        }
        default: return state;
    }
}
function AppGetEstimatePayorDetailsReducer(state = {
    GetEstimatePayorDetailsData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ESTIMATE_GETPAYORDETAILS_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ESTIMATE_GETPAYORDETAILS_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ESTIMATE_GETPAYORDETAILS_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetEstimatePayorDetailsData: action.payload
            }
        }
        default: return state;
    }
}
//////////////// Start Amarjeet /////////////////////////////////////////////////////////////////////////////////////////////

function AppDischargePatientDataReducer(state = {
    AdmPatientData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DischargePatient_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DischargePatient_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DischargePatient_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DischargePatientData: action.payload
            }
        }
        default: return state;
    }

}

//////////////// End Amarjeet /////////////////////////////////////////////////////////////////////////////////////////////

function AppPatientDetailsIPReducer(state = {
    PatientDetailIPData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Admission_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Admission_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Admission_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PatientDetailIPData: action.payload
            }
        }
        default: return state;
    }

}
function AppPatientDetailsIPReducerRX(state = {
    PatientDetailIPDataRX: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AdmissionRX_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.AdmissionRX_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.AdmissionRX_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PatientDetailIPDataRX: action.payload
            }
        }
        default: return state;
    }

}
function saveVisitorPassReducer(state = {
    saveVisitorPassData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.VisitorPass_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.VisitorPass_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.VisitorPass_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveVisitorPassData: action.payload
            }
        }
        default: return state;
    }

}
function AppVisitorPassReducer(state = {
    VisitorPassData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.VisitorPass_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.VisitorPass_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload, fetched: true } }
        case AMCnst.VisitorPass_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                VisitorPassData: action.payload
            }
        }
        default: return state;
    }
}
function AppVisitorPassUploadedFileReducer(state = {
    VisitorPassUploadFileData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FileUpload_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FileUpload_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FileUpload_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                VisitorPassUploadFileData: action.payload
            }
        }
        default: return state;
    }
}
function AppFaceSheetReducer(state = {
    FaceSheetData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.FaceSheet_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.FaceSheet_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.FaceSheet_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FaceSheetData: action.payload
            }
        }
        default: return state;
    }
}
function AppFacilityMasterReducer(state = {
    FacilityData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Facility_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Facility_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Facility_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                FacilityData: action.payload
            }
        }
        default: return state;
    }
}
function AppPrintVPReducer(state = {
    VPData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PrintVP_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PrintVP_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PrintVP_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                VPData: action.payload
            }
        }
        default: return state;
    }
}
function CancelERAdmissionReducer(state = {
    CancelEREncounterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EREncounter_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EREncounter_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EREncounter_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CancelEREncounterData: action.payload
            }
        }
        default: return state;
    }

}
function AppADTRemarksDetailReducer(state = {
    ADTRemarksData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RemarksDetail_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RemarksDetail_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RemarksDetail_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ADTRemarksData: action.payload
            }
        }
        default: return state;
    }
}
function AppADTCheckListReducer(state = {
    ADTCheckListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CHECKLIST_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CHECKLIST_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CHECKLIST_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ADTCheckListData: action.payload
            }
        }
        default: return state;
    }
}
function AdmMarkArrivalReducer(state = {
    AdmMarkArrivalData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Get_Admission_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Get_Admission_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Get_Admission_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AdmMarkArrivalData: action.payload
            }
        }
        default: return state;
    }

}
function EncounterADTReducer(state = {
    EncounterADTData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EncounterADT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EncounterADT_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EncounterADT_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EncounterADTData: action.payload
            }
        }
        default: return state;
    }

}
function ERAllPatientlistReducer(state = {
    ERAllPatientlistData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ERAllPatientlist_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ERAllPatientlist_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ERAllPatientlist_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ERAllPatientlistData: action.payload
            }
        }
        default: return state;
    }
}
function AppAdmissionRequestForEstimateReducer(state = {
    AdmRequestForEstimateData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AdmissionRequestForEstimate_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.AdmissionRequestForEstimate_LIST_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload, fetched: true } }
        case AMCnst.AdmissionRequestForEstimate_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AdmRequestForEstimateData: action.payload
            }
        }
        default: return state;
    }

}


export {
    // rajesh work start

    AppAdmissionRequestReducer, saveAdmissionRequestReducer, EncounterReducer, AppAdmissionNoReducer, saveBedBookReducer, AppBedBookingReducer
    , AppAdmissionAdmittedPatientReducer, AppTimeLineViewReducer, savePatientTransferReducer, AppPatientTransferReducer, savePractitionerTransferReducer
    , AppPractitionerTransferReducer, AppPatientTransferbyEncounterIdReducer, AppPractitionerTransferbyEncounterIdReducer
    , savePatientTransferRequestReducer, AppPatientTransferRequestReducer, AppPatientTransferRequestbyEncounterIdReducer, AppSaveBedTimelineReducer
    // rajesh work end
    // ujjwal work start
    , CostEstimationNoReducer, PreAuthNoReducer, saveAdmissionReducer, AppAdmissionReducer
    // ujjwal work end
    , AppSaveRetainBedReducer
    // Amarjeet work start
    , AppDischargePatientDataReducer
    // Amarjeet work end
    , AppPatientDetailsIPReducer,
    AppPatientDetailsIPReducerRX,
    //Chandan work start
    AppSaveEstTemplateMasterReducer, AppGetEstTemplateListReducer, AppSaveEstBILLHeadReducer, AppGetEstTemplateLDetailsReducer
    , AppGetEstTemplateBillHeadDetailsReducer, AppGetEstTemplateBillHeadDetailsBedCatgWiseReducer, AppGetEstTemplateBillHeadDetailsServiceWiseReducer
    , AppSaveEstimateReducer, AppGetGetEstimateDetailsReducer, AppCancelEstimateReducer
    //End work end
    , saveVisitorPassReducer, AppVisitorPassReducer, AppVisitorPassUploadedFileReducer
    , AppFaceSheetReducer, AppFacilityMasterReducer, AppPrintVPReducer, ERVisitDetailsReducer, AppERBedOccupiReducer, ERAllPatientDetailsReducer, ERPatientDetailsReducer, savePreAuthReducer, cancelPreAuthReducer, savePreAuthQueryReducer, savePreAuthQueryCommunicationReducer, PreAuthTPACheckListReducer
    , CancelERAdmissionReducer, AppAdmissionRequestListReducer
    , AppADTRemarksDetailReducer
    , AppADTCheckListReducer, AdmMarkArrivalReducer, AppGetEstimatePayorDetailsReducer, EncounterADTReducer
    , ERAllPatientlistReducer, AppAdmissionRequestForEstimateReducer, saveDiagnosisData, deleteDiagnosisData
};