import React, { Component } from 'react';
import { CoreMasterAPI, CoreBaseAPI } from 'GlobalConfig';
import axios from 'axios';
import * as GlobalConfig from 'GlobalConfig';
import { Modal } from 'react-bootstrap';
// import ErrorStackParser from "error-stack-parser";
import StackTrace from "stacktrace-js"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
    this.HideModal = this.HideModal.bind(this);
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }


  HideModal() {
    console.log("modal hide clicked");
    this.setState({ hasError: false })
  }


  componentDidMount() {
    // window.onerror = (msg, url, line, column, error) => {
    //   console.log(msg);
    //   this.setState({ hasError: true });
    //   ErrorBoundary.saveError(msg, "onerror", line, column, error);
    // };


    window.addEventListener('error', (event) => {
      if (event.error.hasBeenCaught !== undefined) {
        return false
      }
      event.error.hasBeenCaught = true
      // ... your useful code here
      this.setState({ hasError: true });
      ErrorBoundary.saveErrorNew(event.error.message, "addEventListener", event.error.line, event.error.column, event.error);
    })


    window.onunhandledrejection = (event) => {
      console.log(event);
      this.setState({ hasError: true });
      ErrorBoundary.saveErrorNew(event.reason.message, "onunhandledrejection", event.reason.line, event.reason.column, event.reason);


    }

  }



  // componentDidCatch(error, info) {
  //   // log the error to our server with loglevel
  //   ErrorBoundary.saveError(error.message, "componentDidCatch", error.line, error.column, error);
  // }


  // static async saveError(msg, source, line, column, error) {
  //   this.lastError = error;


  //   let stackframes = { stackframes: ErrorStackParser.parse(error) };

  //   console.log(stackframes["stackframes"][0].functionName);


  //   StackTrace.fromError(error)
  //     .then((data) => {
  //       console.log(data);
  //     });


  //   var className = "";
  //   var methodName = "";
  //   var stackSpilitArray1 = [];
  //   var stackSpilitArray2 = [];
  //   var stack = [];
  //   var stackClass = [];



  //   // stackSpilitArray1 = error.stack.split("(");
  //   // stackSpilitArray2 = stackSpilitArray1[0].split("\n");
  //   // stack = stackSpilitArray2[1].split(".");
  //   // stackClass = stack[0].split(",");
  //   // className = stackClass[0].replace(' at ', '').trim();
  //   // methodName = stack[1].trim();




  //   // if (error.stack !== undefined) {
  //   //   stackSpilitArray1 = error.stack.split("(");
  //   // }
  //   // else {
  //   //   stackSpilitArray1 = [];
  //   // }

  //   // if (stackSpilitArray1[0] !== undefined) {
  //   //   stackSpilitArray2 = stackSpilitArray1[0].split("\n");
  //   // }
  //   // else {
  //   //   stackSpilitArray2 = [];
  //   // }


  //   // if (stackSpilitArray2[1] !== undefined) {
  //   //   stack = stackSpilitArray2[1].split(".");
  //   // }
  //   // else {
  //   //   stack = [];
  //   // }

  //   // if (stack[0] !== undefined) {
  //   //   stackClass = stack[0].split(",");
  //   // }
  //   // else {
  //   //   stackClass = [];
  //   // }


  //   // if (stackClass[0] !== undefined) {
  //   //   className = stackClass[0].replace(' at ', '').trim();
  //   // }
  //   // else {
  //   //   className = "";
  //   // }

  //   // if (stack[1] !== undefined) {
  //   //   methodName = stack[1].trim();
  //   // }
  //   // else {
  //   //   methodName = "";
  //   // }

  //   if (stackframes["stackframes"][0].functionName !== undefined) {
  //     stack = stackframes["stackframes"][0].functionName.split(".");
  //     className = stack[0];
  //     methodName = stack[1];
  //   }
  //   else {
  //     stack = stackframes["stackframes"][1].functionName.split(".");
  //     className = stack[0];
  //     methodName = stack[1];
  //   }

  //   // if (methodName === "chunk") {
  //   //   return;
  //   // }

  //   // if (className === "") {
  //   //   return;
  //   // }

  //   // if (methodName === "") {
  //   //   return;
  //   // }

  //   let url = CoreBaseAPI + "/ExpectionLogs/AddException";
  //   let Parameter = {
  //     "enteredBy": "" + GlobalConfig.enteredBy,
  //     "pageName": "" + className,
  //     "methodName": "" + methodName,
  //     "enteredByName": "" + GlobalConfig.enteredByName,
  //     "enteredDate": new Date(),
  //     "message": "" + msg,
  //     "stackTrace": "" + JSON.stringify(stackframes),
  //     "innerException": "",
  //     "source": "" + source,
  //     "WANIPAddress": "" + GlobalConfig.userWANIP,
  //     "LANIPAddress": "" + GlobalConfig.userLANIP,
  //     "OSName": "" + GlobalConfig.userOSName,
  //     "BrowserName": "" + GlobalConfig.userBrowserName,
  //     "Url": "" + document.URL,
  //   }


  //   axios.post(url,
  //     Parameter, {
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',

  //     }
  //   }
  //   )
  //     .then((response) => {

  //     })
  //     .catch((error) => {

  //     })

  // }



  static async saveErrorNew(msg, source, line, column, error) {
    this.lastError = error;


    StackTrace.fromError(error)
      .then((stackframes) => {
        console.log(stackframes);


        var className = stackframes[0].fileName;
        var methodName = stackframes[0].functionName;


        let url = CoreBaseAPI + "/ExpectionLogs/AddException";
        let Parameter = {
          "enteredBy": "" + GlobalConfig.enteredBy,
          "pageName": "" + className,
          "methodName": "" + methodName,
          "enteredByName": "" + GlobalConfig.enteredByName,
          "enteredDate": new Date(),
          "message": "" + msg,
          "stackTrace": "" + JSON.stringify(stackframes),
          "innerException": "",
          "source": "" + source,
          "WANIPAddress": "" + GlobalConfig.userWANIP,
          "LANIPAddress": "" + GlobalConfig.userLANIP,
          "OSName": "" + GlobalConfig.userOSName,
          "BrowserName": "" + GlobalConfig.userBrowserName,
          "Url": "" + document.URL,
        }


        axios.post(url,
          Parameter, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
             
          }
        }
        )
          .then((response) => {

          })
          .catch((error) => {

          })


      });




  }


  render() {
    // if (this.state.hasError) {
    //   // You can render any custom fallback UI
    //   return (<div>
    //     {this.props.children}
    //     <Modal show={this.state.hasError} onHide={this.HideModal} dialogClassName="modal-50 p-3 shadowbody-modal" >
    //       <Modal.Header closeButton className="pl-3">
    //         {/* <Modal.Title>Expection Log</Modal.Title> */}
    //       </Modal.Header>
    //       <Modal.Body className="p-5" style={{ 'min-height': '300px' }}>

    //         <div className="d-flex flex-column align-items-center justify-content-center">
    //           {/* <img src={GlobalConfig.Loader} width='70' /> */}
    //           <i style={{ fontSize: 50 }} className="his-icon icon-info text-danger"></i>
    //           <br></br>
    //           <p><strong>An error has occurred please contact IT Department</strong></p>
    //         </div>


    //       </Modal.Body>
    //     </Modal>
    //   </div>);
    // }

    return this.props.children;
  }
}


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};


export default ErrorBoundary;