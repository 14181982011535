import {
  Document_SAVE_FAIL, Document_SAVE_REQUEST, Document_SAVE_SUCCESS, Document_SAVE_RESET,
  Document_LIST_FAIL, Document_LIST_REQUEST, Document_LIST_SUCCESS,
  Document_UHID_FAIL, Document_UHID_REQUEST, Document_UHID_SUCCESS,
  Document_Data_SUCCESS, Document_Data_FAIL, Document_Data_REQUEST,
  Document_Data_Dtl_SUCCESS, Document_Data_Dtl_FAIL, Document_Data_Dtl_REQUEST,
  DocumentDetails_Image_REQUEST, DocumentDetails_Image_FAIL, DocumentDetails_Image_SUCCESS,
  Document_Type_SUCCESS, Document_Type_REQUEST, Document_Type_FAIL,
  Document_Delete_FAIL, Document_Delete_REQUEST, Document_Delete_SUCCESS, Document_Data_DtlClm_REQUEST, Document_Data_DtlClm_SUCCESS, Document_Data_DtlClm_FAIL
  , ClaimBook_Request, ClaimBook_Success, ClaimBook_Fail
} from '../Constants/DocumentConstants';



function DocumentListReducer(state = { DocumentType: [] }, action) {
  switch (action.type) {
    case Document_LIST_REQUEST:
      return { loading: true, DocumentType: [] };
    case Document_LIST_SUCCESS:
      return { loading: false, DocumentType: action.payload };
    case Document_LIST_FAIL:
      return { loading: false, error: action.payload,fetched: true };
    default:
      return state;
  }
}


function DocumentTypeListReducer(state = { Documents: [] }, action) {
  switch (action.type) {
    case Document_Type_REQUEST:
      return { loading: true, Documents: [] };
    case Document_Type_SUCCESS:
      return { loading: false, Documents: action.payload };
    case Document_Type_FAIL:
      return { loading: false, error: action.payload,fetched: true };
    default:
      return state;
  }
}


function DocumentFildSaveReducer(state = { product: {} }, action) {
  switch (action.type) {
    case Document_SAVE_REQUEST:
      return { loading: true };
    case Document_SAVE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case Document_SAVE_FAIL:
      return { loading: false, error: action.payload,fetched: true };
    default:
      return state;
  }
}


function UHIDReducer(state = {}, action) {
  switch (action.type) {
    case Document_UHID_REQUEST:
      return { loading: true };
    case Document_UHID_SUCCESS:
      return { loading: false, UHIDINFO: action.payload };
    case Document_UHID_FAIL:
      return { loading: false, error: action.payload,fetched: true };
    // case USER_LOGOUT:
    //   return {};
    default: return state;
  }
}

function DocumentDataListReducer(state = {}, action) {
  switch (action.type) {
    case Document_Data_REQUEST:
      return { loading: true };
    case Document_Data_SUCCESS:
      return { loading: false, DocumentDataLI: action.payload };
    case Document_Data_FAIL:
      return { loading: false, error: action.payload,fetched: true };
    // case USER_LOGOUT:
    //   return {};
    default: return state;
  }
}
function DocumentDataListDtlReducer(state = {}, action) {
  switch (action.type) {
    case Document_Data_REQUEST:
      return { loading: true };
    case Document_Data_Dtl_SUCCESS:
      return { loading: false, DocumentDatalistDl: action.payload };
    case Document_Data_Dtl_FAIL:
      return { loading: false, error: action.payload,fetched: true };
    // case USER_LOGOUT:
    //   return {};
    default: return state;
  }
}

function DocumentDeleteReducer(state = {}, action) {
  switch (action.type) {
    case Document_Delete_REQUEST:
      return { loading: true };
    case Document_Delete_SUCCESS:
      return { loading: false, DocumentDataLI: action.payload };
    case Document_Data_FAIL:
      return { loading: false, error: action.payload,fetched: true };
    // case USER_LOGOUT:
    //   return {};
    default: return state;
  }
}

function DocumentListImageReducer(state = {}, action) {
  switch (action.type) {
    case DocumentDetails_Image_REQUEST:
      return { loading: true };
    case DocumentDetails_Image_SUCCESS:
      return { loading: false, DocumentImage: action.payload };
    case DocumentDetails_Image_FAIL:
      return { loading: false, error: action.payload,fetched: true };
    // case USER_LOGOUT:
    //   return {};
    default: return state;
  }
}

function DocumentDataListDtlClmReducer(state = {}, action) {
  switch (action.type) {
    case Document_Data_DtlClm_REQUEST:
      return { loading: true };
    case Document_Data_DtlClm_SUCCESS:
      return { loading: false, DocumentDatalistDlClm: action.payload };
    case Document_Data_DtlClm_FAIL:
      return { loading: false, error: action.payload,fetched: true };
    // case USER_LOGOUT:
    //   return {};
    default: return state;
  }
}


const intialstate = {};
function ClaimBookSaveReducer(state = {
  ClaimBookSaveData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case ClaimBook_Request: { return { ...state, fetching: true, fetched: false, error: null } }
    case ClaimBook_Fail: { return { ...state, fetching: false, error: action.payload,fetched: true } }
    case ClaimBook_Success: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        ClaimBookSaveData: action.payload
      }
    }
    default: return state;
  }

}

export {
  DocumentListReducer,
  DocumentFildSaveReducer,
  UHIDReducer,
  DocumentDataListReducer, DocumentDataListDtlReducer, DocumentDataListDtlClmReducer, ClaimBookSaveReducer,
  DocumentListImageReducer,
  DocumentTypeListReducer,
  DocumentDeleteReducer,
};