// import React from 'react';
// import ReactDOM from 'react-dom';
// // import './index.css';
// import App from './App';
// // import * as serviceWorker from './serviceWorker';
// import { BrowserRouter } from "react-router-dom";
// import Menus from './menu'


import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import { ClearCache } from "./ClearCacheNew";
import 'bootstrap/dist/css/bootstrap.min.css';
//import 'jquery/dist/jquery.min.js';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'popper.js';
//import {JqueryMenu} from './assets/js/ThemeFunctions';
import './assets/scss/style.scss';
import './assets/scss/ipd/index.scss';
import './assets/scss/ipd/patient_chart_summary.css';
import './assets/scss/ipd/medicine.scss';
import './assets/scss/ipd/patient-history.scss';
//import App from './App';
//import Menus from './menu';
import 'antd/dist/antd.css';
import { Provider } from 'react-redux';
import RedusStore from './RedusStore'
import * as serviceWorker from './serviceWorker';
import { authenticationService } from '../src/authorize/_services';
import ErrorBoundary from './ErrorBoundary';
// import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache';
const AppComponent = React.lazy(() => import('./App'));
const MenuComponent = React.lazy(() => import('./menu'));



ReactDOM.render(

  <div className="container-fluid" >
    {/* {authenticationService.currentUserValue ? '' : window.location.href = '/signin'} */}
    <Provider store={RedusStore}>
      <BrowserRouter>
        <Suspense fallback={
          <main>
            <div style={{ width: '100%', height: '100vh', background: 'rgba(255,255,255,.7)', borderRadius: '4px', overflow: "hidden", position: "absolute", zIndex: '999999' }} className="d-flex flex-column align-items-center justify-content-center">
              <img src='https://myhealthcare-his.s3.ap-south-1.amazonaws.com/Test/Assets/images/loader.gif' width='70' />
              <p><strong>Loading..</strong></p>
            </div>
          </main>
        }>
          {authenticationService.currentUserValue ? <MenuComponent /> : <div style={{ width: '100vw', height: '100vh' }}></div>}

          <ErrorBoundary>
            {/* <ClearBrowserCacheBoundary auto={true} fallback='Clearing Browser Cache Loading..' duration={60000}>
             
            </ClearBrowserCacheBoundary> */}

            {/* <ClearCache> */}
              <AppComponent />
            {/* </ClearCache> */}
          </ErrorBoundary>
        </Suspense>


      </BrowserRouter>

    </Provider>
  </div>

  , document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();