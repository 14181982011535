import * as AMCnst from './Constants';

const intialstate = {};

//Registration Type Reducer
function ExceptionListReducer(state = {
    ExceptionData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EXCEPTION_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EXCEPTION_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EXCEPTION_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ExceptionData: action.payload
            }

        }
        default: return state;
    }

}


export {
    ExceptionListReducer
};
