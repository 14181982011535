export const GET_ROLE_UNDER_FACILITY_REQUEST = 'GET_ROLE_UNDER_FACILITY_REQUEST';
export const GET_ROLE_UNDER_FACILITY_SUCCESS = 'GET_ROLE_UNDER_FACILITY_SUCCESS';
export const GET_ROLE_UNDER_FACILITY_FAIL    = 'GET_ROLE_UNDER_FACILITY_FAIL';

export const GET_USER_MAPED_WITH_FACILITY_REQUEST = 'GET_RUSER_MAPED_WITH_REQUEST';
export const GET_USER_MAPED_WITH_FACILITY_SUCCESS = 'GET_USER_MAPED_WITH_FACILITY_SUCCESS';
export const GET_USER_MAPED_WITH_FACILITY_FAIL    = 'GET_USER_MAPED_WITH_FACILITY_FAIL';

export const GET_EmployeeRoleMapping_REQUEST = 'GET_EmployeeRoleMapping_REQUEST';
export const GET_EmployeeRoleMapping_SUCCESS = 'GET_EmployeeRoleMapping_SUCCESS';
export const GET_EmployeeRoleMapping_FAIL    = 'GET_EmployeeRoleMapping_FAIL';


export const GET_MODULE_MAPED_WITH_FACILITY_REQUEST = 'GET_MODULE_MAPED_WITH_REQUEST';
export const GET_MODULE_MAPED_WITH_FACILITY_SUCCESS = 'GET_MODULE_MAPED_WITH_FACILITY_SUCCESS';
export const GET_MODULE_MAPED_WITH_FACILITY_FAIL    = 'GET_MODULE_MAPED_WITH_FACILITY_FAIL';


export const GET_PAGE_MAPED_WITH_MODULE_REQUEST = 'GET_PAGE_MAPED_WITH_MAPED_REQUEST';
export const GET_PAGE_MAPED_WITH_MODULE_SUCCESS = 'GET_PAGE_MAPED_WITH_MAPED_SUCCESS';
export const GET_PAGE_MAPED_WITH_MODULE_FAIL    = 'GET_PAGE_MAPED_WITH_MAPED_FAIL';



export const GET_FIELD_MAPED_WITH_EMPLOYEE_REQUEST = 'GET_FIELD_MAPED_WITH_EMPLOYEE_REQUEST';
export const GET_FIELD_MAPED_WITH_EMPLOYEE_SUCCESS = 'GET_FIELD_MAPED_WITH_EMPLOYEE_SUCCESS';
export const GET_FIELD_MAPED_WITH_EMPLOYEE_FAIL    = 'GET_FIELD_MAPED_WITH_EMPLOYEE_FAIL';



export const SAVE_FIELD_MODIFICATION_REQUEST = 'SAVE_FIELD_MODIFICATION_REQUEST';
export const SAVE_FIELD_MODIFICATION_SUCCESS = 'SAVE_FIELD_MODIFICATION_SUCCESS';
export const SAVE_FIELD_MODIFICATION_FAIL    = 'SAVE_FIELD_MODIFICATION_FAIL';
