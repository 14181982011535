export const OPERATING_ROOM_LIST_REQUEST = 'OPERATING_ROOM_LIST_REQUEST';
export const OPERATING_ROOM_LIST_SUCCESS = 'OPERATING_ROOM_LIST_SUCCESS';
export const OPERATING_ROOM_LIST_FAIL = 'OPERATING_ROOM_LIST_FAIL';

export const OPERATING_ROOM_SAVE_REQUEST = 'OPERATING_ROOM_SAVE_REQUEST';
export const OPERATING_ROOM_SAVE_SUCCESS = 'OPERATING_ROOM_SAVE_SUCCESS';
export const OPERATING_ROOM_SAVE_FAIL = 'OPERATING_ROOM_SAVE_FAIL';

export const PATIENT_POSITION_LIST_REQUEST = 'PATIENT_POSITION_LIST_REQUEST';
export const PATIENT_POSITION_LIST_SUCCESS = 'PATIENT_POSITION_LIST_SUCCESS';
export const PATIENT_POSITION_LIST_FAIL = 'PATIENT_POSITION_LIST_FAIL';

export const PATIENT_POSITION_SAVE_REQUEST = 'PATIENT_POSITION_SAVE_REQUEST';
export const PATIENT_POSITION_SAVE_SUCCESS = 'PATIENT_POSITION_SAVE_SUCCESS';
export const PATIENT_POSITION_SAVE_FAIL = 'PATIENT_POSITION_SAVE_FAIL';

export const SURGERY_CATEGORY_LIST_REQUEST = 'SURGERY_CATEGORY_LIST_REQUEST';
export const SURGERY_CATEGORY_LIST_SUCCESS = 'SURGERY_CATEGORY_LIST_SUCCESS';
export const SURGERY_CATEGORY_LIST_FAIL = 'SURGERY_CATEGORY_LIST_FAIL';

export const SURGERY_CATEGORY_SAVE_REQUEST = 'SURGERY_CATEGORY_SAVE_REQUEST';
export const SURGERY_CATEGORY_SAVE_SUCCESS = 'SURGERY_CATEGORY_SAVE_SUCCESS';
export const SURGERY_CATEGORY_SAVE_FAIL = 'SURGERY_CATEGORY_SAVE_FAIL';

export const AnesthesiaType_LIST_REQUEST = 'AnesthesiaType_LIST_REQUEST';
export const AnesthesiaType_LIST_SUCCESS = 'AnesthesiaType_LIST_SUCCESS';
export const AnesthesiaType_LIST_FAIL = 'AnesthesiaType_LIST_FAIL';

export const AnesthesiaType_SAVE_REQUEST = 'AnesthesiaType_SAVE_REQUEST';
export const AnesthesiaType_SAVE_SUCCESS = 'AnesthesiaType_SAVE_SUCCESS';
export const AnesthesiaType_SAVE_FAIL = 'AnesthesiaType_SAVE_FAIL';

export const PREFERENCE_LIST_REQUEST = 'PREFERENCE_LIST_REQUEST';
export const PREFERENCE_LIST_SUCCESS = 'PREFERENCE_LIST_SUCCESS';
export const PREFERENCE_LIST_FAIL = 'PREFERENCE_LIST_FAIL';

export const PREFERENCE_SAVE_REQUEST = 'PREFERENCE_SAVE_REQUEST';
export const PREFERENCE_SAVE_SUCCESS = 'PREFERENCE_SAVE_SUCCESS';
export const PREFERENCE_SAVE_FAIL = 'PREFERENCE_SAVE_FAIL';

export const SURGERY_MAIN_LIST_REQUEST = 'SURGERY_MAIN_LIST_REQUEST';
export const SURGERY_MAIN_LIST_SUCCESS = 'SURGERY_MAIN_LIST_SUCCESS';
export const SURGERY_MAIN_LIST_FAIL = 'SURGERY_MAIN_LIST_FAIL';

export const SURGERY_MAIN_SAVE_REQUEST = 'SURGERY_MAIN_SAVE_REQUEST';
export const SURGERY_MAIN_SAVE_SUCCESS = 'SURGERY_MAIN_SAVE_SUCCESS';
export const SURGERY_MAIN_SAVE_FAIL = 'SURGERY_MAIN_SAVE_FAIL';

export const SURGERY_REQUEST_LIST_REQUEST = 'SURGERY_REQUEST_LIST_REQUEST';
export const SURGERY_REQUEST_LIST_SUCCESS = 'SURGERY_REQUEST_LIST_SUCCESS';
export const SURGERY_REQUEST_LIST_FAIL = 'SURGERY_REQUEST_LIST_FAIL';

export const OTBOOKING_LIST_REQUEST = 'OTBOOKING_LIST_REQUEST';
export const OTBOOKING_LIST_SUCCESS = 'OTBOOKING_LIST_SUCCESS';
export const OTBOOKING_LIST_FAIL = 'OTBOOKING_LIST_FAIL';

export const OTBOOKING_SAVE_REQUEST = 'OTBOOKING_SAVE_REQUEST';
export const OTBOOKING_SAVE_SUCCESS = 'OTBOOKING_SAVE_SUCCESS';
export const OTBOOKING_SAVE_FAIL = 'OTBOOKING_SAVE_FAIL';

export const OTBLOCK_SAVE_REQUEST = 'OTBLOCK_SAVE_REQUEST';
export const OTBLOCK_SAVE_SUCCESS = 'OTBLOCK_SAVE_SUCCESS';
export const OTBLOCK_SAVE_FAIL = 'OTBLOCK_SAVE_FAIL';

export const OTBLOCK_LIST_REQUEST = 'OTBLOCK_LIST_REQUEST';
export const OTBLOCK_LIST_SUCCESS = 'OTBLOCK_LIST_SUCCESS';
export const OTBLOCK_LIST_FAIL = 'OTBLOCK_LIST_FAIL';

export const SURGERY_DATE_UPDATE_REQUEST = 'SURGERY_DATE_UPDATE_REQUEST';
export const SURGERY_DATE_UPDATE_SUCCESS = 'SURGERY_DATE_UPDATE_SUCCESS';
export const SURGERY_DATE_UPDATE_FAIL = 'SURGERY_DATE_UPDATE_FAIL';

export const AUTO_ALLOCATE_SAVE_REQUEST = 'AUTO_ALLOCATE_SAVE_REQUEST';
export const AUTO_ALLOCATE_SAVE_SUCCESS = 'AUTO_ALLOCATE_SAVE_SUCCESS';
export const AUTO_ALLOCATE_SAVE_FAIL = 'AUTO_ALLOCATE_SAVE_FAIL';

export const PREVIEW_SAVE_REQUEST = 'PREVIEW_SAVE_REQUEST';
export const PREVIEW_SAVE_SUCCESS = 'PREVIEW_SAVE_SUCCESS';
export const PREVIEW_SAVE_FAIL = 'PREVIEW_SAVE_FAIL';

export const OTBOOKING_UPDATE_REQUEST = 'OTBOOKING_UPDATE_REQUEST';
export const OTBOOKING_UPDATE_SUCCESS = 'OTBOOKING_UPDATE_SUCCESS';
export const OTBOOKING_UPDATE_FAIL = 'OTBOOKING_UPDATE_FAIL';

export const OTBOOKING_CANCEL_REQUEST = 'OTBOOKING_CANCEL_REQUEST';
export const OTBOOKING_CANCEL_SUCCESS = 'OTBOOKING_CANCEL_SUCCESS';
export const OTBOOKING_CANCEL_FAIL = 'OTBOOKING_CANCEL_FAIL';

export const OTBOOKING_CHECK_REQUEST = 'OTBOOKING_CHECK_REQUEST';
export const OTBOOKING_CHECK_SUCCESS = 'OTBOOKING_CHECK_SUCCESS';
export const OTBOOKING_CHECK_FAIL = 'OTBOOKING_CHECK_FAIL';

export const APPLYCHANGES_SAVE_REQUEST = 'APPLYCHANGES_SAVE_REQUEST';
export const APPLYCHANGES_SAVE_SUCCESS = 'APPLYCHANGES_SAVE_SUCCESS';
export const APPLYCHANGES_SAVE_FAIL = 'APPLYCHANGES_SAVE_FAIL';

export const OTBOOKING_CHECKACTION_REQUEST = 'OTBOOKING_CHECKACTION_REQUEST';
export const OTBOOKING_CHECKACTION_SUCCESS = 'OTBOOKING_CHECKACTION_SUCCESS';
export const OTBOOKING_CHECKACTION_FAIL = 'OTBOOKING_CHECKACTION_FAIL';

export const OTBOOKING_CHECKACTION_CANCEL_REQUEST = 'OTBOOKING_CHECKACTION_CANCEL_REQUEST';
export const OTBOOKING_CHECKACTION_CANCEL_SUCCESS = 'OTBOOKING_CHECKACTION_CANCEL_SUCCESS';
export const OTBOOKING_CHECKACTION_CANCEL_FAIL = 'OTBOOKING_CHECKACTION_CANCEL_FAIL';

export const PACKAGE_LIST_REQUEST = 'PACKAGE_LIST_REQUEST';
export const PACKAGE_LIST_SUCCESS = 'PACKAGE_LIST_SUCCESS';
export const PACKAGE_LIST_FAIL = 'PACKAGE_LIST_FAIL';

export const POST_INPUT_LIST_REQUEST = 'POST_INPUT_LIST_REQUEST';
export const POST_INPUT_LIST_SUCCESS = 'POST_INPUT_LIST_SUCCESS';
export const POST_INPUT_LIST_FAIL = 'POST_INPUT_LIST_FAIL';

export const GET_PERI_OP_REQUEST = 'GET_PERI_OP_REQUEST';
export const GET_PERI_OP_SUCCESS = 'GET_PERI_OP_SUCCESS';
export const GET_PERI_OP_FAIL = 'GET_PERI_OP_FAIL';

export const PERSONNEL_SAVE_REQUEST = 'PERSONNEL_SAVE_REQUEST';
export const PERSONNEL_SAVE_SUCCESS = 'PERSONNEL_SAVE_SUCCESS';
export const PERSONNEL_SAVE_FAIL = 'PERSONNEL_SAVE_FAIL';

export const PERSONNEL_GET_REQUEST = 'PERSONNEL_GET_REQUEST';
export const PERSONNEL_GET_SUCCESS = 'PERSONNEL_GET_SUCCESS';
export const PERSONNEL_GET_FAIL = 'PERSONNEL_GET_FAIL';

export const SURGERYTIMEDETAILS_SAVE_REQUEST = 'SURGERYTIMEDETAILS_SAVE_REQUEST';
export const SURGERYTIMEDETAILS_SAVE_SUCCESS = 'SURGERYTIMEDETAILS_SAVE_SUCCESS';
export const SURGERYTIMEDETAILS_SAVE_FAIL = 'SURGERYTIMEDETAILS_SAVE_FAIL';

export const SURGERYTIMEDETAILS_GET_REQUEST = 'SURGERYTIMEDETAILS_GET_REQUEST';
export const SURGERYTIMEDETAILS_GET_SUCCESS = 'SURGERYTIMEDETAILS_GET_SUCCESS';
export const SURGERYTIMEDETAILS_GET_FAIL = 'SURGERYTIMEDETAILS_GET_FAIL';

export const WOUNDDETAILS_SAVE_REQUEST = 'WOUNDDETAILS_SAVE_REQUEST';
export const WOUNDDETAILS_SAVE_SUCCESS = 'WOUNDDETAILS_SAVE_SUCCESS';
export const WOUNDDETAILS_SAVE_FAIL = 'WOUNDDETAILS_SAVE_FAIL';

export const WOUNDDETAILS_GET_REQUEST = 'WOUNDDETAILS_GET_REQUEST';
export const WOUNDDETAILS_GET_SUCCESS = 'WOUNDDETAILS_GET_SUCCESS';
export const WOUNDDETAILS_GET_FAIL = 'WOUNDDETAILS_GET_FAIL';

export const UNCONFIRM_SAVE_REQUEST = 'UNCONFIRM_SAVE_REQUEST';
export const UNCONFIRM_SAVE_SUCCESS = 'UNCONFIRM_SAVE_SUCCESS';
export const UNCONFIRM_SAVE_FAIL = 'UNCONFIRM_SAVE_FAIL';

export const ACKNOWLEDGE_SAVE_REQUEST = 'ACKNOWLEDGE_SAVE_REQUEST';
export const ACKNOWLEDGE_SAVE_SUCCESS = 'ACKNOWLEDGE_SAVE_SUCCESS';
export const ACKNOWLEDGE_SAVE_FAIL = 'ACKNOWLEDGE_SAVE_FAIL';

export const TRANSFER_OUT_SAVE_REQUEST = 'TRANSFER_OUT_SAVE_REQUEST';
export const TRANSFER_OUT_SAVE_SUCCESS = 'TRANSFER_OUT_SAVE_SUCCESS';
export const TRANSFER_OUT_SAVE_FAIL = 'TRANSFER_OUT_SAVE_FAIL';

export const OTTIMELINE_LIST_REQUEST = 'OTTIMELINE_LIST_REQUEST';
export const OTTIMELINE_LIST_SUCCESS = 'OTTIMELINE_LIST_SUCCESS';
export const OTTIMELINE_LIST_FAIL = 'OTTIMELINE_LIST_FAIL';

export const SURGERYCLEARANCE_LIST_REQUEST = 'SURGERYCLEARANCE_LIST_REQUEST';
export const SURGERYCLEARANCE_LIST_SUCCESS = 'SURGERYCLEARANCE_LIST_SUCCESS';
export const SURGERYCLEARANCE_LIST_FAIL = 'SURGERYCLEARANCE_LIST_FAIL';

export const SURGERY_CLEARANCE_SAVE_REQUEST = 'SURGERY_CLEARANCE_SAVE_REQUEST';
export const SURGERY_CLEARANCE_SAVE_SUCCESS = 'SURGERY_CLEARANCE_SAVE_SUCCESS';
export const SURGERY_CLEARANCE_SAVE_FAIL = 'SURGERY_CLEARANCE_SAVE_FAIL';

export const PAC_CLEARANCE_SAVE_REQUEST = 'PAC_CLEARANCE_SAVE_REQUEST';
export const PAC_CLEARANCE_SAVE_SUCCESS = 'PAC_CLEARANCE_SAVE_SUCCESS';
export const PAC_CLEARANCE_SAVE_FAIL = 'PAC_CLEARANCE_SAVE_FAIL';

export const OTSTAT_LIST_REQUEST = 'OTSTAT_LIST_REQUEST';
export const OTSTAT_LIST_SUCCESS = 'OTSTAT_LIST_SUCCESS';
export const OTSTAT_LIST_FAIL = 'OTSTAT_LIST_FAIL';

export const OTDASHBOARD_LIST_REQUEST = 'OTDASHBOARD_LIST_REQUEST';
export const OTDASHBOARD_LIST_SUCCESS = 'OTDASHBOARD_LIST_SUCCESS';
export const OTDASHBOARD_LIST_FAIL = 'OTDASHBOARD_LIST_FAIL';



