import * as Mlst from '../Constant/MedicalLoanConstant';

const intialstate = {};



function SaveLoanFinancerReducer(state = {
    SaveLoanFinancerdata: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Mlst.SaveLoanFinancer_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Mlst.SaveLoanFinancer_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Mlst.SaveLoanFinancer_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveLoanFinancerdata: action.payload
            }
        }
        default: return state;
    }

}

function GetFinancerAsyncReducer(state = {
    LoanFinancerdata: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Mlst.LoanFinancer_REQ_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Mlst.LoanFinancer_REQ_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Mlst.LoanFinancer_REQ_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                LoanFinancerdata: action.payload
            }
        }
        default: return state;
    }

}

function GetFinanceLoanReducer(state = {
    getFinanceLoanData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Mlst.GET_FINANCELOAN_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Mlst.GET_FINANCELOAN_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
        case Mlst.GET_FINANCELOAN_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                getFinanceLoanData: action.payload
            }

        }
        default: return state;
    }

}


function GetFinanceLoanEMIReducer(state = {
    getFinanceLoanEMIData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Mlst.GET_FINANCELOAN_EMI_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Mlst.GET_FINANCELOAN_EMI_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
        case Mlst.GET_FINANCELOAN_EMI_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                getFinanceLoanEMIData: action.payload
            }

        }
        default: return state;
    }

}

export {
    SaveLoanFinancerReducer,GetFinancerAsyncReducer,GetFinanceLoanReducer,GetFinanceLoanEMIReducer
};