export const LoanFinancer_REQ_LIST_REQUEST = "LoanFinancer_REQ_LIST_REQUEST";
export const LoanFinancer_REQ_LIST_SUCCESS = "LoanFinancer_REQ_LIST_SUCCESS";
export const LoanFinancer_REQ_LIST_FAIL = "LoanFinancer_REQ_LIST_FAIL";

export const SaveLoanFinancer_REQ_LIST_REQUEST = "SaveLoanFinancer_REQ_LIST_REQUEST";
export const SaveLoanFinancer_REQ_LIST_SUCCESS = "SaveLoanFinancer_REQ_LIST_SUCCESS";
export const SaveLoanFinancer_REQ_LIST_FAIL = "SaveLoanFinancer_REQ_LIST_FAIL";

export const GET_FINANCELOAN_REQUEST = 'GET_FINANCELOAN_REQUEST';
export const GET_FINANCELOAN_SUCCESS = 'GET_FINANCELOAN_SUCCESS';
export const GET_FINANCELOAN_FAIL = 'GET_FINANCELOAN_FAIL';

export const GET_FINANCELOAN_EMI_REQUEST = 'GET_FINANCELOAN_EMI_REQUEST';
export const GET_FINANCELOAN_EMI_SUCCESS = 'GET_FINANCELOAN_EMI_SUCCESS';
export const GET_FINANCELOAN_EMI_FAIL = 'GET_FINANCELOAN_EMI_FAIL';


