import * as Cnst from '../Constant/DietConstant';

const intialstate = {};


function getDietCategoryReducer(state = {
    dietCategoryList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.GET_DIET_CATEGORY_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_DIET_CATEGORY_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_DIET_CATEGORY_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                dietCategoryList: action.payload
            }

        }
        default: return state;
    }

}

function getTherapeuticRestrictionReducer(state = {
    getTherapeuticList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.GET_THERAPEUTIC_RESTRICTIONS_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_THERAPEUTIC_RESTRICTIONS_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_THERAPEUTIC_RESTRICTIONS_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getTherapeuticList: action.payload
            }

        }
        default: return state;
    }

}

function getFoodHabitsReducer(state = {
    getFoodHabitsList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.GET_FOOD_HABITS_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_FOOD_HABITS_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_FOOD_HABITS_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getFoodHabitsList: action.payload
            }

        }
        default: return state;
    }

}

function getIntegrationFlagsReducer(state = {
    getIntegrationFlagsData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.get_Integration_Flags_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.get_Integration_Flags_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.get_Integration_Flags_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getIntegrationFlagsData: action.payload
            }

        }
        default: return state;
    }

}


function getMealReducer(state = {
    getMealList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.GET_MEAL_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_MEAL_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_MEAL_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getMealList: action.payload
            }

        }
        default: return state;
    }

}

function getPayorMealMappingReducer(state = {
    getPayorMealMappList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.GET_PAYOR_MEAL_MAPP_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_PAYOR_MEAL_MAPP_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_PAYOR_MEAL_MAPP_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getPayorMealMappList: action.payload
            }

        }
        default: return state;
    }

}

function savePayorMealMappingReducer(state = {
    savePayorMealMappStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.SAVE_PAYOR_MEAL_MAPP_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.SAVE_PAYOR_MEAL_MAPP_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.SAVE_PAYOR_MEAL_MAPP_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                savePayorMealMappStatus: action.payload
            }

        }
        default: return state;
    }

}

function getSeasonReducer(state = {
    getSeasonList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.GET_SEASON_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_SEASON_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_SEASON_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getSeasonList: action.payload
            }

        }
        default: return state;
    }

}

function getFoodCategoryReducer(state = {
    getFoodCategoryList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.GET_FOOD_CATEGORY_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_FOOD_CATEGORY_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_FOOD_CATEGORY_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getFoodCategoryList: action.payload
            }

        }
        default: return state;
    }

}

function getFoodItemReducer(state = {
    getFoodItemList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.GET_FOOD_ITEM_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_FOOD_ITEM_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_FOOD_ITEM_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getFoodItemList: action.payload
            }

        }
        default: return state;
    }

}

function saveFoodItemReducer(state = {
    saveFoodItemStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.SAVE_FOOD_ITEM_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.SAVE_FOOD_ITEM_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.SAVE_FOOD_ITEM_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveFoodItemStatus: action.payload
            }

        }
        default: return state;
    }

}

function getRTLiquidFeedReducer(state = {
    getRTLiquidFeedList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.GET_RT_LIQUID_FEED_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_RT_LIQUID_FEED_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_RT_LIQUID_FEED_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getRTLiquidFeedList: action.payload
            }

        }
        default: return state;
    }

}

function getMenuCycleReducer(state = {
    getMenuCycleList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.GET_MENU_CYCLE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_MENU_CYCLE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_MENU_CYCLE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getMenuCycleList: action.payload
            }

        }
        default: return state;
    }

}

function saveMenuCycleReducer(state = {
    saveMenuCycleStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.SAVE_MENU_CYCLE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.SAVE_MENU_CYCLE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.SAVE_MENU_CYCLE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveMenuCycleStatus: action.payload
            }

        }
        default: return state;
    }

}

function getItemOfDayReducer(state = {
    getItemOfDayList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.GET_ITEM_OF_DAY_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_ITEM_OF_DAY_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_ITEM_OF_DAY_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getItemOfDayList: action.payload
            }

        }
        default: return state;
    }

}
//sandee work start
function saveDietCategoryReducer(state = {
    saveDietCategory: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.SAVE_DIET_CATEGORY_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.SAVE_DIET_CATEGORY_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.SAVE_DIET_CATEGORY_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveDietCategory: action.payload
            }

        }
        default: return state;
    }

}
function saveFoodHabitReducer(state = {
    saveFoodHabitStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.SAVE_FOOD_HABITS_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.SAVE_FOOD_HABITS_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.SAVE_FOOD_HABITS_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveFoodHabitStatus: action.payload
            }

        }
        default: return state;
    }

}
function getCuisineReducer(state = {
    getCuisineList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.GET_CUISINE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_CUISINE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_CUISINE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getCuisineList: action.payload
            }

        }
        default: return state;
    }

}
function saveDietCuisineReducer(state = {
    saveDietCuisine: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.SAVE_CUISINE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.SAVE_CUISINE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.SAVE_CUISINE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveDietCuisine: action.payload
            }

        }
        default: return state;
    }

}
function saveDietSeasonReducer(state = {
    saveDietSeason: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.SAVE_SEASON_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.SAVE_SEASON_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.SAVE_SEASON_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveDietSeason: action.payload
            }

        }
        default: return state;
    }

}
function saveDietMealReducer(state = {
    saveDietMeal: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.SAVE_MEAL_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.SAVE_MEAL_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.SAVE_MEAL_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveDietMeal: action.payload
            }

        }
        default: return state;
    }

}
function saveDietTherapeuticRestrictionReducer(state = {
    saveDietTherapeuticRestriction: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.SAVE_THERAPEUTIC_RESTRICTIONS_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.SAVE_THERAPEUTIC_RESTRICTIONS_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.SAVE_THERAPEUTIC_RESTRICTIONS_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveDietTherapeuticRestriction: action.payload
            }

        }
        default: return state;
    }

}
function saveDietFoodCategoryReducer(state = {
    saveDietFoodCategory: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.SAVE_FOOD_CATEGORY_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.SAVE_FOOD_CATEGORY_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.SAVE_FOOD_CATEGORY_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveDietFoodCategory: action.payload
            }

        }
        default: return state;
    }

}
function saveDietItemOfDayReducer(state = {
    saveDietItemOfday: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.SAVE_ITEM_OF_DAY_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.SAVE_ITEM_OF_DAY_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.SAVE_ITEM_OF_DAY_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveDietItemOfday: action.payload
            }

        }
        default: return state;
    }

}
const getDietOrderDataByIdReducer = (state = {
    getDietOrderData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) => {
    switch (action.type) {
        case Cnst.GET_DIET_ORDER_DATA_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_DIET_ORDER_DATA_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_DIET_ORDER_DATA_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getDietOrderData: action.payload
            }
        }
        default: return state;
    }
}
function saveRTLiquidFeedStatusReducer(state = {
    saveRTLiquidFeedStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Cnst.SAVE_RT_LIQUID_FEED_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.SAVE_RT_LIQUID_FEED_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.SAVE_RT_LIQUID_FEED_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveRTLiquidFeedStatus: action.payload
            }

        }
        default: return state;
    }

}


const getDietOrderForStatusUpdateReducer = (state = {
    getDietOrderForUpdateStatusData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) => {
    switch (action.type) {
        case Cnst.GET_DIET_ORDER_DATA_STATUS_UPDT_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_DIET_ORDER_DATA_STATUS_UPDT_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_DIET_ORDER_DATA_STATUS_UPDT_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getDietOrderForUpdateStatusData: action.payload
            }
        }
        default: return state;
    }
}


const getPreviousDietOrderListReducer = (state = {
    getPreviousDietOrderListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) => {
    switch (action.type) {
        case Cnst.GET_PREVIOUS_DIET_ORDER_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_PREVIOUS_DIET_ORDER_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_PREVIOUS_DIET_ORDER_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getPreviousDietOrderListData: action.payload
            }
        }
        default: return state;
    }
}


const getPreviousDietMeaListReducer = (state = {
    getPreviousDietMealListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) => {
    switch (action.type) {
        case Cnst.GET_PREVIOUS_DIET_MEAL_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_PREVIOUS_DIET_MEAL_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_PREVIOUS_DIET_MEAL_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getPreviousDietMealListData: action.payload
            }
        }
        default: return state;
    }
}

const getPreviousDietOrderByRegIdReducer = (state = {
    getPreviousDietOrderData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) => {
    switch (action.type) {
        case Cnst.GET_PREVIOUS_DIET_ORDER_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_PREVIOUS_DIET_ORDER_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_PREVIOUS_DIET_ORDER_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getPreviousDietOrderData: action.payload
            }
        }
        default: return state;
    }
}

const getDietOrderStatusListReducer = (state = {
    dietOrderStatusList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) => {
    switch (action.type) {
        case Cnst.GET_DIET_ORDER_STATUS_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_DIET_ORDER_STATUS_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_DIET_ORDER_STATUS_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                dietOrderStatusList: action.payload
            }
        }
        default: return state;
    }
}

const getAdmittedPatientListReducer = (state = {
    patientList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) => {
    switch (action.type) {
        case Cnst.GET_ADMITTED_PATIENT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_ADMITTED_PATIENT_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_ADMITTED_PATIENT_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                patientList: action.payload
            }
        }
        default: return state;
    }
}

const GetDietPlanItemQuantitybybedcategorylistReducer = (state = {
    ItemQuantitybybedcategorylist: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) => {
    switch (action.type) {
        case Cnst.GET_Diet_Plan_ItemQuantitybybedcategory_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_Diet_Plan_ItemQuantitybybedcategory_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_Diet_Plan_ItemQuantitybybedcategory_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ItemQuantitybybedcategorylist: action.payload
            }
        }
        default: return state;
    }
}

const GetMealIndentSummaryReducer = (state = {
    MealIndentSummarylist: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) => {
    switch (action.type) {
        case Cnst.GET_MealIndentSummarylist_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Cnst.GET_MealIndentSummarylist_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Cnst.GET_MealIndentSummarylist_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                MealIndentSummarylist: action.payload
            }
        }
        default: return state;
    }
}

export {
    getDietCategoryReducer, getTherapeuticRestrictionReducer, getFoodHabitsReducer, getCuisineReducer, getMealReducer, getPayorMealMappingReducer,
    savePayorMealMappingReducer, getSeasonReducer, getFoodCategoryReducer, getFoodItemReducer, saveFoodItemReducer, getRTLiquidFeedReducer,
    getMenuCycleReducer, saveMenuCycleReducer, getItemOfDayReducer, saveDietCategoryReducer, saveFoodHabitReducer, saveDietCuisineReducer,
    saveDietSeasonReducer, saveDietMealReducer, saveDietTherapeuticRestrictionReducer, saveDietFoodCategoryReducer, saveDietItemOfDayReducer,
    getDietOrderDataByIdReducer, getDietOrderForStatusUpdateReducer, saveRTLiquidFeedStatusReducer, getPreviousDietOrderListReducer,
    getPreviousDietOrderByRegIdReducer, getDietOrderStatusListReducer, getAdmittedPatientListReducer, getPreviousDietMeaListReducer,
    GetDietPlanItemQuantitybybedcategorylistReducer, getIntegrationFlagsReducer, GetMealIndentSummaryReducer
};