export const CustomFild_LIST_REQUEST = 'CustomFild_LIST_REQUEST';
export const CustomFild_LIST_SUCCESS = 'CustomFild_LIST_SUCCESS';
export const CustomFild_LIST_FAIL = 'CustomFild_LIST_FAIL';

export const Facility_LIST_REQUEST = 'Facility_LIST_REQUEST';
export const Facility_LIST_SUCCESS = 'Facility_LIST_SUCCESS';
export const Facility_LIST_FAIL = 'Facility_LIST_FAIL';

export const Module_LIST_REQUEST = 'Module_LIST_REQUEST';
export const Module_LIST_SUCCESS = 'Module_LIST_SUCCESS';
export const Module_LIST_FAIL = 'Module_LIST_FAIL';

export const Form_LIST_REQUEST = 'Form_LIST_REQUEST';
export const Form_LIST_SUCCESS = 'Form_LIST_SUCCESS';
export const Form_LIST_FAIL = 'Form_LIST_FAIL';

export const Section_LIST_REQUEST = 'Section_LIST_REQUEST';
export const Section_LIST_SUCCESS = 'Section_LIST_SUCCESS';
export const Section_LIST_FAIL = 'Section_LIST_FAIL';

export const FildType_LIST_REQUEST = 'FildType_LIST_REQUEST';
export const FildType_LIST_SUCCESS = 'FildType_LIST_SUCCESS';
export const FildType_LIST_FAIL = 'FildType_LIST_FAIL';

export const CustomFild_DETAILS_REQUEST = 'CustomFild_DETAILS_REQUEST';
export const CustomFild_DETAILS_SUCCESS = 'CustomFild_DETAILS_SUCCESS';
export const CustomFild_DETAILS_FAIL = 'CustomFild_DETAILS_FAIL';

export const CustomFild_SAVE_REQUEST = 'CustomFild_SAVE_REQUEST';
export const CustomFild_SAVE_SUCCESS = 'CustomFild_SAVE_SUCCESS';
export const CustomFild_SAVE_FAIL = 'CustomFild_SAVE_FAIL';



export const CustomFild_DELETE_REQUEST = 'CustomFild_DELETE_REQUEST';
export const CustomFild_DELETE_SUCCESS = 'CustomFild_DELETE_SUCCESS';
export const CustomFild_DELETE_FAIL = 'CustomFild_DELETE_FAIL';

export const CustomFild_REVIEW_SAVE_REQUEST = 'CustomFild_REVIEW_SAVE_REQUEST';
export const CustomFild_REVIEW_SAVE_SUCCESS = 'CustomFild_REVIEW_SAVE_SUCCESS';
export const CustomFild_REVIEW_SAVE_FAIL = 'CustomFild_REVIEW_SAVE_FAIL';
export const CustomFild_REVIEW_SAVE_RESET = 'CustomFild_REVIEW_SAVE_RESET';

export const CustomFild_DEPENDENT_VALUE_SUCCESS = 'CustomFild_DEPENDENT_VALUE_SUCCESS';
export const CustomFild_DEPENDENT_VALUE_FAIL = 'CustomFild_DEPENDENT_VALUE_FAIL';
export const CustomFild_DEPENDENT_VALUE_REQUEST = 'CustomFild_DEPENDENT_VALUE_REQUEST';

export const CustomFild_DEPENDENCY_SAVE_SUCCESS = 'CustomFild_DEPENDENCY_SAVE_SUCCESS';
export const CustomFild_DEPENDENCY_SAVE_FAIL = 'CustomFild_DEPENDENCY_SAVE_FAIL';
export const CustomFild_DEPENDENCY_SAVE_REQUEST = 'CustomFild_DEPENDENCY_SAVE_REQUEST';

export const CustomFild_DEPENDENCY_GET_SUCCESS = 'CustomFild_DEPENDENCY_GET_SUCCESS';
export const CustomFild_DEPENDENCY_GET_FAIL = 'CustomFild_DEPENDENCY_GET_FAIL';
export const CustomFild_DEPENDENCY_GET_REQUEST = 'CustomFild_DEPENDENCY_GET_REQUEST';