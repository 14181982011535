import * as AMCnst from '../../AppConstants/AppConstants';

const intialstate = {};

function ReceiptListReducer(state = {
    ReceiptData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RECEIPT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RECEIPT_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RECEIPT_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReceiptData: action.payload
            }

        }
        default: return state;
    }

}
function EncounterListReducer(state = {
    EncounterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Encounter_LIST_Bill_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Encounter_LIST_Bill_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Encounter_LIST_Bill_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EncounterData: action.payload
            }
        }
        default: return state;
    }
}

function EncounterListForSTPReducer(state = {
    EncounterData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Encounter_LISTFORSTP_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Encounter_LISTFORSTP_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Encounter_LISTFORSTP_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EncounterData: action.payload
            }
        }
        default: return state;
    }
}

//added 1Oct2021 for Appointment Integration
function PatientAppointmentReducer(state = {
    PatientAppointmentData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientAppointment_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PatientAppointment_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PatientAppointment_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PatientAppointmentData: action.payload
            }
        }
        default: return state;
    }
}
function GetPatientAppointmentSlotsReducer(state = {
    PatientAppointmentSlotsData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientAppointmentSlots_LIST_REQUEST: {
            return { ...state, fetching: true, fetched: false, error: null }
        }
        case AMCnst.PatientAppointmentSlots_LIST_FAIL: {
            return { ...state, fetching: false, fetched: false, error: action.payload, fetched: true }
        }
        case AMCnst.PatientAppointmentSlots_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PatientAppointmentSlotsData: action.payload
            }
        }
        default: return state;
    }
}
//end added 1Oct2021 for Appointment Integraion
//added 10Dec2022 for Service Appointment
function ServiceAppointmentReducer(state = {
    ServiceAppointmentData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ServiceAppointment_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ServiceAppointment_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ServiceAppointment_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceAppointmentData: action.payload
            }
        }
        default: return state;
    }
}
//end added 10Dec2022 for Service Appointment
function ENCSTATUSListReducer(state = {
    ENCSTATUSData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ENCSTATUS_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ENCSTATUS_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ENCSTATUS_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ENCSTATUSData: action.payload
            }

        }
        default: return state;
    }

}

function DischargeListReducer(state = {
    DischargeListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DISCHARGE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DISCHARGE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DISCHARGE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DischargeListData: action.payload
            }

        }
        default: return state;
    }

}

function EncounterSaveReducer(state = {
    EncounterSaveData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Encounter_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Encounter_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Encounter_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EncounterSaveData: action.payload
            }

        }
        default: return state;
    }
}



function ReceiptSaveReducer(state = {
    ReceiptSaveData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RECEIPT_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RECEIPT_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RECEIPT_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EncoReceiptSaveDataunterSaveData: action.payload
            }

        }
        default: return state;
    }

}
function RefundSaveReducer(state = {
    RefundSaveData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Refund_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Refund_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Refund_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                RefundSaveData: action.payload
            }

        }
        default: return state;
    }

}

function fetchReceiptDetailReducer(state = {
    ReceiptDetailData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RECEIPTGETDETAILS_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RECEIPTGETDETAILS_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RECEIPTGETDETAILS_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReceiptDetailData: action.payload
            }

        }
        default: return state;
    }

}


function fetchRefundDetailReducer(state = {
    RefundDetailData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.REFUNDGETDETAILS_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.REFUNDGETDETAILS_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.REFUNDGETDETAILS_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                RefundDetailData: action.payload
            }

        }
        default: return state;
    }

}



function ServiceOrderByEncReducer(state = {
    ServiceOrderByEnc: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ServiceOrder_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ServiceOrder_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ServiceOrder_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceOrderByEnc: action.payload
            }

        }
        default: return state;
    }

}


function UnAckServiceOrderByEncReducer(state = {
    UnAckServiceOrderByEnc: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.UNACKService_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.UNACKService_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.UNACKService_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                UnAckServiceOrderByEnc: action.payload
            }

        }
        default: return state;
    }

}

function SingleServiceChargeReducer(state = {
    SingleServiceCharge: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SingleServiceCharge_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SingleServiceCharge_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SingleServiceCharge_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SingleServiceCharge: action.payload
            }

        }
        default: return state;
    }

}

function ServiceCancelReducer(state = {
    ServiceCancelData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ServCxl_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ServCxl_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ServCxl_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceCancelData: action.payload
            }

        }
        default: return state;
    }

}


function ServiceOrderSaveReducer(state = {
    ServiceOrdData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ServOrd_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ServOrd_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ServOrd_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ServiceOrdData: action.payload
            }

        }
        default: return state;
    }

}


function ServiceOrderReducer(state = {
    UnBillServiceOrder: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.UnBillServiceOrder_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.UnBillServiceOrder_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.UnBillServiceOrder_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                UnBillServiceOrder: action.payload
            }

        }
        default: return state;
    }

}

function SurgeryOrderReducer(state = {
    SurgeryServiceOrder: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SurgeryServiceOrder_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SurgeryServiceOrder_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SurgeryServiceOrder_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SurgeryServiceOrder: action.payload
            }

        }
        default: return state;
    }

}

function IPUnBillServiceOrderReducer(state = {
    IPUnBillServiceOrder: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPUnBillServiceOrder_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPUnBillServiceOrder_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPUnBillServiceOrder_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IPUnBillServiceOrder: action.payload
            }

        }
        default: return state;
    }

}

function IPSupplServiceDtlReducer(state = {
    IPSupplServiceDtl: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPSupplServiceDtl_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPSupplServiceDtl_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPSupplServiceDtl_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IPSupplServiceDtl: action.payload
            }

        }
        default: return state;
    }

}


function DocIPVisitReducer(state = {
    DocIPVisit: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.DOCIPVISIT_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.DOCIPVISIT_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.DOCIPVISIT_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DocIPVisit: action.payload
            }

        }
        default: return state;
    }

}


function fetchCompanyChangeRateReducer(state = {
    CompanyChangeRate: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CompanyChangeRate_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CompanyChangeRate_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CompanyChangeRate_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CompanyChangeRate: action.payload
            }

        }
        default: return state;
    }

}
function SaveServiceDetailRateChange_Reducer(state = {
    SaveServiceDTLRateChange: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SaveServiceDetailRateChange_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SaveServiceDetailRateChange_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SaveServiceDetailRateChange_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveServiceDTLRateChange: action.payload
            }
        }
        default: return state;
    }
}
function OPSaveInvoiceReducer(state = {
    OPInvoiceData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.OPInvoice_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.OPInvoice_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.OPInvoice_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                OPInvoiceData: action.payload
            }
        }
        default: return state;
    }
}
function SearchOPInvoiceReducer(state = {
    SearchOPInvoiceData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.OPSEARCHINVOICE_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.OPSEARCHINVOICE_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.OPSEARCHINVOICE_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SearchOPInvoiceData: action.payload
            }
        }
        default: return state;
    }
}
function SearchOPInvoiceDetailDataReducer(state = {
    SearchOPInvoiceDetailData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.OPSEARCHINVOICEData_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.OPSEARCHINVOICEData_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.OPSEARCHINVOICEData_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SearchOPInvoiceDetailData: action.payload
            }
        }
        default: return state;
    }
}

function PrintOPInvoiceDetailDataReducer(state = {
    PrintOPInvoiceDetailData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.OPPRINTINVOICEData_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.OPPRINTINVOICEData_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.OPPRINTINVOICEData_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PrintOPInvoiceDetailData: action.payload
            }
        }
        default: return state;
    }
}

function InvSearchWithoutBillDataReducer(state = {
    InvSearchWithoutBillData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.INVSEARCHWITHOUTBILLDATA_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.INVSEARCHWITHOUTBILLDATA_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.INVSEARCHWITHOUTBILLDATA_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                InvSearchWithoutBillData: action.payload
            }
        }
        default: return state;
    }
}

function SearchConsultationReducer(state = {
    SearchConsultationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CONSULTAION_CHARGE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CONSULTAION_CHARGE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CONSULTAION_CHARGE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SearchConsultationData: action.payload
            }
        }
        default: return state;
    }
}


function SearchAdmittedPatient(state = {
    AdmittedList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPPATIENT_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPPATIENT_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPPATIENT_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                AdmittedList: action.payload
            }
        }
        default: return state;
    }
}

function IPBillDeptSummaryReducer(state = {
    IPBillDeptSumm: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPBILLSUMM_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPBILLSUMM_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPBILLSUMM_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IPBillDeptSumm: action.payload
            }
        }
        default: return state;
    }
}
function OPSaveRefundReducer(state = {
    OPRefundeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.OPRefund_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.OPRefund_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.OPRefund_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                OPRefundeData: action.payload
            }
        }
        default: return state;
    }
}

function ENCCXLSERReducer(state = {
    ENCCXLSERData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ENCCXLSER_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ENCCXLSER_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ENCCXLSER_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ENCCXLSERData: action.payload
            }

        }
        default: return state;
    }

}

function srchADMListReducer(state = {
    srchADMListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.srchADMList_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.srchADMList_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.srchADMList_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                srchADMListData: action.payload
            }

        }
        default: return state;
    }

}

function IPbillPackageSummaryReducer(state = {
    IPbillPackageSummaryData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPbillPackageSummary_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPbillPackageSummary_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPbillPackageSummary_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IPbillPackageSummaryData: action.payload
            }

        }
        default: return state;
    }

}

function UpdateOrderReducer(state = {
    UpdateOrderReducer: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ORDERUPDATE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ORDERUPDATE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ORDERUPDATE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                UpdateOrderReducer: action.payload
            }

        }
        default: return state;
    }

}

function updBillPayCatReducer(state = {
    updBillPayCatReducer: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.updBillPayCat_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.updBillPayCat_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.updBillPayCat_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                updBillPayCatReducer: action.payload
            }

        }
        default: return state;
    }

}


function RoomRentSaveReducer(state = {
    RoomRentSaveData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RoomRent_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RoomRent_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RoomRent_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                RoomRentSaveData: action.payload
            }

        }
        default: return state;
    }

}

function savenursingDischargeReducer(state = {
    savenursingDischargeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.NurDis_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.NurDis_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.NurDis_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                savenursingDischargeData: action.payload
            }

        }
        default: return state;
    }

}

function saveDischargeReducer(state = {
    saveDischargeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Discharge_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Discharge_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Discharge_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveDischargeData: action.payload
            }

        }
        default: return state;
    }

}

function Ip_Bill_Settlement_InvoiceReducer(state = {
    IpBIllSattlementInvoiceData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Ip_bill_settlement_byInvoiceId_List_request: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Ip_bill_settlement_byInvoiceId_List_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Ip_bill_settlement_byInvoiceId_List_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IpBIllSattlementInvoiceData: action.payload
            }

        }
        default: return state;
    }

}

function Ip_Bill_Settlemen_Save_Reducer(state = {
    IpBIllSattlementSaveData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.Ip_bill_settlement_save_request: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.Ip_bill_settlement_save_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.Ip_bill_settlement_save_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IpBIllSattlementSaveData: action.payload
            }

        }
        default: return state;
    }

}

function IPbillVerifyReducer(state = {
    IPbillVerifyData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPbillVerify_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPbillVerify_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPbillVerify_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IPbillVerifyData: action.payload
            }

        }
        default: return state;
    }

}
function TPAClearanceReducer(state = {
    TPAClearanceData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.TPAClearance_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.TPAClearance_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.TPAClearance_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                TPAClearanceData: action.payload
            }

        }
        default: return state;
    }

}



function IPPackServiceDtlReducer(state = {
    IPPackServiceDtlData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPPackServiceDtl_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPPackServiceDtl_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPPackServiceDtl_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IPPackServiceDtlData: action.payload
            }

        }
        default: return state;
    }

}



function IncExlPckDtlReducer(state = {
    IncExlPckDtlData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IncExlPckDtl_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IncExlPckDtl_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IncExlPckDtl_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IncExlPckDtlData: action.payload
            }

        }
        default: return state;
    }

}

function IncExlSuppDtlReducer(state = {
    IncExlSuppDtlData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IncExlSuppDtl_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IncExlSuppDtl_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IncExlSuppDtl_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IncExlSuppDtlData: action.payload
            }

        }
        default: return state;
    }

}

function saveIPInvoiceReducer(state = {
    saveIPInvoiceData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.saveIPInvoice_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.saveIPInvoice_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.saveIPInvoice_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveIPInvoiceData: action.payload
            }

        }
        default: return state;
    }

}
function saveDischargeIntimationDataReducer(state = {
    saveDischargeIntimationData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.save_DischargeIntimation_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.save_DischargeIntimation_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.save_DischargeIntimation_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveDischargeIntimationData: action.payload
            }

        }
        default: return state;
    }

}
function PatientUnSettleAdvReducer(state = {
    PatientUnSettleAdvData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientUnSettleAdv_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PatientUnSettleAdv_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PatientUnSettleAdv_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PatientUnSettleAdvData: action.payload
            }

        }
        default: return state;
    }

}

function saveFinancialClearanceReducer(state = {
    saveFinancialClearanceData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.saveFinancialClearance_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.saveFinancialClearance_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.saveFinancialClearance_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveFinancialClearanceData: action.payload
            }

        }
        default: return state;
    }

}
function GetSurgeryPackageChargesReducer(state = {
    GetSurgeryPackageCharges: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SurgeryPackageCharges_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SurgeryPackageCharges_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SurgeryPackageCharges_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetSurgeryPackageCharges: action.payload
            }

        }
        default: return state;
    }
}
function GetPackageSurgeonChargesReducer(state = {
    GetPackageSurgeonCharges: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PackageSurgeonCharges_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PackageSurgeonCharges_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PackageSurgeonCharges_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetPackageSurgeonCharges: action.payload
            }

        }
        default: return state;
    }
}

function saveCalculatePackage(state = {
    savePackageCalculate: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.CalculatePackage_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.CalculatePackage_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.CalculatePackage_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                savePackageCalculate: action.payload
            }

        }
        default: return state;
    }

}
function SaveSurgeryPackageDataReducer(state = {
    SaveSurgeryPackageData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SaveSurgeryPackageOrder_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SaveSurgeryPackageOrder_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.SaveSurgeryPackageOrder_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveSurgeryPackageData: action.payload
            }

        }
        default: return state;
    }
}

function InsEncRecTagReducer(state = {
    InsEncRecTagData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.InsEncRecTag_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.InsEncRecTag_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.InsEncRecTag_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                InsEncRecTagData: action.payload
            }

        }
        default: return state;
    }
}
function getAdvanceHeadReducer(state = {
    getAdvanceHeadData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.AdvanceHead_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.AdvanceHead_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.AdvanceHead_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getAdvanceHeadData: action.payload
            }

        }
        default: return state;
    }
}


function invoiceSettlementDataReducer(state = {
    invoiceSettlementData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.invoiceSettlementData_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.invoiceSettlementData_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.invoiceSettlementData_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                invoiceSettlementData: action.payload
            }

        }
        default: return state;
    }

}


function cxlIPBillInitiateReducer(state = {
    cxlIPBillInitiateData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.cxlIPBillInitiate_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.cxlIPBillInitiate_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.cxlIPBillInitiate_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                cxlIPBillInitiateData: action.payload
            }

        }
        default: return state;
    }

}


function NoteSaveReducer(state = {
    NoteSaveReducer: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.NoteSave_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.NoteSave_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.NoteSave_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                NoteSaveReducer: action.payload
            }

        }
        default: return state;
    }

}


function NoteFetchReducer(state = {
    NoteFetchReducer: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.NoteList_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.NoteList_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.NoteList_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                NoteFetchReducer: action.payload
            }

        }
        default: return state;
    }

}

function cxlIPBillDischargeReducer(state = {
    cxlIPBillDischargeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.cxlIPBillDischarge_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.cxlIPBillDischarge_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.cxlIPBillDischarge_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                cxlIPBillDischargeData: action.payload
            }

        }
        default: return state;
    }

}
function getPkgDtlByRegId(state = {
    getPkgDtlByRegId: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PACKAGEMULREG_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PACKAGEMULREG_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PACKAGEMULREG_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getPkgDtlByRegId: action.payload
            }

        }
        default: return state;
    }

}
function getPkgRenDtlBypkgId(state = {
    getPkgRenDtlBypkgId: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PACKAGEMULREGDET_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PACKAGEMULREGDET_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PACKAGEMULREGDET_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getPkgRenDtlBypkgId: action.payload
            }

        }
        default: return state;
    }

}
function savePackageRenderReducer(state = {
    savePackageRenderReducer: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PACKAGERENDERSAVE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PACKAGERENDERSAVE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PACKAGERENDERSAVE_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                savePackageRenderReducer: action.payload
            }

        }
        default: return state;
    }

}
function GetPackageRenderDetailsReducer(state = {
    GetPackageRenderDetails: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.OPPackageRender_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.OPPackageRender_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.OPPackageRender_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetPackageRenderDetails: action.payload
            }

        }
        default: return state;
    }

}



function cxlIPFinancialClearanceReducer(state = {
    cxlIPFinancialClearanceData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.cxlIPFinancialClearance_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.cxlIPFinancialClearance_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.cxlIPFinancialClearance_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                cxlIPFinancialClearanceData: action.payload
            }

        }
        default: return state;
    }

}

function EmplScrollSummaryReducer(state = {
    EmplScrollSummaryData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EmplScrollSummary_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EmplScrollSummary_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EmplScrollSummary_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EmplScrollSummaryData: action.payload
            }

        }
        default: return state;
    }

}

function EmplScrollSummaryPHReducer(state = {
    EmplScrollSummaryPHData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EmplScrollSummaryPH_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EmplScrollSummaryPH_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EmplScrollSummaryPH_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EmplScrollSummaryPHData: action.payload
            }

        }
        default: return state;
    }

}

function fetchPatientLedgerReducer(state = {
    PatientLedgerData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PatientLedger_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PatientLedger_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PatientLedger_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PatientLedgerData: action.payload
            }

        }
        default: return state;
    }

}

function InsRoomRentAutoReducer(state = {
    InsRoomRentAutoData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.InsRoomRentAuto_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.InsRoomRentAuto_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.InsRoomRentAuto_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                InsRoomRentAutoData: action.payload
            }

        }
        default: return state;
    }

}


function getEmpLastSubmitScrollReducer(state = {
    getEmpLastSubmitScrollData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.getEmpLastSubmitScroll_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.getEmpLastSubmitScroll_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.getEmpLastSubmitScroll_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getEmpLastSubmitScrollData: action.payload
            }

        }
        default: return state;
    }

}

function fetchSearchReceiptDataReducer(state = {
    getReceiptSearch: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.RECEIPT_SEARCH_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.RECEIPT_SEARCH_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.RECEIPT_SEARCH_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getReceiptSearch: action.payload
            }

        }
        default: return state;
    }

}

function saveCashierScrollReducer(state = {
    saveCashierScroll: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.saveCashierScroll_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.saveCashierScroll_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.saveCashierScroll_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveCashierScroll: action.payload
            }
        }
        default: return state;
    }
}

function getEmpFirstSubmitCollecReducer(state = {
    getEmpFirstSubmitCollecData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.getEmpFirstSubmitCollec_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.getEmpFirstSubmitCollec_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.getEmpFirstSubmitCollec_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getEmpFirstSubmitCollecData: action.payload
            }

        }
        default: return state;
    }

}
function fetchRefundSearchDetailsReducer(state = {
    getRefundSearch: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.REFUND_SEARCH_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.REFUND_SEARCH_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.REFUND_SEARCH_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getRefundSearch: action.payload
            }

        }
        default: return state;
    }

}


function getScrollDataReducer(state = {
    getScrollData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.getScrollData_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.getScrollData_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.getScrollData_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getScrollData: action.payload
            }

        }
        default: return state;
    }

}

function getScrollListReducer(state = {
    getScrollList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.getScrollList_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.getScrollList_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.getScrollList_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getScrollList: action.payload
            }

        }
        default: return state;
    }

}


function saveCashierCollectionReducer(state = {
    saveCashierCollection: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.saveCashierCollection_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.saveCashierCollection_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.saveCashierCollection_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveCashierCollection: action.payload
            }
        }
        default: return state;
    }
}
function SavePrescriptionOrderReducer(state = {
    SavePrescriptionOrderData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PrescriptionOrder_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PrescriptionOrder_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PrescriptionOrder_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SavePrescriptionOrderData: action.payload
            }
        }
        default: return state;
    }
}
function ERSaveServiceOrderReducer(state = {
    ERSaveServiceOrderData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ERServiceOrder_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ERServiceOrder_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ERServiceOrder_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ERSaveServiceOrderData: action.payload
            }
        }
        default: return state;
    }
}


function fetchERTestOrderReducer(state = {
    ERTestOrderData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.TESTORDER_SEARCH_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.TESTORDER_SEARCH_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.TESTORDER_SEARCH_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ERTestOrderData: action.payload
            }
        }
        default: return state;
    }
}

function cxlIPInvoiceReducer(state = {
    cxlIPInvoiceData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.cxlIPInvoice_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.cxlIPInvoice_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.cxlIPInvoice_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                cxlIPInvoiceData: action.payload
            }
        }
        default: return state;
    }
}


function cxlIPDischargeReducer(state = {
    cxlIPDischargeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.cxlIPDischarge_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.cxlIPDischarge_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.cxlIPDischarge_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                cxlIPDischargeData: action.payload
            }
        }
        default: return state;
    }
}

function updCSDtlReducer(state = {
    updCSDtl: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.updCSDtl_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.updCSDtl_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.updCSDtl_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                updCSDtl: action.payload
            }
        }
        default: return state;
    }
}

function ERPatientDetailsListReducer(state = {
    ERPatientDetailsListData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ERPatientDetailsList_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ERPatientDetailsList_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ERPatientDetailsList_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ERPatientDetailsListData: action.payload
            }

        }
        default: return state;
    }

}

function EmplScrollDtlReducer(state = {
    EmplScrollDtl: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EmplScrollDtl_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EmplScrollDtl_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EmplScrollDtl_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EmplScrollDtl: action.payload
            }

        }
        default: return state;
    }

}

function EmplScrollDtlPHReducer(state = {
    EmplScrollDtlPH: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.EmplScrollDtlPH_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.EmplScrollDtlPH_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.EmplScrollDtlPH_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                EmplScrollDtlPH: action.payload
            }

        }
        default: return state;
    }

}


function billListByStatusReducer(state = {
    billListByStatus: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.billListByStatus_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.billListByStatus_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.billListByStatus_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                billListByStatus: action.payload
            }

        }
        default: return state;
    }
}
function billListForVerificationReducer(state = {
    billListForVerification: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.billListForVerification_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.billListForVerification_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.billListForVerification_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                billListForVerification: action.payload
            }

        }
        default: return state;
    }
}
function billListForDispatchReducer(state = {
    billListForDispatch: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.billListForDispatch_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.billListForDispatch_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.billListForDispatch_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                billListForDispatch: action.payload
            }

        }
        default: return state;
    }
}
function billListDispatchedReducer(state = {
    billListDispatched: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.billListDispatched_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.billListDispatched_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.billListDispatched_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                billListDispatched: action.payload
            }

        }
        default: return state;
    }
}


function cxlTPAClearanceReducer(state = {
    cxlTPAClearance: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.cxlTPAClearance_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.cxlTPAClearance_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.cxlTPAClearance_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                cxlTPAClearance: action.payload
            }

        }
        default: return state;
    }
}

function cxlInvoiceSettlementReducer(state = {
    cxlInvoiceSettlement: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.cxlInvoiceSettlement_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.cxlInvoiceSettlement_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.cxlInvoiceSettlement_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                cxlInvoiceSettlement: action.payload
            }

        }
        default: return state;
    }
}
function saveBillSettlementReducer(state = {
    saveBillSettlement: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.saveBillSettlement_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.saveBillSettlement_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.saveBillSettlement_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveBillSettlement: action.payload
            }

        }
        default: return state;
    }
}

function saveWriteOffReducer(state = {
    saveWriteOff: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.saveWriteOff_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.saveWriteOff_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.saveWriteOff_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveWriteOff: action.payload
            }

        }
        default: return state;
    }
}
function getBillAdjustDtlReducer(state = {
    getBillAdjustDtl: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.getBillAdjustDtl_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.getBillAdjustDtl_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.getBillAdjustDtl_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getBillAdjustDtl: action.payload
            }

        }
        default: return state;
    }
}

function getBillDispatchNoReducer(state = {
    getBillDispatchNo: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.getBillDispatchNo_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.getBillDispatchNo_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.getBillDispatchNo_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getBillDispatchNo: action.payload
            }

        }
        default: return state;
    }
}

function getwriteoffListReducer(state = {
    getwriteoffList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.getwriteoffList_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.getwriteoffList_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.getwriteoffList_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getwriteoffList: action.payload
            }

        }
        default: return state;
    }
}

function getwriteoffNoDtlReducer(state = {
    getwriteoffNoDtl: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.getwriteoffNoDtl_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.getwriteoffNoDtl_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.getwriteoffNoDtl_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getwriteoffNoDtl: action.payload
            }

        }
        default: return state;
    }
}

function getServDtlCrNoteReducer(state = {
    getServDtlCrNote: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.getServDtlCrNote_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.getServDtlCrNote_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.getServDtlCrNote_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getServDtlCrNote: action.payload
            }

        }
        default: return state;
    }
}

function saveDisAllowedReducer(state = {
    saveDisAllowed: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.saveDisAllowed_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.saveDisAllowed_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.saveDisAllowed_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveDisAllowed: action.payload
            }

        }
        default: return state;
    }
}

function getCreditNoteListReducer(state = {
    getCreditNoteList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.getCreditNoteList_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.getCreditNoteList_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.getCreditNoteList_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getCreditNoteList: action.payload
            }

        }
        default: return state;
    }
}


function approveWriteOffReducer(state = {
    approveWriteOff: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.approveWriteOff_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.approveWriteOff_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.approveWriteOff_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                approveWriteOff: action.payload
            }

        }
        default: return state;
    }
}


function approveDisAllowedReducer(state = {
    approveDisAllowed: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.approveDisAllowed_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.approveDisAllowed_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.approveDisAllowed_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                approveDisAllowed: action.payload
            }

        }
        default: return state;
    }
}


function IPPayableServiceDtlReducer(state = {
    IPPayableServiceDtl: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPPayableServiceDtl_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPPayableServiceDtl_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IPPayableServiceDtl_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IPPayableServiceDtl: action.payload
            }

        }
        default: return state;
    }

}
function IncExlPayableDtlReducer(state = {
    IncExlPayableDtlData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IncExlPayableDtl_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IncExlPayableDtl_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.IncExlPayableDtl_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                IncExlPayableDtlData: action.payload
            }

        }
        default: return state;
    }

}
function SampleDataListReducer(state = {
    SampleData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SampleData_LIST_REQUEST: { return { ...state, fetching: true } }
        case AMCnst.SampleData_LIST_FAIL: { return { ...state, fetching: false, error: action.payload } }
        case AMCnst.SampleData_LIST_SUCCESS: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                SampleData: action.payload
            }

        }
        default: return state;
    }

}
function ServiceDetailsDataListReducer(state = {
    ServiceDetailsData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ServiceDetails_LIST_REQUEST: { return { ...state, fetching: true } }
        case AMCnst.ServiceDetails_LIST_FAIL: { return { ...state, fetching: false, error: action.payload } }
        case AMCnst.ServiceDetails_LIST_SUCCESS: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                ServiceDetailsData: action.payload
            }

        }
        default: return state;
    }

}
function getSampleTATReducer(state = {
    getSampleTATData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SampleTAT_LIST_REQUEST: { return { ...state, fetching: true } }
        case AMCnst.SampleTAT_LIST_FAIL: { return { ...state, fetching: false, error: action.payload } }
        case AMCnst.SampleTAT_LIST_SUCCESS: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                getSampleTATData: action.payload
            }

        }
        default: return state;
    }

}
function sendLISRISReportInterfacedReducer(state = {
    sendLISRISReportInterfacedData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.sendLISRISReportInterfaced_LIST_REQUEST: { return { ...state, fetching: true } }
        case AMCnst.sendLISRISReportInterfaced_LIST_FAIL: { return { ...state, fetching: false, error: action.payload } }
        case AMCnst.sendLISRISReportInterfaced_LIST_SUCCESS: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                sendLISRISReportInterfacedData: action.payload
            }

        }
        default: return state;
    }

}
function fetchPendingPharmacyOrdersReducer(state = {
    PendingPharmacyOrders: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.PendingOrder_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.PendingOrder_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.PendingOrder_SUCCESS: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                PendingPharmacyOrders: action.payload
            }

        }
        default: return state;
    }

}

function updateAdvanceTypeReducer(state = {
    updateAdvanceType: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.UPDATE_ADVANCETYPE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.UPDATE_ADVANCETYPE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.UPDATE_ADVANCETYPE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                updateAdvanceType: action.payload
            }

        }
        default: return state;
    }

}


function GetReceiptAdvanceTypeAuditReducer(state = {
    ReceiptAdvanceTypeAudit: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GetReceiptAdvanceTypeAudit_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GetReceiptAdvanceTypeAudit_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.GetReceiptAdvanceTypeAudit_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ReceiptAdvanceTypeAudit: action.payload
            }

        }
        default: return state;
    }

}
function getScrollUserReducer(state = {
    getScrollUserData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ScrollUser_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ScrollUser_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ScrollUser_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getScrollUserData: action.payload
            }

        }
        default: return state;
    }

}

function GetPayorChangeDetailsReducer(state = {
    getPayorChangeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.IPPACKAGE_Get_Payor_Change_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.IPPACKAGE_Get_Payor_Change_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload } }
        case AMCnst.IPPACKAGE_Get_Payor_Change_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getPayorChangeData: action.payload
            }
        }
        default: return state;
    }

}
function ProcedureTypeServiceDataListReducer(state = {
    ProcedureTypeServiceData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ProcedureTypeData_LIST_REQUEST: { return { ...state, fetching: true } }
        case AMCnst.ProcedureTypeData_LIST_FAIL: { return { ...state, fetching: false, error: action.payload } }
        case AMCnst.ProcedureTypeData_LIST_SUCCESS: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                ProcedureTypeServiceData: action.payload
            }

        }
        default: return state;
    }

}

function SendDiscSummToClaimBookReducer(state = {
    SendDiscSummToClaimBookData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SendDiscSummToClaimBook_LIST_REQUEST: { return { ...state, fetching: true } }
        case AMCnst.SendDiscSummToClaimBook_LIST_FAIL: { return { ...state, fetching: false, error: action.payload } }
        case AMCnst.SendDiscSummToClaimBook_LIST_SUCCESS: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                SendDiscSummToClaimBookData: action.payload
            }

        }
        default: return state;
    }

}
function saveInvoiceVerificationReducer(state = {
    saveInvoiceVerification: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.saveInvoiceVerification_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.saveInvoiceVerification_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.saveInvoiceVerification_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveInvoiceVerification: action.payload
            }

        }
        default: return state;
    }
}

function SaveOtherdetailDataReducer(state = {
    SaveOtherdetailData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SaveOtherdetailData_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SaveOtherdetailData_FAIL: { return { ...state, fetching: false, fetched: true, error: action.payload, fetched: true } }
        case AMCnst.SaveOtherdetailData_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveOtherdetailData: action.payload
            }
        }
        default: return state;
    }
}

export {
    GetReceiptAdvanceTypeAuditReducer,
    savePackageRenderReducer, GetPackageRenderDetailsReducer, getPkgRenDtlBypkgId, getPkgDtlByRegId, EncounterListReducer, EncounterSaveReducer, ReceiptListReducer,
    ReceiptSaveReducer, RefundSaveReducer, ServiceOrderByEncReducer, ServiceOrderReducer, ServiceCancelReducer, fetchReceiptDetailReducer, ServiceOrderSaveReducer,
    SingleServiceChargeReducer, fetchRefundDetailReducer, fetchCompanyChangeRateReducer, SaveServiceDetailRateChange_Reducer, OPSaveInvoiceReducer, SearchOPInvoiceReducer, SearchOPInvoiceDetailDataReducer,
    InvSearchWithoutBillDataReducer, SearchConsultationReducer, SearchAdmittedPatient, IPBillDeptSummaryReducer, IPUnBillServiceOrderReducer, OPSaveRefundReducer,
    DocIPVisitReducer, ENCCXLSERReducer, UnAckServiceOrderByEncReducer, IPSupplServiceDtlReducer, srchADMListReducer, IPbillPackageSummaryReducer, UpdateOrderReducer,
    RoomRentSaveReducer, saveDischargeReducer, Ip_Bill_Settlement_InvoiceReducer, Ip_Bill_Settlemen_Save_Reducer, IPbillVerifyReducer, IPPackServiceDtlReducer,
    IncExlPckDtlReducer, IncExlSuppDtlReducer, saveIPInvoiceReducer, updBillPayCatReducer, PatientUnSettleAdvReducer, saveFinancialClearanceReducer,
    GetSurgeryPackageChargesReducer, GetPackageSurgeonChargesReducer, ENCSTATUSListReducer, saveCalculatePackage, SaveSurgeryPackageDataReducer,
    InsEncRecTagReducer, saveDischargeIntimationDataReducer, getAdvanceHeadReducer, DischargeListReducer, invoiceSettlementDataReducer,
    cxlIPBillInitiateReducer, NoteSaveReducer, NoteFetchReducer, cxlIPBillDischargeReducer, cxlIPFinancialClearanceReducer, EmplScrollSummaryReducer, fetchPatientLedgerReducer, InsRoomRentAutoReducer, getEmpLastSubmitScrollReducer, fetchSearchReceiptDataReducer, saveCashierScrollReducer, getEmpFirstSubmitCollecReducer, getScrollDataReducer, fetchRefundSearchDetailsReducer, getScrollListReducer, saveCashierCollectionReducer, ERSaveServiceOrderReducer, fetchERTestOrderReducer, cxlIPInvoiceReducer, cxlIPDischargeReducer, SavePrescriptionOrderReducer, updCSDtlReducer,
    ERPatientDetailsListReducer, EmplScrollDtlReducer, TPAClearanceReducer, billListByStatusReducer, PrintOPInvoiceDetailDataReducer, cxlTPAClearanceReducer, savenursingDischargeReducer, cxlInvoiceSettlementReducer, getBillAdjustDtlReducer, saveBillSettlementReducer, PatientAppointmentReducer, getBillDispatchNoReducer, saveWriteOffReducer, getwriteoffListReducer, getwriteoffNoDtlReducer, getServDtlCrNoteReducer, saveDisAllowedReducer, getCreditNoteListReducer, approveWriteOffReducer, approveDisAllowedReducer, GetPatientAppointmentSlotsReducer
    , IPPayableServiceDtlReducer, IncExlPayableDtlReducer, SampleDataListReducer, ServiceDetailsDataListReducer, sendLISRISReportInterfacedReducer, fetchPendingPharmacyOrdersReducer, getSampleTATReducer, updateAdvanceTypeReducer, ServiceAppointmentReducer, getScrollUserReducer, SurgeryOrderReducer, GetPayorChangeDetailsReducer, ProcedureTypeServiceDataListReducer, billListForVerificationReducer, SendDiscSummToClaimBookReducer,
    billListForDispatchReducer, billListDispatchedReducer, saveInvoiceVerificationReducer, EmplScrollSummaryPHReducer, EmplScrollDtlPHReducer,SaveOtherdetailDataReducer,EncounterListForSTPReducer
}