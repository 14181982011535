export const FORMGROUP_LIST_REQUEST = 'FORMGROUP_LIST_REQUEST';
export const FORMGROUP_LIST_SUCCESS = 'FORMGROUP_LIST_SUCCESS';
export const FORMGROUP_LIST_FAIL = 'FORMGROUP_LIST_FAIL';


export const FORMGROUP_SAVE_REQUEST = 'FORMGROUP_SAVE_REQUEST';
export const FORMGROUP_SAVE_SUCCESS = 'FORMGROUP_SAVE_SUCCESS';
export const FORMGROUP_SAVE_FAIL = 'FORMGROUP_SAVE_FAIL';

// export const FORMSUBCATEGORY_LIST_REQUEST = 'FORMSUBCATEGORY_LIST_REQUEST';
// export const FORMSUBCATEGORY_LIST_SUCCESS = 'FORMSUBCATEGORY_LIST_SUCCESS';
// export const FORMSUBCATEGORY_LIST_FAIL = 'FORMSUBCATEGORY_LIST_FAIL';


export const FORMSUBCATEGORY_SAVE_REQUEST = 'FORMSUBCATEGORY_SAVE_REQUEST';
export const FORMSUBCATEGORY_SAVE_SUCCESS = 'FORMSUBCATEGORY_SAVE_SUCCESS';
export const FORMSUBCATEGORY_SAVE_FAIL = 'FORMSUBCATEGORY_SAVE_FAIL';

export const FORMMASTER_SAVE_REQUEST = 'FORMMASTER_SAVE_REQUEST';
export const FORMMASTER_SAVE_SUCCESS = 'FORMMASTER_SAVE_SUCCESS';
export const FORMMASTER_SAVE_FAIL = 'FORMMASTER_SAVE_FAIL';


export const FORMMASTER_LIST_REQUEST = 'FORMMASTER_LIST_REQUEST';
export const FORMMASTER_LIST_SUCCESS = 'FORMMASTER_LIST_SUCCESS';
export const FORMMASTER_LIST_FAIL = 'FORMMASTER_LIST_FAIL';

export const FORMSUBCATEGORY_LIST_REQUEST = 'FORMSUBCATEGORY_LIST_REQUEST';
export const FORMSUBCATEGORY_LIST_SUCCESS = 'FORMSUBCATEGORY_LIST_SUCCESS';
export const FORMSUBCATEGORY_LIST_FAIL = 'FORMSUBCATEGORY_LIST_FAIL';

export const FORMPARAMTER_SAVE_REQUEST = 'FORMPARAMTER_SAVE_REQUEST';
export const FORMPARAMTER_SAVE_SUCCESS = 'FORMPARAMTER_SAVE_SUCCESS';
export const FORMPARAMTER_SAVE_FAIL = 'FORMPARAMTER_SAVE_FAIL';


export const FORMPARAMTER_LIST_REQUEST = 'FORMPARAMTER_LIST_REQUEST';
export const FORMPARAMTER_LIST_SUCCESS = 'FORMPARAMTER_LIST_SUCCESS';
export const FORMPARAMTER_LIST_FAIL = 'FORMPARAMTER_LIST_FAIL';
export const ComponentMasters_SAVE_REQUEST = 'ComponentMasters_SAVE_REQUEST';
export const ComponentMasters_SAVE_SUCCESS = 'ComponentMasters_SAVE_SUCCESS';
export const ComponentMasters_SAVE_FAIL = 'ComponentMasters_SAVE_FAIL';


export const ComponentMasters_LIST_REQUEST = 'ComponentMasters_LIST_REQUEST';
export const ComponentMasters_LIST_SUCCESS = 'ComponentMasters_LIST_SUCCESS';
export const ComponentMasters_LIST_FAIL = 'ComponentMasters_LIST_FAIL';

export const ListComponent_LIST_REQUEST = 'ListComponent_LIST_REQUEST';
export const ListComponent_LIST_SUCCESS = 'ListComponent_LIST_SUCCESS';
export const ListComponent_LIST_FAIL = 'ListComponent_LIST_FAIL';

export const ViewImage_LIST_REQUEST = 'ViewImage_LIST_REQUEST';
export const ViewImage_LIST_SUCCESS = 'ViewImage_LIST_SUCCESS';
export const ViewImage_LIST_FAIL = 'ViewImage_LIST_FAIL';

export const frmDataObjectList_REQUEST = 'frmDataObjectList_REQUEST';
export const frmDataObjectList_SUCCESS = 'frmDataObjectList_SUCCESS';
export const frmDataObjectList_FAIL = 'frmDataObjectList_FAIL';

export const dataObjectClinicalOtherSetup_REQUEST = 'dataObjectClinicalOtherSetup_REQUEST';
export const dataObjectClinicalOtherSetup_SUCCESS = 'dataObjectClinicalOtherSetup_SUCCESS';
export const dataObjectClinicalOtherSetup_FAIL = 'dataObjectClinicalOtherSetup_FAIL';

export const formReportData_REQUEST = 'formReportData_REQUEST';
export const formReportData_SUCCESS = 'formReportData_SUCCESS';
export const formReportData_FAIL = 'formReportData_FAIL';

export const patientInfoList_REQUEST = 'patientInfoList_REQUEST';
export const patientInfoList_SUCCESS = 'patientInfoList_SUCCESS';
export const patientInfoList_FAIL = 'patientInfoList_FAIL';
