export const StoreUserAccessAudit_REQUEST = 'StoreUserAccessAudit_REQUEST';
export const StoreUserAccessAudit_SUCCESS = 'StoreUserAccessAudit_SUCCESS';
export const StoreUserAccessAudit_FAIL = 'StoreUserAccessAudit_FAIL';

export const ItemCategory_LIST_REQUEST = 'ItemCategory_LIST_REQUEST';
export const ItemCategory_LIST_SUCCESS = 'ItemCategory_LIST_SUCCESS';
export const ItemCategory_LIST_FAIL = 'ItemCategory_LIST_FAIL';

export const EmpUpdateTimeForExportCSV_LIST_REQUEST = 'EmpUpdateTimeForExportCSV_LIST_REQUEST';
export const EmpUpdateTimeForExportCSV_LIST_SUCCESS = 'EmpUpdateTimeForExportCSV_LIST_SUCCESS';
export const EmpUpdateTimeForExportCSV_LIST_FAIL = 'EmpUpdateTimeForExportCSV_LIST_FAIL';

export const INVSTATUSBYTYPE_LIST_REQUEST = 'INVSTATUSBYTYPE_LIST_REQUEST';
export const INVSTATUSBYTYPE_LIST_SUCCESS = 'INVSTATUSBYTYPE_LIST_SUCCESS';
export const INVSTATUSBYTYPE_LIST_FAIL = 'INVSTATUSBYTYPE_LIST_FAIL';


export const STATUSBYTYPE_LIST_REQUEST = 'STATUSBYTYPE_LIST_REQUEST';
export const STATUSBYTYPE_LIST_SUCCESS = 'STATUSBYTYPE_LIST_SUCCESS';
export const STATUSBYTYPE_LIST_FAIL = 'STATUSBYTYPE_LIST_FAIL';


export const ItemSubCategory_LIST_REQUEST = 'ItemSubCategory_LIST_REQUEST';
export const ItemSubCategory_LIST_SUCCESS = 'ItemSubCategory_LIST_SUCCESS';
export const ItemSubCategory_LIST_FAIL = 'ItemSubCategory_LIST_FAIL';

export const Charges_LIST_REQUEST = 'Charges_LIST_REQUEST';
export const Charges_LIST_SUCCESS = 'Charges_LIST_SUCCESS';
export const Charges_LIST_FAIL = 'Charges_LIST_FAIL';

export const ChargeSetup_LIST_REQUEST = 'ChargeSetup_LIST_REQUEST';
export const ChargeSetup_LIST_SUCCESS = 'ChargeSetup_LIST_SUCCESS';
export const ChargeSetup_LIST_FAIL = 'ChargeSetup_LIST_FAIL';

export const Charges_GetCharges_REQUEST = 'Charges_GetCharges_REQUEST';
export const Charges_GetCharges_SUCCESS = 'Charges_GetCharges_SUCCESS';
export const Charges_GetCharges_FAIL = 'Charges_GetCharges_FAIL';

export const UnitMaster_LIST_REQUEST = 'UnitMaster_LIST_REQUEST';
export const UnitMaster_LIST_SUCCESS = 'UnitMaster_LIST_SUCCESS';
export const UnitMaster_LIST_FAIL = 'UnitMaster_LIST_FAIL';

export const ItemUnitMaster_LIST_REQUEST = 'ItemUnitMaster_LIST_REQUEST';
export const ItemUnitMaster_LIST_SUCCESS = 'ItemUnitMaster_LIST_SUCCESS';
export const ItemUnitMaster_LIST_FAIL = 'ItemUnitMaster_LIST_FAIL';

export const HSNGroup_LIST_REQUEST = 'HSNGroup_LIST_REQUEST';
export const HSNGroup_LIST_SUCCESS = 'HSNGroup_LIST_SUCCESS';
export const HSNGroup_LIST_FAIL = 'HSNGroup_LIST_FAIL';

export const HSNCode_LIST_REQUEST = 'HSNCode_LIST_REQUEST';
export const HSNCode_LIST_SUCCESS = 'HSNCode_LIST_SUCCESS';
export const HSNCode_LIST_FAIL = 'HSNCode_LIST_FAIL';

export const ItemMaster_LIST_REQUEST = 'ItemMaster_LIST_REQUEST';
export const ItemMaster_LIST_SUCCESS = 'ItemMaster_LIST_SUCCESS';
export const ItemMaster_LIST_FAIL = 'ItemMaster_LIST_FAIL';

export const NewItemRequest_LIST_REQUEST = 'NewItemRequest_LIST_REQUEST';
export const NewItemRequest_LIST_SUCCESS = 'NewItemRequest_LIST_SUCCESS';
export const NewItemRequest_LIST_FAIL = 'NewItemRequest_LIST_FAIL';

export const ItemMasterBySubCat_LIST_REQUEST = 'ItemMasterBySubCat_LIST_REQUEST';
export const ItemMasterBySubCat_LIST_SUCCESS = 'ItemMasterBySubCat_LIST_SUCCESS';
export const ItemMasterBySubCat_LIST_FAIL = 'ItemMasterBySubCat_LIST_FAIL';

export const ItemCharges_LIST_REQUEST = 'ItemCharges_LIST_REQUEST';
export const ItemCharges_LIST_SUCCESS = 'ItemCharges_LIST_SUCCESS';
export const ItemCharges_LIST_FAIL = 'ItemCharges_LIST_FAIL';

export const ItemAttributes_LIST_REQUEST = 'ItemAttributes_LIST_REQUEST';
export const ItemAttributes_LIST_SUCCESS = 'ItemAttributes_LIST_SUCCESS';
export const ItemAttributes_LIST_FAIL = 'ItemAttributes_LIST_FAIL';

export const ItemwithItemUnitMapping_LIST_REQUEST = 'ItemwithItemUnitMapping_LIST_REQUEST';
export const ItemwithItemUnitMapping_LIST_SUCCESS = 'ItemwithItemUnitMapping_LIST_SUCCESS';
export const ItemwithItemUnitMapping_LIST_FAIL = 'ItemwithItemUnitMapping_LIST_FAIL';

export const ChargeTemplateMain_LIST_REQUEST = 'ChargeTemplateMain_LIST_REQUEST';
export const ChargeTemplateMain_LIST_SUCCESS = 'ChargeTemplateMain_LIST_SUCCESS';
export const ChargeTemplateMain_LIST_FAIL = 'ChargeTemplateMain_LIST_FAIL';

export const ChargeTemplateDetails_LIST_REQUEST = 'ChargeTemplateDetails_LIST_REQUEST';
export const ChargeTemplateDetails_LIST_SUCCESS = 'ChargeTemplateDetails_LIST_SUCCESS';
export const ChargeTemplateDetails_LIST_FAIL = 'ChargeTemplateDetails_LIST_FAIL';

export const ChargeTemplates_LIST_REQUEST = 'ChargeTemplates_LIST_REQUEST';
export const ChargeTemplates_LIST_SUCCESS = 'ChargeTemplates_LIST_SUCCESS';
export const ChargeTemplates_LIST_FAIL = 'ChargeTemplates_LIST_FAIL';

export const TherapeuticCategory_LIST_REQUEST = 'TherapeuticCategory_LIST_REQUEST';
export const TherapeuticCategory_LIST_SUCCESS = 'TherapeuticCategory_LIST_SUCCESS';
export const TherapeuticCategory_LIST_FAIL = 'TherapeuticCategory_LIST_FAIL';

export const TherapeuticSubCategory_LIST_REQUEST = 'TherapeuticSubCategory_LIST_REQUEST';
export const TherapeuticSubCategory_LIST_SUCCESS = 'TherapeuticSubCategory_LIST_SUCCESS';
export const TherapeuticSubCategory_LIST_FAIL = 'TherapeuticSubCategory_LIST_FAIL';

export const GenericMaster_LIST_REQUEST = 'GenericMaster_LIST_REQUEST';
export const GenericMaster_LIST_SUCCESS = 'GenericMaster_LIST_SUCCESS';
export const GenericMaster_LIST_FAIL = 'GenericMaster_LIST_FAIL';

export const ItemGenericMapping_LIST_REQUEST = 'ItemGenericMapping_LIST_REQUEST';
export const ItemGenericMapping_LIST_SUCCESS = 'ItemGenericMapping_LIST_SUCCESS';
export const ItemGenericMapping_LIST_FAIL = 'ItemGenericMapping_LIST_FAIL';

export const ItemFormMaster_LIST_REQUEST = 'ItemFormMaster_LIST_REQUEST';
export const ItemFormMaster_LIST_SUCCESS = 'ItemFormMaster_LIST_SUCCESS';
export const ItemFormMaster_LIST_FAIL = 'ItemFormMaster_LIST_FAIL';

export const ItemFacilityMapping_LIST_REQUEST = 'ItemFacilityMapping_LIST_REQUEST';
export const ItemFacilityMapping_LIST_SUCCESS = 'ItemFacilityMapping_LIST_SUCCESS';
export const ItemFacilityMapping_LIST_FAIL = 'ItemFacilityMapping_LIST_FAIL';

export const ItemSubCategoryByCategory_LIST_REQUEST = 'ItemSubCategoryByCategory_LIST_REQUEST';
export const ItemSubCategoryByCategory_LIST_SUCCESS = 'ItemSubCategoryByCategory_LIST_SUCCESS';
export const ItemSubCategoryByCategory_LIST_FAIL = 'ItemSubCategoryByCategory_LIST_FAIL';

export const TherapeuticSubCategoryByCategory_LIST_REQUEST = 'TherapeuticSubCategoryByCategory_LIST_REQUEST';
export const TherapeuticSubCategoryByCategory_LIST_SUCCESS = 'TherapeuticSubCategoryByCategory_LIST_SUCCESS';
export const TherapeuticSubCategoryByCategory_LIST_FAIL = 'TherapeuticSubCategoryByCategory_LIST_FAIL';

export const HSNCodeByHSNGroup_LIST_REQUEST = 'HSNCodeByHSNGroup_LIST_REQUEST';
export const HSNCodeByHSNGroup_LIST_SUCCESS = 'HSNCodeByHSNGroup_LIST_SUCCESS';
export const HSNCodeByHSNGroup_LIST_FAIL = 'HSNCodeByHSNGroup_LIST_FAIL';

export const ItemAttributesOnly_LIST_REQUEST = 'ItemAttributesOnly_LIST_REQUEST';
export const ItemAttributesOnly_LIST_SUCCESS = 'ItemAttributesOnly_LIST_SUCCESS';
export const ItemAttributesOnly_LIST_FAIL = 'ItemAttributesOnly_LIST_FAIL';

export const ItemChargesOnly_LIST_REQUEST = 'ItemChargesOnly_LIST_REQUEST';
export const ItemChargesOnly_LIST_SUCCESS = 'ItemChargesOnly_LIST_SUCCESS';
export const ItemChargesOnly_LIST_FAIL = 'ItemChargesOnly_LIST_FAIL';

export const ItemWithItemUnitsOnly_LIST_REQUEST = 'ItemWithItemUnitsOnly_LIST_REQUEST';
export const ItemWithItemUnitsOnly_LIST_SUCCESS = 'ItemWithItemUnitsOnly_LIST_SUCCESS';
export const ItemWithItemUnitsOnly_LIST_FAIL = 'ItemWithItemUnitsOnly_LIST_FAIL';

export const ItemGenericsOnly_LIST_REQUEST = 'ItemGenericsOnly_LIST_REQUEST';
export const ItemGenericsOnly_LIST_SUCCESS = 'ItemGenericsOnly_LIST_SUCCESS';
export const ItemGenericsOnly_LIST_FAIL = 'ItemGenericsOnly_LIST_FAIL';

export const ItemFacilityOnly_LIST_REQUEST = 'ItemFacilityOnly_LIST_REQUEST';
export const ItemFacilityOnly_LIST_SUCCESS = 'ItemFacilityOnly_LIST_SUCCESS';
export const ItemFacilityOnly_LIST_FAIL = 'ItemFacilityOnly_LIST_FAIL';

export const ItemByItemSubCategory_COUNT_REQUEST = 'ItemByItemSubCategory_COUNT_REQUEST';
export const ItemByItemSubCategory_COUNT_SUCCESS = 'ItemByItemSubCategory_COUNT_SUCCESS';
export const ItemByItemSubCategory_COUNT_FAIL = 'ItemByItemSubCategory_COUNT_FAIL';

export const GRNMain_LIST_REQUEST = 'GRNMain_LIST_REQUEST';
export const GRNMain_LIST_SUCCESS = 'GRNMain_LIST_SUCCESS';
export const GRNMain_LIST_FAIL = 'GRNMain_LIST_FAIL';

export const GRNDetails_LIST_REQUEST = 'GRNDetails_LIST_REQUEST';
export const GRNDetails_LIST_SUCCESS = 'GRNDetails_LIST_SUCCESS';
export const GRNDetails_LIST_FAIL = 'GRNDetails_LIST_FAIL';

export const GRNBatchDetails_LIST_REQUEST = 'GRNBatchDetails_LIST_REQUEST';
export const GRNBatchDetails_LIST_SUCCESS = 'GRNBatchDetails_LIST_SUCCESS';
export const GRNBatchDetails_LIST_FAIL = 'GRNBatchDetails_LIST_FAIL';

export const GRNItemCharges_LIST_REQUEST = 'GRNItemCharges_LIST_REQUEST';
export const GRNItemCharges_LIST_SUCCESS = 'GRNItemCharges_LIST_SUCCESS';
export const GRNItemCharges_LIST_FAIL = 'GRNItemCharges_LIST_FAIL';

export const GRNDocumentCharges_LIST_REQUEST = 'GRNDocumentCharges_LIST_REQUEST';
export const GRNDocumentCharges_LIST_SUCCESS = 'GRNDocumentCharges_LIST_SUCCESS';
export const GRNDocumentCharges_LIST_FAIL = 'GRNDocumentCharges_LIST_FAIL';

export const POMain_LIST_REQUEST = 'POMain_LIST_REQUEST';
export const POMain_LIST_SUCCESS = 'POMain_LIST_SUCCESS';
export const POMain_LIST_FAIL = 'POMain_LIST_FAIL';

export const PODetails_LIST_REQUEST = 'PODetails_LIST_REQUEST';
export const PODetails_LIST_SUCCESS = 'PODetails_LIST_SUCCESS';
export const PODetails_LIST_FAIL = 'PODetails_LIST_FAIL';

export const POItemCharges_LIST_REQUEST = 'POItemCharges_LIST_REQUEST';
export const POItemCharges_LIST_SUCCESS = 'POItemCharges_LIST_SUCCESS';
export const POItemCharges_LIST_FAIL = 'POItemCharges_LIST_FAIL';

export const ChargeTemplateID_LIST_REQUEST = 'ChargeTemplateID_LIST_REQUEST';
export const ChargeTemplateID_LIST_SUCCESS = 'ChargeTemplateID_LIST_SUCCESS';
export const ChargeTemplateID_LIST_FAIL = 'ChargeTemplateID_LIST_FAIL';

export const WorkOrderCharges_LIST_REQUEST = 'WorkOrderCharges_LIST_REQUEST';
export const WorkOrderCharges_LIST_SUCCESS = 'WorkOrderCharges_LIST_SUCCESS';
export const WorkOrderCharges_LIST_FAIL = 'WorkOrderCharges_LIST_FAIL';

export const WorkOrderData_LIST_REQUEST = 'WorkOrderData_LIST_REQUEST';
export const WorkOrderData_LIST_SUCCESS = 'WorkOrderData_LIST_SUCCESS';
export const WorkOrderData_LIST_FAIL = 'WorkOrderData_LIST_FAIL';

export const WorkOrderSearch_LIST_REQUEST = 'WorkOrderSearch_LIST_REQUEST';
export const WorkOrderSearch_LIST_SUCCESS = 'WorkOrderSearch_LIST_SUCCESS';
export const WorkOrderSearch_LIST_FAIL = 'WorkOrderSearch_LIST_FAIL';

export const PODocumentCharges_LIST_REQUEST = 'PODocumentCharges_LIST_REQUEST';
export const PODocumentCharges_LIST_SUCCESS = 'PODocumentCharges_LIST_SUCCESS';
export const PODocumentCharges_LIST_FAIL = 'PODocumentCharges_LIST_FAIL';

export const POData_LIST_REQUEST = 'POData_LIST_REQUEST';
export const POData_LIST_SUCCESS = 'POData_LIST_SUCCESS';
export const POData_LIST_FAIL = 'POData_LIST_FAIL';

export const POSearch_LIST_REQUEST = 'POSearch_LIST_REQUEST';
export const POSearch_LIST_SUCCESS = 'POSearch_LIST_SUCCESS';
export const POSearch_LIST_FAIL = 'POSearch_LIST_FAIL';

export const ItemByAttribute_LIST_REQUEST = 'ItemByAttribute_LIST_REQUEST';
export const ItemByAttribute_LIST_SUCCESS = 'ItemByAttribute_LIST_SUCCESS';
export const ItemByAttribute_LIST_FAIL = 'ItemByAttribute_LIST_FAIL';

export const ReusableItem_LIST_REQUEST = 'ReusableItem_LIST_REQUEST';
export const ReusableItem_LIST_SUCCESS = 'ReusableItem_LIST_SUCCESS';
export const ReusableItem_LIST_FAIL = 'ReusableItem_LIST_FAIL';

export const ReusableItems_LIST_REQUEST = 'ReusableItems_LIST_REQUEST';
export const ReusableItems_LIST_SUCCESS = 'ReusableItems_LIST_SUCCESS';
export const ReusableItems_LIST_FAIL = 'ReusableItems_LIST_FAIL';

export const ReusableItemCharges_LIST_REQUEST = 'ReusableItemCharges_LIST_REQUEST';
export const ReusableItemCharges_LIST_SUCCESS = 'ReusableItemCharges_LIST_SUCCESS';
export const ReusableItemCharges_LIST_FAIL = 'ReusableItemCharges_LIST_FAIL';

export const ChargesByItem_LIST_REQUEST = 'ChargesByItem_LIST_REQUEST';
export const ChargesByItem_LIST_SUCCESS = 'ChargesByItem_LIST_SUCCESS';
export const ChargesByItem_LIST_FAIL = 'ChargesByItem_LIST_FAIL';

export const ChargesCalculation_LIST_REQUEST = 'ChargesCalculation_LIST_REQUEST';
export const ChargesCalculation_LIST_SUCCESS = 'ChargesCalculation_LIST_SUCCESS';
export const ChargesCalculation_LIST_FAIL = 'ChargesCalculation_LIST_FAIL';

export const GRNData_LIST_REQUEST = 'GRNData_LIST_REQUEST';
export const GRNData_LIST_SUCCESS = 'GRNData_LIST_SUCCESS';
export const GRNData_LIST_FAIL = 'GRNData_LIST_FAIL';

export const GRNSearch_LIST_REQUEST = 'GRNSearch_LIST_REQUEST';
export const GRNSearch_LIST_SUCCESS = 'GRNSearch_LIST_SUCCESS';
export const GRNSearch_LIST_FAIL = 'GRNSearch_LIST_FAIL';

export const ChargeTemplate_LIST_REQUEST = 'ChargeTemplate_LIST_REQUEST';
export const ChargeTemplate_LIST_SUCCESS = 'ChargeTemplate_LIST_SUCCESS';
export const ChargeTemplate_LIST_FAIL = 'ChargeTemplate_LIST_FAIL';

export const Last5Purchases_LIST_REQUEST = 'Last5Purchases_LIST_REQUEST';
export const Last5Purchases_LIST_SUCCESS = 'Last5Purchases_LIST_SUCCESS';
export const Last5Purchases_LIST_FAIL = 'Last5Purchases_LIST_FAIL';

export const LastPurchases_LIST_REQUEST = 'LastPurchases_LIST_REQUEST';
export const LastPurchases_LIST_SUCCESS = 'LastPurchases_LIST_SUCCESS';
export const LastPurchases_LIST_FAIL = 'LastPurchases_LIST_FAIL';

export const StoresByTransType_LIST_REQUEST = 'StoresByTransType_LIST_REQUEST';
export const StoresByTransType_LIST_SUCCESS = 'StoresByTransType_LIST_SUCCESS';
export const StoresByTransType_LIST_FAIL = 'StoresByTransType_LIST_FAIL';

export const ItemsBySupplier_LIST_REQUEST = 'ItemsBySupplier_LIST_REQUEST';
export const ItemsBySupplier_LIST_SUCCESS = 'ItemsBySupplier_LIST_SUCCESS';
export const ItemsBySupplier_LIST_FAIL = 'ItemsBySupplier_LIST_FAIL';

export const SuppliersByFacility_LIST_REQUEST = 'SuppliersByFacility_LIST_REQUEST';
export const SuppliersByFacility_LIST_SUCCESS = 'SuppliersByFacility_LIST_SUCCESS';
export const SuppliersByFacility_LIST_FAIL = 'SuppliersByFacility_LIST_FAIL';

export const ItemByDesc_LIST_REQUEST = 'ItemByDesc_LIST_REQUEST';
export const ItemByDesc_LIST_SUCCESS = 'ItemByDesc_LIST_SUCCESS';
export const ItemByDesc_LIST_FAIL = 'ItemByDesc_LIST_FAIL';

export const ItemPurchaseUnit_LIST_REQUEST = 'ItemPurchaseUnit_LIST_REQUEST';
export const ItemPurchaseUnit_LIST_SUCCESS = 'ItemPurchaseUnit_LIST_SUCCESS';
export const ItemPurchaseUnit_LIST_FAIL = 'ItemPurchaseUnit_LIST_FAIL';

export const PrescriptionUnit_LIST_REQUEST = 'PrescriptionUnit_LIST_REQUEST';
export const PrescriptionUnit_LIST_SUCCESS = 'PrescriptionUnit_LIST_SUCCESS';
export const PrescriptionUnit_LIST_FAIL = 'PrescriptionUnit_LIST_FAIL';

export const AffiliatedStore_LIST_REQUEST = 'AffiliatedStore_LIST_REQUEST';
export const AffiliatedStore_LIST_SUCCESS = 'AffiliatedStore_LIST_SUCCESS';
export const AffiliatedStore_LIST_FAIL = 'AffiliatedStore_LIST_FAIL';

export const GetStoresByAffiliatedStore_LIST_REQUEST = 'GetStoresByAffiliatedStore_LIST_REQUEST';
export const GetStoresByAffiliatedStore_LIST_SUCCESS = 'GetStoresByAffiliatedStore_LIST_SUCCESS';
export const GetStoresByAffiliatedStore_LIST_FAIL = 'GetStoresByAffiliatedStore_LIST_FAIL';

export const WOSupplierTermsConditions_LIST_REQUEST = 'WOSupplierTermsConditions_LIST_REQUEST';
export const WOSupplierTermsConditions_LIST_SUCCESS = 'WOSupplierTermsConditions_LIST_SUCCESS';
export const WOSupplierTermsConditions_LIST_FAIL = 'WOSupplierTermsConditions_LIST_FAIL';

export const SupplierTermsConditions_LIST_REQUEST = 'SupplierTermsConditions_LIST_REQUEST';
export const SupplierTermsConditions_LIST_SUCCESS = 'SupplierTermsConditions_LIST_SUCCESS';
export const SupplierTermsConditions_LIST_FAIL = 'SupplierTermsConditions_LIST_FAIL';

export const POTimeLine_LIST_REQUEST = 'POTimeLine_LIST_REQUEST';
export const POTimeLine_LIST_SUCCESS = 'POTimeLine_LIST_SUCCESS';
export const POTimeLine_LIST_FAIL = 'POTimeLine_LIST_FAIL';

export const ItemwiseBatches_LIST_REQUEST = 'ItemwiseBatches_LIST_REQUEST';
export const ItemwiseBatches_LIST_SUCCESS = 'ItemwiseBatches_LIST_SUCCESS';
export const ItemwiseBatches_LIST_FAIL = 'ItemwiseBatches_LIST_FAIL';

export const OPReturnTimeLine_LIST_REQUEST = 'OPReturnTimeLine_LIST_REQUEST';
export const OPReturnTimeLine_LIST_SUCCESS = 'OPReturnTimeLine_LIST_SUCCESS';
export const OPReturnTimeLine_LIST_FAIL = 'OPReturnTimeLine_LIST_FAIL';

export const PurchaseIndentforPO_LIST_REQUEST = 'PurchaseIndentforPO_LIST_REQUEST';
export const PurchaseIndentforPO_LIST_SUCCESS = 'PurchaseIndentforPO_LIST_SUCCESS';
export const PurchaseIndentforPO_LIST_FAIL = 'PurchaseIndentforPO_LIST_FAIL';


export const GetIPIssueStoreListforIPReturn_LIST_REQUEST = 'GetIPIssueStoreListforIPReturn_LIST_REQUEST';
export const GetIPIssueStoreListforIPReturn_LIST_SUCCESS = 'GetIPIssueStoreListforIPReturn_LIST_SUCCESS';
export const GetIPIssueStoreListforIPReturn_LIST_FAIL = 'GetIPIssueStoreListforIPReturn_LIST_FAIL';

export const IPReturn_LIST_REQUEST = 'IPReturn_LIST_REQUEST';
export const IPReturn_LIST_SUCCESS = 'IPReturn_LIST_SUCCESS';
export const IPReturn_LIST_FAIL = 'IPReturn_LIST_FAIL';

export const IPReturnData_LIST_REQUEST = 'IPReturnData_LIST_REQUEST';
export const IPReturnData_LIST_SUCCESS = 'IPReturnData_LIST_SUCCESS';
export const IPReturnData_LIST_FAIL = 'IPReturnData_LIST_FAIL';

export const IPReturnSearch_LIST_REQUEST = 'IPReturnSearch_LIST_REQUEST';
export const IPReturnSearch_LIST_SUCCESS = 'IPReturnSearch_LIST_SUCCESS';
export const IPReturnSearch_LIST_FAIL = 'IPReturnSearch_LIST_FAIL';

export const GetStoreWiseItemListforIPReturn_LIST_REQUEST = 'GetStoreWiseItemListforIPReturn_LIST_REQUEST';
export const GetStoreWiseItemListforIPReturn_LIST_SUCCESS = 'GetStoreWiseItemListforIPReturn_LIST_SUCCESS';
export const GetStoreWiseItemListforIPReturn_LIST_FAIL = 'GetStoreWiseItemListforIPReturn_LIST_FAIL';

export const GetStoreWiseIPIssuedItemsforIPReturn_LIST_REQUEST = 'GetStoreWiseIPIssuedItemsforIPReturn_LIST_REQUEST';
export const GetStoreWiseIPIssuedItemsforIPReturn_LIST_SUCCESS = 'GetStoreWiseIPIssuedItemsforIPReturn_LIST_SUCCESS';
export const GetStoreWiseIPIssuedItemsforIPReturn_LIST_FAIL = 'GetStoreWiseIPIssuedItemsforIPReturn_LIST_FAIL';

export const IPReturnTimeLine_LIST_REQUEST = 'IPReturnTimeLine_LIST_REQUEST';
export const IPReturnTimeLine_LIST_SUCCESS = 'IPReturnTimeLine_LIST_SUCCESS';
export const IPReturnTimeLine_LIST_FAIL = 'IPReturnTimeLine_LIST_FAIL';

export const ChargeTemplateIdByCode_REQUEST = 'ChargeTemplateIdByCode_REQUEST';
export const ChargeTemplateIdByCode_SUCCESS = 'ChargeTemplateIdByCode_SUCCESS';
export const ChargeTemplateIdByCode_FAIL = 'ChargeTemplateIdByCode_FAIL';

export const ItemPrice_REQUEST = 'ItemPrice_REQUEST';
export const ItemPrice_SUCCESS = 'ItemPrice_SUCCESS';
export const ItemPrice_FAIL = 'ItemPrice_FAIL';

export const STATUSBYTYPEINDENT_LIST_REQUEST = 'STATUSBYTYPEINDENT_LIST_REQUEST';
export const STATUSBYTYPEINDENT_LIST_SUCCESS = 'STATUSBYTYPEINDENT_LIST_SUCCESS';
export const STATUSBYTYPEINDENT_LIST_FAIL = 'STATUSBYTYPEINDENT_LIST_FAIL';

export const ConsignmentMain_LIST_REQUEST = 'ConsignmentMain_LIST_REQUEST';
export const ConsignmentMain_LIST_SUCCESS = 'ConsignmentMain_LIST_SUCCESS';
export const ConsignmentMain_LIST_FAIL = 'ConsignmentMain_LIST_FAIL';

export const ConsignmentDetails_LIST_REQUEST = 'ConsignmentDetails_LIST_REQUEST';
export const ConsignmentDetails_LIST_SUCCESS = 'ConsignmentDetails_LIST_SUCCESS';
export const ConsignmentDetails_LIST_FAIL = 'ConsignmentDetails_LIST_FAIL';

export const ConsignmentBatchDetails_LIST_REQUEST = 'ConsignmentBatchDetails_LIST_REQUEST';
export const ConsignmentBatchDetails_LIST_SUCCESS = 'ConsignmentBatchDetails_LIST_SUCCESS';
export const ConsignmentBatchDetails_LIST_FAIL = 'ConsignmentBatchDetails_LIST_FAIL';

export const ConsignmentItemCharges_LIST_REQUEST = 'ConsignmentItemCharges_LIST_REQUEST';
export const ConsignmentItemCharges_LIST_SUCCESS = 'ConsignmentItemCharges_LIST_SUCCESS';
export const ConsignmentItemCharges_LIST_FAIL = 'ConsignmentItemCharges_LIST_FAIL';


export const ConsignmentData_LIST_REQUEST = 'ConsignmentData_LIST_REQUEST';
export const ConsignmentData_LIST_SUCCESS = 'ConsignmentData_LIST_SUCCESS';
export const ConsignmentData_LIST_FAIL = 'ConsignmentData_LIST_FAIL';

export const ConsignmentSearch_LIST_REQUEST = 'ConsignmentSearch_LIST_REQUEST';
export const ConsignmentSearch_LIST_SUCCESS = 'ConsignmentSearch_LIST_SUCCESS';
export const ConsignmentSearch_LIST_FAIL = 'ConsignmentSearch_LIST_FAIL';


export const ConsignmentReceiveMain_LIST_REQUEST = 'ConsignmentReceiveMain_LIST_REQUEST';
export const ConsignmentReceiveMain_LIST_SUCCESS = 'ConsignmentReceiveMain_LIST_SUCCESS';
export const ConsignmentReceiveMain_LIST_FAIL = 'ConsignmentReceiveMain_LIST_FAIL';

export const ConsignmentReceiveDetails_LIST_REQUEST = 'ConsignmentReceiveDetails_LIST_REQUEST';
export const ConsignmentReceiveDetails_LIST_SUCCESS = 'ConsignmentReceiveDetails_LIST_SUCCESS';
export const ConsignmentReceiveDetails_LIST_FAIL = 'ConsignmentReceiveDetails_LIST_FAIL';

export const ConsignmentReceiveBatchDetails_LIST_REQUEST = 'ConsignmentReceiveBatchDetails_LIST_REQUEST';
export const ConsignmentReceiveBatchDetails_LIST_SUCCESS = 'ConsignmentReceiveBatchDetails_LIST_SUCCESS';
export const ConsignmentReceiveBatchDetails_LIST_FAIL = 'ConsignmentReceiveBatchDetails_LIST_FAIL';

export const ConsignmentReceiveItemCharges_LIST_REQUEST = 'ConsignmentReceiveItemCharges_LIST_REQUEST';
export const ConsignmentReceiveItemCharges_LIST_SUCCESS = 'ConsignmentReceiveItemCharges_LIST_SUCCESS';
export const ConsignmentReceiveItemCharges_LIST_FAIL = 'ConsignmentReceiveItemCharges_LIST_FAIL';


export const ConsignmentReceiveData_LIST_REQUEST = 'ConsignmentReceiveData_LIST_REQUEST';
export const ConsignmentReceiveData_LIST_SUCCESS = 'ConsignmentReceiveData_LIST_SUCCESS';
export const ConsignmentReceiveData_LIST_FAIL = 'ConsignmentReceiveData_LIST_FAIL';

export const ConsignmentReceiveSearch_LIST_REQUEST = 'ConsignmentReceiveSearch_LIST_REQUEST';
export const ConsignmentReceiveSearch_LIST_SUCCESS = 'ConsignmentReceiveSearch_LIST_SUCCESS';
export const ConsignmentReceiveSearch_LIST_FAIL = 'ConsignmentReceiveSearch_LIST_FAIL';

export const GetConsignmentAllocationSearch_LIST_REQUEST = 'GetConsignmentAllocationSearch_LIST_REQUEST';
export const GetConsignmentAllocationSearch_LIST_SUCCESS = 'GetConsignmentAllocationSearch_LIST_SUCCESS';
export const GetConsignmentAllocationSearch_LIST_FAIL = 'GetConsignmentAllocationSearch_LIST_FAIL';

export const GetConsignmentReturnSearch_LIST_REQUEST = 'GetConsignmentReturnSearch_LIST_REQUEST';
export const GetConsignmentReturnSearch_LIST_SUCCESS = 'GetConsignmentReturnSearch_LIST_SUCCESS';
export const GetConsignmentReturnSearch_LIST_FAIL = 'GetConsignmentReturnSearch_LIST_FAIL';

export const GetItemForDepartmentReturnByBarCode_REQUEST = 'GetItemForDepartmentReturnByBarCode_REQUEST';
export const GetItemForDepartmentReturnByBarCode_SUCCESS = 'GetItemForDepartmentReturnByBarCode_SUCCESS';
export const GetItemForDepartmentReturnByBarCode_FAIL = 'GetItemForDepartmentReturnByBarCode_FAIL';

export const StoresByStoreTypeCode_LIST_REQUEST = 'StoresByStoreTypeCode_LIST_REQUEST';
export const StoresByStoreTypeCode_LIST_SUCCESS = 'StoresByStoreTypeCode_LIST_SUCCESS';
export const StoresByStoreTypeCode_LIST_FAIL = 'StoresByStoreTypeCode_LIST_FAIL';

export const GetRxOrderDetails_LIST_REQUEST = 'GetRxOrderDetails_LIST_REQUEST';
export const GetRxOrderDetails_LIST_SUCCESS = 'GetRxOrderDetails_LIST_SUCCESS';
export const GetRxOrderDetails_LIST_FAIL = 'GetRxOrderDetails_LIST_FAIL';

export const GetAdminssionStatus_LIST_REQUEST = 'GetAdminssionStatus_LIST_REQUEST';
export const GetAdminssionStatus_LIST_SUCCESS = 'GetAdminssionStatus_LIST_SUCCESS';
export const GetAdminssionStatus_LIST_FAIL = 'GetAdminssionStatus_LIST_FAIL';

export const getScrollData_REQUEST = 'getScrollData_REQUEST';
export const getScrollData_SUCCESS = 'getScrollData_SUCCESS';
export const getScrollData_FAIL = 'getScrollData_FAIL';

export const getEmpFirstSubmitCollec_REQUEST = 'getEmpFirstSubmitCollec_REQUEST';
export const getEmpFirstSubmitCollec_SUCCESS = 'getEmpFirstSubmitCollec_SUCCESS';
export const getEmpFirstSubmitCollec_FAIL = 'getEmpFirstSubmitCollec_FAIL';

export const ScrollUser_REQUEST = 'ScrollUser_REQUEST';
export const ScrollUser_SUCCESS = 'ScrollUser_SUCCESS';
export const ScrollUser_FAIL = 'ScrollUser_FAIL';

export const EmplScrollSummary_REQUEST = 'EmplScrollSummary_REQUEST';
export const EmplScrollSummary_SUCCESS = 'EmplScrollSummary_SUCCESS';
export const EmplScrollSummary_FAIL = 'EmplScrollSummary_FAIL';

export const getScrollList_REQUEST = 'getScrollList_REQUEST';
export const getScrollList_SUCCESS = 'getScrollList_SUCCESS';
export const getScrollList_FAIL = 'getScrollList_FAIL';

export const getEmpLastSubmitScroll_REQUEST = 'getEmpLastSubmitScroll_REQUEST';
export const getEmpLastSubmitScroll_SUCCESS = 'getEmpLastSubmitScroll_SUCCESS';
export const getEmpLastSubmitScroll_FAIL = 'getEmpLastSubmitScroll_FAIL';

//-----------------------------SHASHI-------------------------------

export const POAPPROVALSETUP_LIST_REQUEST = 'POApprovalSetup_LIST_REQUEST';
export const POAPPROVALSETUP_LIST_SUCCESS = 'POApprovalSetup_LIST_SUCCESS';
export const POAPPROVALSETUP_LIST_FAIL = 'POApprovalSetup_LIST_FAIL';

export const SUPPLIERDETAIL_LIST_REQUEST = 'SupplierDetail_List_Request';
export const SUPPLIERDETAIL_LIST_SUCCESS = 'SupplierDetail_List_Success';
export const SUPPLIERDETAIL_LIST_FAIL = 'SupplierDetail_List_Fail';

export const SUPPLIERTERMSCONDITION_LIST_REQUEST = 'SUPPLIERTERMSCONDITION_LIST_REQUEST';
export const SUPPLIERTERMSCONDITION_LIST_SUCCESS = 'SUPPLIERTERMSCONDITION_LIST_SUCCESS';
export const SUPPLIERTERMSCONDITION_LIST_FAIL = 'SUPPLIERTERMSCONDITION_LIST_FAIL';

export const SUPPLIER_FACILITY_MAPPING_LIST_REQUEST = 'SUPPLIER_FACILITY_MAPPING_LIST_REQUEST';
export const SUPPLIER_FACILITY_MAPPING_LIST_SUCCESS = 'SUPPLIER_FACILITY_MAPPING_LIST_SUCCESS';
export const SUPPLIER_FACILITY_MAPPING_LIST_FAIL = 'SUPPLIER_FACILITY_MAPPING_LIST_FAIL';

export const TERMS_CONDITION_LIST_REQUEST = 'TERMS_CONDITION_LIST_REQUEST';
export const TERMS_CONDITION_LIST_SUCCESS = 'TERMS_CONDITION_LIST_SUCCESS';
export const TERMS_CONDITION_LIST_FAIL = 'TERMS_CONDITION_LIST_FAIL';

export const SUPPLIER_ITEM_MAPPING_LIST_REQUEST = 'SUPPLIER_ITEM_MAPPING_LIST_REQUEST';
export const SUPPLIER_ITEM_MAPPING_LIST_SUCCESS = 'SUPPLIER_ITEM_MAPPING_LIST_SUCCESS';
export const SUPPLIER_ITEM_MAPPING_LIST_FAIL = 'SUPPLIER_ITEM_MAPPING_LIST_FAIL';

export const SUPPLIER_LOGIN_DETAIL_REQUEST = 'SUPPLIER_LOGIN_DETAIL_REQUEST';
export const SUPPLIER_LOGIN_DETAIL_SUCCESS = 'SUPPLIER_LOGIN_DETAIL_SUCCESS';
export const SUPPLIER_LOGIN_DETAIL_FAIL = 'SUPPLIER_LOGIN_DETAIL_FAIL';

export const POAPPROVALLISTBYFACILITY_REQUEST = 'POAPPROVALLISTBYFACILITY_REQUEST';
export const POAPPROVALLISTBYFACILITY_SUCCESS = 'POAPPROVALLISTBYFACILITY_SUCCESS';
export const POAPPROVALLISTBYFACILITY_FAIL = 'POAPPROVALLISTBYFACILITY_FAIL';

//---------------------------------------------
export const STORE_LIST_REQUEST = 'STORE_LIST_REQUEST';
export const STORE_LIST_SUCCESS = 'STORE_LIST_SUCCESS';
export const STORE_LIST_FAIL = 'STORE_LIST_FAIL';

export const STOREfacilityWise_LIST_REQUEST = 'STOREfacilityWise_LIST_REQUEST';
export const STOREfacilityWise_LIST_SUCCESS = 'STOREfacilityWise_LIST_SUCCESS';
export const STOREfacilityWise_LIST_FAIL = 'STOREfacilityWise_LIST_FAIL';

export const EMPSTORE_LIST_REQUEST = 'EMPSTORE_LIST_REQUEST';
export const EMPSTORE_LIST_SUCCESS = 'EMPSTORE_LIST_SUCCESS';
export const EMPSTORE_LIST_FAIL = 'EMPSTORE_LIST_FAIL';

export const EMPSTOREReportMapped_LIST_REQUEST = 'EMPSTOREReportMapped_LIST_REQUEST';
export const EMPSTOREReportMapped_LIST_SUCCESS = 'EMPSTOREReportMapped_LIST_SUCCESS';
export const EMPSTOREReportMapped_LIST_FAIL = 'EMPSTOREReportMapped_LIST_FAIL';

export const STORE_TRANSACTION_TYPE_LIST_REQUEST = 'STORE_TRANSACTION_TYPE_LIST_REQUEST';
export const STORE_TRANSACTION_TYPE_LIST_SUCCESS = 'STORE_TRANSACTION_TYPE_LIST_SUCCESS';
export const STORE_TRANSACTION_TYPE_LIST_FAIL = 'STORE_TRANSACTION_TYPE_LIST_FAIL';

export const STORE_ItemAutoConsumptionMapping_REQUEST = 'STORE_ItemAutoConsumptionMapping_REQUEST';
export const STORE_ItemAutoConsumptionMapping_SUCCESS = 'STORE_ItemAutoConsumptionMapping_SUCCESS';
export const STORE_ItemAutoConsumptionMapping_FAIL = 'STORE_ItemAutoConsumptionMapping_FAIL';


export const SOTRE_ALL_TRANSACTIONS_REQUEST = 'SOTRE_ALL_TRANSACTIONS_REQUEST';
export const SOTRE_ALL_TRANSACTIONS_SUCCESS = 'SOTRE_ALL_TRANSACTIONS_SUCCESS';
export const SOTRE_ALL_TRANSACTIONS_FAIL = 'SOTRE_ALL_TRANSACTIONS_FAIL';

export const STORE_AFFILLIATION_LIST_REQUEST = 'STORE_AFFILLIATION_LIST_REQUEST';
export const STORE_AFFILLIATION_LIST_SUCCESS = 'STORE_AFFILLIATION_LIST_SUCCESS';
export const STORE_AFFILLIATION_LIST_FAIL = 'STORE_AFFILLIATION_LIST_FAIL';

export const STORE_AUTO_INDENT_FREQUENCY_LIST_REQUEST = 'STORE_AUTO_INDENT_FREQUENCY_LIST_REQUEST';
export const STORE_AUTO_INDENT_FREQUENCY_LIST_SUCCESS = 'STORE_AUTO_INDENT_FREQUENCY_LIST_SUCCESS';
export const STORE_AUTO_INDENT_FREQUENCY_LIST_FAIL = 'STORE_AUTO_INDENT_FREQUENCY_LIST_FAIL';

export const STORE_ITEM_MAPPING_LIST_REQUEST = 'STORE_ITEM_MAPPING_LIST_REQUEST';
export const STORE_ITEM_MAPPING_LIST_SUCCESS = 'STORE_ITEM_MAPPING_LIST_SUCCESS';
export const STORE_ITEM_MAPPING_LIST_FAIL = 'STORE_ITEM_MAPPING_LIST_FAIL';

export const STORE_ALL_SUBCATEGORY_AND_ITEM_LIST_REQUEST = 'STORE_ALL_SUBCATEGORY_AND_ITEM_LIST_REQUEST';
export const STORE_ALL_SUBCATEGORY_AND_ITEM_LIST_SUCCESS = 'STORE_ALL_SUBCATEGORY_AND_ITEM_LIST_SUCCESS';
export const STORE_ALL_SUBCATEGORY_AND_ITEM_LIST_FAIL = 'STORE_ALL_SUBCATEGORY_AND_ITEM_LIST_FAIL';

export const STORE_SUBCATEGORY_OR_ITEM_MAPPING_REQUEST = 'STORE_SUBCATEGORY_OR_ITEM_MAPPING_REQUEST';
export const STORE_SUBCATEGORY_OR_ITEM_MAPPING_SUCCESS = 'STORE_SUBCATEGORY_OR_ITEM_MAPPING_SUCCESS';
export const STORE_SUBCATEGORY_OR_ITEM_MAPPING_FAIL = 'STORE_SUBCATEGORY_OR_ITEM_MAPPING_FAIL';

export const STORE_ROLSETUP_LIST_REQUEST = 'STORE_ROLSETUP_LIST_REQUEST';
export const STORE_ROLSETUP_LIST_SUCCESS = 'STORE_ROLSETUP_LIST_SUCCESS';
export const STORE_ROLSETUP_LIST_FAIL = 'STORE_ROLSETUP_LIST_FAIL';

export const BIN_LOCATION_LIST_REQUEST = 'BIN_LOCATION_LIST_REQUEST';
export const BIN_LOCATION_LIST_SUCCESS = 'BIN_LOCATION_LIST_SUCCESS';
export const BIN_LOCATION_LIST_FAIL = 'BIN_LOCATION_LIST_FAIL';

export const FINANCIALYEAR_LIST_REQUEST = 'FINANCIALYEAR_LIST_REQUEST';
export const FINANCIALYEAR_LIST_SUCCESS = 'FINANCIALYEAR_LIST_SUCCESS';
export const FINANCIALYEAR_LIST_FAIL = 'FINANCIALYEAR_LIST_FAIL';


export const ALL_ITEMCHARGE_TEMPLATE_REQUEST = 'ALL_ITEMCHARGE_TEMPLATE_REQUEST';
export const ALL_ITEMCHARGE_TEMPLATE_SUCCESS = 'ALL_ITEMCHARGE_TEMPLATE_SUCCESS';
export const ALL_ITEMCHARGE_TEMPLATE_FAIL = 'ALL_ITEMCHARGE_TEMPLATE_FAIL';

export const ITEM_SALE_PRICE_SETUP_LIST_REQUEST = "ITEM_SALE_PRICE_SETUP_LIST_REQUEST";
export const ITEM_SALE_PRICE_SETUP_LIST_SUCCESS = "ITEM_SALE_PRICE_SETUP_LIST_SUCCESS";
export const ITEM_SALE_PRICE_SETUP_LIST_FAIL = "ITEM_SALE_PRICE_SETUP_LIST_FAIL";

export const KITMASTER_LIST_REQUEST = "KITMASTER_LIST_REQUEST";
export const KITMASTER_LIST_SUCCESS = "KITMASTER_LIST_SUCCESS";
export const KITMASTER_LIST_FAIL = "KITMASTER_LIST_FAIL";

export const KIT_ITEM_MAPPING_LIST_REQUEST = "KIT_ITEM_MAPPING_LIST_REQUEST";
export const KIT_ITEM_MAPPING_LIST_SUCCESS = "KIT_ITEM_MAPPING_LIST_SUCCESS";
export const KIT_ITEM_MAPPING_LIST_FAIL = "KIT_ITEM_MAPPING_LIST_FAIL";

export const DEPARTMENT_INDENT_DETAILS_LIST_REQUEST = "DEPARTMENT_INDENT_DETAILS_LIST_REQUEST";
export const DEPARTMENT_INDENT_DETAILS_LIST_SUCCESS = "DEPARTMENT_INDENT_DETAILS_LIST_SUCCESS";
export const DEPARTMENT_INDENT_DETAILS_LIST_FAIL = "DEPARTMENT_INDENT_DETAILS_LIST_FAIL";

export const DEPARTMENT_INDENT_MAIN_LIST_REQUEST = "DEPARTMENT_INDENT_MAIN_LIST_REQUEST";
export const DEPARTMENT_INDENT_MAIN_LIST_SUCCESS = "DEPARTMENT_INDENT_MAIN_LIST_SUCCESS";
export const DEPARTMENT_INDENT_MAIN_LIST_FAIL = "DEPARTMENT_INDENT_MAIN_LIST_FAIL";

export const ITEMS_FOR_DEPARTMENTINDENT_LIST_REQUEST = "ITEMS_FOR_DEPARTMENTINDENT_LIST_REQUEST";
export const ITEMS_FOR_DEPARTMENTINDENT_LIST_SUCCESS = "ITEMS_FOR_DEPARTMENTINDENT_LIST_SUCCESS";
export const ITEMS_FOR_DEPARTMENTINDENT_LIST_FAIL = "ITEMS_FOR_DEPARTMENTINDENT_LIST_FAIL";

export const DEPARTMENTINDENT_TIMELINE_REQUEST = 'DEPARTMENTINDENT_TIMELINE_REQUEST';
export const DEPARTMENTINDENT_TIMELINE_SUCCESS = 'DEPARTMENTINDENT_TIMELINE_SUCCESS';
export const DEPARTMENTINDENT_TIMELINE_FAIL = 'DEPARTMENTINDENT_TIMELINE_FAIL';

export const GetStoreMappedWithDocumentSetup_REQUEST = 'GetStoreMappedWithDocumentSetup_REQUEST';
export const GetStoreMappedWithDocumentSetup_SUCCESS = 'GetStoreMappedWithDocumentSetup_SUCCESS';
export const GetStoreMappedWithDocumentSetup_FAIL = 'GetStoreMappedWithDocumentSetup_FAIL';

export const ITEMSUBSTITUTE_BATCHSELECTION_REQUEST = 'ITEMSUBSTITUTE_BATCHSELECTION_REQUEST';
export const ITEMSUBSTITUTE_BATCHSELECTION_SUCCESS = 'ITEMSUBSTITUTE_BATCHSELECTION_SUCCESS';
export const ITEMSUBSTITUTE_BATCHSELECTION_FAIL = 'ITEMSUBSTITUTE_BATCHSELECTION_FAIL';

export const ITEMSELECTION_REQUEST = 'ITEMSELECTION_REQUEST';
export const ITEMSELECTION_SUCCESS = 'ITEMSELECTION_SUCCESS';
export const ITEMSELECTION_FAIL = 'ITEMSELECTION_FAIL';

export const DEPARTMENTINDENT_APPROVALDETAILS_REQUEST = 'DEPARTMENTINDENT_APPROVALDETAILS_REQUEST';
export const DEPARTMENTINDENT_APPROVALDETAILS_SUCCESS = 'DEPARTMENTINDENT_APPROVALDETAILS_SUCCESS';
export const DEPARTMENTINDENT_APPROVALDETAILS_FAIL = 'DEPARTMENTINDENT_APPROVALDETAILS_FAIL';

export const INDENTFORISSUE_REQUEST = 'INDENTFORISSUE_REQUEST';
export const INDENTFORISSUE_SUCCESS = 'INDENTFORISSUE_SUCCESS';
export const INDENTFORISSUE_FAIL = 'INDENTFORISSUE_FAIL';

export const GETBATCHBYBARCODE_REQUEST = 'GETBATCHBYBARCODE_REQUEST';
export const GETBATCHBYBARCODE_SUCCESS = 'GETBATCHBYBARCODE_SUCCESS';
export const GETBATCHBYBARCODE_FAIL = 'GETBATCHBYBARCODE_FAIL';

export const INDENTRECEIVE_REQUEST = 'INDENTRECEIVE_REQUEST';
export const INDENTRECEIVE_SUCCESS = 'INDENTRECEIVE_SUCCESS';
export const INDENTRECEIVE_FAIL = 'INDENTRECEIVE_FAIL';

export const INDENT_RECEIVED_REQUEST = 'INDENT_RECEIVED_REQUEST';
export const INDENT_RECEIVED_SUCCESS = 'INDENT_RECEIVED_SUCCESS';
export const INDENT_RECEIVED_FAIL = 'INDENT_RECEIVED_FAIL';

export const INDENT_RETURN_REQUEST = 'INDENT_RETURN_REQUEST';
export const INDENT_RETURN_SUCCESS = 'INDENT_RETURN_SUCCESS';
export const INDENT_RETURN_FAIL = 'INDENT_RECEIVED_FAIL';

export const INDENT_RETURN_SEARCH_REQUEST = 'INDENT_RETURN_SEARCH_REQUEST';
export const INDENT_RETURN_SEARCH_SUCCESS = 'INDENT_RETURN_SEARCH_SUCCESS';
export const INDENT_RETURN_SEARCH_FAIL = 'INDENT_RETURN_SEARCH_FAIL';

export const INDENT_RETURNACK_REQUEST = 'INDENT_RETURNACK_REQUEST';
export const INDENT_RETURNACK_SUCCESS = 'INDENT_RETURNACK_SUCCESS';
export const INDENT_RETURNACK_FAIL = 'INDENT_RETURNACK_FAIL';

export const ITEMS_FOR_DEPARTENTRETURN_REQUEST = 'ITEMS_FOR_DEPARTENTRETURN_REQUEST';
export const ITEMS_FOR_DEPARTENTRETURN_SUCCESS = 'ITEMS_FOR_DEPARTENTRETURN_SUCCESS';
export const ITEMS_FOR_DEPARTENTRETURN_FAIL = 'ITEMS_FOR_DEPARTENTRETURN_FAIL';

export const GET_USER_STORE_ACCESS_RIGHTS_REQUEST = 'GET_USER_STORE_ACCESS_RIGHTS_REQUEST';
export const GET_USER_STORE_ACCESS_RIGHTS_SUCCESS = 'GET_USER_STORE_ACCESS_RIGHTS_SUCCESS';
export const GET_USER_STORE_ACCESS_RIGHTS_FAIL = 'GET_USER_STORE_ACCESS_RIGHTS_FAIL';

export const INDENT_RECEIVED_SEARCHPOPUP_REQUEST = 'INDENT_RECEIVED_SEARCHPOPUP_REQUEST';
export const INDENT_RECEIVED_SEARCHPOPUP_SUCCESS = 'INDENT_RECEIVED_SEARCHPOPUP_SUCCESS';
export const INDENT_RECEIVED_SEARCHPOPUP_FAIL = 'INDENT_RECEIVED_SEARCHPOPUP_FAIL';

export const GetItemListROL_REQUEST = 'GetItemListROL_REQUEST';
export const GetItemListROL_SUCCESS = 'GetItemListROL_SUCCESS';
export const GetItemListROL_FAIL = 'GetItemListROL_FAIL';

export const GetItemListConsumption_REQUEST = 'GetItemListConsumption_REQUEST';
export const GetItemListConsumption_SUCCESS = 'GetItemListConsumption_SUCCESS';
export const GetItemListConsumption_FAIL = 'GetItemListConsumption_FAIL';

export const PURCHASEINDENTMAIN_REQUEST = 'PURCHASEINDENTMAIN_REQUEST';
export const PURCHASEINDENTMAIN_SUCCESS = 'PURCHASEINDENTMAIN_SUCCESS';
export const PURCHASEINDENTMAIN_FAIL = 'PURCHASEINDENTMAIN_FAIL';

export const PURCHASEINDENTDETAIL_REQUEST = 'PURCHASEINDENTDETAIL_REQUEST';
export const PURCHASEINDENTDETAIL_SUCCESS = 'PURCHASEINDENTDETAIL_SUCCESS';
export const PURCHASEINDENTDETAIL_FAIL = 'PURCHASEINDENTDETAIL_FAIL';

export const GETITEMDATA_REQUEST = 'GETITEMDATA_REQUEST';
export const GETITEMDATA_SUCCESS = 'GETITEMDATA_SUCCESS';
export const GETITEMDATA_FAIL = 'GETITEMDATA_FAIL';

export const GETPURCHASEINDENTAGAINSTINDENT_REQUEST = 'GETPURCHASEINDENTAGAINSTINDENT_REQUEST';
export const GETPURCHASEINDENTAGAINSTINDENT_SUCCESS = 'GETPURCHASEINDENTAGAINSTINDENT_SUCCESS';
export const GETPURCHASEINDENTAGAINSTINDENT_FAIL = 'GETPURCHASEINDENTAGAINSTINDENT_FAIL';

export const PURCHASEINDENT_TIMELINE_REQUEST = 'PURCHASEINDENT_TIMELINE_REQUEST';
export const PURCHASEINDENT_TIMELINE_SUCCESS = 'PURCHASEINDENT_TIMELINE_SUCCESS';
export const PURCHASEINDENT_TIMELINE_FAIL = 'PURCHASEINDENT_TIMELINE_FAIL';

export const GetKitTemplateItemWithBatch_REQUEST = 'GetKitTemplateItemWithBatch_REQUEST';
export const GetKitTemplateItemWithBatch_SUCCESS = 'GetKitTemplateItemWithBatch_SUCCESS';
export const GetKitTemplateItemWithBatch_FAIL = 'GetKitTemplateItemWithBatch_FAIL';

export const GetAllDepartmentConsumption_REQUEST = 'GetAllDepartmentConsumption_REQUEST';
export const GetAllDepartmentConsumption_SUCCESS = 'GetAllDepartmentConsumption_SUCCESS';
export const GetAllDepartmentConsumption_FAIL = 'GetAllDepartmentConsumption_FAIL';

export const GetDepartmentConsumptionDetail_REQUEST = 'GetDepartmentConsumptionDetail_REQUEST';
export const GetDepartmentConsumptionDetail_SUCCESS = 'GetDepartmentConsumptionDetail_SUCCESS';
export const GetDepartmentConsumptionDetail_FAIL = 'GetDepartmentConsumptionDetail_FAIL';

export const fetchAdmitPatientIPPATIENT_REQUEST = 'fetchAdmitPatientIPPATIENT_REQUEST';
export const fetchAdmitPatientIPPATIENT_SUCCESS = 'fetchAdmitPatientIPPATIENT_SUCCESS';
export const fetchAdmitPatientIPPATIENT_FAIL = 'fetchAdmitPatientIPPATIENT_FAIL';

export const ViewDrugOrderList_REQUEST = 'ViewDrugOrderList_REQUEST';
export const ViewDrugOrderList_SUCCESS = 'ViewDrugOrderList_SUCCESS';
export const ViewDrugOrderList_FAIL = 'ViewDrugOrderList_FAIL';

export const GetIPIssueAllocationList_REQUEST = 'GetIPIssueAllocationList_REQUEST';
export const GetIPIssueAllocationList_SUCCESS = 'GetIPIssueAllocationList_SUCCESS';
export const GetIPIssueAllocationList_FAIL = 'GetIPIssueAllocationList_FAIL';

export const GetIPIssueAllocationDeatils_REQUEST = 'GetIPIssueAllocationDeatils_REQUEST';
export const GetIPIssueAllocationDeatils_SUCCESS = 'GetIPIssueAllocationDeatils_SUCCESS';
export const GetIPIssueAllocationDeatils_FAIL = 'GetIPIssueAllocationDeatils_FAIL';

export const GetIPIssueList_REQUEST = 'GetIPIssueList_REQUEST';
export const GetIPIssueList_SUCCESS = 'GetIPIssueList_SUCCESS';
export const GetIPIssueList_FAIL = 'GetIPIssueList_FAIL';

export const PrescriptionDetailsIPPATIENT_REQUEST = 'PrescriptionDetailsIPPATIENT_REQUEST';
export const PrescriptionDetailsIPPATIENT_SUCCESS = 'PrescriptionDetailsIPPATIENT_SUCCESS';
export const PrescriptionDetailsIPPATIENT_FAIL = 'PrescriptionDetailsIPPATIENT_FAIL';

export const VitalSign_REQUEST = 'VitalSign_REQUEST';
export const VitalSign_SUCCESS = 'VitalSign_SUCCESS';
export const VitalSign_FAIL = 'VitalSign_FAIL';

export const GETIPISSUEALLOCATION_REQUEST = 'GETIPISSUEALLOCATION_REQUEST';
export const GETIPISSUEALLOCATION_SUCCESS = 'GETIPISSUEALLOCATION_SUCCESS';
export const GETIPISSUEALLOCATION_FAIL = 'GETIPISSUEALLOCATION_FAIL';

export const GetIPIssueDataRxWardAcknowledge_LIST_REQUEST = 'GetIPIssueDataRxWardAcknowledge_LIST_REQUEST';
export const GetIPIssueDataRxWardAcknowledge_LIST_SUCCESS = 'GetIPIssueDataRxWardAcknowledge_LIST_SUCCESS';
export const GetIPIssueDataRxWardAcknowledge_LIST_FAIL = 'GetIPIssueDataRxWardAcknowledge_LIST_FAIL';

export const GetItemListforSupplierReturn_REQUEST = 'GetItemListforSupplierReturn_REQUEST';
export const GetItemListforSupplierReturn_SUCCESS = 'GetItemListforSupplierReturn_SUCCESS';
export const GetItemListforSupplierReturn_FAIL = 'GetItemListforSupplierReturn_FAIL';

export const GetItemListforSupplierReturnByBarcode_REQUEST = 'GetItemListforSupplierReturnByBarcode_REQUEST';
export const GetItemListforSupplierReturnByBarcode_SUCCESS = 'GetItemListforSupplierReturnByBarcode_SUCCESS';
export const GetItemListforSupplierReturnByBarcode_FAIL = 'GetItemListforSupplierReturnByBarcode_FAIL';

export const GetSupplierReturnTimeLine_REQUEST = 'GetSupplierReturnTimeLine_REQUEST';
export const GetSupplierReturnTimeLine_SUCCESS = 'GetSupplierReturnTimeLine_SUCCESS';
export const GetSupplierReturnTimeLine_FAIL = 'GetSupplierReturnTimeLine_FAIL';

export const GetSupplierReturnDetails_REQUEST = 'GetSupplierReturnDetails_REQUEST';
export const GetSupplierReturnDetails_SUCCESS = 'GetSupplierReturnDetails_SUCCESS';
export const GetSupplierReturnDetails_FAIL = 'GetSupplierReturnDetails_FAIL';

export const GetSupplierReturnSearch_REQUEST = 'GetSupplierReturnSearch_REQUEST';
export const GetSupplierReturnSearch_SUCCESS = 'GetSupplierReturnSearch_SUCCESS';
export const GetSupplierReturnSearch_FAIL = 'GetSupplierReturnSearch_FAIL';

export const GetOpeningAdjustmentTimeLine_REQUEST = 'GetOpeningAdjustmentTimeLine_REQUEST';
export const GetOpeningAdjustmentTimeLine_SUCCESS = 'GetOpeningAdjustmentTimeLine_SUCCESS';
export const GetOpeningAdjustmentTimeLine_FAIL = 'GetOpeningAdjustmentTimeLine_FAIL';

export const GetSupplierReturnDetailsForGRN_REQUEST = 'GetSupplierReturnDetailsForGRN_REQUEST';
export const GetSupplierReturnDetailsForGRN_SUCCESS = 'GetSupplierReturnDetailsForGRN_SUCCESS';
export const GetSupplierReturnDetailsForGRN_FAIL = 'GetSupplierReturnDetailsForGRN_FAIL';

export const GetItemsForQuotation_REQUEST = 'GetItemsForQuotation_REQUEST';
export const GetItemsForQuotation_SUCCESS = 'GetItemsForQuotation_SUCCESS';
export const GetItemsForQuotation_FAIL = 'GetItemsForQuotation_FAIL';

export const GetQuotationSearch_REQUEST = 'GetQuotationSearch_REQUEST';
export const GetQuotationSearch_SUCCESS = 'GetQuotationSearch_SUCCESS';
export const GetQuotationSearch_FAIL = 'GetQuotationSearch_FAIL';

export const GetQuotation_REQUEST = 'GetQuotation_REQUEST';
export const GetQuotation_SUCCESS = 'GetQuotation_SUCCESS';
export const GetQuotation_FAIL = 'GetQuotation_FAIL';

export const QuotationItemCharges_REQUEST = 'QuotationItemCharges_REQUEST';
export const QuotationItemCharges_SUCCESS = 'QuotationItemCharges_SUCCESS';
export const QuotationItemCharges_FAIL = 'QuotationItemCharges_FAIL';

export const ViewAllQuotationsItems_REQUEST = 'ViewAllQuotationsItems_REQUEST';
export const ViewAllQuotationsItems_SUCCESS = 'ViewAllQuotationsItems_SUCCESS';
export const ViewAllQuotationsItems_FAIL = 'ViewAllQuotationsItems_FAIL';

export const GetSupplierWiseLastItemPrice_REQUEST = 'GetSupplierWiseLastItemPrice_REQUEST';
export const GetSupplierWiseLastItemPrice_SUCCESS = 'GetSupplierWiseLastItemPrice_SUCCESS';
export const GetSupplierWiseLastItemPrice_FAIL = 'GetSupplierWiseLastItemPrice_FAIL';

export const GetDepartmentIndentAllocation_REQUEST = 'GetDepartmentIndentAllocation_REQUEST';
export const GetDepartmentIndentAllocation_SUCCESS = 'GetDepartmentIndentAllocation_SUCCESS';
export const GetDepartmentIndentAllocation_FAIL = 'GetDepartmentIndentAllocation_FAIL';

export const SearchSaleToPatient_REQUEST = 'SearchSaleToPatient_REQUEST';
export const SearchSaleToPatient_SUCCESS = 'SearchSaleToPatient_SUCCESS';
export const SearchSaleToPatient_FAIL = 'SearchSaleToPatient_FAIL';

export const GetItemGeneric_REQUEST = 'GetItemGeneric_REQUEST';
export const GetItemGeneric_SUCCESS = 'GetItemGeneric_SUCCESS';
export const GetItemGeneric_FAIL = 'GetItemGeneric_FAIL';

export const PrintBarcodeLabel_REQUEST = 'PrintBarcodeLabel_REQUEST';
export const PrintBarcodeLabel_SUCCESS = 'PrintBarcodeLabel_SUCCESS';
export const PrintBarcodeLabel_FAIL = 'PrintBarcodeLabel_FAIL';

export const GetItemByMultipleStoresAsync_REQUEST = 'GetItemByMultipleStoresAsync_REQUEST';
export const GetItemByMultipleStoresAsync_SUCCESS = 'GetItemByMultipleStoresAsync_SUCCESS';
export const GetItemByMultipleStoresAsync_FAIL = 'GetItemByMultipleStoresAsync_FAIL';

export const GetPendingIndentList_REQUEST = 'GetPendingIndentList_REQUEST';
export const GetPendingIndentList_SUCCESS = 'GetPendingIndentList_SUCCESS';
export const GetPendingIndentList_FAIL = 'GetPendingIndentList_FAIL';

export const ItemsByFacility_REQUEST = 'ItemsByFacility_REQUEST';
export const ItemsByFacility_SUCCESS = 'ItemsByFacility_SUCCESS';
export const ItemsByFacility_FAIL = 'ItemsByFacility_FAIL';

export const GetPendingIndentReceive_REQUEST = 'GetPendingIndentReceive_REQUEST';
export const GetPendingIndentReceive_SUCCESS = 'GetPendingIndentReceive_SUCCESS';
export const GetPendingIndentReceive_FAIL = 'GetPendingIndentReceive_FAIL';

export const GetReportMaster_REQUEST = 'GetReportMaster_REQUEST';
export const GetReportMaster_SUCCESS = 'GetReportMaster_SUCCESS';
export const GetReportMaster_FAIL = 'GetReportMaster_FAIL';

export const GetDepartmentIndentAllocationSearch_REQUEST = 'GetDepartmentIndentAllocationSearch_REQUEST';
export const GetDepartmentIndentAllocationSearch_SUCCESS = 'GetDepartmentIndentAllocationSearch_SUCCESS';
export const GetDepartmentIndentAllocationSearch_FAIL = 'GetDepartmentIndentAllocationSearch_FAIL';

export const GetDepartmentDeliverySearch_REQUEST = 'GetDepartmentDeliverySearch_REQUEST';
export const GetDepartmentDeliverySearch_SUCCESS = 'GetDepartmentDeliverySearch_SUCCESS';
export const GetDepartmentDeliverySearch_FAIL = 'GetDepartmentDeliverySearch_FAIL';

export const GetPendingIndentListByItem_REQUEST = 'GetPendingIndentListByItem_REQUEST';
export const GetPendingIndentListByItem_SUCCESS = 'GetPendingIndentListByItem_SUCCESS';
export const GetPendingIndentListByItem_FAIL = 'GetPendingIndentListByItem_FAIL';

export const GetItemListByItemName_REQUEST = 'GetItemListByItemName_REQUEST';
export const GetItemListByItemName_SUCCESS = 'GetItemListByItemName_SUCCESS';
export const GetItemListByItemName_FAIL = 'GetItemListByItemName_FAIL';

export const GetItemListByItemNameExport_REQUEST = 'GetItemListByItemNameExport_REQUEST';
export const GetItemListByItemNameExport_SUCCESS = 'GetItemListByItemNameExport_SUCCESS';
export const GetItemListByItemNameExport_FAIL = 'GetItemListByItemNameExport_FAIL';

export const GetRequiredItemListByItemName_REQUEST = 'GetRequiredItemListByItemName_REQUEST';
export const GetRequiredItemListByItemName_SUCCESS = 'GetRequiredItemListByItemName_SUCCESS';
export const GetRequiredItemListByItemName_FAIL = 'GetRequiredItemListByItemName_FAIL';

export const GetItemNameSetup_REQUEST = 'GetItemNameSetup_REQUEST';
export const GetItemNameSetup_SUCCESS = 'GetItemNameSetup_SUCCESS';
export const GetItemNameSetup_FAIL = 'GetItemNameSetup_FAIL';

export const GetItemStrengthMaster_REQUEST = 'GetItemStrengthMaster_REQUEST';
export const GetItemStrengthMaster_SUCCESS = 'GetItemStrengthMaster_SUCCESS';
export const GetItemStrengthMaster_FAIL = 'GetItemStrengthMaster_FAIL';

export const GetItemEnterpriseLevelAttributes_REQUEST = 'GetItemEnterpriseLevelAttributes_REQUEST';
export const GetItemEnterpriseLevelAttributes_SUCCESS = 'GetItemEnterpriseLevelAttributes_SUCCESS';
export const GetItemEnterpriseLevelAttributes_FAIL = 'GetItemEnterpriseLevelAttributes_FAIL';

export const GetItemReferenceSizeMaster_REQUEST = 'GetItemReferenceSizeMaster_REQUEST';
export const GetItemReferenceSizeMaster_SUCCESS = 'GetItemReferenceSizeMaster_SUCCESS';
export const GetItemReferenceSizeMaster_FAIL = 'GetItemReferenceSizeMaster_FAIL';

export const GetItemPackSizeMaster_REQUEST = 'GetItemPackSizeMaster_REQUEST';
export const GetItemPackSizeMaster_SUCCESS = 'GetItemPackSizeMaster_SUCCESS';
export const GetItemPackSizeMaster_FAIL = 'GetItemPackSizeMaster_FAIL';

export const GetItemUniqueCategoryMaster_REQUEST = 'GetItemUniqueCategoryMaster_REQUEST';
export const GetItemUniqueCategoryMaster_SUCCESS = 'GetItemUniqueCategoryMaster_SUCCESS';
export const GetItemUniqueCategoryMaster_FAIL = 'GetItemUniqueCategoryMaster_FAIL';

export const GetItemNames_LIST_REQUEST = 'GetItemNames_LIST_REQUEST';
export const GetItemNames_LIST_SUCCESS = 'GetItemNames_LIST_SUCCESS';
export const GetItemNames_LIST_FAIL = 'GetItemNames_LIST_FAIL';

export const GetItemConsumtion_LIST_REQUEST = 'GetItemConsumtion_LIST_REQUEST';
export const GetItemConsumtion_LIST_SUCCESS = 'GetItemConsumtion_LIST_SUCCESS';
export const GetItemConsumtion_LIST_FAIL = 'GetItemConsumtion_LIST_FAIL';

export const GetDocumentsList_LIST_REQUEST = 'GetDocumentsList_LIST_REQUEST';
export const GetDocumentsList_LIST_SUCCESS = 'GetDocumentsList_LIST_SUCCESS';
export const GetDocumentsList_LIST_FAIL = 'GetDocumentsList_LIST_FAIL';

export const GetItemListReadyToReuseByBatchId_LIST_REQUEST = 'GetItemListReadyToReuseByBatchId_LIST_REQUEST';
export const GetItemListReadyToReuseByBatchId_LIST_SUCCESS = 'GetItemListReadyToReuseByBatchId_LIST_SUCCESS';
export const GetItemListReadyToReuseByBatchId_LIST_FAIL = 'GetItemListReadyToReuseByBatchId_LIST_FAIL';

export const POGRNReceivedDetails_LIST_REQUEST = 'POGRNReceivedDetails_LIST_REQUEST';
export const POGRNReceivedDetails_LIST_SUCCESS = 'POGRNReceivedDetails_LIST_SUCCESS';
export const POGRNReceivedDetails_LIST_FAIL = 'POGRNReceivedDetails_LIST_FAIL';

export const GetConsignmentItemWithStockByChallan_REQUEST = 'GetConsignmentItemWithStockByChallan_REQUEST';
export const GetConsignmentItemWithStockByChallan_SUCCESS = 'GetConsignmentItemWithStockByChallan_SUCCESS';
export const GetConsignmentItemWithStockByChallan_FAIL = 'GetConsignmentItemWithStockByChallan_FAIL';

//------------------------------SHRIKANT SAINI--------------------------------------------
export const OPIssueSaleMain_LIST_REQUEST = 'OPIssueSaleMain_LIST_REQUEST';
export const OPIssueSaleMain_LIST_SUCCESS = 'OPIssueSaleMain_LIST_SUCCESS';
export const OPIssueSaleMain_LIST_FAIL = 'OPIssueSaleMain_LIST_FAIL';

export const IPIssueMain_LIST_REQUEST = 'IPIssueMain_LIST_REQUEST';
export const IPIssueMain_LIST_SUCCESS = 'IPIssueMain_LIST_SUCCESS';
export const IPIssueMain_LIST_FAIL = 'IPIssueMain_LIST_FAIL';

export const OPIssueSaleReturnMain_LIST_REQUEST = 'OPIssueSaleReturnMain_LIST_REQUEST';
export const OPIssueSaleReturnMain_LIST_SUCCESS = 'OPIssueSaleReturnMain_LIST_SUCCESS';
export const OPIssueSaleReturnMain_LIST_FAIL = 'OPIssueSaleReturnMain_LIST_FAIL';

export const OPIssueSaleDetails_LIST_REQUEST = 'OPIssueSaleDetails_LIST_REQUEST';
export const OPIssueSaleDetails_LIST_SUCCESS = 'OPIssueSaleDetails_LIST_SUCCESS';
export const OPIssueSaleDetails_LIST_FAIL = 'OPIssueSaleDetails_LIST_FAIL';

export const IPIssueDetails_LIST_REQUEST = 'IPIssueDetails_LIST_REQUEST';
export const IPIssueDetails_LIST_SUCCESS = 'IPIssueDetails_LIST_SUCCESS';
export const IPIssueDetails_LIST_FAIL = 'IPIssueDetails_LIST_FAIL';

export const OPIssueSaleBatchDetails_LIST_REQUEST = 'OPIssueSaleBatchDetails_LIST_REQUEST';
export const OPIssueSaleBatchDetails_LIST_SUCCESS = 'OPIssueSaleBatchDetails_LIST_SUCCESS';
export const OPIssueSaleBatchDetails_LIST_FAIL = 'OPIssueSaleBatchDetails_LIST_FAIL';

export const OPIssueSaleDetailsItemBatchList_LIST_REQUEST = 'OPIssueSaleDetailsItemBatchList_LIST_REQUEST';
export const OPIssueSaleDetailsItemBatchList_LIST_SUCCESS = 'OPIssueSaleDetailsItemBatchList_LIST_SUCCESS';
export const OPIssueSaleDetailsItemBatchList_LIST_FAIL = 'OPIssueSaleDetailsItemBatchList_LIST_FAIL';

export const OPIssueSaleDetailsItemBatchListBarcode_LIST_REQUEST = 'OPIssueSaleDetailsItemBatchListBarcode_LIST_REQUEST';
export const OPIssueSaleDetailsItemBatchListBarcode_LIST_SUCCESS = 'OPIssueSaleDetailsItemBatchListBarcode_LIST_SUCCESS';
export const OPIssueSaleDetailsItemBatchListBarcode_LIST_FAIL = 'OPIssueSaleDetailsItemBatchListBarcode_LIST_FAIL';

export const OPIssueSaleDetailsItemBatchListPayorChange_LIST_REQUEST = 'OPIssueSaleDetailsItemBatchListPayorChange_LIST_REQUEST';
export const OPIssueSaleDetailsItemBatchListPayorChange_LIST_SUCCESS = 'OPIssueSaleDetailsItemBatchListPayorChange_LIST_SUCCESS';
export const OPIssueSaleDetailsItemBatchListPayorChange_LIST_FAIL = 'OPIssueSaleDetailsItemBatchListPayorChange_LIST_FAIL';

export const GetItemRx_LIST_REQUEST = 'GetItemRx_LIST_REQUEST';
export const GetItemRx_LIST_SUCCESS = 'GetItemRx_LIST_SUCCESS';
export const GetItemRx_LIST_FAIL = 'GetItemRx_LIST_FAIL';


export const EmpStoreAccessData_LIST_REQUEST = 'EmpStoreAccessData_LIST_REQUEST';
export const EmpStoreAccessData_LIST_SUCCESS = 'EmpStoreAccessData_LIST_SUCCESS';
export const EmpStoreAccessData_LIST_FAIL = 'EmpStoreAccessData_LIST_FAIL';

export const OPIssueSaleDetailsItemBatchSubstitutionList_LIST_REQUEST = 'OPIssueSaleDetailsItemBatchSubstitutionList_LIST_REQUEST';
export const OPIssueSaleDetailsItemBatchSubstitutionList_LIST_SUCCESS = 'OPIssueSaleDetailsItemBatchSubstitutionList_LIST_SUCCESS';
export const OPIssueSaleDetailsItemBatchSubstitutionList_LIST_FAIL = 'OPIssueSaleDetailsItemBatchSubstitutionList_LIST_FAIL';

export const OPIssueSaleDetailsItemForPickList_LIST_REQUEST = 'OPIssueSaleDetailsItemForPickList_LIST_REQUEST';
export const OPIssueSaleDetailsItemForPickList_LIST_SUCCESS = 'OPIssueSaleDetailsItemForPickList_LIST_SUCCESS';
export const OPIssueSaleDetailsItemForPickList_LIST_FAIL = 'OPIssueSaleDetailsItemForPickList_LIST_FAIL';

export const OPIssueSaleMainGetPickList_LIST_REQUEST = 'OPIssueSaleMainGetPickList_LIST_REQUEST';
export const OPIssueSaleMainGetPickList_LIST_SUCCESS = 'OPIssueSaleMainGetPickList_LIST_SUCCESS';
export const OPIssueSaleMainGetPickList_LIST_FAIL = 'OPIssueSaleMainGetPickList_LIST_FAIL';

export const OPIssueSaleDetailsGetPickList_LIST_REQUEST = 'OPIssueSaleDetailsGetPickList_LIST_REQUEST';
export const OPIssueSaleDetailsGetPickList_LIST_SUCCESS = 'OPIssueSaleDetailsGetPickList_LIST_SUCCESS';
export const OPIssueSaleDetailsGetPickList_LIST_FAIL = 'OPIssueSaleDetailsGetPickList_LIST_FAIL';

export const OPIssueSaleDetailsItemForEncounterList_LIST_REQUEST = 'OPIssueSaleDetailsItemForEncounterList_LIST_REQUEST';
export const OPIssueSaleDetailsItemForEncounterList_LIST_SUCCESS = 'OPIssueSaleDetailsItemForEncounterList_LIST_SUCCESS';
export const OPIssueSaleDetailsItemForEncounterList_LIST_FAIL = 'OPIssueSaleDetailsItemForEncounterList_LIST_FAIL';

export const GetInventoryReports_LIST_REQUEST = 'GetInventoryReports_LIST_REQUEST';
export const GetInventoryReports_LIST_SUCCESS = 'GetInventoryReports_LIST_SUCCESS';
export const GetInventoryReports_LIST_FAIL = 'GetInventoryReports_LIST_FAIL';

export const GetItemNameDisplaySetup_LIST_REQUEST = 'GetItemNameDisplaySetup_LIST_REQUEST';
export const GetItemNameDisplaySetup_LIST_SUCCESS = 'GetItemNameDisplaySetup_LIST_SUCCESS';
export const GetItemNameDisplaySetup_LIST_FAIL = 'GetItemNameDisplaySetup_LIST_FAIL';

export const GetStockTransferSearchSearchPopup_REQUEST = 'GetStockTransferSearchSearchPopup_REQUEST';
export const GetStockTransferSearchSearchPopup_SUCCESS = 'GetStockTransferSearchSearchPopup_SUCCESS';
export const GetStockTransferSearchSearchPopup_FAIL = 'GetStockTransferSearchSearchPopup_FAIL';

export const OPSEARCHINVOICEData_REQUEST = 'OPSEARCHINVOICEData_REQUEST';
export const OPSEARCHINVOICEData_SUCCESS = 'OPSEARCHINVOICEData_SUCCESS';
export const OPSEARCHINVOICEData_FAIL = 'OPSEARCHINVOICEData_FAIL';

export const OPIssueSalePrescriptionList_LIST_REQUEST = 'OPIssueSalePrescriptionList_LIST_REQUEST';
export const OPIssueSalePrescriptionList_LIST_SUCCESS = 'GOPIssueSalePrescriptionList_LIST_SUCCESS';
export const OPIssueSalePrescriptionList_LIST_FAIL = 'OPIssueSalePrescriptionList_LIST_FAIL';

export const OPIssueSalePrescriptionDetail_LIST_REQUEST = 'OPIssueSalePrescriptionDetail_LIST_REQUEST';
export const OPIssueSalePrescriptionDetail_LIST_SUCCESS = 'GOPIssueSalePrescriptionDetail_LIST_SUCCESS';
export const OPIssueSalePrescriptionDetail_LIST_FAIL = 'OPIssueSalePrescriptionDetail_LIST_FAIL';
// Added By: kishan
export const GetItemMasterAuditFacilityMapping_LIST_REQUEST = 'GetItemMasterAuditFacilityMapping_LIST_REQUEST';
export const GetItemMasterAuditFacilityMapping_LIST_SUCCESS = 'GetItemMasterAuditFacilityMapping_LIST_SUCCESS';
export const GetItemMasterAuditFacilityMapping_LIST_FAIL = 'GetItemMasterAuditFacilityMapping_LIST_FAIL';

export const GetItemMasterAuditControlledPrice_LIST_REQUEST = 'GetItemMasterAuditControlledPrice_LIST_REQUEST';
export const GetItemMasterAuditControlledPrice_LIST_SUCCESS = 'GetItemMasterAuditControlledPrice_LIST_SUCCESS';
export const GetItemMasterAuditControlledPrice_LIST_FAIL = 'GetItemMasterAuditControlledPrice_LIST_FAIL';

export const GetItemMasterAuditGeneric_LIST_REQUEST = 'GetItemMasterAuditGeneric_LIST_REQUEST';
export const GetItemMasterAuditGeneric_LIST_SUCCESS = 'GetItemMasterAuditGeneric_LIST_SUCCESS';
export const GetItemMasterAuditGeneric_LIST_FAIL = 'GetItemMasterAuditGeneric_LIST_FAIL';

export const GetItemMasterAuditItemAttribute_LIST_REQUEST = 'GetItemMasterAuditItemAttribute_LIST_REQUEST';
export const GetItemMasterAuditItemAttribute_LIST_SUCCESS = 'GetItemMasterAuditItemAttribute_LIST_SUCCESS';
export const GetItemMasterAuditItemAttribute_LIST_FAIL = 'GetItemMasterAuditItemAttribute_LIST_FAIL';

export const GetItemMasterAuditDoseSettings_LIST_REQUEST = 'GetItemMasterAuditDoseSettings_LIST_REQUEST';
export const GetItemMasterAuditDoseSettings_LIST_SUCCESS = 'GetItemMasterAuditDoseSettings_LIST_SUCCESS';
export const GetItemMasterAuditDoseSettings_LIST_FAIL = 'GetItemMasterAuditDoseSettings_LIST_FAIL';

export const GetItemMasterAuditFrequency_LIST_REQUEST = 'GetItemMasterAuditFrequency_LIST_REQUEST';
export const GetItemMasterAuditFrequency_LIST_SUCCESS = 'GetItemMasterAuditFrequency_LIST_SUCCESS';
export const GetItemMasterAuditFrequency_LIST_FAIL = 'GetItemMasterAuditFrequency_LIST_FAIL';

export const GetItemMasterAuditCharacteristics_LIST_REQUEST = 'GetItemMasterAuditCharacteristics_LIST_REQUEST';
export const GetItemMasterAuditCharacteristics_LIST_SUCCESS = 'GetItemMasterAuditCharacteristics_LIST_SUCCESS';
export const GetItemMasterAuditCharacteristics_LIST_FAIL = 'GetItemMasterAuditCharacteristics_LIST_FAIL';

export const GetItemMasterAuditLabelInstruction_LIST_REQUEST = 'GetItemMasterAuditLabelInstruction_LIST_REQUEST';
export const GetItemMasterAuditLabelInstruction_LIST_SUCCESS = 'GetItemMasterAuditLabelInstruction_LIST_SUCCESS';
export const GetItemMasterAuditLabelInstruction_LIST_FAIL = 'GetItemMasterAuditLabelInstruction_LIST_FAIL';

export const GetItemMasterAuditRouteMapping_LIST_REQUEST = 'GetItemMasterAuditRouteMapping_LIST_REQUEST';
export const GetItemMasterAuditRouteMapping_LIST_SUCCESS = 'GetItemMasterAuditRouteMapping_LIST_SUCCESS';
export const GetItemMasterAuditRouteMapping_LIST_FAIL = 'GetItemMasterAuditRouteMapping_LIST_FAIL';

//Added by : Rajesh Yati   
export const DrugItemSetting_LIST_REQUEST = 'DrugItemSetting_LIST_REQUEST';
export const DrugItemSetting_LIST_SUCCESS = 'DrugItemSetting_LIST_SUCCESS';
export const DrugItemSetting_LIST_FAIL = 'DrugItemSetting_LIST_FAIL';

export const FrequencyMaster_LIST_REQUEST = 'FrequencyMaster_LIST_REQUEST';
export const FrequencyMaster_LIST_SUCCESS = 'FrequencyMaster_LIST_SUCCESS';
export const FrequencyMaster_LIST_FAIL = 'FrequencyMaster_LIST_FAIL';

export const ItemLookSoundSetup_LIST_REQUEST = 'ItemLookSoundSetup_LIST_REQUEST';
export const ItemLookSoundSetup_LIST_SUCCESS = 'ItemLookSoundSetup_LIST_SUCCESS';
export const ItemLookSoundSetup_LIST_FAIL = 'ItemLookSoundSetup_LIST_FAIL';


export const ItemCharactoristics_LIST_REQUEST = 'ItemCharactoristics_LIST_REQUEST';
export const ItemCharactoristics_LIST_SUCCESS = 'ItemCharactoristics_LIST_SUCCESS';
export const ItemCharactoristics_LIST_FAIL = 'ItemCharactoristics_LIST_FAIL';

export const STATUSBYTYPE_POApp_LIST_REQUEST = 'STATUSBYTYPE_POApp_LIST_REQUEST';
export const STATUSBYTYPE_POApp_LIST_SUCCESS = 'STATUSBYTYPE_POApp_LIST_SUCCESS';
export const STATUSBYTYPE_POApp_LIST_FAIL = 'STATUSBYTYPE_POApp_LIST_FAIL';

export const ItemWiseStock_LIST_REQUEST = 'ItemWiseStock_LIST_REQUEST';
export const ItemWiseStock_LIST_SUCCESS = 'ItemWiseStock_LIST_SUCCESS';
export const ItemWiseStock_LIST_FAIL = 'ItemWiseStock_LIST_FAIL';


export const ItemByName_LIST_REQUEST = 'ItemByName_LIST_REQUEST';
export const ItemByName_LIST_SUCCESS = 'ItemByName_LIST_SUCCESS';
export const ItemByName_LIST_FAIL = 'ItemByName_LIST_FAIL';

export const STKADJSearch_LIST_REQUEST = 'STKADJSearch_LIST_REQUEST';
export const STKADJSearch_LIST_SUCCESS = 'STKADJSearch_LIST_SUCCESS';
export const STKADJSearch_LIST_FAIL = 'STKADJSearch_LIST_FAIL';

export const STKADJMain_LIST_REQUEST = 'STKADJMain_LIST_REQUEST';
export const STKADJMain_LIST_SUCCESS = 'STKADJMain_LIST_SUCCESS';
export const STKADJMain_LIST_FAIL = 'STKADJMain_LIST_FAIL';


export const BatchNo_LIST_REQUEST = 'BatchNo_LIST_REQUEST';
export const BatchNo_LIST_SUCCESS = 'BatchNo_LIST_SUCCESS';
export const BatchNo_LIST_FAIL = 'BatchNo_LIST_FAIL';

export const BatchDetails_LIST_REQUEST = 'BatchDetails_LIST_REQUEST';
export const BatchDetails_LIST_SUCCESS = 'BatchDetails_LIST_SUCCESS';
export const BatchDetails_LIST_FAIL = 'BatchDetails_LIST_FAIL';


export const ItemRouteMapping_LIST_REQUEST = 'ItemRouteMapping_LIST_REQUEST';
export const ItemRouteMapping_LIST_SUCCESS = 'ItemRouteMapping_LIST_SUCCESS';
export const ItemRouteMapping_LIST_FAIL = 'ItemRouteMapping_LIST_FAIL';

export const ItemRouteMaster_LIST_REQUEST = 'ItemRouteMaster_LIST_REQUEST';
export const ItemRouteMaster_LIST_SUCCESS = 'ItemRouteMaster_LIST_SUCCESS';
export const ItemRouteMaster_LIST_FAIL = 'ItemRouteMaster_LIST_FAIL';


export const ItemRouteFormMaster_LIST_REQUEST = 'ItemRouteFormMaster_LIST_REQUEST';
export const ItemRouteFormMaster_LIST_SUCCESS = 'ItemRouteFormMaster_LIST_SUCCESS';
export const ItemRouteFormMaster_LIST_FAIL = 'ItemRouteFormMaster_LIST_FAIL';

export const ItemDisc_LIST_REQUEST = 'ItemDisc_LIST_REQUEST';
export const ItemDisc_LIST_SUCCESS = 'ItemDisc_LIST_SUCCESS';
export const ItemDisc_LIST_FAIL = 'ItemDisc_LIST_FAIL';

export const CompanyNonDiscountable_LIST_REQUEST = 'CompanyNonDiscountable_LIST_REQUEST';
export const CompanyNonDiscountable_LIST_SUCCESS = 'CompanyNonDiscountable_LIST_SUCCESS';
export const CompanyNonDiscountable_LIST_FAIL = 'CompanyNonDiscountable_LIST_FAIL';

export const CompanyItemExclusion_LIST_REQUEST = 'CompanyItemExclusion_LIST_REQUEST';
export const CompanyItemExclusion_LIST_SUCCESS = 'CompanyItemExclusion_LIST_SUCCESS';
export const CompanyItemExclusion_LIST_FAIL = 'CompanyItemExclusion_LIST_FAIL';

export const ItemwiseBatchesDetails_LIST_REQUEST = 'ItemwiseBatchesDetails_LIST_REQUEST';
export const ItemwiseBatchesDetails_LIST_SUCCESS = 'ItemwiseBatchesDetails_LIST_SUCCESS';
export const ItemwiseBatchesDetails_LIST_FAIL = 'ItemwiseBatchesDetails_LIST_FAIL';

export const ItemControlledPrice_LIST_REQUEST = 'ItemControlledPrice_LIST_REQUEST';
export const ItemControlledPrice_LIST_SUCCESS = 'ItemControlledPrice_LIST_SUCCESS';
export const ItemControlledPrice_LIST_FAIL = 'ItemControlledPrice_LIST_FAIL';

export const GetManufacturerSupplierMapping_REQUEST = 'GetManufacturerSupplierMapping_REQUEST';
export const GetManufacturerSupplierMapping_SUCCESS = 'GetManufacturerSupplierMapping_SUCCESS';
export const GetManufacturerSupplierMapping_FAIL = 'GetManufacturerSupplierMapping_FAIL';

export const SendEmail_REQUEST = 'SendEmail_REQUEST';
export const SendEmail_SUCCESS = 'SendEmail_SUCCESS';
export const SendEmail_FAIL = 'SendEmail_FAIL';

export const GetConsignmentItemWithStock_REQUEST = 'GetConsignmentItemWithStock_REQUEST';
export const GetConsignmentItemWithStock_SUCCESS = 'GetConsignmentItemWithStock_SUCCESS';
export const GetConsignmentItemWithStock_FAIL = 'GetConsignmentItemWithStock_FAIL';

export const GetConsignmentAllocationDetail_REQUEST = 'GetConsignmentAllocationDetail_REQUEST';
export const GetConsignmentAllocationDetail_SUCCESS = 'GetConsignmentAllocationDetail_SUCCESS';
export const GetConsignmentAllocationDetail_FAIL = 'GetConsignmentAllocationDetail_FAIL';

export const GetConsignmentIssueDetail_REQUEST = 'GetConsignmentIssueDetail_REQUEST';
export const GetConsignmentIssueDetail_SUCCESS = 'GetConsignmentIssueDetail_SUCCESS';
export const GetConsignmentIssueDetail_FAIL = 'GetConsignmentIssueDetail_FAIL';

export const GetConsignmentIssueList_REQUEST = 'GetConsignmentIssueList_REQUEST';
export const GetConsignmentIssueList_SUCCESS = 'GetConsignmentIssueList_SUCCESS';
export const GetConsignmentIssueList_FAIL = 'GetConsignmentIssueList_FAIL';

export const GetEmpList_REQUEST = 'GetEmpList_REQUEST';
export const GetEmpList_SUCCESS = 'GetEmpList_SUCCESS';
export const GetEmpList_FAIL = 'GetEmpList_FAIL';

export const GetPayorItemDiscount_LIST_REQUEST = 'GetPayorItemDiscount_LIST_REQUEST';
export const GetPayorItemDiscount_LIST_SUCCESS = 'GetPayorItemDiscount_LIST_SUCCESS';
export const GetPayorItemDiscount_LIST_FAIL = 'GetPayorItemDiscount_LIST_FAIL';

export const GetSlabPayorDiscount_LIST_REQUEST = 'GetSlabPayorDiscount_LIST_REQUEST';
export const GetSlabPayorDiscount_LIST_SUCCESS = 'GetSlabPayorDiscount_LIST_SUCCESS';
export const GetSlabPayorDiscount_LIST_FAIL = 'GetSlabPayorDiscount_LIST_FAIL';

export const GetFilterCategoryData_LIST_REQUEST = 'GetFilterCategoryData_LIST_REQUEST';
export const GetFilterCategoryData_LIST_SUCCESS = 'GetFilterCategoryData_LIST_SUCCESS';
export const GetFilterCategoryData_LIST_FAIL = 'GetFilterCategoryData_LIST_FAIL';

export const GetPrescriptionViewData_LIST_REQUEST = 'GetPrescriptionViewData_LIST_REQUEST';
export const GetPrescriptionViewData_LIST_SUCCESS = 'GetPrescriptionViewData_LIST_SUCCESS';
export const GetPrescriptionViewData_LIST_FAIL = 'GetPrescriptionViewData_LIST_FAIL';

export const GetItemNameSetup_LIST_REQUEST = 'GetItemNameSetup_LIST_REQUEST';
export const GetItemNameSetup_LIST_SUCCESS = 'GetItemNameSetup_LIST_SUCCESS';
export const GetItemNameSetup_LIST_FAIL = 'GetItemNameSetup_LIST_FAIL';

export const GetItemCommonMaster_LIST_REQUEST = 'GetItemCommonMaster_LIST_REQUEST';
export const GetItemCommonMaster_LIST_SUCCESS = 'GetItemCommonMaster_LIST_SUCCESS';
export const GetItemCommonMaster_LIST_FAIL = 'GetItemCommonMaster_LIST_FAIL';

export const GetItemBrandMaster_REQUEST = 'GetItemBrandMaster_REQUEST';
export const GetItemBrandMaster_SUCCESS = 'GetItemBrandMaster_SUCCESS';
export const GetItemBrandMaster_FAIL = 'GetItemBrandMaster_FAIL';

export const INDENTFORISSUEPicklistPrint_REQUEST = 'INDENTFORISSUEPicklistPrint_REQUEST';
export const INDENTFORISSUEPicklistPrint_SUCCESS = 'INDENTFORISSUEPicklistPrint_SUCCESS';
export const INDENTFORISSUEPicklistPrint_FAIL = 'INDENTFORISSUEPicklistPrint_FAIL';

export const SuppliersOfState_LIST_REQUEST = 'SuppliersOfState_LIST_REQUEST';
export const SuppliersOfState_LIST_SUCCESS = 'SuppliersOfState_LIST_SUCCESS';
export const SuppliersOfState_LIST_FAIL = 'SuppliersOfState_LIST_FAIL';

export const GetSearchStoreCategory_REQUEST = 'GetSearchStoreCategory_REQUEST';
export const GetSearchStoreCategory_SUCCESS = 'GetSearchStoreCategory_SUCCESS';
export const GetSearchStoreCategory_FAIL = 'GetSearchStoreCategory_FAIL';

export const GetSearchSupplierCategory_REQUEST = 'GetSearchSupplierCategory_REQUEST';
export const GetSearchSupplierCategory_SUCCESS = 'GetSearchSupplierCategory_SUCCESS';
export const GetSearchSupplierCategory_FAIL = 'GetSearchSupplierCategory_FAIL';

export const GetSearchItemQueryType_REQUEST = 'GetSearchItemQueryType_REQUEST';
export const GetSearchItemQueryType_SUCCESS = 'GetSearchItemQueryType_SUCCESS';
export const GetSearchItemQueryType_FAIL = 'GetSearchItemQueryType_FAIL';

export const GetSearchItemGenericStock_REQUEST = 'GetSearchItemGenericStock_REQUEST';
export const GetSearchItemGenericStock_SUCCESS = 'GetSearchItemGenericStock_SUCCESS';
export const GetSearchItemGenericStock_FAIL = 'GetSearchItemGenericStock_FAIL';

export const ReusableItemUsage_LIST_REQUEST = 'ReusableItemUsage_LIST_REQUEST';
export const ReusableItemUsage_LIST_SUCCESS = 'ReusableItemUsage_LIST_SUCCESS';
export const ReusableItemUsage_LIST_FAIL = 'ReusableItemUsage_LIST_FAIL';

export const BounceItems_LIST_REQUEST = 'BounceItems_LIST_REQUEST';
export const BounceItems_LIST_SUCCESS = 'BounceItems_LIST_SUCCESS';
export const BounceItems_LIST_FAIL = 'BounceItems_LIST_FAIL';


export const GetStore_List_REQUEST = 'GetStore_List_REQUEST';
export const GetStore_List_SUCCESS = 'GetStore_List_SUCCESS';
export const GetStore_List_FAIL = 'GetStore_List_FAIL';

export const GetSlabWiseDiscount_LIST_REQUEST = 'GetSlabWiseDiscount_LIST_REQUEST';
export const GetSlabWiseDiscount_LIST_SUCCESS = 'GetSlabWiseDiscount_LIST_SUCCESS';
export const GetSlabWiseDiscount_LIST_FAIL = 'GetSlabWiseDiscount_LIST_FAIL';

export const GetPOPlanner_REQUEST = 'GetPOPlanner_REQUEST';
export const GetPOPlanner_SUCCESS = 'GetPOPlanner_SUCCESS';
export const GetPOPlanner_FAIL = 'GetPOPlanner_FAIL';

export const GetItemListConsumptionPI_LIST_REQUEST = 'GetItemListConsumptionPI_LIST_REQUEST';
export const GetItemListConsumptionPI_LIST_SUCCESS = 'GetItemListConsumptionPI_LIST_SUCCESS';
export const GetItemListConsumptionPI_LIST_FAIL = 'GetItemListConsumptionPI_LIST_FAIL';


export const VitalMapping_REQUEST = 'VitalMapping_REQUEST';
export const VitalMapping_SUCCESS = 'VitalMapping_SUCCESS';
export const VitalMapping_FAIL = 'VitalMapping_FAIL';


export const GetIPIssueTATReport_LIST_REQUEST = 'GetIPIssueTATReport_LIST_REQUEST';
export const GetIPIssueTATReport_LIST_SUCCESS = 'GetIPIssueTATReport_LIST_SUCCESS';
export const GetIPIssueTATReport_LIST_FAIL = 'GetIPIssueTATReport_LIST_FAIL';

export const GetItemSearch_LIST_REQUEST = 'GetItemSearch_LIST_REQUEST';
export const GetItemSearch_LIST_SUCCESS = 'GetItemSearch_LIST_SUCCESS';
export const GetItemSearch_LIST_FAIL = 'GetItemSearch_LIST_FAIL';

export const GetCIMSData_LIST_REQUEST = 'GetCIMSData_LIST_REQUEST';
export const GetCIMSData_LIST_SUCCESS = 'GetCIMSData_LIST_SUCCESS';
export const GetCIMSData_LIST_FAIL = 'GetCIMSData_LIST_FAIL';

//-------------------------------------------------------------------------------------------


//-------------------------------------------------------------------------------------------
