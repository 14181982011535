
import {
  REGISTRATIONMANDATORY_GET_REQUEST,
  REGISTRATIONMANDATORY_GET_SUCCESS,
  REGISTRATIONMANDATORY_GET_FAIL,
  REGISTRATIONTYPE_LIST_FAIL,
  REGISTRATIONTYPE_LIST_SUCCESS,
  REGISTRATIONTYPE_LIST_REQUEST,
  TITLE_LIST_REQUEST,
  TITLE_LIST_FAIL,
  TITLE_LIST_SUCCESS,
  NATIONALITY_LIST_REQUEST,
  NATIONALITY_LIST_SUCCESS,
  RELATIONSHIP_LIST_SUCCESS,
  NATIONALITY_LIST_FAIL,
  MagicSearch_FAIL_UHID,
  MagicSearch_SUCCESS_UHID,
  MagicSearch_REQUEST_UHID,
  MagicSearch_FAIL,
  MagicSearch_SUCCESS,
  MagicSearch_REQUEST,
  RELATIONSHIP_LIST_FAIL,
  RELATIONSHIP_LIST_REQUEST,
  AGETYPE_LIST_REQUEST,
  AGETYPE_LIST_FAIL,
  AGETYPE_LIST_SUCCESS,
  RELIGION_LIST_REQUEST,
  RELIGION_LIST_FAIL,
  RELIGION_LIST_SUCCESS,
  OCCUPATION_LIST_REQUEST, OCCUPATION_LIST_SUCCESS, OCCUPATION_LIST_FAIL,
  EDUCATION_LIST_REQUEST, EDUCATION_LIST_SUCCESS, EDUCATION_LIST_FAIL,
  LANGUAGE_LIST_REQUEST, LANGUAGE_LIST_SUCCESS, LANGUAGE_LIST_FAIL,
  HWCTYPE_LIST_REQUEST, HWCTYPE_LIST_SUCCESS, HWCTYPE_LIST_FAIL,
  COUNTRY_LIST_REQUEST, COUNTRY_LIST_SUCCESS, COUNTRY_LIST_FAIL,
  STATE_LIST_REQUEST, STATE_LIST_SUCCESS, STATE_LIST_FAIL,
  CITY_LIST_REQUEST, CITY_LIST_SUCCESS, CITY_LIST_FAIL,
  AREA_LIST_REQUEST_PRE, AREA_LIST_SUCCESS_PRE, AREA_LIST_FAIL_PRE,
  AREA_LIST_REQUEST_PER, AREA_LIST_SUCCESS_PER, AREA_LIST_FAIL_PER,
  HCF_LIST_REQUEST, HCF_LIST_SUCCESS, HCF_LIST_FAIL,
  REGISTRATION_SAVE_SUCCESS, REGISTRATION_SAVE_FAIL, REGISTRATION_SAVE_REQUEST,
  BULKREGISTRATION_SAVE_SUCCESS, BULKREGISTRATION_SAVE_FAIL, BULKREGISTRATION_SAVE_REQUEST,
  REGISTRATION_GET_SUCCESS, REGISTRATION_GET_FAIL, REGISTRATION_GET_REQUEST,
  OPPATIENTBANNERDATA_GET_REQUEST, OPPATIENTBANNERDATA_GET_SUCCESS, OPPATIENTBANNERDATA_GET_FAIL,
  STATE_LIST_SUCCESS_PRE, STATE_LIST_REQUEST_PRE, STATE_LIST_FAIL_PRE,
  STATE_LIST_REQUEST_PER, STATE_LIST_SUCCESS_PER, STATE_LIST_FAIL_PER,
  STATE_LIST_REQUEST_STAFF, STATE_LIST_SUCCESS_STAFF, STATE_LIST_FAIL_STAFF,
  CITY_LIST_REQUEST_PRE, CITY_LIST_FAIL_PRE, CITY_LIST_SUCCESS_PRE,
  CITY_LIST_REQUEST_PER, CITY_LIST_FAIL_PER, CITY_LIST_SUCCESS_PER,
  PAYER_LIST_REQUEST, PAYER_LIST_SUCCESS, PAYER_LIST_FAIL,
  PAYERMAGIC_LIST_SUCCESS,PAYERMAGIC_LIST_REQUEST,PAYERMAGIC_LIST_FAIL,
  ALLCOMPANY_LIST_REQUEST, ALLCOMPANY_LIST_SUCCESS, ALLCOMPANY_LIST_FAIL,
  EMPLOYER_LIST_REQUEST, EMPLOYER_LIST_SUCCESS, EMPLOYER_LIST_FAIL,
  CustomField_GET_SUCCESS, CustomField_GET_FAIL, CustomField_GET_REQUEST, RegistrationDuplicate_REQUEST, RegistrationDuplicate_FAIL, RegistrationDuplicate_SUCCESS, CustomFieldOption_GET_REQUEST, CustomFieldOption_GET_FAIL, CustomFieldOption_GET_SUCCESS,
  REGISTRATION_STAFFSAVE_REQUEST, REGISTRATION_STAFFSAVE_FAIL, REGISTRATION_STAFFSAVE_SUCCESS,
  REGISTRATION_DUPLICATE_SAVE_REQUEST, REGISTRATION_DUPLICATE_SAVE_FAIL, REGISTRATION_DUPLICATE_SAVE_SUCCESS,
  PARAMETER_SETUP_REQUEST, PARAMETER_SETUP_SUCCESS, PARAMETER_SETUP_FAIL, STAFF_REGDATA_REQUEST, STAFF_REGDATA_SUCCESS, STAFF_REGDATA_FAIL, CAMP_DATA_REQUEST, CAMP_DATA_SUCCESS, CAMP_DATA_FAIL,
  REG_DATA_BYREGID_REQUEST, REG_DATA_BYREGID_SUCCESS, REG_DATA_BYREGID_FAIL, REG_MERGER_DATA_REQUEST, REG_MERGER_DATA_SUCCESS, REG_MERGER_DATA_FAIL,
  PARAMETER_SETUP_MAIN_REQUEST, PARAMETER_SETUP_MAIN_SUCCESS, PARAMETER_SETUP_MAIN_FAIL, REGISTRATION_INS_SAVE_FAIL, REGISTRATION_INS_SAVE_REQUEST, REGISTRATION_INS_SAVE_SUCCESS
  , Facility_List_REQUEST, Facility_List_FAIL, Facility_List_SUCCESS, SCREENCONFIGURATION_SAVE_REQUEST, SCREENCONFIGURATION_SAVE_SUCCESS, SCREENCONFIGURATION_SAVE_FAIL
  , ModulePage_List_REQUEST, ModulePage_List_FAIL, ModulePage_List_SUCCESS, Reason_SAVE_REQUEST, Reason_SAVE_SUCCESS, Reason_SAVE_FAIL, ADMITTED_PATIENT_FAIL, ADMITTED_PATIENT_REQUEST, ADMITTED_PATIENT_SUCCESS, BED_BOOKED_PATIENT_FAIL, BED_BOOKED_PATIENT_SUCCESS, BED_BOOKED_PATIENT_REQUEST,
  ENCOUNTER_COUNT_LIST_REQUEST, ENCOUNTER_COUNT_LIST_FAIL, ENCOUNTER_COUNT_LIST_SUCCESS,
  CHECK_MERGE_PATIENT_REQUEST, CHECK_MERGE_PATIENT_FAIL, CHECK_MERGE_PATIENT_SUCCESS, CHECK_REG_VALIDITY_REQUEST, CHECK_REG_VALIDITY_SUCCESS, CHECK_REG_VALIDITY_FAIL,
  PARAMETER_SETUP_REQUEST_Global, PARAMETER_SETUP_SUCCESS_Global, PARAMETER_SETUP_FAIL_Global, SearchPatientAppointment_SUCCESS, SearchPatientAppointment_FAIL, SearchPatientAppointment_REQUEST, PatientAppointmentCancellation_REQUEST, PatientAppointmentCancellation_SUCCESS, PatientAppointmentCancellation_FAIL, MarkArrivalPatient_REQUEST, MarkArrivalPatient_FAIL, MarkArrivalPatient_SUCCESS, PatientAppointmentSlots_LIST_REQUEST, PatientAppointmentSlots_LIST_FAIL, PatientAppointmentSlots_LIST_SUCCESS, BookRescheduleAppointment_REQUEST, BookRescheduleAppointment_FAIL, BookRescheduleAppointment_SUCCESS, GetFamilyMemberList_FAIL, GetFamilyMemberList_SUCCESS, GetFamilyMemberList_REQUEST
} from '../Constants/DemograpicsConstant';
import * as DGCnst from '../Constants/DemograpicsConstant';
const intialstate = {};
//Registration Type Reducer
function RegistrationTypeReducer(state = {
  RegistrationTypeData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case REGISTRATIONTYPE_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case REGISTRATIONTYPE_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case REGISTRATIONTYPE_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        RegistrationTypeData: action.payload
      }

    }
    default: return state;
  }

}
function AllCompanyReducer(state = {
  AllCompanyData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case ALLCOMPANY_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case ALLCOMPANY_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case ALLCOMPANY_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        AllCompanyData: action.payload
      }
    }
    default: return state;
  }

}
//Title  Reducer
function TitleReducer(state = {
  TitleData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case TITLE_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case TITLE_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case TITLE_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        TitleData: action.payload
      }
    }
    default: return state;
  }

}
//Nationality Reducer
function NationalityReducer(state = {
  NationalityData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case NATIONALITY_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case NATIONALITY_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case NATIONALITY_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        NationalityData: action.payload
      }
    }
    default: return state;
  }
}

function DuplicateRecordReducer(state = {
  RegistrationDuplicate: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case RegistrationDuplicate_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case RegistrationDuplicate_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case RegistrationDuplicate_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        RegistrationDuplicate: action.payload
      }
    }
    default: return state;
  }
}





//MagicSearch Reducer
function MagicSearchReducerUHID(state = {
  MagicSearchDataUHID: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case MagicSearch_REQUEST_UHID: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case MagicSearch_FAIL_UHID: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case MagicSearch_SUCCESS_UHID: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        MagicSearchDataUHID: action.payload
      }
    }
    default: return state;
  }
}

//MagicSearch Reducer
function MagicSearchReducer(state = {
  MagicSearchData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case MagicSearch_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case MagicSearch_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case MagicSearch_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        MagicSearchData: action.payload
      }
    }
    default: return state;
  }
}

//Relationship Reducer
function RelationshipReducer(state = {
  RelationshipData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case RELATIONSHIP_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case RELATIONSHIP_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case RELATIONSHIP_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        RelationshipData: action.payload
      }
    }
    default: return state;
  }

}

function AgeTypeReducer(state = {
  AgeTypeData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case AGETYPE_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case AGETYPE_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case AGETYPE_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        AgeTypeData: action.payload
      }
    }
    default: return state;
  }

}



function ReligionReducer(state = {
  ReligionData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case RELIGION_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case RELIGION_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case RELIGION_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        ReligionData: action.payload
      }
    }
    default: return state;
  }

}

function OccupationReducer(state = {
  OccupationData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case OCCUPATION_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case OCCUPATION_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case OCCUPATION_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        OccupationData: action.payload
      }
    }
    default: return state;
  }

}

function EducationReducer(state = {
  EducationData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case EDUCATION_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case EDUCATION_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case EDUCATION_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        EducationData: action.payload
      }
    }
    default: return state;
  }

}

function LanguageReducer(state = {
  LanguageData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case LANGUAGE_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case LANGUAGE_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case LANGUAGE_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        LanguageData: action.payload
      }
    }
    default: return state;
  }

}


function HWCTypeReducer(state = {
  HWCTypeData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case HWCTYPE_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case HWCTYPE_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case HWCTYPE_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        HWCTypeData: action.payload
      }
    }
    default: return state;
  }

}


function CountryReducer(state = {
  CountryData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case COUNTRY_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case COUNTRY_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case COUNTRY_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        CountryData: action.payload
      }
    }
    default: return state;
  }

}


// function StateReducer(state = {
//   StateData: intialstate,
//   fetching: false,
//   fetched: false,
//   error: null
// }, action) {
//   switch (action.type) {
//     case STATE_LIST_REQUEST: {
//       return { ...state, fetching: true, fetched: false, error: null }
//     }
//     case STATE_LIST_FAIL: {
//       return { ...state, fetching: false, error: action.payload,fetched: true }
//     }
//     case STATE_LIST_SUCCESS: {
//       return {
//         ...state,
//         fetching: false,
//         fetched: true,
//         StateData: action.payload
//       }
//     }
//     default: return state;
//   }

// }

function StateByCountryReducerPre(state = {
  StateDataPre: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case STATE_LIST_REQUEST_PRE: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case STATE_LIST_FAIL_PRE: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case STATE_LIST_SUCCESS_PRE: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        StateDataPre: action.payload
      }
    }
    default: return state;
  }

}


function StateByCountryReducerPer(state = {
  StateDataPer: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case STATE_LIST_REQUEST_PER: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case STATE_LIST_FAIL_PER: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case STATE_LIST_SUCCESS_PER: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        StateDataPer: action.payload
      }
    }
    default: return state;
  }

}
function StaffByCodeReducer(state = {
  StaffDetails: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case STATE_LIST_REQUEST_STAFF: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case STATE_LIST_FAIL_STAFF: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case STATE_LIST_SUCCESS_STAFF: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        StaffDetails: action.payload
      }
    }
    default: return state;
  }

}


function CityReducerPre(state = {
  CityDataPre: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case CITY_LIST_REQUEST_PRE: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case CITY_LIST_FAIL_PRE: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case CITY_LIST_SUCCESS_PRE: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        CityDataPre: action.payload
      }
    }
    default: return state;
  }

}



function CityReducerPer(state = {
  CityDataPer: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case CITY_LIST_REQUEST_PER: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case CITY_LIST_FAIL_PER: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case CITY_LIST_SUCCESS_PER: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        CityDataPer: action.payload
      }
    }
    default: return state;
  }

}


function AreaReducerPer(state = {
  AreaDataPer: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case AREA_LIST_REQUEST_PER: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case AREA_LIST_FAIL_PER: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case AREA_LIST_SUCCESS_PER: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        AreaDataPer: action.payload
      }
    }
    default: return state;
  }

}

function AreaReducerPre(state = {
  AreaDataPre: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case AREA_LIST_REQUEST_PRE: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case AREA_LIST_FAIL_PRE: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case AREA_LIST_SUCCESS_PRE: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        AreaDataPre: action.payload
      }
    }
    default: return state;
  }

}




function HCFReducer(state = {
  HCFData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case HCF_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case HCF_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case HCF_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        HCFData: action.payload
      }
    }
    default: return state;
  }

}
function GetRegistrationReducer(state = {
  RegistrationData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case REGISTRATION_GET_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case REGISTRATION_GET_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case REGISTRATION_GET_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        RegistrationData: action.payload
      }
    }
    default: return state;
  }
}

function GetOPPatientBannerReducer(state = {
  OPPatientBannerData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case OPPATIENTBANNERDATA_GET_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case OPPATIENTBANNERDATA_GET_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case OPPATIENTBANNERDATA_GET_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        OPPatientBannerData: action.payload
      }
    }
    default: return state;
  }
}
function RegistrationDuplicateMergeReducer(state = {
  RegistrationDuplicateMerge: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case REGISTRATION_DUPLICATE_SAVE_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case REGISTRATION_DUPLICATE_SAVE_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case REGISTRATION_DUPLICATE_SAVE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        RegistrationDuplicateMerge: action.payload
      }
    }
    default: return state;
  }

}

function SaveRegistrationReducer(state = {
  SaveRegistrationData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case REGISTRATION_SAVE_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case REGISTRATION_SAVE_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case REGISTRATION_SAVE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        SaveRegistrationData: action.payload
      }
    }
    default: return state;
  }

}
function SaveBulkRegistrationReducer(state = {
  SaveBulkRegistrationData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case BULKREGISTRATION_SAVE_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case BULKREGISTRATION_SAVE_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case BULKREGISTRATION_SAVE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        SaveBulkRegistrationData: action.payload
      }
    }
    default: return state;
  }

}
function SaveStaffRegistrationReducer(state = {
  SaveStaffRegistrationData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case REGISTRATION_STAFFSAVE_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case REGISTRATION_STAFFSAVE_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case REGISTRATION_STAFFSAVE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        SaveStaffRegistrationData: action.payload
      }
    }
    default: return state;
  }

}
function CompanyReducerMagic(state = {
  CompanyDataMagic: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case PAYERMAGIC_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case PAYERMAGIC_LIST_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
    case PAYERMAGIC_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        CompanyDataMagic: action.payload
      }
    }
    default: return state;
  }

}


function CompanyReducer(state = {
  CompanyData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case PAYER_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case PAYER_LIST_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
    case PAYER_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        CompanyData: action.payload
      }
    }
    default: return state;
  }

}


function EmployerReducer(state = {
  EmployerData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case EMPLOYER_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case EMPLOYER_LIST_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
    case EMPLOYER_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        EmployerData: action.payload
      }
    }
    default: return state;
  }

}
function RegistrationMandatoryReducer(state = {
  MandatoryData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case REGISTRATIONMANDATORY_GET_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case REGISTRATIONMANDATORY_GET_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
    case REGISTRATIONMANDATORY_GET_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        MandatoryData: action.payload
      }
    }
    default: return state;
  }

}

function CustomFieldOPtionReducer(state = {
  CustomFieldOptionData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case CustomFieldOption_GET_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case CustomFieldOption_GET_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
    case CustomFieldOption_GET_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        CustomFieldOptionData: action.payload
      }
    }
    default: return state;
  }

}

function CustomFieldReducer(state = {
  CustomFieldData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case CustomField_GET_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case CustomField_GET_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
    case CustomField_GET_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        CustomFieldData: action.payload
      }
    }
    default: return state;
  }

}
//Parameter setup details reducer
function ParameterSetupDetailsReducer(state = {
  ParameterSetupData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case PARAMETER_SETUP_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case PARAMETER_SETUP_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case PARAMETER_SETUP_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        ParameterSetupData: action.payload
      }

    }
    default: return state;
  }

}

function ParameterSetupDetailsReducerForGlobal(state = {
  ParameterSetupDataGlobal: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case PARAMETER_SETUP_REQUEST_Global: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case PARAMETER_SETUP_FAIL_Global: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case PARAMETER_SETUP_SUCCESS_Global: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        ParameterSetupDataGlobal: action.payload
      }

    }
    default: return state;
  }

}

//Parameter setup details reducer
function ParameterSetupMainReducer(state = {
  ParameterSetupData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case PARAMETER_SETUP_MAIN_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case PARAMETER_SETUP_MAIN_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case PARAMETER_SETUP_MAIN_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        ParameterSetupData: action.payload
      }

    }
    default: return state;
  }

}
//#region 
function staffGetRegDataReducer(state = {
  StaffSearchRegData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case STAFF_REGDATA_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case STAFF_REGDATA_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case STAFF_REGDATA_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        StaffSearchRegData: action.payload
      }

    }
    default: return state;
  }

}
//Camp list data
function fetchCampListDataReducer(state = {
  CampListData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case CAMP_DATA_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case CAMP_DATA_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case CAMP_DATA_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        CampListData: action.payload
      }

    }
    default: return state;
  }

}
function GetRegDataByRegistrationIdReducer(state = {
  SearchRegDataByRegId: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case REG_DATA_BYREGID_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case REG_DATA_BYREGID_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case REG_DATA_BYREGID_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        SearchRegDataByRegId: action.payload
      }

    }
    default: return state;
  }

}

function GetRegistrationDuplicateMergeIdReducer(state = {
  MergerPaitentRegId: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case REG_MERGER_DATA_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case REG_MERGER_DATA_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case REG_MERGER_DATA_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        MergerPaitentRegId: action.payload
      }

    }
    default: return state;
  }

}
function SaveRegistrationInsuranceReducer(state = {
  SaveRegistrationInsStatus: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case REGISTRATION_INS_SAVE_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case REGISTRATION_INS_SAVE_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case REGISTRATION_INS_SAVE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        SaveRegistrationInsStatus: action.payload
      }
    }
    default: return state;
  }

}

function FacilityMasterReducer(state = {
  FacilityMasterData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case Facility_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case Facility_List_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
    case Facility_List_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        FacilityMasterData: action.payload
      }
    }
    default: return state;
  }

}
//#endregion
function saveScreenConfigurationReducer(state = {
  saveScreenConfigurationData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case SCREENCONFIGURATION_SAVE_FAIL: { return { ...state, fetching: true, fetched: false, error: null } }
    case SCREENCONFIGURATION_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
    case SCREENCONFIGURATION_SAVE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        saveScreenConfigurationData: action.payload
      }

    }
    default: return state;
  }

}


// function saveDefaultScreenConfigurationReducer(state = {
//   saveDefaultScreenConfigurationData: intialstate,
//   fetching: false,
//   fetched: false,
//   error: null
// }, action) {
//   switch (action.type) {
//       case SCREENCONFIGURATION_SAVE_FAIL: { return { ...state, fetching: true, fetched: false, error: null } }
//       case SCREENCONFIGURATION_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
//       case SCREENCONFIGURATION_SAVE_SUCCESS: {
//           return {
//               ...state,
//               fetching: false,
//               fetched: true,
//               saveDefaultScreenConfigurationData: action.payload
//           }

//       }
//       default: return state;
//   }

// }
//#endregion
function FetchModulePageReducer(state = {
  ModulePageData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case ModulePage_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case ModulePage_List_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
    case ModulePage_List_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        ModulePageData: action.payload
      }
    }
    default: return state;
  }

}
function ReasonMasterSaveReducer(state = {
  ReasonSaveData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case Reason_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case Reason_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
    case Reason_SAVE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        ReasonSaveData: action.payload
      }

    }
    default: return state;
  }

}
//added 5Oct2021 for Appointment Integration
function SearchPatientAppointmentReducer(state = {
  SearchPatientAppointment: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case SearchPatientAppointment_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case SearchPatientAppointment_FAIL: {
      return { ...state, fetching: false, fetched: true, error: action.payload,fetched: true }
    }
    case SearchPatientAppointment_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        SearchPatientAppointment: action.payload
      }
    }
    default: return state;
  }
}
function CancelPatientAppointmentReducer(state = {
  CancelPatientAppointment: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case PatientAppointmentCancellation_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case PatientAppointmentCancellation_FAIL: {
      return { ...state, fetching: false, fetched: false, error: action.payload,fetched: true }
    }
    case PatientAppointmentCancellation_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        CancelPatientAppointment: action.payload
      }
    }
    default: return state;
  }
}
function MarkArrivalPatientReducer(state = {
  MarkArrivalPatient: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case MarkArrivalPatient_REQUEST: {
      return { ...state, fetching: true, error: null }
    }
    case MarkArrivalPatient_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case MarkArrivalPatient_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        MarkArrivalPatient: action.payload
      }
    }
    default: return state;
  }
}
function GetPatientAppointmentSlotsReducer(state = {
  PatientAppointmentSlots: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case PatientAppointmentSlots_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case PatientAppointmentSlots_LIST_FAIL: {
      return { ...state, fetching: false, fetched: false, error: action.payload,fetched: true }
    }
    case PatientAppointmentSlots_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        PatientAppointmentSlots: action.payload
      }
    }
    default: return state;
  }
}
function BookReschedulePatientAppointmentReducer(state = {
  BookReschedulePatientAppointment: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case BookRescheduleAppointment_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case BookRescheduleAppointment_FAIL: {
      return { ...state, fetching: false, fetched: false, error: action.payload,fetched: true }
    }
    case BookRescheduleAppointment_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        BookReschedulePatientAppointment: action.payload
      }
    }
    default: return state;
  }
}
//end added 5Oct2021 for Appointment Integration
//AdmitPatient Reducer
function SearchAdmitPatientReducer(state = {
  SearchAdmintPatientData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case ADMITTED_PATIENT_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case ADMITTED_PATIENT_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case ADMITTED_PATIENT_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        SearchAdmintPatientData: action.payload
      }
    }
    default: return state;
  }
}


function SearchBedBookedReducer(state = {
  SearchBedBookedData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case BED_BOOKED_PATIENT_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case BED_BOOKED_PATIENT_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case BED_BOOKED_PATIENT_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        SearchBedBookedData: action.payload
      }
    }
    default: return state;
  }
}

function SaveParameterSetupMainReducer(state = {
  SaveParameterSetupData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case PARAMETER_SETUP_MAIN_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case PARAMETER_SETUP_MAIN_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case PARAMETER_SETUP_MAIN_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        SaveParameterSetupData: action.payload
      }

    }
    default: return state;
  }

}

function EncounterCountReducer(state = {
  RegistrationEncounterCount: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case ENCOUNTER_COUNT_LIST_REQUEST: {
      return { ...state, fetching: true, fetched: false, error: null }
    }
    case ENCOUNTER_COUNT_LIST_FAIL: {
      return { ...state, fetching: false, error: action.payload,fetched: true }
    }
    case ENCOUNTER_COUNT_LIST_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        RegistrationEncounterCount: action.payload
      }
    }
    default: return state;
  }
}

function CheckMergePatientReducer(state = {
  CheckMergePatientData: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case CHECK_MERGE_PATIENT_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case CHECK_MERGE_PATIENT_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
    case CHECK_MERGE_PATIENT_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        CheckMergePatientData: action.payload
      }
    }
    default: return state;
  }

}

function checkRegValidityReducer(state = {
  getRegistrationValidity: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case CHECK_REG_VALIDITY_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case CHECK_REG_VALIDITY_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
    case CHECK_REG_VALIDITY_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        getRegistrationValidity: action.payload
      }
    }
    default: return state;
  }

}

function GetFamilyMemberListDataReducer(state = { familyMemberList: intialstate, fetching: false, fetched: false, error: null }, action) {
  switch (action.type) {
    case GetFamilyMemberList_REQUEST: { return { ...state, fetching: true } }
    case GetFamilyMemberList_FAIL: { return { ...state, fetching: false, error: action.payload } }
    case GetFamilyMemberList_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        familyMemberList: action.payload
      }
    }
    default: return state;
  }
}

function GetRegSourceReducer(state = {
  SourceDescriptionList: intialstate,
  fetching: false,
  fetched: false,
  error: null
}, action) {
  switch (action.type) {
    case DGCnst.GetRegSourceLsit_REQUEST: { return { ...state, fetching: true , fetched : false, error : null } }
    case DGCnst.GetRegSourceLsit_FAIL: { return { ...state, fetching: false, error: action.payload , fetched : true } }
    case DGCnst.GetRegSourceLsit_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        SourceDescriptionList: action.payload
      }
    }
    default: return state;
  }
}

export {
  RegistrationTypeReducer, TitleReducer, NationalityReducer, RelationshipReducer, AgeTypeReducer, ReligionReducer,
  OccupationReducer, EducationReducer, LanguageReducer, HWCTypeReducer, CountryReducer, StateByCountryReducerPer, CityReducerPer, CityReducerPre,
  AreaReducerPer, HCFReducer, StateByCountryReducerPre, SaveRegistrationReducer, CompanyReducer, EmployerReducer,
  GetRegistrationReducer, RegistrationMandatoryReducer, AreaReducerPre, MagicSearchReducer, CustomFieldReducer, MagicSearchReducerUHID,
  DuplicateRecordReducer, CustomFieldOPtionReducer, StaffByCodeReducer, SaveStaffRegistrationReducer,
  RegistrationDuplicateMergeReducer, ParameterSetupDetailsReducer, staffGetRegDataReducer, fetchCampListDataReducer, GetRegDataByRegistrationIdReducer,
  GetRegistrationDuplicateMergeIdReducer, ParameterSetupMainReducer, SaveRegistrationInsuranceReducer, FacilityMasterReducer, saveScreenConfigurationReducer,
  FetchModulePageReducer, ReasonMasterSaveReducer, SearchAdmitPatientReducer, SearchBedBookedReducer,
  SaveParameterSetupMainReducer, AllCompanyReducer, EncounterCountReducer, CheckMergePatientReducer, SaveBulkRegistrationReducer,
  checkRegValidityReducer, ParameterSetupDetailsReducerForGlobal, SearchPatientAppointmentReducer, CancelPatientAppointmentReducer, MarkArrivalPatientReducer, GetPatientAppointmentSlotsReducer, BookReschedulePatientAppointmentReducer,
  GetOPPatientBannerReducer,GetFamilyMemberListDataReducer,CompanyReducerMagic,GetRegSourceReducer
};
