import {
    SHOW_REPORT_REQUEST, SHOW_REPORT_FAIL, SHOW_REPORT_SUCCESS,
    CURRENT_ADMITTED_PATIENT_LIST_REQUEST, CURRENT_ADMITTED_PATIENT_LIST_FAIL, CURRENT_ADMITTED_PATIENT_LIST_SUCCESS,
    DISCHARGE_PATIENT_LIST_REQUEST, DISCHARGE_PATIENT_LIST_FAIL, DISCHARGE_PATIENT_LIST_SUCCESS,
    BED_TRANSFER_LIST_REQUEST, BED_TRANSFER_LIST_FAIL, BED_TRANSFER_LIST_SUCCESS,
    PRACTITIONER_TRANSFER_LIST_REQUEST, PRACTITIONER_TRANSFER_LIST_FAIL, PRACTITIONER_TRANSFER_LIST_SUCCESS,
    RE_ADMISSION_LIST_REQUEST, RE_ADMISSION_LIST_SUCCESS, RE_ADMISSION_LIST_FAIL,
    DISCLAIMER_LIST_REQUEST, DISCLAIMER_LIST_SUCCESS, DISCLAIMER_LIST_FAIL,
    SAVE_DISCLAIMER_REQUEST, SAVE_DISCLAIMER_SUCCESS, SAVE_DISCLAIMER_FAIL,
    GET_DAY_LIST_REQUEST, GET_DAY_LIST_SUCCESS, GET_DAY_LIST_FAIL,
    GET_FREQUENCY_LIST_REQUEST, GET_FREQUENCY_LIST_SUCCESS, GET_FREQUENCY_LIST_FAIL,
    GET_REPEAT_LIST_REQUEST, GET_REPEAT_LIST_SUCCESS, GET_REPEAT_LIST_FAIL,
    SCHEDULER_SAVE_REQUEST, SCHEDULER_SAVE_FAIL, SCHEDULER_SAVE_SUCCESS,
    GET_SCHEDULER_LIST_REQUEST, GET_SCHEDULER_LIST_SUCCESS, GET_SCHEDULER_LIST_FAIL,
    SCHEDULER_DELETE_REQUEST, SCHEDULER_DELETE_FAIL, SCHEDULER_DELETE_SUCCESS,
    AuditStatus_LIST_REQUEST, AuditStatus_LIST_FAIL, AuditStatus_LIST_SUCCESS,
    Resend_SMS_REQUEST, Resend_SMS_FAIL, Resend_SMS_SUCCESS,
    GetAllEmployee_List_REQUEST, GetAllEmployee_List_FAIL, GetAllEmployee_List_SUCCESS,
    GetOTPDetails_List_REQUEST, GetOTPDetails_List_FAIL, GetOTPDetails_List_SUCCESS
} from '../Constants/Constants';

import * as RCnst from '../Constants/Constants';

const intialstate = {};


function ShowReportReducer(state = {
    ShowReport: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case SHOW_REPORT_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case SHOW_REPORT_FAIL: { return { ...state, fetching: false, fetched: false, error: action.payload, fetched: true } }
        case SHOW_REPORT_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ShowReport: action.payload

            }
        }
        default: return state;
    }

}
function CurrentAdmittedPatientListReducer(state = {
    CurrentAdmittedPatientList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case CURRENT_ADMITTED_PATIENT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case CURRENT_ADMITTED_PATIENT_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case CURRENT_ADMITTED_PATIENT_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                CurrentAdmittedPatientList: action.payload
            }
        }
        default: return state;
    }

}
function DischargePatientListReducer(state = {
    DischargePatientList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case DISCHARGE_PATIENT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case DISCHARGE_PATIENT_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case DISCHARGE_PATIENT_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DischargePatientList: action.payload
            }
        }
        default: return state;
    }

}
function BedTransferListReducer(state = {
    BedTransferList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case BED_TRANSFER_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case BED_TRANSFER_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case BED_TRANSFER_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                BedTransferList: action.payload
            }
        }
        default: return state;
    }

}
function PractitionerTransferListReducer(state = {
    PractitionerTransferList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case PRACTITIONER_TRANSFER_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case PRACTITIONER_TRANSFER_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case PRACTITIONER_TRANSFER_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PractitionerTransferList: action.payload
            }
        }
        default: return state;
    }

}

function AdmissionRequestListReducer(state = {
    AdmissionRequestList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case RE_ADMISSION_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case RE_ADMISSION_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case RE_ADMISSION_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                PractitionerTransferList: action.payload
            }
        }
        default: return state;
    }

}

function getDisclaimerPrintReducer(state = {
    getDisclaimerPrint: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case DISCLAIMER_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case DISCLAIMER_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case DISCLAIMER_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getDisclaimerPrint: action.payload
            }
        }
        default: return state;
    }

}
function saveDisclaimerPrintReducer(state = {
    saveDisclaimerPrint: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case SAVE_DISCLAIMER_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case SAVE_DISCLAIMER_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case SAVE_DISCLAIMER_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                saveDisclaimerPrint: action.payload
            }
        }
        default: return state;
    }

}
function GetDayListReducer(state = {
    GetDayList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case GET_DAY_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case GET_DAY_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case GET_DAY_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetDayList: action.payload
            }
        }
        default: return state;
    }

}
function GetFrequencyListReducer(state = {
    GetFrequencyList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case GET_FREQUENCY_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case GET_FREQUENCY_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case GET_FREQUENCY_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetFrequencyList: action.payload
            }
        }
        default: return state;
    }

}
function GetRepeatListReducer(state = {
    GetRepeatList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case GET_REPEAT_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case GET_REPEAT_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case GET_REPEAT_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetRepeatList: action.payload
            }
        }
        default: return state;
    }

}
function SaveReportSchedulerReducer(state = {
    SaveReportScheduler: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case SCHEDULER_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case SCHEDULER_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case SCHEDULER_SAVE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                SaveReportScheduler: action.payload
            }
        }
        default: return state;
    }

}
function GetSchedulerListReducer(state = {
    GetSchedulerList: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case GET_SCHEDULER_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case GET_SCHEDULER_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case GET_SCHEDULER_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                GetSchedulerList: action.payload
            }
        }
        default: return state;
    }

}
function DeleteReportSchedulerReducer(state = {
    DeleteReportScheduler: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case SCHEDULER_DELETE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case SCHEDULER_DELETE_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case SCHEDULER_DELETE_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                DeleteReportScheduler: action.payload
            }
        }
        default: return state;
    }

}


////////////start nitesh

function AuditStatusReducer(state = {
    getAuditStatusData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AuditStatus_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AuditStatus_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AuditStatus_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                getAuditStatusData: action.payload
            }
        }
        default: return state;
    }


}


function ResendSMSReducer(state = {
    ResendSMSData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case Resend_SMS_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case Resend_SMS_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case Resend_SMS_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ResendSMSsData: action.payload
            }
        }
        default: return state;
    }


}


function getAllEmployeeListReducer(state = {
    AllemployeeData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case GetAllEmployee_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case GetAllEmployee_List_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case GetAllEmployee_List_SUCCESS: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                AllemployeeData: action.payload
            }
        }
        default: return state;
    }
}


function getOTPDetailsListReducer(state = {
    GetOTPDetailsData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case GetOTPDetails_List_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case GetOTPDetails_List_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case GetOTPDetails_List_SUCCESS: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                GetOTPDetailsData: action.payload
            }
        }
        default: return state;
    }
}

function UserCashCollectionReducer(state = {
    GetUserCashCollectionData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case RCnst.User_Cash_Collection_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case RCnst.User_Cash_Collection_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case RCnst.User_Cash_Collection_SUCCESS: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                GetUserCashCollectionData: action.payload
            }
        }
        default: return state;
    }

}
////////////end nitesh

function GetSendEmailCollectionReducer(state = {
    GetSendEmailCollectionData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case RCnst.GetSendEmail_Audit_Collection_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case RCnst.GetSendEmail_Audit_Collection_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case RCnst.GetSendEmail_Audit_Collection_LIST_SUCCESS: {
            return {
                ...state,
                fetching: false,
                fetched: true,
                GetSendEmailCollectionData: action.payload
            }
        }
        default: return state;
    }

}


export {
    ShowReportReducer, CurrentAdmittedPatientListReducer, DischargePatientListReducer, BedTransferListReducer, PractitionerTransferListReducer, AdmissionRequestListReducer,
    getDisclaimerPrintReducer, saveDisclaimerPrintReducer, GetDayListReducer, GetFrequencyListReducer, GetRepeatListReducer, SaveReportSchedulerReducer, GetSchedulerListReducer,
    DeleteReportSchedulerReducer, AuditStatusReducer, ResendSMSReducer, getAllEmployeeListReducer, getOTPDetailsListReducer, UserCashCollectionReducer,GetSendEmailCollectionReducer
};