import {
    GET_ROLE_UNDER_FACILITY_REQUEST,
    GET_ROLE_UNDER_FACILITY_SUCCESS,
    GET_ROLE_UNDER_FACILITY_FAIL,
    GET_USER_MAPED_WITH_FACILITY_REQUEST,
    GET_USER_MAPED_WITH_FACILITY_SUCCESS,
    GET_USER_MAPED_WITH_FACILITY_FAIL,
    GET_EmployeeRoleMapping_REQUEST,
    GET_EmployeeRoleMapping_SUCCESS,
    GET_EmployeeRoleMapping_FAIL,
    GET_MODULE_MAPED_WITH_FACILITY_REQUEST,
    GET_MODULE_MAPED_WITH_FACILITY_SUCCESS,
    GET_MODULE_MAPED_WITH_FACILITY_FAIL,
    GET_PAGE_MAPED_WITH_MODULE_REQUEST,
    GET_PAGE_MAPED_WITH_MODULE_SUCCESS,
    GET_PAGE_MAPED_WITH_MODULE_FAIL,
    GET_FIELD_MAPED_WITH_EMPLOYEE_REQUEST,
    GET_FIELD_MAPED_WITH_EMPLOYEE_SUCCESS,
    GET_FIELD_MAPED_WITH_EMPLOYEE_FAIL,
    SAVE_FIELD_MODIFICATION_REQUEST,
    SAVE_FIELD_MODIFICATION_SUCCESS,
    SAVE_FIELD_MODIFICATION_FAIL
} from '../Constants/FieldModificationConstants';


const intialstate = {};

function RoleUnderFacilityListReducer(RoleUnderFacilityListData = { RoleUnderFacilityListData: [] }, action) {

    switch (action.type) {
        case GET_ROLE_UNDER_FACILITY_REQUEST:
            return { loading: true, RoleUnderFacilityListData: [] };
        case GET_ROLE_UNDER_FACILITY_SUCCESS:
            return { loading: false, RoleUnderFacilityListData: action.payload };
        case GET_ROLE_UNDER_FACILITY_FAIL:
            return { loading: false, error: action.payload,fetched: true };
        default:
            return RoleUnderFacilityListData;
    }
}

function UserMapedWithFacilityListReducer(UserMapedWithFacilityistData = { UserMapedWithFacilityistData: [] }, action) {

    switch (action.type) {
        case GET_USER_MAPED_WITH_FACILITY_REQUEST:
            return { loading: true, UserMapedWithFacilityistData: [] };
        case GET_USER_MAPED_WITH_FACILITY_SUCCESS:
            return { loading: false, UserMapedWithFacilityistData: action.payload };
        case GET_USER_MAPED_WITH_FACILITY_FAIL:
            return { loading: false, error: action.payload,fetched: true };
        default:
            return UserMapedWithFacilityistData;
    }
}
function EmployeeRoleMappingReducer(EmployeeRoleMappingData = { EmployeeRoleMappingData: [] }, action) {

    switch (action.type) {
        case GET_EmployeeRoleMapping_REQUEST:
            return { loading: true, EmployeeRoleMappingData: [] };
        case GET_EmployeeRoleMapping_SUCCESS:
            return { loading: false, EmployeeRoleMappingData: action.payload };
        case GET_EmployeeRoleMapping_FAIL:
            return { loading: false, error: action.payload,fetched: true };
        default:
            return EmployeeRoleMappingData;
    }
}

function ModuleMapedWithFacilityListReducer(ModlueMapedWithFacilityistData = { ModlueMapedWithFacilityistData: [] }, action) {

    switch (action.type) {
        case GET_MODULE_MAPED_WITH_FACILITY_REQUEST:
            return { loading: true, ModlueMapedWithFacilityistData: [] };
        case GET_MODULE_MAPED_WITH_FACILITY_SUCCESS:
            return { loading: false, ModlueMapedWithFacilityistData: action.payload };
        case GET_MODULE_MAPED_WITH_FACILITY_FAIL:
            return { loading: false, error: action.payload,fetched: true };
        default:
            return ModlueMapedWithFacilityistData;
    }
}

function PageMapedWithModuleListReducer(PageMapedWithModlueistData = { PageMapedWithModlueistData: [] }, action) {

    switch (action.type) {
        case GET_PAGE_MAPED_WITH_MODULE_REQUEST:
            return { loading: true, PageMapedWithModlueistData: [] };
        case GET_PAGE_MAPED_WITH_MODULE_SUCCESS:
            return { loading: false, PageMapedWithModlueistData: action.payload };
        case GET_PAGE_MAPED_WITH_MODULE_FAIL:
            return { loading: false, error: action.payload,fetched: true };
        default:
            return PageMapedWithModlueistData;
    }
}


function AppFieldDataListReducer(FieldDataListData = { FieldDataListData: [] }, action) {

    switch (action.type) {
        case GET_FIELD_MAPED_WITH_EMPLOYEE_REQUEST:
            return { loading: true, FieldDataListData: [] };
        case GET_FIELD_MAPED_WITH_EMPLOYEE_SUCCESS:
            return { loading: false, FieldDataListData: action.payload };
        case GET_FIELD_MAPED_WITH_EMPLOYEE_FAIL:
            return { loading: false, error: action.payload,fetched: true };
        default:
            return FieldDataListData;
    }
}

function saveFieldModificationSetupReducer(state = {
    saveFieldModification: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case SAVE_FIELD_MODIFICATION_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case SAVE_FIELD_MODIFICATION_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true, fetched: true } }
        case SAVE_FIELD_MODIFICATION_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                saveFieldModification: action.payload
            }
        }
        default: return state;
    }

}

export {
    RoleUnderFacilityListReducer,
    UserMapedWithFacilityListReducer,
    ModuleMapedWithFacilityListReducer,
    PageMapedWithModuleListReducer,
    AppFieldDataListReducer,
    saveFieldModificationSetupReducer,
    EmployeeRoleMappingReducer,

}