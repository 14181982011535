// ujjwal work Start
export const WorkList_SEARCH_REQUEST = 'WorkList_SEARCH_REQUEST';
export const WorkList_SEARCH_SUCCESS = 'WorkList_SEARCH_SUCCESS';
export const WorkList_SEARCH_FAIL = 'WorkList_SEARCH_FAIL';

export const WorkList_SAVE_REQUEST = 'WorkList_SAVE_REQUEST';
export const WorkList_SAVE_SUCCESS = 'WorkList_SAVE_SUCCESS';
export const WorkList_SAVE_FAIL = 'WorkList_SAVE_FAIL';

export const PatientWorkList_SEARCH_REQUEST = 'PatientWorkList_SEARCH_REQUEST';
export const PatientWorkList_SEARCH_SUCCESS = 'PatientWorkList_SEARCH_SUCCESS';
export const PatientWorkList_SEARCH_FAIL = 'PatientWorkList_SEARCH_FAIL';


export const PatientWorkList_CANCEL_REQUEST = 'PatientWorkList_CANCEL_REQUEST';
export const PatientWorkList_CANCEL_SUCCESS = 'PatientWorkList_CANCEL_SUCCESS';
export const PatientWorkList_CANCEL_FAIL = 'PatientWorkList_CANCEL_FAIL';

export const PatientWorkList_Document_REQUEST = 'PatientWorkList_Document_REQUEST';
export const PatientWorkList_Document_SUCCESS = 'PatientWorkList_Document_SUCCESS';
export const PatientWorkList_Document_FAIL = 'PatientWorkList_Document_FAIL';


export const PatientWorkList_DocumentDetails_REQUEST = 'PatientWorkList_DocumentDetails_REQUEST';
export const PatientWorkList_DocumentDetails_SUCCESS = 'PatientWorkList_DocumentDetails_SUCCESS';
export const PatientWorkList_DocumentDetails_FAIL = 'PatientWorkList_DocumentDetails_FAIL';


export const PatientWorkListNotification_SEARCH_REQUEST = 'PatientWorkListNotification_SEARCH_REQUEST';
export const PatientWorkListNotification_SEARCH_SUCCESS = 'PatientWorkListNotification_SEARCH_SUCCESS';
export const PatientWorkListNotification_SEARCH_FAIL = 'PatientWorkListNotification_SEARCH_FAIL';


export const PatientWorkListCompleted_SEARCH_REQUEST = 'PatientWorkListCompleted';
export const PatientWorkListCompleted_SEARCH_SUCCESS = 'PatientWorkListCompleted_SEARCH_SUCCESS';
export const PatientWorkListCompleted_SEARCH_FAIL = 'PatientWorkListCompleted_SEARCH_FAIL';

export const PatientWorkListDetail_SEARCH_REQUEST = 'PatientWorkListDetail_SEARCH_REQUEST';
export const PatientWorkListDetail_SEARCH_SUCCESS = 'PatientWorkListDetail_SEARCH_SUCCESS';
export const PatientWorkListDetail_SEARCH_FAIL = 'PatientWorkListDetail_SEARCH_FAIL';

export const ProcessOwner_LIST_REQUEST = 'ProcessOwner_LIST_REQUEST';
export const ProcessOwner_LIST_SUCCESS = 'ProcessOwner_LIST_SUCCESS';
export const ProcessOwner_LIST_FAIL = 'ProcessOwner_LIST_FAIL';

export const ProcessOwner_SAVE_REQUEST = 'ProcessOwner_SAVE_REQUEST';
export const ProcessOwner_SAVE_SUCCESS = 'ProcessOwner_SAVE_SUCCESS';
export const ProcessOwner_SAVE_FAIL = 'ProcessOwner_SAVE_FAIL';

export const WFTAT_LIST_REQUEST = 'WFTAT_LIST_REQUEST';
export const WFTAT_LIST_SUCCESS = 'WFTAT_LIST_SUCCESS';
export const WFTAT_LIST_FAIL = 'WFTAT_LIST_FAIL';

export const WFTAT_SAVE_REQUEST = 'WFTAT_SAVE_REQUEST';
export const WFTAT_SAVE_SUCCESS = 'WFTAT_SAVE_SUCCESS';
export const WFTAT_SAVE_FAIL = 'WFTAT_SAVE_FAIL';

export const WFNotification_LIST_REQUEST = 'WFNotification_LIST_REQUEST';
export const WFNotification_LIST_SUCCESS = 'WFNotification_LIST_SUCCESS';
export const WFNotification_LIST_FAIL = 'WFNotification_LIST_FAIL';

export const WFNotification_SAVE_REQUEST = 'WFNotification_SAVE_REQUEST';
export const WFNotification_SAVE_SUCCESS = 'WFNotification_SAVE_SUCCESS';

export const WFNotification_SAVE_FAIL = 'WFNotification_SAVE_FAIL';
export const WFFallback_LIST_REQUEST = 'WFFallback_LIST_REQUEST';
export const WFFallback_LIST_SUCCESS = 'WFFallback_LIST_SUCCESS';
export const WFFallback_LIST_FAIL = 'WFFallback_LIST_FAIL';

export const WFFallback_SAVE_REQUEST = 'WFFallback_SAVE_REQUEST';
export const WFFallback_SAVE_SUCCESS = 'WFFallback_SAVE_SUCCESS';
export const WFFallback_SAVE_FAIL = 'WFFallback_SAVE_FAIL';

export const WorkflowMain_LIST_REQUEST = 'WorkflowMain_LIST_REQUEST';
export const WorkflowMain_LIST_SUCCESS = 'WorkflowMain_LIST_SUCCESS';
export const WorkflowMain_LIST_FAIL = 'WorkflowMain_LIST_FAIL';

export const WorkflowMain_SAVE_REQUEST = 'WorkflowMain_SAVE_REQUEST';
export const WorkflowMain_SAVE_SUCCESS = 'WorkflowMain_SAVE_SUCCESS';
export const WorkflowMain_SAVE_FAIL = 'WorkflowMain_SAVE_FAIL';
export const WorkflowFacility_SAVE_REQUEST = 'WorkflowFacility_SAVE_REQUEST';
export const WorkflowFacility_SAVE_SUCCESS = 'WorkflowFacility_SAVE_SUCCESS';
export const WorkflowFacility_SAVE_FAIL = 'WorkflowFacility_SAVE_FAIL';
export const WorkflowSteps_SAVE_REQUEST = 'WorkflowSteps_SAVE_REQUEST';
export const WorkflowSteps_SAVE_SUCCESS = 'WorkflowSteps_SAVE_SUCCESS';
export const WorkflowSteps_SAVE_FAIL = 'WorkflowSteps_SAVE_FAIL';



export const WorkflowFacility_LIST_REQUEST = 'WorkflowFacility_LIST_REQUEST';
export const WorkflowFacility_LIST_SUCCESS = 'WorkflowFacility_LIST_SUCCESS';
export const WorkflowFacility_LIST_FAIL = 'WorkflowFacility_LIST_FAIL';
export const WorkflowSteps_LIST_REQUEST = 'WorkflowSteps_LIST_REQUEST';
export const WorkflowSteps_LIST_SUCCESS = 'WorkflowSteps_LIST_SUCCESS';
export const WorkflowSteps_LIST_FAIL = 'WorkflowSteps_LIST_FAIL';
export const WorkflowSCM_LIST_REQUEST = 'WorkflowSCM_LIST_REQUEST';
export const WorkflowSCM_LIST_SUCCESS = 'WorkflowSCM_LIST_SUCCESS';
export const WorkflowSCM_LIST_FAIL = 'WorkflowSCM_LIST_FAIL';

export const WorkflowDetailMaster_LIST_REQUEST = 'WorkflowDetailMaster_LIST_REQUEST';
export const WorkflowDetailMaster_LIST_SUCCESS = 'WorkflowDetailMaster_LIST_SUCCESS';
export const WorkflowDetailMaster_LIST_FAIL = 'WorkflowDetailMaster_LIST_FAIL';
export const PageCTA_LIST_REQUEST = 'PageCTA_LIST_REQUEST';
export const PageCTA_LIST_SUCCESS = 'PageCTA_LIST_SUCCESS';
export const PageCTA_LIST_FAIL = 'PageCTA_LIST_FAIL';
export const ToPageCTA_LIST_REQUEST = 'ToPageCTA_LIST_REQUEST';
export const ToPageCTA_LIST_SUCCESS = 'ToPageCTA_LIST_SUCCESS';
export const ToPageCTA_LIST_FAIL = 'ToPageCTA_LIST_FAIL';


export const WFDetailMaster_SAVE_REQUEST = 'WFDetailMaster_SAVE_REQUEST';
export const WFDetailMaster_SAVE_SUCCESS = 'WFDetailMaster_SAVE_SUCCESS';
export const WFDetailMaster_SAVE_FAIL = 'WFDetailMaster_SAVE_FAIL';

export const WFSTOP_SAVE_REQUEST = 'WFSTOP_SAVE_REQUEST';
export const WFSTOP_SAVE_SUCCESS = 'WFSTOP_SAVE_SUCCESS';
export const WFSTOP_SAVE_FAIL = 'WFSTOP_SAVE_FAIL';
// ujjwal work end
