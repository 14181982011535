import { authenticationService } from '../_services';

export function authHeader() {
    // return authorization header with jwt token
    const currentUser = JSON.parse(localStorage.getItem('currentUser')) //authenticationService.currentUserValue;
    if ((currentUser && currentUser.token) || sessionStorage.getItem("UserToken")) {
        // return { Authorization: `Bearer ${currentUser.token}` };
     //   return `Bearer ${currentUser.token}`; Comment By Ramesh on 28th March 2023
     return `Bearer ${sessionStorage.getItem("UserToken")}`;   //Added By Ramesh on 28th March 2023
    } else {
        return "No Auth";
    }
}

export function getUserId() {  
    if (localStorage.getItem("userId")) {
        // return { Authorization: `Bearer ${currentUser.token}` };
     //   return `Bearer ${currentUser.token}`; Comment By Ramesh on 28th March 2023
     return (localStorage.getItem("userId") ? parseInt(localStorage.getItem("userId")):0);   //Added By Ramesh on 28th March 2023
    } else {
        return 0;
    }
}