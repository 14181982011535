export const REGISTRATIONTYPE_LIST_REQUEST = 'REGISTRATIONTYPE_LIST_REQUEST';
export const REGISTRATIONTYPE_LIST_SUCCESS = 'REGISTRATIONTYPE_LIST_SUCCESS';
export const REGISTRATIONTYPE_LIST_FAIL = 'REGISTRATIONTYPE_LIST_FAIL';

export const PATIENT_SEARCH_LIST_REQUEST = 'PATIENT_SEARCH_LIST_REQUEST';
export const PATIENT_SEARCH_LIST_SUCCESS = 'PATIENT_SEARCH_LIST_SUCCESS';
export const PATIENT_SEARCH_LIST_FAIL = 'PATIENT_SEARCH_LIST_FAIL';

export const TITLE_LIST_REQUEST = 'TITLE_LIST_REQUEST';
export const TITLE_LIST_SUCCESS = 'TITLE_LIST_SUCCESS';
export const TITLE_LIST_FAIL = 'TITLE_LIST_FAIL';

export const REGISTRATION_SAVE_REQUEST = 'REGISTRATION_SAVE_REQUEST';
export const REGISTRATION_SAVE_SUCCESS = 'REGISTRATION_SAVE_SUCCESS';
export const REGISTRATION_SAVE_FAIL = 'REGISTRATION_SAVE_FAIL';

export const BULKREGISTRATION_SAVE_REQUEST = 'BULKREGISTRATION_SAVE_REQUEST';
export const BULKREGISTRATION_SAVE_SUCCESS = 'BULKREGISTRATION_SAVE_SUCCESS';
export const BULKREGISTRATION_SAVE_FAIL = 'BULKREGISTRATION_SAVE_FAIL';

export const REGISTRATION_DUPLICATE_SAVE_REQUEST = 'REGISTRATION_DUPLICATE_SAVE_REQUEST';
export const REGISTRATION_DUPLICATE_SAVE_SUCCESS = 'REGISTRATION_DUPLICATE_SAVE_SUCCESS';
export const REGISTRATION_DUPLICATE_SAVE_FAIL = 'REGISTRATION_DUPLICATE_SAVE_FAIL';

export const REGISTRATION_DUPLICATE_LIST_REQUEST = 'REGISTRATION_DUPLICATE_LIST_REQUEST';
export const REGISTRATION_DUPLICATE_LIST_SUCCESS = 'REGISTRATION_DUPLICATE_LIST_SUCCESS';
export const REGISTRATION_DUPLICATE_LIST_FAIL = 'REGISTRATION_DUPLICATE_LIST_FAIL';

export const REGISTRATION_STAFFSAVE_REQUEST = 'REGISTRATION_STAFFSAVE_REQUEST';
export const REGISTRATION_STAFFSAVE_SUCCESS = 'REGISTRATION_STAFFSAVE_SUCCESS';
export const REGISTRATION_STAFFSAVE_FAIL = 'REGISTRATION_STAFFSAVE_FAIL';

export const REGISTRATION_GET_REQUEST = 'REGISTRATION_GET_REQUEST';
export const REGISTRATION_GET_SUCCESS = 'REGISTRATION_GET_SUCCESS';
export const REGISTRATION_GET_FAIL = 'REGISTRATION_GET_FAIL';

export const OPPATIENTBANNERDATA_GET_REQUEST = 'OPPATIENTBANNERDATA_GET_REQUEST';
export const OPPATIENTBANNERDATA_GET_SUCCESS = 'OPPATIENTBANNERDATA_GET_SUCCESS';
export const OPPATIENTBANNERDATA_GET_FAIL = 'OPPATIENTBANNERDATA_GET_FAIL';

export const REGISTRATIONMANDATORY_GET_REQUEST = 'REGISTRATIONMANDATORY_GET_REQUEST';
export const REGISTRATIONMANDATORY_GET_SUCCESS = 'REGISTRATIONMANDATORY_GET_SUCCESS';
export const REGISTRATIONMANDATORY_GET_FAIL = 'REGISTRATIONMANDATORY_GET_FAIL';

export const RELATIONSHIP_LIST_REQUEST = 'RELATIONSHIP_LIST_REQUEST';
export const RELATIONSHIP_LIST_SUCCESS = 'RELATIONSHIP_LIST_SUCCESS';
export const RELATIONSHIP_LIST_FAIL = 'RELATIONSHIP_LIST_FAIL';

export const NATIONALITY_LIST_REQUEST = 'NATIONALITY_LIST_REQUEST';
export const NATIONALITY_LIST_SUCCESS = 'NATIONALITY_LIST_SUCCESS';
export const NATIONALITY_LIST_FAIL = 'NATIONALITY_LIST_FAIL';

export const MagicSearch_REQUEST = 'MagicSearch_REQUEST';
export const MagicSearch_SUCCESS = 'MagicSearch_SUCCESS';
export const MagicSearch_FAIL = 'MagicSearch_FAIL';

export const RegistrationDuplicate_REQUEST = 'RegistrationDuplicate_REQUEST';
export const RegistrationDuplicate_SUCCESS = 'RegistrationDuplicate_SUCCESS';
export const RegistrationDuplicate_FAIL = 'RegistrationDuplicate_FAIL';

export const MagicSearch_REQUEST_UHID = 'MagicSearch_REQUEST_UHID';
export const MagicSearch_SUCCESS_UHID = 'MagicSearch_SUCCESS_UHID';
export const MagicSearch_FAIL_UHID = 'MagicSearch_FAIL_UHID';

export const COUNTRY_LIST_REQUEST = 'COUNTRY_LIST_REQUEST';
export const COUNTRY_LIST_SUCCESS = 'COUNTRY_LIST_SUCCESS';
export const COUNTRY_LIST_FAIL = 'COUNTRY_LIST_FAIL';

export const STATE_LIST_REQUEST_PER = 'STATE_LIST_REQUEST_PER';
export const STATE_LIST_SUCCESS_PER = 'STATE_LIST_SUCCESS_PER';
export const STATE_LIST_FAIL_PER = 'STATE_LIST_FAIL_PER';

export const Currency_LIST_REQUEST_PER = 'Currency_LIST_REQUEST_PER';
export const Currency_LIST_SUCCESS_PER = 'Currency_LIST_SUCCESS_PER';
export const Currency_LIST_FAIL_PER = 'Currency_LIST_FAIL_PER';

export const STATE_LIST_REQUEST_STAFF = 'STATE_LIST_REQUEST_STAFF';
export const STATE_LIST_SUCCESS_STAFF = 'STATE_LIST_SUCCESS_STAFF';
export const STATE_LIST_FAIL_STAFF = 'STATE_LIST_FAIL_STAFF';

export const STATE_LIST_REQUEST_PRE = 'STATE_LIST_REQUEST_PRE';
export const STATE_LIST_SUCCESS_PRE = 'STATE_LIST_SUCCESS_PRE';
export const STATE_LIST_FAIL_PRE = 'STATE_LIST_FAIL_PRE';

export const CITY_LIST_REQUEST = 'CITY_LIST_REQUEST';
export const CITY_LIST_SUCCESS = 'CITY_LIST_SUCCESS';
export const CITY_LIST_FAIL = 'CITY_LIST_FAIL';

export const CustomField_GET_REQUEST = 'CustomField_GET_REQUEST';
export const CustomField_GET_SUCCESS = 'CustomField_GET_SUCCESS';
export const CustomField_GET_FAIL = 'CustomField_GET_FAIL';

export const ALLCOMPANY_LIST_REQUEST = 'ALLCOMPANY_LIST_REQUEST';
export const ALLCOMPANY_LIST_SUCCESS = 'ALLCOMPANY_LIST_SUCCESS';
export const ALLCOMPANY_LIST_FAIL = 'ALLCOMPANY_LIST_FAIL';

export const CustomFieldOption_GET_REQUEST = 'CustomFieldOption_GET_REQUEST';
export const CustomFieldOption_GET_SUCCESS = 'CustomFieldOption_GET_SUCCESS';
export const CustomFieldOption_GET_FAIL = 'CustomFieldOption_GET_FAIL';

export const CITY_LIST_REQUEST_PRE = 'CITY_LIST_REQUEST_PRE';
export const CITY_LIST_SUCCESS_PRE = 'CITY_LIST_SUCCESS_PRE';
export const CITY_LIST_FAIL_PRE = 'CITY_LIST_FAIL_PRE';

export const CITY_LIST_REQUEST_PER = 'CITY_LIST_REQUEST_PER';
export const CITY_LIST_SUCCESS_PER = 'CITY_LIST_SUCCESS_PER';
export const CITY_LIST_FAIL_PER = 'CITY_LIST_FAIL_PER';

export const AREA_LIST_REQUEST_PER = 'AREA_LIST_REQUEST_PER';
export const AREA_LIST_SUCCESS_PER = 'AREA_LIST_SUCCESS_PER';
export const AREA_LIST_FAIL_PER = 'AREA_LIST_FAIL_PER';

export const AREA_LIST_REQUEST_PRE = 'AREA_LIST_REQUEST_PRE';
export const AREA_LIST_SUCCESS_PRE = 'AREA_LIST_SUCCESS_PRE';
export const AREA_LIST_FAIL_PRE = 'AREA_LIST_FAIL_PRE';

export const STREET_LIST_REQUEST = 'STREET_LIST_REQUEST';
export const STREET_LIST_SUCCESS = 'STREET_LIST_SUCCESS';
export const STREET_LIST_FAIL = 'STREET_LIST_FAIL';

export const PAYER_LIST_REQUEST = 'PAYER_LIST_REQUEST';
export const PAYER_LIST_SUCCESS = 'PAYER_LIST_SUCCESS';
export const PAYER_LIST_FAIL = 'PAYER_LIST_FAIL';

export const PAYERMAGIC_LIST_REQUEST = 'PAYERMAGIC_LIST_REQUEST';
export const PAYERMAGIC_LIST_SUCCESS = 'PAYERMAGIC_LIST_SUCCESS';
export const PAYERMAGIC_LIST_FAIL = 'PAYERMAGIC_LIST_FAIL';


export const SPONSOR_LIST_REQUEST = 'SPONSOR_LIST_REQUEST';
export const SPONSOR_LIST_SUCCESS = 'SPONSOR_LIST_SUCCESS';
export const SPONSOR_LIST_FAIL = 'SPONSOR_LIST_FAIL';

export const PLAN_LIST_REQUEST = 'PLAN_LIST_REQUEST';
export const PLAN_LIST_SUCCESS = 'PLAN_LIST_SUCCESS';
export const PLAN_LIST_FAIL = 'PLAN_LIST_FAIL';

export const EMPLOYER_LIST_REQUEST = 'EMPLOYER_LIST_REQUEST';
export const EMPLOYER_LIST_SUCCESS = 'EMPLOYER_LIST_SUCCESS';
export const EMPLOYER_LIST_FAIL = 'EMPLOYER_LIST_FAIL';

export const AGETYPE_LIST_REQUEST = 'AGETYPE_LIST_REQUEST';
export const AGETYPE_LIST_SUCCESS = 'AGETYPE_LIST_SUCCESS';
export const AGETYPE_LIST_FAIL = 'AGETYPE_LIST_FAIL';

export const RELIGION_LIST_REQUEST = 'RELIGION_LIST_REQUEST';
export const RELIGION_LIST_SUCCESS = 'RELIGION_LIST_SUCCESS';
export const RELIGION_LIST_FAIL = 'RELIGION_LIST_FAIL';

export const OCCUPATION_LIST_REQUEST = 'OCCUPATION_LIST_REQUEST';
export const OCCUPATION_LIST_SUCCESS = 'OCCUPATION_LIST_SUCCESS';
export const OCCUPATION_LIST_FAIL = 'OCCUPATION_LIST_FAIL';

export const EDUCATION_LIST_REQUEST = 'EDUCATION_LIST_REQUEST';
export const EDUCATION_LIST_SUCCESS = 'EDUCATION_LIST_SUCCESS';
export const EDUCATION_LIST_FAIL = 'EDUCATION_LIST_FAIL';

export const LANGUAGE_LIST_REQUEST = 'LANGUAGE_LIST_REQUEST';
export const LANGUAGE_LIST_SUCCESS = 'LANGUAGE_LIST_SUCCESS';
export const LANGUAGE_LIST_FAIL = 'LANGUAGE_LIST_FAIL';

export const HWCTYPE_LIST_REQUEST = 'HWCTYPE_LIST_REQUEST';
export const HWCTYPE_LIST_SUCCESS = 'HWCTYPE_LIST_SUCCESS';
export const HWCTYPE_LIST_FAIL = 'HWCTYPE_LIST_FAIL';

export const HCF_LIST_REQUEST = 'HCF_LIST_REQUEST';
export const HCF_LIST_SUCCESS = 'HCF_LIST_SUCCESS';
export const HCF_LIST_FAIL = 'HCF_LIST_FAIL';

export const PARAMETER_SETUP_REQUEST = 'PARAMETER_SETUP_REQUEST'
export const PARAMETER_SETUP_SUCCESS = 'PARAMETER_SETUP_SUCCESS'
export const PARAMETER_SETUP_FAIL = 'PARAMETER_SETUP_FAIL'

export const PARAMETER_SETUP_REQUEST_Global = 'PARAMETER_SETUP_REQUEST_Global'
export const PARAMETER_SETUP_SUCCESS_Global = 'PARAMETER_SETUP_SUCCESS_Global'
export const PARAMETER_SETUP_FAIL_Global = 'PARAMETER_SETUP_FAIL_Global'

export const STAFF_REGDATA_REQUEST = 'STAFF_REGDATA_REQUEST'
export const STAFF_REGDATA_SUCCESS = 'STAFF_REGDATA_SUCCESS'
export const STAFF_REGDATA_FAIL = 'STAFF_REGDATA_FAIL'

export const CAMP_DATA_REQUEST = 'CAMP_DATA_REQUEST'
export const CAMP_DATA_SUCCESS = 'CAMP_DATA_SUCCESS'
export const CAMP_DATA_FAIL = 'CAMP_DATA_FAIL'

export const REG_DATA_BYREGID_REQUEST = 'REG_DATA_BYREGID_REQUEST'
export const REG_DATA_BYREGID_SUCCESS = 'REG_DATA_BYREGID_SUCCESS'
export const REG_DATA_BYREGID_FAIL = 'REG_DATA_BYREGID_FAIL'

export const REG_MERGER_DATA_REQUEST = 'REG_MERGER_DATA_REQUEST'
export const REG_MERGER_DATA_SUCCESS = 'REG_MERGER_DATA_SUCCESS'
export const REG_MERGER_DATA_FAIL = 'REG_MERGER_DATA_FAIL'

export const PARAMETER_SETUP_MAIN_REQUEST = 'PARAMETER_SETUP_MAIN_REQUEST'
export const PARAMETER_SETUP_MAIN_SUCCESS = 'PARAMETER_SETUP_MAIN_SUCCESS'
export const PARAMETER_SETUP_MAIN_FAIL = 'PARAMETER_SETUP_MAIN_FAIL'

export const REGISTRATION_INS_SAVE_REQUEST = 'REGISTRATION_INS_SAVE_REQUEST';
export const REGISTRATION_INS_SAVE_SUCCESS = 'REGISTRATION_INS_SAVE_SUCCESS';
export const REGISTRATION_INS_SAVE_FAIL = 'REGISTRATION_INS_SAVE_FAIL';

export const Facility_List_REQUEST = 'Facility_List_REQUEST';
export const Facility_List_SUCCESS = 'Facility_List_SUCCESS';
export const Facility_List_FAIL = 'Facility_List_FAIL';

export const SCREENCONFIGURATION_SAVE_REQUEST = 'SCREENCONFIGURATION_SAVE_REQUEST';
export const SCREENCONFIGURATION_SAVE_SUCCESS = 'SCREENCONFIGURATION_SAVE_SUCCESS';
export const SCREENCONFIGURATION_SAVE_FAIL = 'SCREENCONFIGURATION_SAVE_FAIL';

export const ModulePage_List_REQUEST = 'ModulePage_List_REQUEST';
export const ModulePage_List_FAIL = 'ModulePage_List_FAIL';
export const ModulePage_List_SUCCESS = 'ModulePage_List_SUCCESS';

export const Reason_SAVE_REQUEST = 'Reason_SAVE_REQUEST';
export const Reason_SAVE_SUCCESS = 'Reason_SAVE_SUCCESS';
export const Reason_SAVE_FAIL = 'Reason_SAVE_FAIL';

//Added by Ritesh kumar Date: 16-Feb-2021
export const ADMITTED_PATIENT_REQUEST = 'ADMITTED_PATIENT_REQUEST';
export const ADMITTED_PATIENT_SUCCESS = 'ADMITTED_PATIENT_SUCCESS';
export const ADMITTED_PATIENT_FAIL = 'ADMITTED_PATIENT_FAIL';

export const BED_BOOKED_PATIENT_REQUEST = 'BED_BOOKED_PATIENT_REQUEST';
export const BED_BOOKED_PATIENT_SUCCESS = 'BED_BOOKED_PATIENT_SUCCESS';
export const BED_BOOKED_PATIENT_FAIL = 'BED_BOOKED_PATIENT_FAIL';

export const ENCOUNTER_COUNT_LIST_REQUEST = 'ENCOUNTER_COUNT_LIST_REQUEST';
export const ENCOUNTER_COUNT_LIST_SUCCESS = 'ENCOUNTER_COUNT_LIST_SUCCESS';
export const ENCOUNTER_COUNT_LIST_FAIL = 'ENCOUNTER_COUNT_LIST_FAIL';

export const CHECK_MERGE_PATIENT_REQUEST = 'CHECK_MERGE_PATIENT_REQUEST';
export const CHECK_MERGE_PATIENT_SUCCESS = 'CHECK_MERGE_PATIENT_SUCCESS';
export const CHECK_MERGE_PATIENT_FAIL = 'CHECK_MERGE_PATIENT_FAIL';

export const CHECK_REG_VALIDITY_REQUEST = 'CHECK_REG_VALIDITY_REQUEST';
export const CHECK_REG_VALIDITY_SUCCESS = 'CHECK_REG_VALIDITY_SUCCESS';
export const CHECK_REG_VALIDITY_FAIL = 'CHECK_REG_VALIDITY_FAIL';

//added 5Oct2021 for Appointment Integration
export const SearchPatientAppointment_REQUEST = 'SearchPatientAppointment_REQUEST';
export const SearchPatientAppointment_SUCCESS = 'SearchPatientAppointment_SUCCESS';
export const SearchPatientAppointment_FAIL = 'SearchPatientAppointment_FAIL';

export const PatientAppointmentCancellation_REQUEST = 'PatientAppointmentCancellation_REQUEST';
export const PatientAppointmentCancellation_SUCCESS = 'PatientAppointmentCancellation_SUCCESS';
export const PatientAppointmentCancellation_FAIL = 'PatientAppointmentCancellation_FAIL';

export const MarkArrivalPatient_REQUEST = 'MarkArrivalPatient_REQUEST';
export const MarkArrivalPatient_SUCCESS = 'MarkArrivalPatient_SUCCESS';
export const MarkArrivalPatient_FAIL = 'MarkArrivalPatient_FAIL';

export const PatientAppointmentSlots_LIST_REQUEST = 'PatientAppointmentSlots_LIST_REQUEST';
export const PatientAppointmentSlots_LIST_SUCCESS = 'PatientAppointmentSlots_LIST_SUCCESS';
export const PatientAppointmentSlots_LIST_FAIL = 'PatientAppointmentSlots_LIST_FAIL';

export const BookRescheduleAppointment_REQUEST = 'BookRescheduleAppointment_REQUEST';
export const BookRescheduleAppointment_SUCCESS = 'BookRescheduleAppointment_SUCCESS';
export const BookRescheduleAppointment_FAIL = 'BookRescheduleAppointment_FAIL';
//end added 5Oct2021 for Appointment Integration

export const GetFamilyMemberList_REQUEST = 'GetFamilyMemberList_REQUEST';
export const GetFamilyMemberList_SUCCESS = 'GetFamilyMemberList_SUCCESS';
export const GetFamilyMemberList_FAIL = 'GetFamilyMemberList_FAIL';


export const GetRegSourceLsit_REQUEST = 'GetRegSourceLsit_REQUEST';
export const GetRegSourceLsit_SUCCESS = 'GetRegSourceLsit_SUCCESS';
export const GetRegSourceLsit_FAIL = 'GetRegSourceLsit_FAIL';