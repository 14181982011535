import {
  PRODUCT_LIST_REQUEST, PRODUCT_LIST_SUCCESS, PRODUCT_LIST_FAIL,
  PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS, PRODUCT_DETAILS_FAIL,
  PRODUCT_SAVE_REQUEST, PRODUCT_SAVE_SUCCESS, PRODUCT_SAVE_FAIL,
  PRODUCT_DELETE_REQUEST, PRODUCT_DELETE_SUCCESS, PRODUCT_DELETE_FAIL,
  PRODUCT_REVIEW_SAVE_SUCCESS, PRODUCT_REVIEW_SAVE_REQUEST, PRODUCT_REVIEW_SAVE_FAIL, PRODUCT_REVIEW_SAVE_RESET,
  CustomFild_LIST_REQUEST, CustomFild_LIST_SUCCESS, CustomFild_LIST_FAIL,
  CustomFild_SAVE_REQUEST, CustomFild_SAVE_SUCCESS, CustomFild_SAVE_FAIL,
  Facility_LIST_SUCCESS, Facility_LIST_REQUEST, Facility_LIST_FAIL,
  Module_LIST_REQUEST, Module_LIST_SUCCESS, Module_LIST_FAIL,
  Form_LIST_REQUEST, Form_LIST_SUCCESS, Form_LIST_FAIL,
  Section_LIST_REQUEST, Section_LIST_SUCCESS, Section_LIST_FAIL,
  FildType_LIST_REQUEST, FildType_LIST_SUCCESS, FildType_LIST_FAIL, CustomFild_DEPENDENT_VALUE_REQUEST, CustomFild_DEPENDENT_VALUE_FAIL, CustomFild_DEPENDENT_VALUE_SUCCESS, CustomFild_DEPENDENCY_SAVE_REQUEST, CustomFild_DEPENDENCY_SAVE_FAIL, CustomFild_DEPENDENCY_SAVE_SUCCESS, CustomFild_DEPENDENCY_GET_REQUEST, CustomFild_DEPENDENCY_GET_SUCCESS, CustomFild_DEPENDENCY_GET_FAIL,
} from '../Constants/CustomFildConstants';

function CustomFildListReducer(state = { products: [] }, action) {

  switch (action.type) {
    case CustomFild_LIST_REQUEST:
      return { loading: true, products: [] };
    case CustomFild_LIST_SUCCESS:
      return { loading: false, products: action.payload };
    case CustomFild_LIST_FAIL:
      return { loading: false, error: action.payload,fetched: true };
    default:
      return state;
  }
}

function FacilityListReducer(state = { Facility: [] }, action) {
  switch (action.type) {
    case Facility_LIST_REQUEST:
      return { loading: true, Facility: [] };
    case Facility_LIST_SUCCESS:
      return { loading: false, Facility: action.payload };
    case Facility_LIST_FAIL:
      return { loading: false, error: action.payload,fetched: true };
    default:
      return state;
  }
}

function FormListReducer(state = { Form: [] }, action) {
  switch (action.type) {
    case Form_LIST_REQUEST:
      return { loading: true, Form: [] };
    case Form_LIST_SUCCESS:
      return { loading: false, Form: action.payload };
    case Form_LIST_FAIL:
      return { loading: false, error: action.payload,fetched: true };
    default:
      return state;
  }
}

function SectionListReducer(state = { Section: [] }, action) {
  switch (action.type) {
    case Section_LIST_REQUEST:
      return { loading: true, Section: [] };
    case Section_LIST_SUCCESS:
      return { loading: false, Section: action.payload };
    case Section_LIST_FAIL:
      return { loading: false, error: action.payload,fetched: true };
    default:
      return state;
  }
}

function FildTypeListReducer(state = { FildType: [] }, action) {
  switch (action.type) {
    case FildType_LIST_REQUEST:
      return { loading: true, FildType: [] };
    case FildType_LIST_SUCCESS:
      return { loading: false, FildType: action.payload };
    case FildType_LIST_FAIL:
      return { loading: false, error: action.payload,fetched: true };
    default:
      return state;
  }
}

function ModuleListReducer(state = { Module: [] }, action) {
  switch (action.type) {
    case Module_LIST_REQUEST:
      return { loading: true, Module: [] };
    case Module_LIST_SUCCESS:
      return { loading: false, Module: action.payload };
    case Module_LIST_FAIL:
      return { loading: false, error: action.payload,fetched: true };
    default:
      return state;
  }
}

//   function productDetailsReducer(state = { product: { reviews: [] } }, action) {
//     switch (action.type) {
//       case PRODUCT_DETAILS_REQUEST:
//         return { loading: true };
//       case PRODUCT_DETAILS_SUCCESS:
//         return { loading: false, product: action.payload };
//       case PRODUCT_DETAILS_FAIL:
//         return { loading: false, error: action.payload,fetched: true };
//       default:
//         return state;
//     }
//   }

//   function productDeleteReducer(state = { product: {} }, action) {
//     switch (action.type) {
//       case PRODUCT_DELETE_REQUEST:
//         return { loading: true };
//       case PRODUCT_DELETE_SUCCESS:
//         return { loading: false, product: action.payload, success: true };
//       case PRODUCT_DELETE_FAIL:
//         return { loading: false, error: action.payload,fetched: true };
//       default:
//         return state;
//     }
//   }

function CustomFildSaveReducer(state = { product: {} }, action) {
  switch (action.type) {
    case CustomFild_SAVE_REQUEST:
      return { loading: true };
    case CustomFild_SAVE_SUCCESS:
      return { loading: false, success: true, product: action.payload };
    case CustomFild_SAVE_FAIL:
      return { loading: false, error: action.payload,fetched: true };
    default:
      return state;
  }
}
function fetchCustomFieldValueReducer(state = {
  CustomFieldValue: {}
}, action) {
  switch (action.type) {
    case CustomFild_DEPENDENT_VALUE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case CustomFild_DEPENDENT_VALUE_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
    case CustomFild_DEPENDENT_VALUE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        CustomFieldValue: action.payload
      }
    }
    default: return state;
  }

}
function saveCustomFieldDependencyReducer(state = {
  CustomDependencySave: {},
}, action) {
  switch (action.type) {
    case CustomFild_DEPENDENCY_SAVE_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
    case CustomFild_DEPENDENCY_SAVE_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
    case CustomFild_DEPENDENCY_SAVE_SUCCESS: {
      return {
        ...state,
        fetching: false,
        fetched: true,
        CustomDependencySave: action.payload
      }
    }
    default: return state;
  }

}

//   function productReviewSaveReducer(state = {}, action) {
//     switch (action.type) {
//       case PRODUCT_REVIEW_SAVE_REQUEST:
//         return { loading: true };
//       case PRODUCT_REVIEW_SAVE_SUCCESS:
//         return { loading: false, review: action.payload, success: true };
//       case PRODUCT_REVIEW_SAVE_FAIL:
//         return { loading: false, errror: action.payload };
//       case PRODUCT_REVIEW_SAVE_RESET:
//         return {};
//       default:
//         return state;
//     }
//   }
function fetchCustomFieldDependencyListReducer(state = { CustomDependencyList: [] }, action) {
  switch (action.type) {
    case CustomFild_DEPENDENCY_GET_REQUEST:
      return { loading: true, CustomDependencyList: [] };
    case CustomFild_DEPENDENCY_GET_SUCCESS:
      return { loading: false, CustomDependencyList: action.payload };
    case CustomFild_DEPENDENCY_GET_FAIL:
      return { loading: false, error: action.payload,fetched: true };
    default:
      return state;
  }
}

export {
  CustomFildListReducer,
  FacilityListReducer,
  ModuleListReducer,
  FormListReducer,
  SectionListReducer,
  FildTypeListReducer,
  // productDetailsReducer,
  CustomFildSaveReducer,
  // productDeleteReducer,
  // productReviewSaveReducer,
  fetchCustomFieldValueReducer,
  saveCustomFieldDependencyReducer,
  fetchCustomFieldDependencyListReducer
};