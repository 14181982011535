import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import {
    RegistrationTypeReducer, TitleReducer, NationalityReducer, RelationshipReducer, AgeTypeReducer, ReligionReducer,
    OccupationReducer, EducationReducer, LanguageReducer, HWCTypeReducer, CountryReducer, CityReducerPer, CityReducerPre,
    AreaReducerPer, AreaReducerPre, HCFReducer, StateByCountryReducerPer, StateByCountryReducerPre, CompanyReducer, EmployerReducer,
    GetRegistrationReducer, RegistrationMandatoryReducer, MagicSearchReducer, CustomFieldReducer,
    MagicSearchReducerUHID, DuplicateRecordReducer, CustomFieldOPtionReducer, StaffByCodeReducer, SaveRegistrationReducer, SaveBulkRegistrationReducer
    , SaveStaffRegistrationReducer, GetOPPatientBannerReducer,
    //#region vibhav
    ParameterSetupDetailsReducer, staffGetRegDataReducer, fetchCampListDataReducer, GetRegDataByRegistrationIdReducer, GetRegistrationDuplicateMergeIdReducer
    //#endregion
    //Amarjeet
    , ParameterSetupMainReducer, SaveRegistrationInsuranceReducer, saveScreenConfigurationReducer
    //
    , FetchModulePageReducer, ReasonMasterSaveReducer, SearchAdmitPatientReducer, SearchBedBookedReducer, RegistrationDuplicateMergeReducer
    , SaveParameterSetupMainReducer, AllCompanyReducer, EncounterCountReducer, CheckMergePatientReducer, checkRegValidityReducer, ParameterSetupDetailsReducerForGlobal, SearchPatientAppointmentReducer, CancelPatientAppointmentReducer, MarkArrivalPatientReducer,
    GetFamilyMemberListDataReducer, CompanyReducerMagic, GetRegSourceReducer
} from './MPI/Reducers/DemograpicsReducer';

import {
    SessionReducer,
    AppRegistrationTypeReducer, AppNationalityReducer, AppReligionReducer, AppOccupationReducer, AppReferralTypeReducer, AppHWCTypeReducer,
    AppTitleReducer, AppDocumentTypeReducer, AppCountryReducer, AppStateByCountryReducer, AppCityReducer, AppAreaReducer, AppEducationReducer,
    AppLanguageReducer, AppRelationshipReducer, AppHCFReducer, AppPayorCategoryReducer, AppReferralReducer,
    AppCompanyReducer, GetSAPPayorCategoryDiscountSetup, AppHospitalperformanceDocterDetailsListReducer, AppHospitalperformancelistdetailsReducer, AppHospitalperformancelistReducer, AppHospitalPerformanceDaslistListReducer, AppHospitalPerformanceREVlistReducer, AppHospitalPerformanceREV_divlistReducer, AppDepartmentNameReducer, AppYearMonthReducer, AppMonthReducer, AppCurrencyReducer, AppCompanyBeneficiaryReducer, AppGetCompanyDataReducer,
    AppReportFormatOPReducer, AppReportFormatERReducer, AppReportFormatIPReducer, AppEmployeeReducer,
    AppFacilityReducer, AppFacilityEMPReducer, AppCompanyFacilityReducer, AppCompanySponsorReducer, AppPayorPlanReducer, fetchStatusByType,
    saveCompanyReducer, AppTariffReducer, AppCompanyTariffReducer, AppDiscountTemplateReducer, AppDepartmentReducer, AppDeptByServiceTypeReducer,
    AppDepartmentSubReducer, AppDepartmentSubByTypeReducer, AppCompanyMarkupReducer, AppCompanyMarkupExlReducer
    , AppCompanyExclReducer, ReportRevenueDepartmentWiseReducer, MisInventoryAnalysisReportReducer,
    AppCompNonDiscReducer,
    AppPrintGroupReducer, AppServiceCategoryReducer, AppServiceMstReducer, AppServiceMstByServiceTypeReducer, AppCompBedCatReducer
    , AppServiceNomenclatureReducer, AppAreaPERReducer, CurrencyMasterReducer,

    AppServiceByFacilityReducer, AppPackageByFacilityReducer, AppSUBServiceByFacilityReducer,
    AppSUBServiceByFacilityTNIReducer,
    AppServiceLinkedReducer, AppServiceFacilityReducer, saveServiceMstReducer, AppLoginTariffReducer,
    AppTariffSaveReducer, AppSpecializationReducer, AppCompanyExlAutoReducer,
    AppExclusionMainReducer, AppExclusionDetailReducer, AppUserGroupReducer, AppUserSubGroupReducer,
    AppGradeReducer, BankListReducer, AppfetchClinic, AppfetchPaymentMode
    , AppOPPackageGetReducer, AppServiceTariffReducer, AppServiceTariffChargeReducer, AppDesignationReducer, AppConsultationTariffChargeReducer
    , saveDiscountTemplateReducer, AppDiscountTempConfigReducer, AppMappedGradeReducer
    //#region Satender
    , AppServiceTariffFactorReducer, AppServiceTariffFactorDtlReducer, AppServiceBedCategoryReducer, AppBillingCountryReducer, AppBillingStateByCountryReducer, AppBillingCityReducer, AppBillingAreaReducer
    , AppCompanywiseFacilityReducer, GetCompanyFacilityWiseReducer, AppGetMOUDetailsListReducer, AppGetCompanyItemExclusionListReducer, AppGetGroupExclusionListReducer
    //#endregion
    // ujjwal work start
    , AppBedTypeReducer, AppModuleReducer, AppFormReducer, AppSectionReducer, AppBEDCATMstReducer, AppERBEDCATMstReducer, AppBEDCATByEncReducer
    , SaveCityMstReducer, SaveStateMstReducer, SaveCountryMstReducer, SaveRefTypeMstReducer, AppPageFuncionPermissionEmployee,
    AppsaveEmployeePermissions, AppFacilitywiseEmployeeMaster, AppPagePermissionMasterEmployee, AppPageFuncionPermission
    , AppGetRolePageSectionMaster, AppGetRolePageMaster, AppGetEmployeePageSectionMaster, AppGetEmployeePageMaster, AppGetClinicForEmployee
    , AppGetDoctorVisitMaster, AppGetEmployeeFacility
    //ujjwal work end
    //rajesh work start
    , AppCaseTypeReducer, AppServiceByServiceCategoryReducer, AppBlockReducer, AppFloorReducer, AppNursingUnitReducer, AppBuildingReducer,
    AppAnesthesiaTypeReducer, AppAdmissionTypeReducer, AppAgeGroupReducer, AppRemarksMasterReducer, AppBedMasterReducer, AppBedMasterByStatusTypeReducer
    , AppBedOccupiReducer, AppRoomMasterReducer, AppFreeServiceOrderReducer, AppCurrencyDenominationReducer, GetSurgeryGradeServiceReducer,
    AppCurrencyexReducer,
    AppGetModulePageFormTaggingReducer, getModulePageFromUserReducer, SaveGetModulePageFormTaggingReducer, saveModuleFromUserReducer,
    AppSaveCompanExclusionListReducer, AppEmploymentTypeReducer
    // , AppPaymentModeReducer
    , FromAppBedMasterReducer, ToAppBedMasterReducer, FromAppNursingUnitReducer, ToAppNursingUnitReducer
    //rajesh work end
    , AppGetNonDiscountItemListReducer, AppGetNonDiscountGroupListReducer, AppSaveDepartmentReducer, AppSaveDepartmentSubReducer, AppSaveRegistrationTypeReducer
    , AppSaveReligionReducer, AppsaveEducationReducer, AppsaveHWCTypeReducer, AppsaveLanguageReducer, AppsaveOccupationReducer, AppsaveReferralReducer, AppsaveAreaReducer
    , AppsaveDocumentTypeReducer, AppsaveNationalityReducer, AppsaveRelationReducer, AppsaveTitleReducer, AppsaveEmploymentTypeReducer, AppPagePermissionMaster, AppFacilitywiseRoleMaster
    , AppSaveAgeGroupReducer, SaveAnesthesiaTypeReducer, SaveBlockReducer, SaveFloorReducer, SaveNursingUnitReducer, SaveRoomMasterReducer, SaveBedMasterReducer, AppEquipmentListReducer, AppsaveEquipmentListReducer,
    //Start Amarjeet Code
    AppEmployeeCopyReducer
    //End Amarjeet Code
    , AppCompanyAllReducer
    , fetchReasonListReducer, fetchApprovalMatrixListReducer, AppsaveRolePermissionsReducer
    , fetchBankMasterListReducer
    , BankMasterSaveReducer
    , AppCardMasterListReducer
    , AppCardMasterSave
    , AppDesinationReducer
    , SaveApprovalMatrixReducer
    , ModulePageMenuListReducer
    , RolePermissionMasterReducer, AccessControlRoleReportReducer, GetEmployeeValidateLoginReducer, ModulePageButtonListReducer
    , AppPatientSeriesReducer, SavePatientSeriesReducer, saveCompanyServiceExclReducer
    , SaveDesigationMstReducer, SaveGradeMstReducer, SaveUserGropMstReducer, SaveUserSubGropMstReducer
    , GetAuditDataReducer, AppEnterpriseReducer, SaveModulePagePinnedReducer
    , BulkRegistrationReducer
    , AppEmployeeByTeamReducer, GetFieldModificationPermissionReducer,
    WeeklyOffListReducer, WeeklyOffSaveReducer, HolidayListReducer, HolidaySaveReducer, HolidayMarkupSaveReducer
    , HolidayMarkupReducer, getSurgeryComponentReducer, saveIpPackageReducer,
    saveIPPackageServiceInclusionReducer, saveIPPackageItemInclusionReducer, saveIPPackageExclusionsReducer, saveIPPackageBedChargeReducer, saveIPPackageSurgeryComponentReducer,
    GetIpPackageListReducer, GetIpPackageAllListReducer
    , AppsaveClinicReducer, getIpPackageBedChargeLimitReducer, getIpPackageSurgeryCompDataReducer, saveCopyFromExistingPackageReducer
    , AppErDashBoardReducer, SaveErDashBoardReducer
    , SaveHCFReducer, SavePayorCategoryReducer, DispositionReducer, CurrEXRateReducer, savePayorPlanReducer, getMappedSurgeryComponentListReducer,
    getSurgeryCompSvcMappingDataReducer, saveSurgeryCompSvcMappingDataReducer, fetchSurgeryComponent, SaveSurgeryComponent, getSurgeryCompTatiffMappingReducer,
    saveSurgeryCompTariffMappingReducer, SaveServiceBedCategoryFactorReducer, AppsaveCopyRolePermissions, AppItemDiscountTempConfigReducer, ServiceChangeRuleFetchReducer,
    ServiceChangeRuleSaveReducer, userValidatePasswordResetReducer, userValidateOTPReducer, getUserDataForResetPasswordReducer, userPasswordResetSaveReducer, changePasswordAfterLoginReducer,
    AssociateEmployeefetchReducer, AssociateEmployeeSaveReducer, changePinAfterLoginReducer, savePaymentModeReducer, SaveCompanyPrintReducer, roleAccessReducer, getCheckListMasterReducer,
    SaveRevenueAndTargetReducer, SaveCurrencyDenominationReducer, SaveSurgeryGradeServiceReducer,
    SaveCurrencyReducer, SaveFreeServiceOrderReducer,
    AppTriageReducer, SaveTriageReducer, getSearchEmployeeListReducer, saveCompanyChecklistReducer, saveIPPackagePayorReducer, CurrencyDenominationReducer
    , SaveEREncounterReducer, fetchServiceLastOrderDtlReducer, fetchFormNamesReducer, AppEmployeeBySpecialityReducer
    , SaveChiefComplaintReducer, AppChiefComplaintReducer, AppDiagnoseMasterReducer, fetchStatusByTypeForGlobal,
    AppFacilityMasterListReducer, AppFacilitySaveReducer
    , SaveCampReducer, AppCampReducer, saveExclusionMainReducer, saveExclusionDetailReducer, AnatomicSiteMstReducer, SaveServiceTariffDetailReducer, saveServiceNomenclatureReducer, AppServiceTariffHistoryReducer
    , getMappedIPPackageComponentListReducer, getCompanyDisTemplateMappingReducer, GetPayorPackageDetailsMaster,
    AppOPPackageSaveReducer, SaveServiceBedCategoryFactorDtlReducer
    , fetchgetCompanyNomenclatureReducer, SaveBuildingReducer, CompanyTariffPlanReducer, AppTariffRuleReducer,
    AppServiceControlledPriceReducer, AppServiceControlledPriceSaveReducer, GetReportMasterDataReducer, AppConsultSetupSaveReducer
    , AppConsultSetupReducer, getBedListReducer, AppDoctorDetailSaveReducer, AppDoctorDetailfetchReducer
    , AppCompMOUDetailSaveReducer, AppServiceAttributeReducer, getSearchFacilityEmployeeListReducer, getSearchFacilityDocListReducer
    , AppEmpByTypeReducer, getSearchServiceMasterReducer, SaveCompanyExlAutoServiceReducer, TariffChargeAuditReducer, ServiceMstAuditReducer
    , reasonMasterReducer, SaveReasonMasterReducer, GetModuleReasonReducer, GetModulePageEventsReducer, SavemoduleReasonReducer, getPageListForValidationSetupReducer, getPageValidationSetupMasterReducer
    , getNursingDischargeOptionMasterReducer, ModulePagesByModuleReducer, SaveModulePageEventReducer,
    getModulePageEventReducer, getReasonWiseModulePageEventReducer,
    GetReasonMasterAuditReducer, GetModuleReasonAuditReducer, SaveCompanyBedCatReducer, GetPayorPageAuditReducer, GetPayorPageAuditFacilityMappingReducer, GetPayorPageAuditPlanReducer,
    GetPayorPageAuditTariffAndDiscountReducer, GetPayorPageAuditMarkupByServiceReducer,
    GetPayorPageAuditMarkupExclusionReducer, GetPayorPageAuditCompNonDiscServiceReducer,
    GetPayorPageAuditExclusionServiceReducer, GetPayorPageAuditExclusionGroupMappingReducer
    , GetPayorPageAuditBedCategoryReducer, GetPayorPageAuditExlAutoServiceDtlReducer, GetPayorPageAuditExclusionItemReducer
    , AppSaveServiceAttributeReducer, AppSponsorReducer, SaveCurrencyMasterReducer,
    AppFIStatusReducer, AppFITCReducer, GetSMSConfigurationReducer, SaveSMSConfigurationReducer, GetSMSEventDetailReducer,
    GetSMSEventWithReceiverDetailReducer, GetReportConfigListReducer, GetEmailSetupReducer, ValidateExcelListBulkReducer, SaveExcelListBulkReducer,
    ValidateItemMasterExcelReducer, SaveItemMasterExcelReducer, GetScheduleData, AppCaseSubTypeReducer, get_emr_comp_Reducer, hpf_grw_sum_Reducer,
    AppDepartmentEmpReducer, SaveSAPPayorCategoryDiscountSetupReducer, Getchecklist_masterReducer, saveChecklistReducer, GetCourierListReducer,
    GetStockDashboardReducer, AppItemSapIntegrationReducer, AppSAPItemPurchageGroupMasterReducer, GetVitalGroupReducer, AppDepartmentEmpResultReducer, GetSMSConfigParameterReducer, GetSMSConfigSubParameterReducer, GetAdmittedPayorListReducer, taxsetupListReducer, ReSyncdataListReducer, InsertInvoicedataListReducer, SettlementReSyncdataListReducer, InsertSettlementdataListReducer, ReceiptReSyncdataListReducer, RefundReSyncdataListReducer, InsertCollectiondataListReducer,
    CreditnoteReSyncdataListReducer, InsertCreditnotedataListReducer, InvoicewriteoffReSyncdataListReducer, InsertWriteoffdataListReducer, SaveserviceMasterReducer, GetservicemasterReducer, AppsaveVulnerableTypeReducer, AppVulnerableTypeReducer, AppIntegrationFlagReducer, EncounterReSyncdataListReducer, InsertencounterdataListReducer, CxlinvoiceReSyncdataListReducer, InsertcxlinvoicedataListReducer
} from './AppReducers/AppMstReducer';
import {
    CustomFildListReducer, CustomFildSaveReducer, FacilityListReducer, ModuleListReducer, FormListReducer, SectionListReducer, FildTypeListReducer,
    fetchCustomFieldValueReducer, saveCustomFieldDependencyReducer, fetchCustomFieldDependencyListReducer
} from './MPI/Reducers/CustomFildReducer';

import {
    getEncounterReducer, getMrdReducer, getRegReducer, MRDSurgeryOrderReducer, GetDischargesummaryviewDataReducer, addMlcFromMrdReducer, GetsurgerydetailmrdReducer
} from './MRD/Reducers/MRDReducer';


import {
    DocumentFildSaveReducer, DocumentListReducer, UHIDReducer, DocumentDataListReducer, DocumentListImageReducer,
    DocumentTypeListReducer, DocumentDeleteReducer, DocumentDataListDtlReducer, DocumentDataListDtlClmReducer, ClaimBookSaveReducer
} from './MPI/Reducers/DocumentReducer'

import {
    saveAdmissionRequestReducer, EncounterReducer, AppAdmissionNoReducer, AppAdmissionRequestReducer, saveBedBookReducer, AppBedBookingReducer
    , AppAdmissionAdmittedPatientReducer, AppTimeLineViewReducer,
    AppAdmissionReducer, AppAdmissionRequestForEstimateReducer
    , savePatientTransferReducer, AppPatientTransferReducer, savePractitionerTransferReducer
    , AppPractitionerTransferReducer, AppPatientTransferbyEncounterIdReducer, AppPractitionerTransferbyEncounterIdReducer, savePatientTransferRequestReducer
    , AppPatientTransferRequestReducer, AppPatientTransferRequestbyEncounterIdReducer
    , EncounterADTReducer
    //ujjwal work start
    , CostEstimationNoReducer, PreAuthNoReducer, saveAdmissionReducer, AppSaveBedTimelineReducer
    //ujjwal work end
    , AppSaveRetainBedReducer,

    //Amarjeet work start
    AppDischargePatientDataReducer
    //Amarjeet work end
    , AppPatientDetailsIPReducer,
    AppPatientDetailsIPReducerRX,
    //Chandan work start
    AppSaveEstTemplateMasterReducer, AppGetEstTemplateListReducer, AppSaveEstBILLHeadReducer, AppGetEstTemplateLDetailsReducer
    , AppGetEstTemplateBillHeadDetailsReducer, AppGetEstTemplateBillHeadDetailsBedCatgWiseReducer
    , AppGetEstTemplateBillHeadDetailsServiceWiseReducer, AppSaveEstimateReducer, AppGetGetEstimateDetailsReducer, AppGetEstimatePayorDetailsReducer
    , AppCancelEstimateReducer
    , saveVisitorPassReducer
    , AppVisitorPassReducer
    , AppVisitorPassUploadedFileReducer
    , AppFaceSheetReducer, AppFacilityMasterReducer, AppPrintVPReducer, ERVisitDetailsReducer, AppERBedOccupiReducer, ERAllPatientDetailsReducer, ERPatientDetailsReducer, savePreAuthReducer, cancelPreAuthReducer, savePreAuthQueryReducer, savePreAuthQueryCommunicationReducer,
    PreAuthTPACheckListReducer, CancelERAdmissionReducer, AppAdmissionRequestListReducer, AppADTRemarksDetailReducer, AppADTCheckListReducer, AdmMarkArrivalReducer, ERAllPatientlistReducer, saveDiagnosisData, deleteDiagnosisData
    //End Here
} from './ADT/Reducers/ADTReducer'
import {
    GetPatientWorkListReducer, GetPatientWorkListDetailReducer, PatientWorkListCancelReducer,
    AppSaveProcessOwnerReducer, AppProcessOwnerReducer,
    AppSaveWorkflowMainReducer, AppWorkflowMainReducer, AppWorkflowFacilityReducer, AppSaveWorkflowFacilityReducer, AppWFDetailMasterReducer,
    AppSaveWorkflowStepsReducer, AppWorkflowStepsReducer, AppGetPageCTAReducer, AppGetToPageCTAReducer, AppGetWorkflowSCMReducer, AppWorkflowDetailMasterReducer,
    AppSaveWFNotificationReducer, AppSaveWFTATReducer, AppWFNotificationReducer, AppWFTATReducer, GetPatientWorkNotificationListReducer,
    GetPatientWorkCompletedListReducer, GetPatientWLDocumentReducer, GetPatientWLDocumentDetailReducer, AppWFSTOPReducer,
    AppSaveWFFallbackReducer, AppWFFallbackReducer
} from './WorkList/Reducers/WorkListReducer'

import {
    GetItemCategoryReducer, GetEmpUpdateTimeForExportCSVReducer, GetStatusByTypeReducer, GetItemSubCategoryReducer, GetChargesReducer, GetUnitMasterReducer, GetItemUnitMasterReducer,
    GetHSNGroupReducer, GetHSNCodeReducer, GetItemMasterReducer, GetItemChargesReducer, GetItemAttributesReducer, GetItemwithItemUnitMappingReducer,
    GetChargeTemplateMainReducer, GetChargeTemplateDetailsReducer, GetChargeTemplatesReducer, GetItemChargesByChargeTemplateReducer,
    GetItemUnitsByIssueUnitReducer, GetTherapeuticCategoryReducer, GetTherapeuticSubCategoryReducer, GetGenericMasterReducer,
    GetItemGenericMappingReducer, GetItemFormMasterReducer, GetChargeMasterListReducer, GetItemFacilityMappingReducer, GetItemSubCategoryByCategoryReducer,
    GetTherapeuticSubCategoryByCategoryReducer, GetHSNCodeByHSNGroupReducer, GetNewItemReuestReducer,
    GetItemAttributesOnlyReducer, GetItemChargesOnlyReducer, GetItemWithItemUnitsOnlyReducer, GetItemGenericOnlyReducer, GetItemFacilityOnlyReducer,
    GetItemCountByItemSubCategoryReducer, GetGRNMainReducer, GetGRNDataReducer, GetGRNDetailsReducer, GetGRNBatchDetailsReducer, GetItemByAttributeReducer,
    GetReusableItemReducer, GetReusableItemsReducer, GetReusableItemChargesReducer, GetChargesByItemReducer, GetGRNItemChargesReducer,
    GetChargesCalculationReducer, GetItemsBySupplierReducer, GetSuppliersByFacilityReducer, GetLast5PurchasesReducer, GetItemListByDescReducer, GetItemPurchaseUnitReducer,
    GetPrescriptionUnitReducer, GetLastPurchasesReducer,
    GetPOMainReducer, GetPODataReducer, WorkOrderDataReducer, GetWorkOrderSearchReducer, GetPOSearchReducer, GetPODetailsReducer, GetChargeTemplateIDReducer, GetPOItemChargesReducer, GetPODocumnetChargesReducer, GetWorkOrderChargesReducer, GetAffiliatedStoreReducer, GetStoresByAffiliatedStoreReducer,
    GetSupplierTermsConditionsReducer, GetWOSupplierTermsConditionsReducer, GetPOTimeLineReducer, GetOPReturnTimeLineReducer, GetItemWiseBatchesReducer, GetPurchaseIndentforPOReducer,
    GetIPIssueStoreListforIPReturnReducer, GetIPReturnMainReducer, GetIPReturnDataReducer, GetIPReturnSearchReducer, GetStoreWiseItemListforIPReturnReducer, GetStoreWiseIPIssuedItemsforIPReturnReducer, GetIPReturnTimeLineReducer,
    GetChargeTemplateIdByCodeReducer, GetItemPriceReducer, ViewAllQuotationsItemsReducer, GetStatusByTypeSpecial_IndentReducer,
    GetConsignmentMainReducer, GetConsignmentDataReducer, GetConsignmentSearchReducer, GetConsignmentDetailsReducer, GetConsignmentBatchDetailsReducer, GetConsignmentItemChargesReducer,
    GetConsignmentReceiveMainReducer, GetConsignmentReceiveDataReducer, GetConsignmentReceiveSearchReducer, GetConsignmentReceiveDetailsReducer, GetConsignmentReceiveBatchDetailsReducer, GetConsignmentReceiveItemChargesReducer,
    GetStoresByStoreTypeCodeReducer, GetRxOrderDetailsReducer, GetConsignmentAllocationSearchReducer, GetConsignmentReturnSearchReducer, GetPOApprovalListByFacilityReducer,

    GetPOApprovalSetupReducer, GetSupplierDetailReducer, GetSupplierTermsConditiontReducer, GetTermsConditionReducer,
    GetSupplierItemMappingReducer, GetSupplierFacilityMappingReducer, GetSupplierLoginDetailReducer, GetStoreReducer, GetStorefacilityWiseReducer, GetStoreTransactionTypeReducer
    , GetStoreItemMappingReducer, GetStoreROLSetupReducer, GetStoreIndentAffilliationReducer, GetStoreAutoIndentFrequency, GetBinLocationReducer, GetStoreItemAutoConsumptionMappingReducer
    , GetStoreAllTransactionTypeReducer, GetStoreSubCategoryOrItemMappingReducer, GetFinancialYearReducer, GetStoreAllSubItemCategoryAndItemMappingReducer
    , GetAllItemChargeTemplateReducer, GetItemSalePriceSetupReducer, GetKITMasterReducer, GetKITItemMappingReducer,
    GetDepartmentIndentDetailReducer, GetItemForDepartmentIndentReducer, GetDepartmentIndentMainReducer, GetItemMasterBySubCatReducer
    , GetDepartmentIndentTimeLineReducer, GetStoreMappedWithDocumentSetupReducer, GetDepartmentIndentApprovalDetailsReducer, GetItemSubstitutionAndBatchSelectionReducer
    , GetIndentForIssueReducer, GetBatchByBarcodeReducer, GetChargeTemplateListReducer, GetGRNSearchReducer, GetDepartmentIssueReducer, GetIndentForIssuePicklistPrintReducer,
    GetStoresByTransTypeReducer, GetDepartmentReceiveReducer, GetDepartmentReturnReducer, GetDepartmentReturnSearchReducer
    , GetItemForDepartmentReturnReducer, GetUserStoreAccessRightsReducer, GetDepartmentReceiveSearchPopupReducer
    , GetItemListROLReducer, GetItemListConsumptionReducer, GetOpeningAdjustmentTimeLineReducer, GetPurchaseIndentMainReducer, GetPurchaseIndentDetailsReducer
    , GetItemDataReducer, getPurchaseIndentAgainstIndentReducer, GetPurchaseIndentTimeLineReducer
    , GetDepartmentConsumptionDetailReducer, GetAllDepartmentConsumptionReducer, GetKitTemplateItemWithBatchReducer, GetGRNDocumnetChargesReducer
    , GetIPIssueAllocationReducer, GetItemListforSupplierReturnReducer, GetSupplierReturnTimeLineReducer, GetSupplierReturnDetailsReducer, GetSupplierReturnSearchReducer
    , GetSupplierReturnDetailsForGRNReducer, GetItemsForQuotationReducer, GetQuotationSearchReducer, GetQuotationReducer, QuotationItemChargesReducer
    , GetSupplierWiseLastItemPriceReducer, GetItemForDepartmentReturnByBarCodeReducer, GetItemListforSupplierReturnByBarcodeReducer
    , SearchSaleToPatientReducer, GetItemGenericReducer, GetInventoryReportsReducer, GetItemByMultipleStoresAsyncReducer, GetManufacturerSupplierMappingReducer
    , SendEmailReducer, GetConsignmentItemWithStockReducer, GetConsignmentAllocationDetailReducer, GetConsignmentIssueDetailReducer, GetConsignmentIssueListReducer
    , GetPendingIndentListReducer, ItemsByFacilityReducer, GetPendingIndentReceiveReducer, GetReportMasterReducer, GetEmpListReducer, GetDepartmentIndentAllocationSearchReducer
    , GetDepartmentIndentAllocationReducer, GetDepartmentDeliverySearchReducer, GetPendingIndentListByItemReducer, GetItemListByItemNameReducer
    , GetItemStrengthMasterReducer, GetItemEnterpriseLevelAttributesReducer, GetItemReferenceSizeMasterReducer, GetItemPackSizeMasterReducer, GetItemUniqueCategoryMasterReducer
    , GetItemNamesReducer, GetItemConsumtionReducer, CheckSupplierStateIdReducer, GetStoreCategoryReducer, GetSupplierCategoryReducer, GetDocumentsListReducer
    , GetReusableItemsUsageReducer, GetPOPlannerReducer, GetRequiredItemListByItemNameReducer, GetItemListByItemNameExportReducer
    , GetConsignmentItemWithStockByChallanReducer
    //-----------------------------SHRIKANT SAINI-------------------------------------------------
    , GetOPIssueSaleMainReducer, GetOPIssueSaleDetailsReducer, GetOPIssueSaleBatchDetailsReducer, GetItemMasterListReducer, GetItemBatchListReducer
    , GetEmpStoreAccessDataReducer, GetItemBatchListPayorChangeReducer, GetItemBatchListBarcodeReducer
    , GetDrugItemSettingReducer, GetFrequencyMasterReducer, GetItemBatchSubstitutionListReducer, GetItemsForPickListReducer, GetPicklistMainReducer
    , GetPicklistDetailsReducer, GetEMPStoreReducer, GetItemsForEncounterListReducer, GetOPIssueSaleReturnMainReducer, GetItemLookSoundSetupReducer, GetItemCharactoristicsReducer
    , GetStatusByTypeNatureReducer, GetStatusByTypePOApprovalReducer, GetItemWiseStockReducer, GetItemByNameReducer, fetchAdmitPatientReducer, GetItemRxReducer
    , GetIPIssueReducer, GetItemSelectionReducer, GetIPIssueMainReducer, GetIPIssueDetailsReducer, PrescriptionDetailsReducer, GetVitalSignReducer
    , GetItemNameDisplaySetupReducer, GetStockTransferSearchSearchPopupReducer, ViewDrugOrderListReducer, GetIPIssueAllocationListReducer, GetIPIssueAllocationDeatilsReducer
    , GetPrescriptionListReducer, GetPrescriptionDetailReducer, GetPrescriptionViewReducer, GetBounceItemsReducer, GetStoreReportMappingReducer, GetSlabWiseDiscountReducer
    , GetAdminssionStatusListReducer, GetItemListConsumptionPIReducer, GetItemSearchReducer
    //---------------------------------------------------------------------------------------------    
    , GetSTKADJSearchReducer, GetSTKADJMainReducer, GetBatchItemWithFacilityReducer, BatchDetailsReducer, GetItemRouteMappingReducer
    , GetItemRouteMasterReducer, GetItemRouteFormMasterReducer, GetItemWiseBatchesDetailsReducer
    , GetItemDiscountSetupReducer, GetCompanyNonDiscountableItemsReducer, GetCompanyItemExclusionReducer
    , GetItemControlledPriceSetupReducer, GetIPIssueListReducer, GetStoreUserAccessAuditReducer, GetPayorItemDiscountReducer, GetFilterCategoryDataReducer
    , GetItemNameSetupReducer, GetInventoryItemNameSetupReducer, GetItemBrandMasterReducer, GetItemMasterAuditFacilityMappingReducer, GetItemMasterAuditControlledPriceReducer
    , GetItemMasterAuditItemAttributeReducer, GetItemMasterAuditDoseSettingsReducer, GetItemMasterAuditFrequencyReducer, GetItemMasterAuditCharacteristicsReducer
    , GetItemMasterAuditLabelInstructionReducer, GetItemMasterAuditRouteMappingReducer, GetItemMasterAuditGenericReducer
    , GetItemQueryTypeReducer, GetItemGenericStockReducer, GetItemListReadyToReuseByBatchIdReducer, GetEMPStoreReportMappedReducer, GetSlabPayorDiscountReducer,
    GetVitalMappinReducer, GetIPIssueTATReportReducer, POGRNReceivedDetailsReducer, getScrollReducer, getEmpFirstSubmitCollectionReducer
    , getScrollUserDataReducer, getEmpLastSubmitionScrollReducer, getEmplScrollSummaryReducer, getScrollListDataReducer, GetCIMSDataReducer
} from './Inventory/InventoryMaster/Reducers/INVM_GETReducers';
import {
    SaveItemCategoryReducer, SaveItemSubCategoryReducer, SaveChargesReducer, SaveUnitMasterReducer, SaveItemUnitMasterReducer,
    SaveHSNGroupReducer, SaveHSNCodeReducer, SaveItemMasterReducer, SaveItemChargesReducer, SaveItemAttributesReducer, SaveItemwithItemUnitMappingReducer,
    SaveChargeTemplateMainReducer, SaveChargeTemplateDetailsReducer, SaveTherapeuticCategoryReducer, SaveTherapeuticSubCategoryReducer,
    SaveGenericMasterReducer, SaveEmpUpdateTimeForExportCSVReducer, SaveItemGenericMappingReducer, SaveItemFormMasterReducer, SaveItemFacilityMappingReducer,
    SaveReusableItemReducer, SaveReusableItemChargesReducer, SaveGRNItemChargesReducer, SaveGRNMainReducer, SavePOMainReducer, SaveWorkOrderReducer, SaveStocksReducer, PostCancelGRNReducer,
    SavePrescriptionUnitReducer,
    SavePOApprovalSetupReducer, SaveSupplierDetailReducer, SaveSupplierTermsConditionReducer, SaveTermsConditionReducer, SaveSupplierFacilityMappingReducer,
    SaveSupplierItemMappingReducer, SaveSupplierLoginDetailReducer, SaveStoreReducer
    , SaveStoreTransactionTypeReducer, SaveStoreItemMappingReducer, SaveStoreROLSetupReducer, SaveStoreIndentAffilliationReducer, SaveStoreAutoIndetFrequencyReducer
    , SaveBinLocationReducer, SaveStoreItemAutoConsumptionReducer,
    SaveItemSalePriceSetupReducer,
    saveKITMasterReducer,
    saveKITItemMappingReducer, saveDepartmentIndentReducer, saveDepartmentIndentApprovalOrRejectionReducer, saveDepartmentIssueReducer, saveDepartmentIndentAllocationReducer
    , SaveDepartmentReceiveReducer, SaveDepartmentReceiveReturnReducer, SaveDepartmentReturnReducer, SaveDepartmentReturnAckReducer, saveConsignmentAllocationReducer, saveConsignmentReturnReducer


    , DepartmentReturnCancelReducer, SavePurchaseIndentApprovalorRejectionReducer, savePurchaseIndentReducer, saveDepartmentConsumptionReducer
    , saveIPIssueReducer, saveIPIssueReturnReducer, saveSupplierReturnReducer, SaveQuotationReducer, UpdateQuotationStatusReducer
    , SaveConsignmentMainReducer, SaveConsignmentItemChargesReducer, PostCancelConsignmentReducer
    , InActiveQuotationItemsReducer
    , SaveConsignmentReceiveMainReducer, SaveConsignmentReceiveItemChargesReducer, SaveManufacturerSupplierMappingReducer, ItemWisePOCloseReducer
    , SaveConsignmentIssueReducer, SaveConsignmentIssueReturnAsyncReducer, DocumentsUploadReducer, DeleteDocumentReducer, saveInvCashierScrollReducer, saveInvCashierCollectionReducer
    //-----------------------------SHRIKANT SAINI-------------------------------------------------   
    , SaveOPIssueSaleMainReducer, SaveEmpStoreAccessDataReducer,
    SaveOPIssueSaleReturnMainReducer, SaveStockAdjMainReducer,
    saveIPIssueAllocationReducer, saveRxWardAcknowledgeReducer, SaveRxReviewReducer, UpdateOPIssueSaleAfterPrintReducer,
    //---------------------------------------------------------------------------------------------
    SaveBatchExpiryMainReducer, SaveItemRouteReducer, SaveItemRouteFormReducer, SaveFrequencyMasterReducer, UpdateIPIssueAfterPrintReducer

    , SaveItemDiscountSetupReducer, SaveCompanyNonDiscountableItemsReducer, SaveCompanyItemExclusionReducer, saveItemDiscountReducer, SaveItemNameSetupReducer
    , UpdateReusableItemStatusReducer, saveBounceItemsReducer, SaveNewItemRequestReducer, UpdateGRNReducer

} from './Inventory/InventoryMaster/Reducers/INVM_SAVEReducers';


import {
    EncounterListReducer, EncounterSaveReducer, ReceiptListReducer, ReceiptSaveReducer, RefundSaveReducer, ServiceOrderByEncReducer, ServiceOrderReducer,
    ServiceCancelReducer, fetchReceiptDetailReducer, ServiceOrderSaveReducer,
    SingleServiceChargeReducer, fetchRefundDetailReducer, fetchCompanyChangeRateReducer, OPSaveInvoiceReducer, SearchOPInvoiceReducer, SearchOPInvoiceDetailDataReducer,
    PrintOPInvoiceDetailDataReducer, InvSearchWithoutBillDataReducer, SearchConsultationReducer, SearchAdmittedPatient, IPBillDeptSummaryReducer,
    IPUnBillServiceOrderReducer, OPSaveRefundReducer, DocIPVisitReducer, ENCCXLSERReducer, UnAckServiceOrderByEncReducer, IPSupplServiceDtlReducer, srchADMListReducer,
    IPbillPackageSummaryReducer, UpdateOrderReducer, RoomRentSaveReducer, saveDischargeReducer, Ip_Bill_Settlement_InvoiceReducer, Ip_Bill_Settlemen_Save_Reducer,
    IPbillVerifyReducer, IPPackServiceDtlReducer, IncExlPckDtlReducer, IncExlSuppDtlReducer, saveIPInvoiceReducer, updBillPayCatReducer, PatientUnSettleAdvReducer,
    saveFinancialClearanceReducer, GetSurgeryPackageChargesReducer, GetPackageSurgeonChargesReducer, ENCSTATUSListReducer, saveCalculatePackage,
    SaveSurgeryPackageDataReducer, InsEncRecTagReducer, saveDischargeIntimationDataReducer, getAdvanceHeadReducer, DischargeListReducer, invoiceSettlementDataReducer,
    cxlIPBillInitiateReducer, NoteSaveReducer, NoteFetchReducer, cxlIPBillDischargeReducer, getPkgRenDtlBypkgId, getPkgDtlByRegId, GetReceiptAdvanceTypeAuditReducer, savePackageRenderReducer,
    GetPackageRenderDetailsReducer, cxlIPFinancialClearanceReducer, EmplScrollSummaryReducer, fetchPatientLedgerReducer, InsRoomRentAutoReducer,
    getEmpLastSubmitScrollReducer, savenursingDischargeReducer,
    fetchSearchReceiptDataReducer, saveCashierScrollReducer, getEmpFirstSubmitCollecReducer, getScrollDataReducer, fetchRefundSearchDetailsReducer, getScrollListReducer, saveCashierCollectionReducer
    , ERSaveServiceOrderReducer, fetchERTestOrderReducer, cxlIPInvoiceReducer, cxlIPDischargeReducer, SavePrescriptionOrderReducer, updCSDtlReducer,
    ERPatientDetailsListReducer, EmplScrollDtlReducer, TPAClearanceReducer, billListByStatusReducer, cxlTPAClearanceReducer, cxlInvoiceSettlementReducer, getBillAdjustDtlReducer, saveBillSettlementReducer, PatientAppointmentReducer, getBillDispatchNoReducer, saveWriteOffReducer, getwriteoffListReducer, getwriteoffNoDtlReducer, getServDtlCrNoteReducer, saveDisAllowedReducer, getCreditNoteListReducer, approveWriteOffReducer, approveDisAllowedReducer, GetPatientAppointmentSlotsReducer
    , IPPayableServiceDtlReducer, IncExlPayableDtlReducer, SampleDataListReducer, ServiceDetailsDataListReducer, sendLISRISReportInterfacedReducer
    , fetchPendingPharmacyOrdersReducer, getSampleTATReducer, updateAdvanceTypeReducer, getScrollUserReducer, GetPayorChangeDetailsReducer, ProcedureTypeServiceDataListReducer, billListForVerificationReducer, SendDiscSummToClaimBookReducer,
    billListForDispatchReducer, billListDispatchedReducer, saveInvoiceVerificationReducer, EmplScrollSummaryPHReducer, EmplScrollDtlPHReducer, SaveOtherdetailDataReducer, EncounterListForSTPReducer
} from './Billing/Reducer/BillingReducer'
//Form Builder
import {
    fetchFormGroupMasterReducer, SaveFormGroupMasterReducer, fetchFormsubCategoryMasterReducer, SaveFormMasterReducer, fetchFormMasterReducer
    , SaveFormsubCategoryMasterReducer, SaveComponentMasterReducer, AppComponentMasterReducer, fetchFormsParameter, SaveFormsParameter, AppListComponentReducer
    , AppViewImageforImageComponentReducer, GetfrmDataObjectListReducer, GetdataObjectClinicalOtherSetup, getFormReportDataReducer, getPatientInfoReducer
} from './FormBuilder/Reducer/formReducer'


//Start User Access Added By Amarjeet
import {
    UserSaveReducer, CountryListReducer, NationalityListReducer, StateListReducer, CityListReducer, AreaListReducer,
    UserGroupListReducer, UserSubGroupListReducer, TitlesListReducer, DepartmentListReducer, EmploymentTypeReducer,
    PrimarySpecilityReducer, SecondarySpecilityReducer, RoleReducer, DefaultLandingPageReducer, PasswordPolicyReducer,
    RoleSaveReducer, PasswordPolicySaveReducer, PasswordPolicyDeleteReducer, FacilitysListReducer, ClientVisitReducer, IPVisitReducer, ServiceReducer, ServiceOPReducer,
    MOduleReducer, SectionReducer, PageReducer, ButtonReducer, SearchEmployeeReducer, TeamReducer, UHIDReducerU, TeamSaveReducer,
    RolePermissionSaveReducer, OtherPermissionSaveReducer, EmployeeBenifitsSaveReducer, GradeListReducer, DesignationListReducer, EmployeeFacilityMappingSaveReducer,
    RoleMasterReducer, FacilityMasterReducer, RoleMasterSaveReducer, DepartmentMasterReducer,
    RoleWithEmployeeReducer, PasswordPolicyMasterReducer, savePasswordPolicyReducer,
    SaveDoctorPermissionReducer, SaveDoctorVisitReducer, RoleMapedWithEmployeListReducer, SaveEmpImageReducer, SaveEmpSignatureReducer, GetRoleToTagFromEmployeeReducer,
    SearchEmployeeDataReducer, CheckMobileEmailIdExistOrNotReducer, ParameterEMPSetupDetailsReducer, PasswordPolicyClassReducer, DoctorTeamsdetailsReducer, GetEmployeeAuditReducer,
    GetTeamAuditReducer, GetEmployeePageEncounterPermissionsAuditReducer, GetFacilityMappingAuditReducer, GetRolePermissionsAuditReducer, GetEmployeeAssociateAuditReducer,
    GetEmpDependandBenefitAuditReducer
} from './UserAccess/Reducers/UserReducer'

import {
    RoleUnderFacilityListReducer, UserMapedWithFacilityListReducer, ModuleMapedWithFacilityListReducer, PageMapedWithModuleListReducer, AppFieldDataListReducer,
    EmployeeRoleMappingReducer, saveFieldModificationSetupReducer
} from './UserAccess/Reducers/FieldModificationReducer'

//End User Access Added By Amarjeet

//Start Umesh Kumar
import {
    fetchOperatingRoomListReducer, OperatingRoomSaveReducer,
    fetchPatientPositionListReducer, PatientPositionSaveReducer,
    fetchSurgeryCategoryListReducer, SurgeryCategorySaveReducer,
    fetchAnesthesiaTypeListReducer, AnesthesiaTypeSaveReducer,
    fetchPreferenceListReducer, PreferenceSaveReducer,
    fetchSurgeryMainListReducer, SurgeryMainSaveReducer,
    fetchSurgeryRequestListReducer, fetchOTBookingReducer,
    OTBookingSaveReducer, OTBlockSlotSaveReducer, fetchOTBlockSlotReducer,
    updatePlannedSurgeryDateReducer, autoAllocateOTReducer, previewOTReducer,
    OTBookingUpdateReducer, OTBookingCancelReducer, OTBookingCheckReducer, ApplyChangesReducer, CheckActionBookingReducer, PackageListByServiceReducer, SurgeryPostingInputReducer, fetchPeriOPReducer,
    fetchPeriOPPersonnelReducer, savePeriOPPersonnelReducer, fetchPeriOPSurgeryTimeDetailReducer, savePeriOPSurgeryTimeDetailReducer,
    fetchPeriOPSurgeryWoundDetailReducer, savePeriOPSurgeryWoundDetailReducer, CheckActionCancelReducer,
    unconfirmOTReducer, acknowledgeOTReducer, transferOutOTReducer, getOTTimelineReducer, GetSurgeryClearanceReducer, SaveSurgeryClearanceReducer, SavePACClearanceReducer, fetchOTStatReducer, fetchOTDashboardReducer

} from './OT/Reducers/Reducer'

import {
    getDietCategoryReducer, getTherapeuticRestrictionReducer, getFoodHabitsReducer, getCuisineReducer, getMealReducer, getPayorMealMappingReducer,
    savePayorMealMappingReducer, getSeasonReducer, getFoodCategoryReducer, getFoodItemReducer, saveFoodItemReducer, getRTLiquidFeedReducer,
    getMenuCycleReducer, saveMenuCycleReducer, getItemOfDayReducer, saveDietCategoryReducer, saveFoodHabitReducer, saveDietCuisineReducer, saveDietSeasonReducer
    , saveDietMealReducer, saveDietTherapeuticRestrictionReducer, saveDietFoodCategoryReducer, saveDietItemOfDayReducer, getDietOrderDataByIdReducer,
    getDietOrderForStatusUpdateReducer, saveRTLiquidFeedStatusReducer, getPreviousDietOrderListReducer, getPreviousDietOrderByRegIdReducer,
    getDietOrderStatusListReducer, getAdmittedPatientListReducer, GetDietPlanItemQuantitybybedcategorylistReducer, getIntegrationFlagsReducer, GetMealIndentSummaryReducer

} from './Diet/Reducer/DietReducer'

import { ExceptionListReducer } from './Exception/Reducer';
import {
    SavePatientTriageDetailsReducer, fetchERPatientTriageDetailsReducer, fetchERPatientWiseLOSDetailsReducer, ERAppFaceSheetReducer
} from './Emergency/Reducer/emergencyReducer'
import {
    ShowReportReducer, CurrentAdmittedPatientListReducer, DischargePatientListReducer, BedTransferListReducer, PractitionerTransferListReducer,
    getDisclaimerPrintReducer, saveDisclaimerPrintReducer, GetDayListReducer, GetFrequencyListReducer, GetRepeatListReducer, SaveReportSchedulerReducer, GetSchedulerListReducer, DeleteReportSchedulerReducer,
    AuditStatusReducer, ResendSMSReducer, getAllEmployeeListReducer, getOTPDetailsListReducer, UserCashCollectionReducer, GetSendEmailCollectionReducer
} from './Reports/Reducers/Reducer'

import { GetFormBuilderReducer } from './AppReducers/AppMstReducer'

import {
    SaveLoanFinancerReducer, GetFinancerAsyncReducer, GetFinanceLoanReducer, GetFinanceLoanEMIReducer
} from './MedicalLoan/Reducer/MedicalLoanReducer'


//End Umesh Kumar

const initialState = {};
const rootReducer = combineReducers({
    SessionReducer,
    GetPayorPageAuditBedCategoryReducer,
    GetPayorPageAuditExclusionItemReducer,
    GetPayorPageAuditExlAutoServiceDtlReducer,
    GetPayorPageAuditExclusionGroupMappingReducer,
    GetPayorPageAuditExclusionServiceReducer,
    GetPayorPageAuditCompNonDiscServiceReducer,
    GetPayorPageAuditMarkupExclusionReducer,
    GetPayorPageAuditMarkupByServiceReducer,
    GetPayorPageAuditTariffAndDiscountReducer, AuditStatusReducer,
    GetPayorPageAuditPlanReducer,
    GetItemMasterAuditFacilityMappingReducer,
    GetItemMasterAuditControlledPriceReducer,
    GetPayorPageAuditFacilityMappingReducer,
    GetItemMasterAuditItemAttributeReducer,
    GetItemMasterAuditDoseSettingsReducer,
    GetItemMasterAuditFrequencyReducer,
    GetItemMasterAuditRouteMappingReducer,
    GetItemMasterAuditGenericReducer,
    GetItemMasterAuditLabelInstructionReducer,
    GetItemMasterAuditCharacteristicsReducer,
    GetEmployeeAuditReducer, GetRolePermissionsAuditReducer, GetEmployeeAssociateAuditReducer, GetEmpDependandBenefitAuditReducer, GetTeamAuditReducer, GetFacilityMappingAuditReducer, GetEmployeePageEncounterPermissionsAuditReducer,
    GetReasonMasterAuditReducer, GetModuleReasonAuditReducer, GetPayorPageAuditReducer,
    RegistrationTypeReducer, TitleReducer, NationalityReducer, RelationshipReducer, AgeTypeReducer, ReligionReducer,
    OccupationReducer, EducationReducer, LanguageReducer, HWCTypeReducer, CountryReducer, CityReducerPer, CityReducerPre,
    AreaReducerPre, AreaReducerPer, HCFReducer, StateByCountryReducerPer, StateByCountryReducerPre, CompanyReducer, EmployerReducer,
    AppRegistrationTypeReducer, AppNationalityReducer, AppReligionReducer, AppOccupationReducer, AppReferralTypeReducer, AppHWCTypeReducer,
    AppTitleReducer, AppDocumentTypeReducer, AppCountryReducer, AppStateByCountryReducer, AppCityReducer, AppAreaReducer, AppEducationReducer,
    AppLanguageReducer, AppRelationshipReducer, AppHCFReducer, AppPayorCategoryReducer, AppReferralReducer,
    AppCompanyReducer, GetSAPPayorCategoryDiscountSetup, SaveSAPPayorCategoryDiscountSetupReducer, Getchecklist_masterReducer, saveChecklistReducer, AppHospitalperformanceDocterDetailsListReducer, AppHospitalperformancelistdetailsReducer, AppHospitalperformancelistReducer, AppHospitalPerformanceDaslistListReducer, AppHospitalPerformanceREVlistReducer, AppHospitalPerformanceREV_divlistReducer, AppDepartmentNameReducer, AppYearMonthReducer, AppMonthReducer, AppCurrencyReducer, getEncounterReducer, AppCompanyBeneficiaryReducer, AppGetCompanyDataReducer, GetCourierListReducer,
    AppReportFormatOPReducer, AppReportFormatERReducer, AppReportFormatIPReducer, AppEmployeeReducer,
    AppFacilityReducer, AppFacilityEMPReducer, AppCompanyFacilityReducer, AppCompanySponsorReducer, AppPayorPlanReducer,
    saveCompanyReducer, AppTariffReducer, AppCompanyTariffReducer, AppDiscountTemplateReducer, AppDepartmentReducer, AppDeptByServiceTypeReducer,
    AppDepartmentSubReducer, AppCompanyMarkupReducer, AppCompanyMarkupExlReducer, AppCompanyExclReducer,
    AppCompNonDiscReducer,
    AppPrintGroupReducer, AppServiceCategoryReducer, AppServiceMstReducer, AppServiceMstByServiceTypeReducer, AppCompBedCatReducer,
    AppServiceNomenclatureReducer, AppAreaPERReducer, AppDepartmentSubByTypeReducer,
    AppOPPackageGetReducer, AppDesignationReducer, AppGradeReducer,
    AppServiceByFacilityReducer, ReportRevenueDepartmentWiseReducer, MisInventoryAnalysisReportReducer
    , AppPackageByFacilityReducer, AppSUBServiceByFacilityReducer, AppSUBServiceByFacilityTNIReducer, AppServiceLinkedReducer, AppServiceFacilityReducer, saveServiceMstReducer,
    AppSpecializationReducer, AppCompanyExlAutoReducer,
    SaveRegistrationReducer, SaveBulkRegistrationReducer, SaveStaffRegistrationReducer, GetReportMasterDataReducer, ModulePagesByModuleReducer, SaveModulePageEventReducer, getModulePageEventReducer,
    getReasonWiseModulePageEventReducer, SaveItemNameSetupReducer, UpdateReusableItemStatusReducer,

    GetRegistrationReducer, RegistrationMandatoryReducer, MagicSearchReducer, GetOPPatientBannerReducer,
    CustomFildList: CustomFildListReducer,
    CustomFildSave: CustomFildSaveReducer,
    FacilityList: FacilityListReducer,
    ModuleList: ModuleListReducer,
    FormList: FormListReducer,
    SectionList: SectionListReducer,
    FildTypeList: FildTypeListReducer, CustomFieldReducer, MagicSearchReducerUHID, DuplicateRecordReducer, CustomFieldOPtionReducer,
    StaffByCodeReducer, fetchStatusByType,
    DocumentSave: DocumentFildSaveReducer,
    DocumentList: DocumentListReducer,
    UHIDList: UHIDReducer,
    DocumentDataList: DocumentDataListReducer,
    DocumentListImage: DocumentListImageReducer, DocumentDataListDtlReducer,
    DocumentTypeList: DocumentTypeListReducer,
    DocumentDeleteSingle: DocumentDeleteReducer,
    AppLoginTariffReducer, AppTariffSaveReducer,
    AppExclusionMainReducer, AppExclusionDetailReducer, AppUserGroupReducer, AppUserSubGroupReducer
    , AppServiceTariffReducer, AppServiceTariffChargeReducer,
    AppConsultationTariffChargeReducer, BankListReducer,
    //Inventory
    GetItemCategoryReducer,
    GetEmpUpdateTimeForExportCSVReducer,
    GetStatusByTypeReducer,
    GetItemSubCategoryReducer,
    GetChargesReducer,
    GetUnitMasterReducer,
    GetItemUnitMasterReducer,
    GetHSNGroupReducer,
    GetHSNCodeReducer,
    GetItemMasterReducer,
    GetItemChargesReducer,
    GetItemAttributesReducer,
    GetItemwithItemUnitMappingReducer,
    GetChargeTemplateMainReducer,
    GetChargeTemplateDetailsReducer,
    GetChargeTemplatesReducer,
    GetItemChargesByChargeTemplateReducer,
    GetItemUnitsByIssueUnitReducer,
    GetTherapeuticCategoryReducer,
    GetTherapeuticSubCategoryReducer,
    GetGenericMasterReducer,
    GetItemGenericMappingReducer,
    GetItemFormMasterReducer,
    GetItemFacilityMappingReducer,
    GetItemSubCategoryByCategoryReducer,
    GetTherapeuticSubCategoryByCategoryReducer,
    GetHSNCodeByHSNGroupReducer,
    GetItemAttributesOnlyReducer,
    GetItemChargesOnlyReducer,
    GetItemWithItemUnitsOnlyReducer,
    GetItemGenericOnlyReducer,
    GetItemFacilityOnlyReducer,
    GetItemCountByItemSubCategoryReducer,
    GetGRNMainReducer, GetGRNDataReducer,
    GetGRNDetailsReducer,
    GetGRNBatchDetailsReducer,
    GetItemByAttributeReducer,
    GetReusableItemReducer,
    GetReusableItemsReducer,
    GetReusableItemChargesReducer,
    GetChargesByItemReducer,
    GetGRNItemChargesReducer,
    GetChargesCalculationReducer,
    GetChargeTemplateListReducer,
    GetGRNSearchReducer,
    GetStoresByTransTypeReducer,
    GetItemsBySupplierReducer,
    GetSuppliersByFacilityReducer,
    GetLast5PurchasesReducer,
    GetLastPurchasesReducer,
    GetItemListByDescReducer,
    GetItemPurchaseUnitReducer,
    GetPrescriptionUnitReducer,
    GetPOMainReducer, GetPODataReducer, WorkOrderDataReducer, GetWorkOrderSearchReducer, GetPOSearchReducer, GetPODetailsReducer, GetChargeTemplateIDReducer, GetPOItemChargesReducer, GetPODocumnetChargesReducer, GetWorkOrderChargesReducer,
    GetAffiliatedStoreReducer, GetStoresByAffiliatedStoreReducer,
    GetSupplierTermsConditionsReducer, GetWOSupplierTermsConditionsReducer,
    GetPOTimeLineReducer,
    GetOPReturnTimeLineReducer,
    GetItemWiseBatchesReducer,
    GetPurchaseIndentforPOReducer,
    GetIPIssueStoreListforIPReturnReducer, GetIPReturnMainReducer, GetIPReturnDataReducer, GetIPReturnSearchReducer, GetStoreWiseItemListforIPReturnReducer, GetStoreWiseIPIssuedItemsforIPReturnReducer, GetIPReturnTimeLineReducer,
    GetChargeTemplateIdByCodeReducer,
    GetItemPriceReducer, ViewAllQuotationsItemsReducer, GetStatusByTypeSpecial_IndentReducer,
    GetConsignmentMainReducer, GetConsignmentDataReducer, GetConsignmentSearchReducer, GetConsignmentDetailsReducer, GetConsignmentBatchDetailsReducer, GetConsignmentItemChargesReducer,
    GetConsignmentReceiveMainReducer, GetConsignmentReceiveDataReducer, GetConsignmentReceiveSearchReducer, GetConsignmentReceiveDetailsReducer, GetConsignmentReceiveBatchDetailsReducer, GetConsignmentReceiveItemChargesReducer,
    GetStoresByStoreTypeCodeReducer, GetRxOrderDetailsReducer, GetConsignmentAllocationSearchReducer, GetConsignmentReturnSearchReducer, GetPOApprovalListByFacilityReducer,
    GetReusableItemsUsageReducer, GetNewItemReuestReducer,

    SaveNewItemRequestReducer,
    SaveItemCategoryReducer,
    SaveItemSubCategoryReducer,
    SaveChargesReducer,
    SaveUnitMasterReducer,
    SaveItemUnitMasterReducer,
    SaveHSNGroupReducer,
    SaveHSNCodeReducer,
    SaveItemMasterReducer,
    SaveItemChargesReducer,
    SaveItemAttributesReducer,
    SaveItemwithItemUnitMappingReducer,
    SaveChargeTemplateMainReducer,
    SaveChargeTemplateDetailsReducer,
    SaveTherapeuticCategoryReducer,
    SaveTherapeuticSubCategoryReducer,
    SaveGenericMasterReducer,
    SaveEmpUpdateTimeForExportCSVReducer,
    SaveItemGenericMappingReducer,
    SaveItemFormMasterReducer,
    GetChargeMasterListReducer,
    SaveItemFacilityMappingReducer,
    SaveReusableItemReducer,
    SaveReusableItemChargesReducer,
    SaveGRNItemChargesReducer,
    SaveGRNMainReducer,
    SavePOMainReducer,
    SaveWorkOrderReducer,
    SaveStocksReducer,
    PostCancelGRNReducer,
    SavePrescriptionUnitReducer,
    SaveConsignmentMainReducer, SaveConsignmentItemChargesReducer, PostCancelConsignmentReducer,
    InActiveQuotationItemsReducer,
    SaveConsignmentReceiveMainReducer, SaveConsignmentReceiveItemChargesReducer, SaveManufacturerSupplierMappingReducer, ItemWisePOCloseReducer,
    SaveConsignmentIssueReducer, SaveConsignmentIssueReturnAsyncReducer, saveItemDiscountReducer, DocumentsUploadReducer, UpdateGRNReducer,

    GetPOApprovalSetupReducer,
    GetSupplierDetailReducer,
    GetSupplierTermsConditiontReducer,
    GetTermsConditionReducer,
    GetSupplierItemMappingReducer,
    GetSupplierFacilityMappingReducer,
    GetSupplierLoginDetailReducer,
    GetStoreReducer,
    GetStorefacilityWiseReducer,
    GetStoreTransactionTypeReducer,
    GetStoreItemMappingReducer,
    GetStoreROLSetupReducer,
    GetStoreIndentAffilliationReducer,
    GetStoreAutoIndentFrequency,
    GetBinLocationReducer,
    GetStoreAllTransactionTypeReducer,
    GetStoreSubCategoryOrItemMappingReducer,
    GetStoreItemAutoConsumptionMappingReducer,
    GetFinancialYearReducer,
    GetStoreAllSubItemCategoryAndItemMappingReducer,
    GetAllItemChargeTemplateReducer,
    GetCompanyFacilityWiseReducer,
    GetItemSalePriceSetupReducer,
    GetKITMasterReducer,
    GetKITItemMappingReducer,
    GetDepartmentIndentDetailReducer, GetItemForDepartmentIndentReducer, GetDepartmentIndentMainReducer,
    GetDepartmentIndentTimeLineReducer,
    GetStoreMappedWithDocumentSetupReducer, GetDepartmentIndentApprovalDetailsReducer,
    GetItemSubstitutionAndBatchSelectionReducer, GetIndentForIssueReducer, GetBatchByBarcodeReducer, GetDepartmentIssueReducer, GetIndentForIssuePicklistPrintReducer,
    fetchReasonListReducer, fetchApprovalMatrixListReducer, GetDepartmentReceiveReducer, GetDepartmentReturnReducer
    , GetDepartmentReturnSearchReducer, GetItemForDepartmentReturnReducer, GetPurchaseIndentMainReducer, GetPurchaseIndentDetailsReducer,
    GetItemDataReducer, getPurchaseIndentAgainstIndentReducer, GetPurchaseIndentTimeLineReducer,
    GetDepartmentConsumptionDetailReducer, GetAllDepartmentConsumptionReducer, GetKitTemplateItemWithBatchReducer,
    GetIPIssueAllocationReducer, GetItemListforSupplierReturnReducer, GetSupplierReturnTimeLineReducer, GetSupplierReturnDetailsReducer, GetSupplierReturnSearchReducer,
    GetSupplierReturnDetailsForGRNReducer, GetItemsForQuotationReducer, GetQuotationSearchReducer, GetQuotationReducer, QuotationItemChargesReducer,
    GetSupplierWiseLastItemPriceReducer, GetItemForDepartmentReturnByBarCodeReducer, GetItemListforSupplierReturnByBarcodeReducer,
    SearchSaleToPatientReducer, GetItemGenericReducer, GetInventoryReportsReducer, GetItemByMultipleStoresAsyncReducer, GetManufacturerSupplierMappingReducer,
    SendEmailReducer, GetConsignmentItemWithStockReducer, GetConsignmentAllocationDetailReducer, GetConsignmentIssueDetailReducer,
    GetConsignmentIssueListReducer, GetPendingIndentListReducer, ItemsByFacilityReducer, GetPendingIndentReceiveReducer, GetReportMasterReducer, GetDepartmentIndentAllocationSearchReducer,
    GetDepartmentIndentAllocationReducer, GetDepartmentDeliverySearchReducer, GetPayorItemDiscountReducer, GetFilterCategoryDataReducer, GetSlabPayorDiscountReducer,
    GetPendingIndentListByItemReducer, GetItemListByItemNameReducer, GetItemNameSetupReducer, GetItemStrengthMasterReducer, GetItemEnterpriseLevelAttributesReducer,
    GetItemReferenceSizeMasterReducer, GetItemPackSizeMasterReducer, GetItemUniqueCategoryMasterReducer, GetInventoryItemNameSetupReducer, GetItemBrandMasterReducer,
    GetItemNamesReducer, GetItemConsumtionReducer, CheckSupplierStateIdReducer, GetStoreCategoryReducer, GetSupplierCategoryReducer, GetDocumentsListReducer,
    GetPOPlannerReducer, GetRequiredItemListByItemNameReducer, GetItemListByItemNameExportReducer,
    GetConsignmentItemWithStockByChallanReducer,

    SaveApprovalMatrixReducer

    , RolePermissionMasterReducer,
    AccessControlRoleReportReducer,
    SavePOApprovalSetupReducer,
    SaveSupplierDetailReducer,
    SaveSupplierTermsConditionReducer,
    SaveTermsConditionReducer,
    SaveSupplierFacilityMappingReducer,
    SaveSupplierItemMappingReducer,
    SaveSupplierLoginDetailReducer,
    SaveStoreReducer,
    SaveStoreTransactionTypeReducer,
    SaveStoreItemMappingReducer,
    SaveStoreItemAutoConsumptionReducer,
    SaveStoreROLSetupReducer,
    SaveStoreIndentAffilliationReducer,
    SaveStoreAutoIndetFrequencyReducer,
    SaveBinLocationReducer,
    SaveItemSalePriceSetupReducer,
    saveKITMasterReducer,
    saveKITItemMappingReducer,
    saveDepartmentIndentReducer, saveDepartmentIndentApprovalOrRejectionReducer,
    saveDepartmentIssueReducer, saveDepartmentIndentAllocationReducer, SaveDepartmentReceiveReducer,
    SaveDepartmentReceiveReturnReducer, SaveDepartmentReturnReducer, SaveDepartmentReturnAckReducer,
    saveConsignmentAllocationReducer, saveConsignmentReturnReducer, DeleteDocumentReducer, saveInvCashierScrollReducer,
    saveInvCashierCollectionReducer,

    DepartmentReturnCancelReducer,
    SavePurchaseIndentApprovalorRejectionReducer, savePurchaseIndentReducer, saveDepartmentConsumptionReducer,
    saveIPIssueReducer, saveIPIssueReturnReducer, saveSupplierReturnReducer, SaveQuotationReducer, UpdateQuotationStatusReducer,
    GetUserStoreAccessRightsReducer, GetDepartmentReceiveSearchPopupReducer,
    GetItemListROLReducer, GetItemListConsumptionReducer, GetOpeningAdjustmentTimeLineReducer,
    GetItemQueryTypeReducer, GetItemGenericStockReducer, GetItemListReadyToReuseByBatchIdReducer,
    //----------------------SHRIKANT SAINI---------------------------
    GetOPIssueSaleMainReducer,
    GetOPIssueSaleDetailsReducer,
    GetOPIssueSaleBatchDetailsReducer, GetItemSearchReducer,
    GetItemMasterListReducer, GetItemBatchListReducer, GetItemBatchListPayorChangeReducer, GetEmpStoreAccessDataReducer,
    SaveOPIssueSaleMainReducer, SaveEmpStoreAccessDataReducer, SaveOPIssueSaleReturnMainReducer,
    GetItemBatchSubstitutionListReducer, GetItemsForPickListReducer, GetPicklistMainReducer, GetPicklistDetailsReducer,
    GetItemsForEncounterListReducer, GetItemBatchListBarcodeReducer, GetOPIssueSaleReturnMainReducer, GetItemRxReducer,
    saveIPIssueAllocationReducer, GetIPIssueReducer, saveRxWardAcknowledgeReducer, GetItemSelectionReducer, GetIPIssueMainReducer, GetIPIssueDetailsReducer,
    PrescriptionDetailsReducer, SaveRxReviewReducer, GetVitalSignReducer, GetItemNameDisplaySetupReducer, GetStockTransferSearchSearchPopupReducer, GetEmpListReducer,
    GetPrescriptionListReducer, GetPrescriptionDetailReducer, GetPrescriptionViewReducer, UpdateOPIssueSaleAfterPrintReducer, saveBounceItemsReducer, GetBounceItemsReducer,
    //---------------------------------------------------------------
    // EnD Inventory
    AppfetchClinic, AppfetchPaymentMode
    // ujjwal work start
    , AppBedTypeReducer, AppModuleReducer, AppFormReducer, AppSectionReducer, AppBEDCATMstReducer, GetPatientWorkListReducer
    , CostEstimationNoReducer, PreAuthNoReducer, GetPatientWorkListDetailReducer, PatientWorkListCancelReducer, GetItemMasterBySubCatReducer
    , AppSaveCompanExclusionListReducer
    , SaveCityMstReducer, SaveStateMstReducer, SaveCountryMstReducer, SaveRefTypeMstReducer
    , AppGetRolePageSectionMaster, AppGetRolePageMaster, AppGetEmployeePageSectionMaster, AppGetEmployeePageMaster, AppGetClinicForEmployee
    , AppGetDoctorVisitMaster, AppGetEmployeeFacility
    , AppPageFuncionPermissionEmployee, AppsaveEmployeePermissions, AppFacilitywiseEmployeeMaster, AppPagePermissionMasterEmployee, AppPageFuncionPermission
    , AppERBEDCATMstReducer, AppBEDCATByEncReducer
    // ujjwal work end
    //tony 
    , saveDiscountTemplateReducer, AppDiscountTempConfigReducer
    //#region Satender
    , AppServiceTariffFactorReducer, AppServiceTariffFactorDtlReducer, AppServiceBedCategoryReducer,
    AppCaseTypeReducer, AppBillingCountryReducer, AppBillingStateByCountryReducer, AppBillingCityReducer, AppBillingAreaReducer
    , AppServiceByServiceCategoryReducer, AppCompanywiseFacilityReducer,
    ServiceOrderByEncReducer, ServiceOrderReducer, ServiceCancelReducer, AppGetMOUDetailsListReducer, ServiceOrderSaveReducer

    //#endregion
    //Billing
    , EncounterListForSTPReducer, EncounterListReducer, EncounterSaveReducer, ReceiptListReducer, ReceiptSaveReducer, fetchReceiptDetailReducer, RefundSaveReducer,
    fetchRefundDetailReducer, fetchCompanyChangeRateReducer, SearchOPInvoiceDetailDataReducer, PrintOPInvoiceDetailDataReducer, InvSearchWithoutBillDataReducer, SearchConsultationReducer,
    SearchAdmittedPatient, IPBillDeptSummaryReducer, IPUnBillServiceOrderReducer, UpdateOrderReducer, RoomRentSaveReducer,
    updBillPayCatReducer, ENCSTATUSListReducer, getPkgRenDtlBypkgId, getPkgDtlByRegId,
    //#region vibhav
    ParameterSetupDetailsReducer, staffGetRegDataReducer, fetchCampListDataReducer, AppGetCompanyItemExclusionListReducer, AppGetGroupExclusionListReducer, GetRegDataByRegistrationIdReducer,
    GetRegistrationDuplicateMergeIdReducer, OPSaveInvoiceReducer, SearchOPInvoiceReducer, OPSaveRefundReducer,
    DocIPVisitReducer, ENCCXLSERReducer, UnAckServiceOrderByEncReducer, IPSupplServiceDtlReducer, srchADMListReducer,
    IPbillPackageSummaryReducer, savePackageRenderReducer, GetReceiptAdvanceTypeAuditReducer, GetPackageRenderDetailsReducer, ERPatientDetailsListReducer,

    //#endregion

    //rajesh work start
    AppRoomMasterReducer, AppBlockReducer, AppFloorReducer, AppNursingUnitReducer, AppBedMasterReducer, AppBedMasterByStatusTypeReducer, AppBuildingReducer,
    FromAppBedMasterReducer, ToAppBedMasterReducer, FromAppNursingUnitReducer, ToAppNursingUnitReducer, AppFreeServiceOrderReducer, AppCurrencyDenominationReducer, GetSurgeryGradeServiceReducer, AppGetModulePageFormTaggingReducer, SaveGetModulePageFormTaggingReducer, saveModuleFromUserReducer
    , AppCurrencyexReducer, AppAdmissionTypeReducer, AppAgeGroupReducer,
    saveAdmissionRequestReducer, EncounterReducer, AppAdmissionNoReducer, AppAdmissionRequestReducer
    , AppRemarksMasterReducer, saveBedBookReducer, AppBedBookingReducer, AppBedOccupiReducer,
    AppAdmissionAdmittedPatientReducer, AppAdmissionRequestForEstimateReducer,
    AppTimeLineViewReducer, EmploymentTypeReducer,
    AppAdmissionReducer, saveAdmissionReducer, AppSaveBedTimelineReducer
    , savePatientTransferReducer, AppPatientTransferReducer, savePractitionerTransferReducer
    , AppPractitionerTransferReducer, AppPatientTransferbyEncounterIdReducer, AppPractitionerTransferbyEncounterIdReducer
    , savePatientTransferRequestReducer, AppPatientTransferRequestReducer, AppPatientTransferRequestbyEncounterIdReducer
    , AppSaveAgeGroupReducer, SaveAnesthesiaTypeReducer, SaveBlockReducer, SaveFloorReducer, SaveNursingUnitReducer, SaveRoomMasterReducer, SaveBedMasterReducer
    //rajesh work end
    //Start User Access Added By Amarjeet
    , UserSaveR: UserSaveReducer, TeamSaveR: TeamSaveReducer, RolePerSaveR: RolePermissionSaveReducer, OtherPerSaveR: OtherPermissionSaveReducer, EmployeeBenifitsSaveR: EmployeeBenifitsSaveReducer,
    CountryList: CountryListReducer, NationalityList: NationalityListReducer, StateList: StateListReducer,
    CityList: CityListReducer, AreaList: AreaListReducer, UserGroupList: UserGroupListReducer, UserSubGroupList: UserSubGroupListReducer,
    TitlesList: TitlesListReducer, DepartmentList: DepartmentListReducer, EmploymentTypeList: EmploymentTypeReducer,
    PrimarySpecilityList: PrimarySpecilityReducer, SecondarySpecilityList: SecondarySpecilityReducer,
    RoleList: RoleReducer, LandigPageList: DefaultLandingPageReducer, PasswordPolicyList: PasswordPolicyReducer,
    ClientVisitList: ClientVisitReducer, IPVisitList: IPVisitReducer, ServiceList: ServiceReducer, ServiceOPList: ServiceOPReducer,
    RoleSaveR: RoleSaveReducer, PasswordPolicySaveR: PasswordPolicySaveReducer, PasswordPolicyDeleteR: PasswordPolicyDeleteReducer,
    FacilityesList: FacilitysListReducer, ModuleListR: MOduleReducer, SectionListR: SectionReducer, PageListR: PageReducer, ButtonListR: ButtonReducer, EmploymentTypeR: EmploymentTypeReducer,
    SearchEmployeeR: SearchEmployeeReducer, TeamR: TeamReducer, UHIDListR: UHIDReducerU, ParameterEMPSetupDetailsReducer, ParameterSetupMainReducer, DoctorTeamsdetailsReducer
    , AppGetNonDiscountItemListReducer, AppGetNonDiscountGroupListReducer, SingleServiceChargeReducer, AppSaveDepartmentReducer, AppSaveDepartmentSubReducer, GradeList: GradeListReducer, DesignationList: DesignationListReducer
    , AppEmploymentTypeReducer,
    // AppPaymentModeReducer,
    EmployeeFacilityMappingR: EmployeeFacilityMappingSaveReducer,
    RoleUnderFacilityListReducer, UserMapedWithFacilityListReducer, ModuleMapedWithFacilityListReducer,
    PageMapedWithModuleListReducer, AppFieldDataListReducer, AppDischargePatientDataReducer, EmployeeRoleMappingReducer,
    SaveDoctorPermissionReducer, SaveDoctorVisitReducer, RoleMapedWithEmployeListReducer,
    SaveEmpImageReducer, SaveEmpSignatureReducer, AppEmployeeCopyReducer,
    GetRoleToTagFromEmployeeReducer, SearchEmployeeDataReducer

    //Ent User Access Added By Amarjeet
    , AppSaveRegistrationTypeReducer, AppSaveReligionReducer, AppsaveEducationReducer, AppsaveHWCTypeReducer, AppsaveLanguageReducer, AppsaveOccupationReducer, AppsaveReferralReducer
    , AppsaveAreaReducer, AppsaveDocumentTypeReducer, AppsaveNationalityReducer, AppsaveRelationReducer, AppsaveTitleReducer, AppsaveEmploymentTypeReducer, AppPagePermissionMaster, AppFacilitywiseRoleMaster
    , AppSaveRetainBedReducer, AppAnesthesiaTypeReducer,
    RoleMasterReducer, FacilityMasterReducer, RoleMasterSaveReducer, DepartmentMasterReducer, RoleWithEmployeeReducer, PasswordPolicyMasterReducer, savePasswordPolicyReducer
    , SaveRegistrationInsuranceReducer, fetchCustomFieldValueReducer, saveScreenConfigurationReducer, saveCustomFieldDependencyReducer, fetchCustomFieldDependencyListReducer, CheckMobileEmailIdExistOrNotReducer
    //Chandan
    , AppEquipmentListReducer, AppsaveEquipmentListReducer
    , AppSaveEstTemplateMasterReducer, AppGetEstTemplateListReducer, AppSaveEstBILLHeadReducer, AppGetEstTemplateLDetailsReducer
    , AppGetEstTemplateBillHeadDetailsReducer, AppGetEstTemplateBillHeadDetailsBedCatgWiseReducer, AppGetEstTemplateBillHeadDetailsServiceWiseReducer
    , AppSaveEstimateReducer, AppGetGetEstimateDetailsReducer, AppCancelEstimateReducer, AppGetEstimatePayorDetailsReducer
    //End Here
    // Added by : Rajesh Yati
    , GetDrugItemSettingReducer, GetFrequencyMasterReducer, AppCompanyAllReducer, AppsaveRolePermissionsReducer
    , AppPatientDetailsIPReducer,
    AppPatientDetailsIPReducerRX,
    //added by Ritesh
    fetchBankMasterListReducer, BankMasterSaveReducer
    , AppCardMasterListReducer
    , AppCardMasterSave

    //
    , FetchModulePageReducer, ReasonMasterSaveReducer, SearchAdmitPatientReducer, SearchBedBookedReducer, SearchPatientAppointmentReducer, CancelPatientAppointmentReducer, MarkArrivalPatientReducer

    , AppDesinationReducer
    , AppSaveProcessOwnerReducer, AppProcessOwnerReducer,
    AppSaveWorkflowMainReducer, AppWorkflowMainReducer, AppWorkflowFacilityReducer, ModulePageMenuListReducer, GetEmployeeValidateLoginReducer
    , AppSaveWorkflowFacilityReducer, AppSaveWorkflowStepsReducer, AppWorkflowStepsReducer, ModulePageButtonListReducer,
    GetEMPStoreReducer, saveCompanyServiceExclReducer, AppGetPageCTAReducer, AppGetToPageCTAReducer, AppGetWorkflowSCMReducer, AppWorkflowDetailMasterReducer
    , SaveGradeMstReducer, SaveDesigationMstReducer, SaveUserGropMstReducer, SaveUserSubGropMstReducer, GetAuditDataReducer
    , AppPatientSeriesReducer, SavePatientSeriesReducer,
    AppEnterpriseReducer, SaveModulePagePinnedReducer, AppWFDetailMasterReducer, BulkRegistrationReducer
    , saveVisitorPassReducer
    , AppVisitorPassReducer
    , AppEmployeeByTeamReducer
    , AppVisitorPassUploadedFileReducer, GetFieldModificationPermissionReducer
    , WeeklyOffListReducer, WeeklyOffSaveReducer, HolidayListReducer, HolidaySaveReducer, SaveParameterSetupMainReducer, AllCompanyReducer
    , HolidayMarkupReducer, HolidayMarkupSaveReducer, getSurgeryComponentReducer, saveIpPackageReducer
    , saveIPPackageServiceInclusionReducer, saveIPPackageItemInclusionReducer, saveIPPackageExclusionsReducer, saveIPPackageBedChargeReducer, saveIPPackageSurgeryComponentReducer
    , GetIpPackageListReducer, GetIpPackageAllListReducer
    , AppsaveClinicReducer, getIpPackageBedChargeLimitReducer, getIpPackageSurgeryCompDataReducer, saveCopyFromExistingPackageReducer
    , SaveHCFReducer, SavePayorCategoryReducer, DispositionReducer, saveDischargeReducer, GetGRNDocumnetChargesReducer, CurrEXRateReducer, Ip_Bill_Settlement_InvoiceReducer, Ip_Bill_Settlemen_Save_Reducer, IPbillVerifyReducer, IPPackServiceDtlReducer
    , savePayorPlanReducer, IncExlPckDtlReducer, IncExlSuppDtlReducer, saveIPInvoiceReducer, saveDischargeIntimationDataReducer, DischargeListReducer, invoiceSettlementDataReducer
    , PatientUnSettleAdvReducer, AppSaveWFFallbackReducer, AppWFFallbackReducer,
    AppSaveWFNotificationReducer, AppSaveWFTATReducer, AppWFNotificationReducer, AppWFTATReducer, saveFinancialClearanceReducer
    , GetItemLookSoundSetupReducer, GetItemCharactoristicsReducer, GetPatientWorkNotificationListReducer, GetPatientWorkCompletedListReducer, GetPatientWLDocumentReducer, GetPatientWLDocumentDetailReducer
    , AppFaceSheetReducer, AppFacilityMasterReducer, AppPrintVPReducer, GetSurgeryPackageChargesReducer, GetPackageSurgeonChargesReducer, AppWFSTOPReducer
    , GetStatusByTypeNatureReducer, saveCalculatePackage, SaveSurgeryPackageDataReducer, getMappedSurgeryComponentListReducer, InsEncRecTagReducer, getAdvanceHeadReducer
    , GetStatusByTypePOApprovalReducer, getSurgeryCompSvcMappingDataReducer, saveSurgeryCompSvcMappingDataReducer,
    fetchSurgeryComponent, SaveSurgeryComponent
    , getSurgeryCompTatiffMappingReducer, saveSurgeryCompTariffMappingReducer, SaveServiceBedCategoryFactorReducer, cxlIPBillInitiateReducer, GetItemWiseStockReducer,
    AppsaveCopyRolePermissions, GetItemByNameReducer, NoteSaveReducer, NoteFetchReducer, cxlIPBillDischargeReducer, fetchAdmitPatientReducer, SaveStockAdjMainReducer, GetSTKADJSearchReducer, GetSTKADJMainReducer
    , AppItemDiscountTempConfigReducer, BatchDetailsReducer, SaveBatchExpiryMainReducer, GetBatchItemWithFacilityReducer, ServiceChangeRuleFetchReducer, ServiceChangeRuleSaveReducer, getBedListReducer,
    GetItemRouteMappingReducer, GetItemRouteMasterReducer, SaveItemRouteReducer, GetItemRouteFormMasterReducer, SaveItemRouteFormReducer, SaveFrequencyMasterReducer, getSearchFacilityEmployeeListReducer, getSearchFacilityDocListReducer,
    ViewDrugOrderListReducer, GetIPIssueAllocationListReducer, GetIPIssueListReducer, GetIPIssueAllocationDeatilsReducer, GetStoreUserAccessAuditReducer, GetEMPStoreReportMappedReducer,
    //Umesh Start
    EncounterCountReducer, CheckMergePatientReducer, userValidatePasswordResetReducer, userValidateOTPReducer, getUserDataForResetPasswordReducer,
    //-------Start OT-------
    fetchOperatingRoomListReducer, OperatingRoomSaveReducer,
    fetchPatientPositionListReducer, PatientPositionSaveReducer,
    fetchSurgeryCategoryListReducer, SurgeryCategorySaveReducer,
    fetchAnesthesiaTypeListReducer, AnesthesiaTypeSaveReducer,
    fetchPreferenceListReducer, PreferenceSaveReducer,
    fetchSurgeryMainListReducer, SurgeryMainSaveReducer,
    fetchSurgeryRequestListReducer, fetchOTBookingReducer,
    OTBookingSaveReducer, OTBlockSlotSaveReducer, fetchOTBlockSlotReducer, updatePlannedSurgeryDateReducer, autoAllocateOTReducer, previewOTReducer,
    OTBookingUpdateReducer, OTBookingCancelReducer, OTBookingCheckReducer, ApplyChangesReducer, saveFieldModificationSetupReducer, CheckActionBookingReducer, PackageListByServiceReducer, SurgeryPostingInputReducer,
    unconfirmOTReducer, acknowledgeOTReducer, transferOutOTReducer, getOTTimelineReducer
    //-------End OT---------
    //Umesh End
    , userPasswordResetSaveReducer, changePasswordAfterLoginReducer, AssociateEmployeefetchReducer, AssociateEmployeeSaveReducer, changePinAfterLoginReducer, ERVisitDetailsReducer, AppERBedOccupiReducer, ERAllPatientDetailsReducer, ERPatientDetailsReducer, PasswordPolicyClassReducer, savePaymentModeReducer, SaveCompanyPrintReducer, roleAccessReducer, getCheckListMasterReducer, AppTriageReducer, SaveTriageReducer, AppErDashBoardReducer, SaveErDashBoardReducer, getSearchEmployeeListReducer, saveCompanyChecklistReducer, saveIPPackagePayorReducer, cxlIPFinancialClearanceReducer, EmplScrollSummaryReducer, fetchPatientLedgerReducer, InsRoomRentAutoReducer, getEmpLastSubmitScrollReducer, CurrencyDenominationReducer, SaveItemDiscountSetupReducer, GetItemDiscountSetupReducer, fetchSearchReceiptDataReducer, saveCashierScrollReducer, getEmpFirstSubmitCollecReducer, getScrollDataReducer
    , fetchRefundSearchDetailsReducer, getScrollListReducer, GetItemWiseBatchesDetailsReducer, ERAllPatientlistReducer
    , SaveCompanyNonDiscountableItemsReducer, SaveCompanyItemExclusionReducer, GetCompanyNonDiscountableItemsReducer, GetCompanyItemExclusionReducer, saveCashierCollectionReducer
    , ERSaveServiceOrderReducer, fetchERTestOrderReducer, savePreAuthReducer, cancelPreAuthReducer, GetItemControlledPriceSetupReducer, savePreAuthQueryReducer, savePreAuthQueryCommunicationReducer, cxlIPInvoiceReducer, cxlIPDischargeReducer, PreAuthTPACheckListReducer
    , fetchServiceLastOrderDtlReducer, SavePrescriptionOrderReducer
    , SaveEREncounterReducer, updCSDtlReducer,
    // Mayur save form parameters
    fetchFormsParameter, SaveFormsParameter
    , fetchFormNamesReducer, EmplScrollDtlReducer, TPAClearanceReducer, AppEmployeeBySpecialityReducer
    , CancelERAdmissionReducer
    , checkRegValidityReducer
    , AppAdmissionRequestListReducer
    , fetchFormGroupMasterReducer, SaveFormGroupMasterReducer, fetchFormsubCategoryMasterReducer, SaveFormMasterReducer
    , fetchFormMasterReducer, SaveFormsubCategoryMasterReducer, SaveComponentMasterReducer, AppComponentMasterReducer
    , SaveChiefComplaintReducer, AppChiefComplaintReducer, AppDiagnoseMasterReducer, billListByStatusReducer, cxlTPAClearanceReducer,
    fetchStatusByTypeForGlobal, ParameterSetupDetailsReducerForGlobal,
    AppFacilityMasterListReducer, AppFacilitySaveReducer
    , savenursingDischargeReducer, RegistrationDuplicateMergeReducer
    //Diet Module
    , getPreviousDietOrderListReducer, getPreviousDietOrderByRegIdReducer, getDietOrderStatusListReducer, getAdmittedPatientListReducer,
    getDietCategoryReducer, getTherapeuticRestrictionReducer, getFoodHabitsReducer, saveFoodHabitReducer, getCuisineReducer, getMealReducer, getPayorMealMappingReducer,
    savePayorMealMappingReducer, getSeasonReducer, getFoodCategoryReducer, getFoodItemReducer, saveFoodItemReducer, getRTLiquidFeedReducer,
    getMenuCycleReducer, saveMenuCycleReducer, getItemOfDayReducer, saveExclusionMainReducer, saveExclusionDetailReducer
    , AppListComponentReducer, SaveCampReducer, AppCampReducer, AnatomicSiteMstReducer, SaveServiceTariffDetailReducer
    , saveServiceNomenclatureReducer, AppADTRemarksDetailReducer, AppServiceTariffHistoryReducer, getMappedIPPackageComponentListReducer
    , SavePatientTriageDetailsReducer, fetchERPatientTriageDetailsReducer
    , getCompanyDisTemplateMappingReducer, AppServiceControlledPriceReducer, AppServiceControlledPriceSaveReducer
    , AppViewImageforImageComponentReducer, cxlInvoiceSettlementReducer, SaveRevenueAndTargetReducer, SaveCurrencyDenominationReducer, SaveSurgeryGradeServiceReducer
    , SaveCurrencyReducer, SaveFreeServiceOrderReducer,
    GetPayorPackageDetailsMaster, AppOPPackageSaveReducer, SaveServiceBedCategoryFactorDtlReducer, fetchERPatientWiseLOSDetailsReducer
    , fetchgetCompanyNomenclatureReducer, SaveBuildingReducer, CompanyTariffPlanReducer, AppTariffRuleReducer, saveDietCategoryReducer, saveDietCuisineReducer, saveDietSeasonReducer
    , saveDietMealReducer, saveDietTherapeuticRestrictionReducer, getBillAdjustDtlReducer, saveDietFoodCategoryReducer, saveDietItemOfDayReducer, getDietOrderDataByIdReducer,
    getDietOrderForStatusUpdateReducer, saveBillSettlementReducer, saveRTLiquidFeedStatusReducer, PatientAppointmentReducer, getBillDispatchNoReducer, saveWriteOffReducer, getwriteoffListReducer, getwriteoffNoDtlReducer, fetchPeriOPReducer, getServDtlCrNoteReducer, saveDisAllowedReducer, getCreditNoteListReducer,
    ShowReportReducer, CurrentAdmittedPatientListReducer, DischargePatientListReducer, fetchPeriOPPersonnelReducer, savePeriOPPersonnelReducer, fetchPeriOPSurgeryTimeDetailReducer, savePeriOPSurgeryTimeDetailReducer,
    fetchPeriOPSurgeryWoundDetailReducer, savePeriOPSurgeryWoundDetailReducer, BedTransferListReducer, PractitionerTransferListReducer, AppADTCheckListReducer, CheckActionCancelReducer, approveWriteOffReducer, approveDisAllowedReducer, GetPatientAppointmentSlotsReducer
    , AppConsultSetupSaveReducer, AppConsultSetupReducer, AppCompMOUDetailSaveReducer, AppDoctorDetailSaveReducer, AppDoctorDetailfetchReducer
    , AppServiceAttributeReducer, ExceptionListReducer, DocumentDataListDtlClmReducer, ClaimBookSaveReducer, IPPayableServiceDtlReducer, IncExlPayableDtlReducer, AdmMarkArrivalReducer
    , AppEmpByTypeReducer, GetFamilyMemberListDataReducer, SampleDataListReducer, ServiceDetailsDataListReducer, sendLISRISReportInterfacedReducer,
    getSearchServiceMasterReducer, EncounterADTReducer, SaveCompanyExlAutoServiceReducer, TariffChargeAuditReducer, ServiceMstAuditReducer
    , reasonMasterReducer, SaveReasonMasterReducer, GetModuleReasonReducer, GetModulePageEventsReducer, SavemoduleReasonReducer, getPageListForValidationSetupReducer, getPageValidationSetupMasterReducer
    , fetchPendingPharmacyOrdersReducer, getNursingDischargeOptionMasterReducer, CompanyReducerMagic, getSampleTATReducer, GetSurgeryClearanceReducer, SaveSurgeryClearanceReducer, updateAdvanceTypeReducer,
    getDisclaimerPrintReducer, saveDisclaimerPrintReducer, SaveCompanyBedCatReducer, GetDayListReducer, GetFrequencyListReducer, GetRepeatListReducer, SaveReportSchedulerReducer, GetSchedulerListReducer, DeleteReportSchedulerReducer, AppSaveServiceAttributeReducer, AppSponsorReducer,
    getScrollUserReducer, SaveCurrencyMasterReducer, CurrencyMasterReducer, getMrdReducer, getRegReducer
    , AppFIStatusReducer, AppFITCReducer, ResendSMSReducer, GetFormBuilderReducer, getAllEmployeeListReducer, MRDSurgeryOrderReducer, GetSMSConfigurationReducer, getOTPDetailsListReducer, SaveSMSConfigurationReducer, GetSMSEventDetailReducer, GetSMSEventWithReceiverDetailReducer,
    GetReportConfigListReducer, GetEmailSetupReducer, GetPayorChangeDetailsReducer, ERAppFaceSheetReducer, AppMappedGradeReducer, ValidateExcelListBulkReducer, SaveExcelListBulkReducer, UserCashCollectionReducer, ValidateItemMasterExcelReducer, SaveItemMasterExcelReducer, GetScheduleData, GetStoreReportMappingReducer, GetSendEmailCollectionReducer,
    GetRegSourceReducer, AppCaseSubTypeReducer, GetSlabWiseDiscountReducer, GetAdminssionStatusListReducer, ProcedureTypeServiceDataListReducer, GetItemListConsumptionPIReducer, SavePACClearanceReducer, get_emr_comp_Reducer, GetDietPlanItemQuantitybybedcategorylistReducer, hpf_grw_sum_Reducer, AppDepartmentEmpReducer, fetchOTStatReducer, SendDiscSummToClaimBookReducer
    , billListForVerificationReducer, billListForDispatchReducer, billListDispatchedReducer, saveInvoiceVerificationReducer, GetStockDashboardReducer, AppItemSapIntegrationReducer, AppSAPItemPurchageGroupMasterReducer, GetDischargesummaryviewDataReducer, addMlcFromMrdReducer, fetchOTDashboardReducer, GetVitalGroupReducer, GetVitalMappinReducer, GetsurgerydetailmrdReducer,
    GetIPIssueTATReportReducer, GetfrmDataObjectListReducer, GetdataObjectClinicalOtherSetup, AppDepartmentEmpResultReducer, POGRNReceivedDetailsReducer, GetSMSConfigParameterReducer, GetSMSConfigSubParameterReducer, GetAdmittedPayorListReducer
    , getScrollReducer, getEmpFirstSubmitCollectionReducer, getScrollUserDataReducer, getEmpLastSubmitionScrollReducer, getEmplScrollSummaryReducer, getScrollListDataReducer, GetCIMSDataReducer, EmplScrollSummaryPHReducer, EmplScrollDtlPHReducer, taxsetupListReducer, getFormReportDataReducer, ReSyncdataListReducer, InsertInvoicedataListReducer,
    getPatientInfoReducer, SaveOtherdetailDataReducer, saveDiagnosisData, deleteDiagnosisData, SettlementReSyncdataListReducer, InsertSettlementdataListReducer, ReceiptReSyncdataListReducer, RefundReSyncdataListReducer, InsertCollectiondataListReducer,
    CreditnoteReSyncdataListReducer, InsertCreditnotedataListReducer, InvoicewriteoffReSyncdataListReducer, InsertWriteoffdataListReducer, SaveserviceMasterReducer, GetservicemasterReducer
    , getScrollReducer, getEmpFirstSubmitCollectionReducer, getScrollUserDataReducer, getEmpLastSubmitionScrollReducer, getEmplScrollSummaryReducer, getScrollListDataReducer, GetCIMSDataReducer, EmplScrollSummaryPHReducer, EmplScrollDtlPHReducer, taxsetupListReducer, getFormReportDataReducer, ReSyncdataListReducer, InsertInvoicedataListReducer,
    getPatientInfoReducer, SaveOtherdetailDataReducer, SaveLoanFinancerReducer, GetFinancerAsyncReducer, GetFinanceLoanReducer, GetFinanceLoanEMIReducer, UpdateIPIssueAfterPrintReducer, AppsaveVulnerableTypeReducer, AppVulnerableTypeReducer, getIntegrationFlagsReducer, GetMealIndentSummaryReducer,
    AppIntegrationFlagReducer, EncounterReSyncdataListReducer, InsertencounterdataListReducer, CxlinvoiceReSyncdataListReducer, InsertcxlinvoicedataListReducer
})
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const RedusStore = createStore(rootReducer, initialState, composeEnhancer(applyMiddleware(thunk)));

//const middleware = applyMiddleware(promise(), thunk, logger())
export default RedusStore //createStore(reducer, middleware)

