import * as AMCnst from '../Constants/emergencyConstants';

const intialstate = {};

function SavePatientTriageDetailsReducer(state = {
    SavePatientTriageDetails: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.SAVEPATIENTTRIAGE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.SAVEPATIENTTRIAGE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
        case AMCnst.SAVEPATIENTTRIAGE_LIST_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                SavePatientTriageDetails: action.payload
            }

        }
        default: return state;
    }
}
function fetchERPatientTriageDetailsReducer(state = {
    GETERPatientTriageDetails: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GETPATIENTTRIAGE_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GETPATIENTTRIAGE_LIST_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
        case AMCnst.GETPATIENTTRIAGE_LIST_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                GETERPatientTriageDetails: action.payload
            }
        }
        default: return state;
    }
}
function fetchERPatientWiseLOSDetailsReducer(state = {
    GETERPatientWiseLOSDetails: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.GETERPATIENTLOS_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.GETERPATIENTLOS_LIST_FAIL: { return { ...state, fetching: false, error: action.payload,fetched: true } }
        case AMCnst.GETERPATIENTLOS_LIST_SUCCESS: {
            return {
                ...state,error: null,
                fetching: false,
                fetched: true,
                GETERPatientWiseLOSDetails: action.payload
            }
        }
        default: return state;
    }
}
function ERAppFaceSheetReducer(state = {
    ERFaceSheetData: intialstate,
    fetching: false,
    fetched: false,
    error: null
}, action) {
    switch (action.type) {
        case AMCnst.ERFaceSheet_LIST_REQUEST: { return { ...state, fetching: true, fetched: false, error: null } }
        case AMCnst.ERFaceSheet_LIST_FAIL: { return { ...state, fetching: false, error: action.payload, fetched: true } }
        case AMCnst.ERFaceSheet_LIST_SUCCESS: {
            return {
                ...state, error: null,
                fetching: false,
                fetched: true,
                ERFaceSheetData: action.payload
            }
        }
        default: return state;
    }
}
export { SavePatientTriageDetailsReducer, fetchERPatientTriageDetailsReducer, fetchERPatientWiseLOSDetailsReducer,ERAppFaceSheetReducer }

