import React, { Component } from 'react';
import { CoreMasterAPI, CoreBaseAPI } from 'GlobalConfig';
import axios from 'axios';
import * as GlobalConfig from 'GlobalConfig';
import { Modal } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'


function setCookie(name, value, days) {
  var expires = "";
  if (days) {
    var date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/" + "; Secure;Priority=High;";
}


function getCookie(name) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}


function eraseCookie(name) {
  document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}


export class ClearCache extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUpdateUI: false,
      latestVersion: "",
      currentVersion: "",
      latest_app_version_no: "",
      current_app_version_no: "",
      fetchedDate: "",
      fetchedTime: ""
    };
    this.HideModal = this.HideModal.bind(this);
  }


  HideModal() {
    console.log("modal hide clicked");
    this.setState({ showUpdateUI: false })
  }


  componentDidMount() {
    const ms = Date.now();
    fetch("/buildVersion.json" + "?cachecontrol=" + ms)
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const latest_app_version_no = meta.app_version_no
        const currentVersion = getCookie('APP_VERSION') === null ? "" : getCookie('APP_VERSION');
        const current_app_version_no = getCookie('app_version_no') === null ? "" : getCookie('app_version_no');


        this.setState({ latestVersion: latestVersion, latest_app_version_no: latest_app_version_no, current_app_version_no: current_app_version_no, currentVersion: currentVersion, fetchedDate: meta.date, fetchedTime: meta.time });

        if (currentVersion === null ||
          currentVersion === "" ||
          currentVersion === "undefined" ||
          latestVersion === null ||
          latestVersion === "" ||
          latestVersion === "undefined" ||
          current_app_version_no === null ||
          current_app_version_no === "" ||
          current_app_version_no === "undefined" ||
          latest_app_version_no === null ||
          latest_app_version_no === "" ||
          latest_app_version_no === "undefined"
        ) {
          setCookie("APP_VERSION", this.state.latestVersion, 360);
          setCookie("app_version_no", "v."+this.state.latest_app_version_no, 360);
          return;
        }

        if (currentVersion !== latestVersion) {
          this.setState({ showUpdateUI: true });

        } else {

          this.setState({ showUpdateUI: false });
          // this.refreshCacheAndReload();
          //update latest build version
        }
      });

  }


  refreshCacheAndReload = () => {
    console.log("refreshCacheAndReload clicked");
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });

      setCookie("APP_VERSION", this.state.latestVersioapp_version_non, 360);
      setCookie("app_version_no", "v."+this.state.latest_app_version_no, 360);
      this.setState({ showUpdateUI: false });

      //delete browser cache and hard reload
      window.location.reload();
    }

  };



  render() {
    if (this.state.showUpdateUI) {
      // You can render any custom fallback UI
      return (<div>
        {this.props.children}
        <Modal show={this.state.showUpdateUI} onHide={this.HideModal} dialogClassName="modal-550 p-3 shadowbody-modal" >
          <Modal.Header className="pl-3">
            {/* <Modal.Title>Expection Log</Modal.Title> */}
          </Modal.Header>
          <Modal.Body className="p-3" >

            <div className="w-100">
              <div className="d-flex  align-items-center justify-content-between">
                <div className="mr-3">
                  <FontAwesomeIcon icon={faExclamationTriangle} className="text-danger" style={{ fontSize: '50px' }} />
                </div>
                <div>
                  <h5>Version Update</h5>
                  <p>
                    You are running an earlier version of MyHealthcare Enterprise Application, Please update to the <br />latest version.
                    <br />Build Date & Time  {this.state.fetchedDate + " " + this.state.fetchedTime}
                    {/* <br />Version {this.state.latest_app_version_no} */}

                  </p>
                </div>
              </div>
              <div className="w-100 text-right">
                <button className='btn btn-primary' onClick={() => this.refreshCacheAndReload()} >Update Now</button>

              </div>
            </div>


          </Modal.Body>
        </Modal>
      </div>);
    }

    return this.props.children;
  }
}



export default ClearCache;