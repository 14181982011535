import { authenticationService } from "../src/authorize/_services";
import { MasterDataService } from "../src/assets/commonMasterData";
export const currentEnv = require("../package.json").environment;
/*current env name should be----> local,test,uat,dev,mhctest,sandbox-----*/
 export const currentEnv1 = 'LOCAL'

//"http://localhost:6007/API"

export const CoreMasterAPI = process.env[`REACT_APP_CoreMasterAPI_${currentEnv.toUpperCase()}`]
export const CoreBaseAPI = process.env[`REACT_APP_CoreBaseAPI_${currentEnv.toUpperCase()}`]
export const CoreInventoryAPI = process.env[`REACT_APP_CoreInventoryAPI_${currentEnv.toUpperCase()}`]
export const CoreReportAPI =process.env[`REACT_APP_CoreReportAPI_${currentEnv.toUpperCase()}`]
export const SIGNALR_NOTIFICATION_URL = process.env[`REACT_APP_SIGNALR_NOTIFICATION_URL_${currentEnv.toUpperCase()}`]
export const REACT_APP_HOST = process.env[`REACT_APP_REACT_APP_HOST_${currentEnv.toUpperCase()}`]
export const REACT_APP_BASE_URL = process.env[`REACT_APP_REACT_APP_BASE_URL_${currentEnv.toUpperCase()}`]
export const REACT_APP_BASE_URL_IPD = process.env[`REACT_APP_REACT_APP_BASE_URL_IPD_${currentEnv.toUpperCase()}`]
export const OPD_APP_HOST = process.env[`REACT_APP_OPD_APP_HOST_${currentEnv.toUpperCase()}`]
export const IntegrationAPI = process.env[`REACT_APP_INT_APP_HOST_${currentEnv.toUpperCase()}`]
export const QmsAddress = process.env[`REACT_APP_QMS_${currentEnv.toUpperCase()}`];
export const SolrAPI = process.env[`REACT_APP_SolrAPI_${currentEnv.toUpperCase()}`] + currentEnv.toUpperCase();
// STATIC INFORMATIONS FOR DEMOe
export const SHOW_STATIC_INVESTIGATION_RESULT = 'Y';

// IPD EMR URLS (START)
export const REACT_APP_NAME = "OPD_EMR";
export const REACT_APP_QB_MODE = "development";
export const REACT_APP_OPD_APP_TYPE = "mhvcmarketplace";
export const REACT_APP_VONAGE_INCOMING_CALL_SOCKET = "https://call.vonage.myhealthcare.co/";
export const REACT_APP_PUBNUB_PUBLISH_KEY = "pub-c-20533dba-07f2-4e57-9015-784869d00066";
export const REACT_APP_PUBNUB_SUBSCRIBE_KEY = "sub-c-806eacae-f330-11ea-afa2-4287c4b9a283";
export const NOTIFICATION_TYPE = "updateable";
export const NOTIFICATION_DURATION = 5;
// IPD EMR URLS (END)
// >>>>>>> 05aa115551d698a2e7e1220b9d023fdc4ca5763
const userData = authenticationService.currentUserValue;
export let timezoneOffsetMinutes = userData !== null ? (userData.timeZoneMinutes || 0) > 0 ? userData.timeZoneMinutes : 330 : 0;
export let enteredBy = userData !== null ? userData.userId : 0;
export let facilityId = userData !== null ? userData.facilityId : 0;
export let clinicMandatory = userData !== null ? userData.clinicMandatory : "";
export let enterpriseId = userData !== null ? userData.enterpriseId : 0;
export let enterpriseName = userData !== null ? userData.enterpriseName : 0;
export let enteredDate = new Date();
export let modifiedBy = userData !== null ? userData.userId : 0;
export let modifiedDate = new Date();
export let decimalPlace = userData !== null ? userData.decimal_places : 0;
export let enteredByName = userData !== null ? userData.employeeData !== null ? userData.employeeData.employeeName : "" : "";
export let titleName = userData !== null ? userData.employeeData !== null ? userData.employeeData.titleName : "" : "";
export let employeeCode = userData !== null ? userData.employeeData !== null ? userData.employeeData.employee_code : "" : "";
export let facilityCode = userData !== null ? userData.facility_code : "";
export let financialYearId = 1;
export let employeeData = userData !== null ? userData.employeeData : null;
export let employeeImage = userData !== null ? userData.employeeData.employeeImage : "";
export let employeeImageSize = 500000;   // 500kb
export let employeeSignatureSize = 100000;   //100kb
export let facilityName = userData !== null ? userData.facilityName : "";
export let facilityImage = userData !== null ? userData.facilityImage : "";
export let faIntegrationCode = userData !== null ? userData.faIntegrationCode : "";
export const facilityAddress = userData !== null ? userData.facilityAddress : "";
export const contactNo = userData !== null ? userData.contactNo : "";
export const emergencyContactNo = userData !== null ? userData.emergencyContactNo : "";
export let roleName = userData !== null ? userData.roleName : "";
export let roleId = userData !== null ? userData.roleId : 0;
export let defaultLandingModulePage = userData !== null ? userData.defaultLandingModulePage : "";
export let facilityStateGSTCode = userData !== null ? userData.stateGSTCode !== null ? userData.stateGSTCode : "06" : "06";
export let GSTApplicable = true;
export let facilityGSTINNo = userData !== null ? userData.facility_gst_number : "";
export let userSubGroupCode = userData !== null ? userData.employeeData !== null ? userData.employeeData.userSubGroupCode : "" : "";
export let cbuFacilityId = userData !== null ? userData.cbuFacilityId !== null ? userData.cbuFacilityId : 1 : 1;
export let otDefaultSlot = 60;
export let defaultRegPageId = 9;
export let emptyMessageInAgGrid = '<span class="ag-overlay-loading-center d-none"></span>';
export let emptyMessageForHTMLTable = "";
export let LoadingTemplate = "<span class='ag-overlay-loading-center border-0'><img src='https://myhealthcare-his.s3.ap-south-1.amazonaws.com/Test/Assets/images/loader.gif' width='70' /></span>";
export let Loader = "https://myhealthcare-his.s3.ap-south-1.amazonaws.com/Test/Assets/images/loader.gif"
export let userGroup = userData !== null ? userData.employeeData !== null ? userData.employeeData.userGroup : "" : "";
export let userGroupName = userData !== null ? userData.employeeData !== null ? userData.employeeData.userGroupName : "" : "";
export const statusTypeList = MasterDataService.currentStatusType.subscribe();
export const paramterSteupList = MasterDataService.parameterSetupData.subscribe();
export const statusTypeListTemp = MasterDataService.dataService.getStatusTypeData();
export const paramterSteupListTemp = MasterDataService.dataService.getParameterSetupData();
export const toastDelayTime = 2500;
export const userLANIP = userData !== null ? userData.userLANIP : '';
export const userWANIP = userData !== null ? userData.userWANIP : '';
export const userOSName = userData !== null ? userData.userOSName : '';
export const userBrowserName = userData !== null ? userData.userBrowserName : '';
export const integrationid = userData !== null ? userData.integrationid : '0';
export const networkPrinterAddress = "http://10.10.95.175/api/printPdf";//http://localhost:3007/api/printPdf
export let compLogo = <img src={userData !== null ? userData.facilityImage : ""} alt="" style={{ maxWidth: '100%' }} />
export let UserData = userData;
export let printAccountDetail = userData !== null ? userData.printAccountDetail : "";
export let cin_number = userData !== null ? userData.cin_number : "";
export let facility_legal_name = userData !== null ? userData.facility_legal_name : "";
export const sessionTimeoutInMinutes = userData !== null ? userData.sessionTimeoutInMinutes : 0;
//export const QmsAddress ='https://mhcdev.api.myhealthcare.co/api/';
